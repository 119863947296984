import CloseIcon from '@public/vectors/close.svg';

import cx from 'classnames';

import { IF, Box, Text, IconButton } from '@ui';

const variants = {
  success: 'bg-green-800',
  error: 'bg-red-600',
  'outline-error': 'bg-transparent border-p-1 border-red-600',
  info: 'bg-blue-600'
};

const MessageBox = ({ children, variant, onClose, className }) => {
  if (!children) {
    return null;
  }

  return (
    <Box
      className={cx(
        'p-4 rounded-md flex items-start relative',
        className,
        variants[variant],
        variant
      )}
    >
      <IF when={typeof children === 'string'}>
        <Box className="flex-1 truncate">
          <Text>{children}</Text>
        </Box>
      </IF>
      {children}
      {onClose && (
        <Box className="absolute right-4 top-3">
          <IconButton type="button" onClick={onClose} variant="outline" size="sm" isRound>
            <CloseIcon width="0.9rem" height="0.9rem" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

MessageBox.defaultProps = {
  variant: 'info'
};

export default MessageBox;
