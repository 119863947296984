import { CHARACTERS } from '@constants/app';

import {
  isAlbum,
  isArtist,
  isPodcast,
  isPlaylist,
  isPlaylistCategory,
  isSpecial,
  isLiveStream,
  isPodcastShow,
  isAd,
  isLink,
  isLikedSongs,
  isEvent,
  isUser,
  isStoryVideo
} from '@functions/is';
import { getAlbumName } from '@functions/get';

export const getTitle = params => {
  if (params?.item?.id) {
    if (params.subtitle) {
      if (isPlaylist(params)) {
        return params.title;
      }

      return params.subtitle;
    }
  }

  const data = params.item?.id ? params.item : params;

  if (isAlbum(data)) {
    return getAlbumName(data);
  }

  if (isLiveStream(data)) {
    return data.title;
  }

  if (isPodcast(data)) {
    return data.title;
  }

  if (isAd(data)) {
    return data.artist;
  }

  if (isEvent(data)) {
    return data.title;
  }

  if (isUser(data)) {
    return data.username;
  }

  if (isStoryVideo(data)) {
    return data.title;
  }

  return data.song || data.name || data.title || data.date;
};

export const getSubTitle = params => {
  if (params?.item?.id) {
    if (params.title) {
      if (isPlaylist(params)) {
        return params.subtitle;
      }

      return params.title;
    }
  }

  const data = params.item?.id ? params.item : params;

  if (
    isSpecial(data) ||
    isLikedSongs(data) ||
    isArtist(data) ||
    isPlaylistCategory(data) ||
    isLiveStream(data) ||
    isPodcastShow(data) ||
    isLink(data) ||
    isStoryVideo(data)
  ) {
    return '';
  }

  if (isPlaylist(data)) {
    if (params.song !== getTitle(params)) {
      return params.song;
    }
  }

  if (isAlbum(data)) {
    if (data.album && data.album.artist) {
      return data.album.artist;
    }
  }

  if (isPodcast(data)) {
    return data.date || data.podcast_artist;
  }

  if (isAd(data)) {
    return data.song;
  }

  if (isEvent(data)) {
    return data.city;
  }

  if (isUser(data)) {
    return data.display_name;
  }

  return data.artist || data.title || data.name;
};

export const getImageAlt = data => {
  const title = getTitle(data);
  const subTitle = getSubTitle(data);

  if (title && subTitle) {
    return `${title} ${CHARACTERS.HEAD} ${subTitle}`;
  }

  return title || subTitle;
};
