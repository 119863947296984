import { EVENT_NAMES } from '@constants/machines/search-history';

import { useMemo, useCallback } from 'react';

import { useApp } from '@contexts';
import { usePartial } from '@state/hooks';
import { getSearchHistoryService } from '@machines/app/selectors';
import { getItems } from '@machines/app/search-history/selectors';

export const useSearchHistoryRef = () => {
  const { service } = useApp();
  const ref = usePartial(service, getSearchHistoryService);

  return ref;
};

export const useSearchHistory = () => {
  const ref = useSearchHistoryRef();
  const value = usePartial(ref, getItems);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useHasSearchHistory = () => {
  const value = useSearchHistory();

  return useMemo(() => {
    return value?.length > 0;
  }, [value?.value]);
};

export const useSearchHelpers = () => {
  const service = useSearchHistoryRef();

  const toggleFocus = useCallback(value => {
    service.send(EVENT_NAMES.SEARCH.TOGGLE_INPUT_FOCUS, { value });
  }, []);

  return useMemo(() => {
    return {
      toggleFocus
    };
  }, [service]);
};

export const useSearchHistoryHelpers = () => {
  const service = useSearchHistoryRef();

  const add = useCallback(data => {
    service.send(EVENT_NAMES.SEARCH_HISTORY_STORAGE.ADD, { data });
  }, []);

  const remove = useCallback(data => {
    service.send(EVENT_NAMES.SEARCH_HISTORY_STORAGE.REMOVE, { data });
  }, []);

  const searchHit = useCallback(data => {
    service.send(EVENT_NAMES.SEARCH_HISTORY_STORAGE.SEARCH_HIT, { data });
  }, []);

  const clearAll = useCallback(data => {
    service.send(EVENT_NAMES.SEARCH_HISTORY_STORAGE.CLEAR, { data });
  }, []);

  return useMemo(() => {
    return {
      add,
      remove,
      clearAll,
      searchHit
    };
  }, [service]);
};
