import { EVENT_NAMES } from '@constants/machines/search-history';
import { APP_SETTINGS } from '@constants';
import { EVENT_NAMES as APP_SETTINGS_EVENT_NAMES } from '@constants/machines/app-settings';
import { getID, getFailedSearchHitID, getSearchHistoryItem } from '@functions/get';
import { isUser } from '@functions/is';
import orderBy from 'lodash/orderBy';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';

const { HISTORY, FAILED_SEARCH_HIT } = APP_SETTINGS.SEARCH;

export const searchHistoryStorageMachine = () => {
  return (sendParent, receiver) => {
    // METHODS
    const updateParent = () => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);
      const searchHistoryItems = getBrowserSettings(HISTORY)(appSettingsService.state);

      sendParent({
        type: EVENT_NAMES.SEARCH_HISTORY.SET_ITEMS,
        items: searchHistoryItems ? Object.values(searchHistoryItems) : []
      });
    };

    const remove = ({ data }) => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);

      const subKey = getID(data);

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: HISTORY,
        subKey
      });

      updateParent();
    };

    const removeOldItem = () => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);
      const searchHistoryValue = getBrowserSettings(HISTORY)(appSettingsService.state);
      const searchHistoryItems = searchHistoryValue ? Object.values(searchHistoryValue) : [];

      const items = orderBy(searchHistoryItems, ['dateAdded'], ['desc']);
      const item = items[items.length - 1];

      remove({ data: item });
    };

    const add = ({ data }) => {
      // ignore user profiles
      if (isUser(data)) {
        return;
      }

      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);

      const value = getSearchHistoryItem(data);
      const subKey = getID(value);

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.SET,
        key: HISTORY,
        subKey,
        value
      });

      const searchHistoryValue = getBrowserSettings(HISTORY)(appSettingsService.state);
      const searchHistoryItems = searchHistoryValue ? Object.values(searchHistoryValue) : [];

      if (searchHistoryItems.length >= 21) {
        removeOldItem();
      }

      updateParent();
    };

    const addFailedSearchHit = ({ data }) => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);
      const subKey = getFailedSearchHitID(data);

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.SET,
        key: FAILED_SEARCH_HIT,
        value: data,
        subKey
      });
    };

    const clearAll = () => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: HISTORY
      });
    };

    const destroy = () => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: HISTORY
      });
    };

    const onStatusChanged = async () => {
      const appService = getAppService();
      const appSettingsService = getAppSettingsService(appService.state);
      const failedSearchHitValue = getBrowserSettings(FAILED_SEARCH_HIT)(appSettingsService.state);
      const failedSearchHitItems = failedSearchHitValue ? Object.values(failedSearchHitValue) : [];

      // send failed items to parent again
      sendParent({
        type: EVENT_NAMES.SEARCH_HISTORY_STORAGE.SEARCH_HIT,
        data: failedSearchHitItems ? Object.values(failedSearchHitItems) : []
      });

      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: FAILED_SEARCH_HIT
      });
    };

    const mount = () => {
      updateParent();

      window.addEventListener('online', onStatusChanged);
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case EVENT_NAMES.SEARCH_HISTORY_STORAGE.ADD:
          add(event);
          break;

        case EVENT_NAMES.SEARCH_HISTORY_STORAGE.ADD_FAILED_SEARCH_HIT:
          addFailedSearchHit(event);
          break;

        case EVENT_NAMES.SEARCH_HISTORY_STORAGE.REMOVE:
          remove(event);
          break;

        case EVENT_NAMES.SEARCH_HISTORY_STORAGE.CLEAR:
          clearAll();
          break;

        case EVENT_NAMES.SEARCH_HISTORY_STORAGE.DESTROY:
          destroy();
          break;

        default:
          break;
      }
    });

    return () => {
      window.removeEventListener('online', onStatusChanged);
    };
  };
};
