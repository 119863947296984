import { isWeb, isDev } from '@functions/env';

export const DEFAULT_SERVER_URL = (() => {
  if (isDev()) {
    // return 'http://localhost:9999';
    return 'http://192.168.1.239:1135';
  }

  if (isWeb()) {
    return `${process.env.SERVE_URL}`;
  }

  // API URL
  return process.env.DEFAULT_API_ENDPOINT;
})();
// export const DEFAULT_SERVER_URL = 'https://api.rjdesktopapp-proxy.com/';
// export const DEFAULT_SERVER_URL = 'https://rjtvapp.app';
export const DEFAULT_RJ_LIVE_STREAM =
  'https://stream.rjtv.stream/live/smil:rjtv.smil/playlist.m3u8';
export const SERVER_CONFIG_URL =
  'https://s3.amazonaws.com/assets.radiojavan.com/app/config/desktop/config-a.json';
export const APP_CONFIG_URL = '/app_config';
export const LOAD_ARTIST_URL = '/artist';
export const LOAD_ARTIST_SUGGESTION_URL = '/artists_recommended';
export const FOLLOW_ARTIST_URL = '/artist_follow';
export const SPECIAL_URL = '/special_items';
export const UN_FOLLOW_ARTIST_URL = '/artist_unfollow';
export const FOLLOWED_ARTISTS_URL = '/artists_followed_list';
export const LOAD_HOME_URL = '/home_items';
export const SEARCH_URL = '/search';
export const SEARCH_HIT = '/search_hit';
export const SEARCH_ARTISTS = '/search_artists';
export const PLAYLISTS_URL = '/playlists_dash';
export const MP3_PLAYLIST_WITH_ITEMS_URL = '/mp3_playlist_with_items';
export const VIDEO_PLAYLIST_WITH_ITEMS_URL = '/video_playlist_with_items';
export const PHOTOS_URL = '/photos';
export const PHOTOS_ALBUM_URL = '/photos_album';
export const BROWSE_URL = '/browse_items';
export const EVENTS_URL = '/events';
export const TAGGED_EVENTS_URL = '/events_tagged';
export const EVENT_URL = '/event';
export const MP3_VOTE_URL = '/mp3_vote';
export const VIDEO_VOTE_URL = '/video_vote';
export const PODCAST_SHOW_URL = '/podcast_show';
export const PODCAST_VOTE_URL = '/podcast_vote';
export const PODCAST_FOLLOW_URL = '/podcast_follow';
export const PODCAST_UN_FOLLOW_URL = '/podcast_unfollow';
export const FOLLOWED_PODCASTS = '/podcasts_followed_list';
export const LOGIN_URL = '/login';
export const SIGNUP_APP_URL = '/signup_mobile';
export const SIGNUP_URL = '/signup';
export const FORGOT_PASS_URL = '/forgot';
export const UPDATE_PASS_URL = '/user_password_update';
export const PROFILE_URL = '/user_profile';
export const DE_ACTIVE_PROFILE_URL = '/deactivate';
export const UPDATE_PROFILE_URL = '/user_profile_update';
export const USER_NOTIFICATIONS_URL = '/user_notifications';
export const UPDATE_USER_NOTIFICATIONS_URL = '/user_notifications_update';
export const CHECK_USER_SUBSCRIPTION = '/user_subscription';
export const SUBSCRIPTION_MANAGE_URL = '/subscription_manage';
export const LOGOUT_URL = '/logout';
export const LOCAL_LOGOUT_URL = '/logout';
export const FOLLOW_USER_URL = '/user_follow';
export const UN_FOLLOW_USER_URL = '/user_unfollow';
export const USER_FOLLOWING_LIST = '/user_following_list';
export const USER_FOLLOWERS_LIST = '/user_followers_list';
export const CREATE_NEW_PLAYLIST_MP3_URL = '/mp3_playlist_add';
export const REMOVE_PLAYLIST_MP3_URL = '/mp3_playlist_remove';
export const REMOVE_ITEM_PLAYLIST_MP3_URL = '/mp3_playlist_item_remove';
export const RENAME_PLAYLIST_MP3_URL = '/mp3_playlist_rename';
export const REORDER_PLAYLIST_ITEMS_MP3_URL = '/mp3_playlist_reorder';
export const FOLLOW_PLAYLIST_MP3_URL = '/mp3_playlist_follow';
export const UN_FOLLOW_PLAYLIST_MP3_URL = '/mp3_playlist_unfollow';
export const MAKE_PLAYLIST_PRIVATE_MP3_URL = '/mp3_playlist_make_private';
export const MAKE_PLAYLIST_PUBLIC_MP3_URL = '/mp3_playlist_make_public';
export const PLAYLIST_SUGGESTION_MP3 = '/mp3_playlist_related';
export const HIDE_PLAYLIST_SONG_URL = '/mp3_playlist_hide_song';
export const UNHIDE_PLAYLIST_SONG_URL = '/mp3_playlist_unhide_song';
export const CREATE_NEW_PLAYLIST_VIDEO_URL = '/video_playlist_add';
export const REMOVE_PLAYLIST_VIDEO_URL = '/video_playlist_remove';
export const REMOVE_ITEM_PLAYLIST_VIDEO_URL = '/video_playlist_item_remove';
export const RENAME_PLAYLIST_VIDEO_URL = '/video_playlist_rename';
export const REORDER_PLAYLIST_ITEMS_VIDEO_URL = '/video_playlist_reorder';
export const MP3_PLAYLIST_COVER_URL = '/mp3_playlist_cover';
export const STORY = '/selfie';
export const STORY_VOTE = '/selfie_vote';
export const STORY_VIDEO_VOTE = '/story_vote';
export const STORY_VIDEO = '/story';
export const MP3_LIKED = '/mp3s_liked';
export const MY_MUSIC_GET_LIBRARY_URL = '/library';
export const MY_MUSIC_GET_LIBRARY_URL_CLEAR_ALL = '/library_remove_all_items';
export const MY_MUSIC_SAVE_TO_LIBRARY_URL = '/library_add_items';
export const MY_MUSIC_REMOVE_FROM_LIBRARY_URL = '/library_remove_items';
export const USER_PLAYS = '/user_plays';
export const DEEP_LINK_URL = '/deeplink';
export const AD = {
  CHECK_BREAK: '/check_ad_break',
  PLAYED: '/ad_break_hit',
  CLICKED: '/ad_break_click'
};
export const DOWNLOAD_APP_URL = '/api/app/download';
export const DOWNLOAD_MAC_OS_APP_URL = `${DOWNLOAD_APP_URL}?macOS=true`;
export const DOWNLOAD_WINDOWS_APP_URL = `${DOWNLOAD_APP_URL}?windows=true`;
export const APP_VERSION_URL = '/app/version';
