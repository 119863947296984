import Router from 'next/router';
import { assign } from 'xstate';

export const redirectToRequireAuth = () => {
  if (Router.asPath) {
    Router.push(`/auth?r=${encodeURIComponent(Router.asPath)}`);
    return;
  }

  Router.push('/auth');
};

export const redirectToAuth = ({ requireAuthentication }) => {
  if (requireAuthentication) {
    Router.push(`/auth/login?r=${encodeURIComponent(Router.asPath)}`);

    return;
  }

  Router.push('/auth/login');
};

export const redirectToApp = () => {
  const { r: redirectURL } = Router.query || {};

  if (redirectURL) {
    Router.replace(redirectURL);
    return;
  }

  Router.replace('/');
};

export const redirectToAppInAuth = () => {
  if (Router.asPath.includes('auth')) {
    redirectToApp();
  }
};

export const updateRequireAuthenticationState = assign({
  requireAuthentication(_, event) {
    return event.value;
  }
});
