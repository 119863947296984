import React, { useContext } from 'react';
import { useInterpret } from '@xstate/react';
import { dragDropMachine } from '@machines/app/drag-drop/drag-drop-machine';

export const DragDropContext = React.createContext();

export const DragDropConfigProvider = ({ children }) => {
  const service = useInterpret(dragDropMachine);

  // React.useEffect(() => {
  //   service.onTransition((...args) => {
  //     console.log('...args...args...args', ...args);
  //   });
  // }, []);

  return (
    <DragDropContext.Provider
      value={{
        service
      }}
    >
      {children}
    </DragDropContext.Provider>
  );
};

export const useDragDrop = () => useContext(DragDropContext);
