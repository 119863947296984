import React, { useMemo } from 'react';
import { useIsTouch } from '@hooks/state/useApp';
import { Virtuoso } from 'react-virtuoso';

// const scrollSeekConfiguration = {
//   enter: velocity => Math.abs(velocity) > 300,
//   exit: velocity => Math.abs(velocity) < 30,
//   change: (_, range) => console.log({ range })
// };

export default function List({ data, elementId, renderRow, itemProps, listId, style }) {
  const isTouch = useIsTouch();

  const element = useMemo(() => {
    if (isTouch) {
      return undefined;
    }

    if (typeof window === 'object') {
      return document.getElementById(elementId);
    }

    return null;
  }, [elementId]);

  return (
    <Virtuoso
      useWindowScroll={isTouch}
      style={style}
      totalCount={data.length}
      customScrollParent={element}
      itemContent={index =>
        renderRow({
          item: data[index],
          index,
          items: data,
          itemProps,
          listId
        })
      }
    />
  );
}

List.defaultProps = {
  data: [],
  itemProps: {},
  overscanRowCount: 10,
  rowAsFunction: false,
  elementId: 'main'
};
