/* eslint-disable prettier/prettier */
import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/playlist';
import { APP_SETTINGS } from '@constants';

import { createMachine, send } from 'xstate';
import { getAppService } from '@state/services/app-service';
import { getAppSettingsService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';

import {
  createPlaylist,
  playlistCover,
  playlistPrivatePublic,
  removePlaylist,
  renamePlaylist
} from '@api/playlists';
import { showNotification } from '@machines/actions';

import { wasAddingToPlaylist } from './matchers';

import * as actions from './actions';
import * as guards from './guards';

const { PLAYLIST } = APP_SETTINGS;

export const playlistMachine = createMachine(
  {
    id: MACHINE_NAMES.PLAYLIST,
    initial: STATES.PLAYLIST.IDLE,
    context: {
      selectedFile: null,
      data: [],
      formData: null,
      playlists: {
        mp3s: [],
        videos: []
      },
      selectedPlaylist: null
    },
    on: {
      [EVENT_NAMES.PLAYLIST.UPDATE_PLAYLISTS]: {
        actions: ['setPlaylists']
      },
      [EVENT_NAMES.PLAYLIST.CLOSE]: {
        target: STATES.PLAYLIST.IDLE
      }
    },
    states: {
      [STATES.PLAYLIST.IDLE]: {
        entry: ['resetData', 'resetSelectedPlaylist', 'resetFormData', 'resetSelectedFile'],
        on: {
          [EVENT_NAMES.PLAYLIST.ADD_TO_PLAYLIST.INDEX]: [
            {
              target: STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX,
              actions: ['setData', 'setFormDataDefaultValue'],
              cond: 'hasPlaylists'
            },
            {
              target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`,
              actions: ['setData', 'setFormDataDefaultValue']
            }
          ],
          [EVENT_NAMES.PLAYLIST.REMOVE_PLAYLIST.INDEX]: {
            target: STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX,
            actions: ['setSelectedPlaylist']
          },
          [EVENT_NAMES.PLAYLIST.EDIT_PLAYLIST.INDEX]: {
            target: STATES.PLAYLIST.EDIT_PLAYLIST.INDEX,
            actions: ['setSelectedPlaylist', 'setFormDataForEditPlaylist']
          }
        }
      },

      [STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX]: {
        id: STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX,
        initial: STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.INDEX,
        states: {
          [STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.INDEX]: {
            id: STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.INDEX,
            initial: STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.IDLE,
            states: {
              [STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.IDLE]: {
                after: {
                  10: {
                    actions: ['resetSelectedPlaylist']
                  },
                  3_000: [
                    {
                      target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`,
                      cond: 'hasNotAnyPlaylists'
                    }
                  ]
                },

                on: {
                  [EVENT_NAMES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.CREATE_NEW_PLAYLIST]: {
                    target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`
                  },
                  [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: [
                    {
                      target: STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.SUBMITTING,
                      actions: ['setSelectedPlaylist']
                    }
                  ],
                  [EVENT_NAMES.PLAYLIST.REMOVE_PLAYLIST.INDEX]: {
                    target: `#${MACHINE_NAMES.PLAYLIST}.${STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX}`,
                    actions: ['setSelectedPlaylist']
                  },
                  [EVENT_NAMES.PLAYLIST.EDIT_PLAYLIST.INDEX]: {
                    target: `#${MACHINE_NAMES.PLAYLIST}.${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                    actions: ['setSelectedPlaylist', 'setFormDataForEditPlaylist']
                  }
                }
              },

              [STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.SUBMITTING]: {
                invoke: {
                  id: STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.SUBMITTING,
                  src({ data }, event) {
                    const appService = getAppService();
                    const appSettingsService = getAppSettingsService(appService.state);
                    const addToTopOfPlaylist = getBrowserSettings(PLAYLIST.ADD_TO_TOP_OF_PLAYLIST)(
                      appSettingsService.state
                    );

                    const requests = data.map(item => {
                      return createPlaylist({
                        id: event.value.id,
                        type: item.type,
                        [item.type]: item.id,
                        start: addToTopOfPlaylist ? '1' : '0'
                      });
                    });

                    return Promise.all(requests);
                  },
                  onDone: {
                    target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                    actions: ['loadPlaylist', 'showNotificationItemsAdded']
                  },
                  onError: {
                    target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              }
            }
          },

          [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX]: {
            id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX,
            initial: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX,

            states: {
              [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX]: {
                id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX,
                initial: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.IDLE,
                states: {
                  [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.IDLE]: {
                    on: {
                      [EVENT_NAMES.PLAYLIST.BACK]: {
                        target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`
                      },
                      [EVENT_NAMES.PLAYLIST.FORM_DATA.ON_CHANGE]: {
                        actions: ['setFormData']
                      },
                      [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: {
                        target:
                          STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.INDEX
                      },
                      [EVENT_NAMES.PLAYLIST.SELECT_FILE]: {
                        target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.CROPPING_PHOTO.INDEX}`,
                        actions: ['setSelectedFile']
                      }
                    }
                  },

                  [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.INDEX]: {
                    id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.INDEX,
                    initial:
                      STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.DATA,

                    states: {
                      [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.DATA]: {
                        invoke: {
                          id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                            .DATA,
                          src({ data }, event) {
                            const appService = getAppService();
                            const appSettingsService = getAppSettingsService(appService.state);
                            const addToTopOfPlaylist = getBrowserSettings(
                              PLAYLIST.ADD_TO_TOP_OF_PLAYLIST
                            )(appSettingsService.state);
                            const item = Array.isArray(data) ? data[0] : data;

                            return createPlaylist({
                              name: event.value.name,
                              type: item.type,
                              [item.type]: item.id,
                              start: addToTopOfPlaylist ? '1' : '0'
                            });
                          },
                          onDone: [
                            {
                              target:
                                STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                                  .ADDING_ITEMS,
                              cond: ({ data }) => Array.isArray(data) && data.length > 1
                            },
                            {
                              target:
                                STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                                  .PHOTO,
                              cond: ({ selectedFile }) => selectedFile
                            },
                            {
                              target:
                                STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                                  .PRIVATE_PUBLIC,
                              cond: ({ formData }) => formData?.private
                            },
                            {
                              target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                              actions: [
                                'resetFormData',
                                'showNotificationPlaylistCreatedAndItemsAdded'
                              ],
                              internal: true
                            }
                          ],
                          onError: {
                            target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX}`,
                            actions: [showNotification({ type: 'error' })]
                          }
                        }
                      },

                      [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                        .ADDING_ITEMS]: {
                        invoke: {
                          id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                            .ADDING_ITEMS,
                          src({ data }, event) {
                            // already added the first item
                            const requests = data.slice(1, data.length).map(item => {
                              return createPlaylist({
                                id: event.data.playlist,
                                type: item.type,
                                [item.type]: item.id
                              });
                            });

                            return Promise.all(requests);
                          },
                          onDone: [
                            {
                              target:
                                STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                                  .PHOTO,
                              cond: ({ selectedFile }) => selectedFile
                            },
                            {
                              target:
                                STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                                  .PRIVATE_PUBLIC,
                              cond: ({ formData }) => formData?.private
                            },
                            {
                              target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                              actions: [
                                'resetFormData',
                                'showNotificationPlaylistCreatedAndItemsAdded'
                              ],
                              internal: true
                            }
                          ],
                          onError: {
                            target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX}`,
                            actions: [showNotification({ type: 'error' })]
                          }
                        }
                      },

                      [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.PHOTO]:
                        {
                          invoke: {
                            id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                              .PHOTO,
                            src({ selectedFile }, event) {
                              const id = Array.isArray(event.data)
                                ? event.data[0].playlist
                                : event.data.playlist;

                              return playlistCover({
                                photo: selectedFile,
                                id
                              });
                            },
                            onDone: [
                              {
                                target:
                                  STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING
                                    .SUBMITTING.PRIVATE_PUBLIC,
                                cond: ({ formData }) => formData?.private
                              },
                              {
                                target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                                actions: [
                                  'resetFormData',
                                  'showNotificationPlaylistCreatedAndItemsAdded'
                                ],
                                internal: true
                              }
                            ],
                            onError: {
                              target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                              actions: [showNotification({ type: 'error' })],
                              internal: true
                            }
                          }
                        },

                      [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                        .PRIVATE_PUBLIC]: {
                        invoke: {
                          id: STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING
                            .PRIVATE_PUBLIC,
                          src({ data, formData }, event) {
                            return playlistPrivatePublic({
                              private: formData.private,
                              type: data?.[0]?.type,
                              id: event?.data?.playlist
                            });
                          },
                          onDone: {
                            target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                            actions: [
                              'resetFormData',
                              'showNotificationPlaylistCreatedAndItemsAdded'
                            ],
                            internal: true
                          },
                          onError: {
                            target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                            actions: [showNotification({ type: 'error' })],
                            internal: true
                          }
                        }
                      }
                    }
                  }
                }
              },

              [STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.CROPPING_PHOTO.INDEX]: {
                on: {
                  [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: {
                    target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`,
                    actions: ['setSelectedFile']
                  },
                  [EVENT_NAMES.PLAYLIST.BACK]: {
                    target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`,
                    actions: ['resetSelectedFile']
                  }
                }
              }
            }
          }
        }
      },

      [STATES.PLAYLIST.EDIT_PLAYLIST.INDEX]: {
        id: STATES.PLAYLIST.EDIT_PLAYLIST.INDEX,
        initial: STATES.PLAYLIST.EDIT_PLAYLIST.IDLE,
        entry: ['setShowBack'],
        exit: ['resetShowBack'],
        states: {
          [STATES.PLAYLIST.EDIT_PLAYLIST.IDLE]: {
            on: {
              [EVENT_NAMES.PLAYLIST.BACK]: {
                target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`
              },
              [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: {
                target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.INDEX
              },
              [EVENT_NAMES.PLAYLIST.FORM_DATA.ON_CHANGE]: {
                actions: ['setFormData']
              },
              [EVENT_NAMES.PLAYLIST.SELECT_FILE]: {
                target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}.${STATES.PLAYLIST.EDIT_PLAYLIST.CROPPING_PHOTO}`,
                actions: ['setSelectedFile']
              }
            }
          },
          [STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.INDEX]: {
            id: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.INDEX,
            initial: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.VALIDATING,
            states: {
              [STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.VALIDATING]: {
                always: [
                  {
                    target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.RENAMING,
                    cond: ({ selectedPlaylist, formData }) => {
                      return selectedPlaylist.title !== formData.name;
                    }
                  },
                  {
                    target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PHOTO,
                    cond: 'hasSelectedDifferentFile'
                  },
                  {
                    target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PUBLIC_PRIVATE,
                    cond: 'publicPrivateHasChanged'
                  },
                  {
                    target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`
                  }
                ]
              },
              [STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.RENAMING]: {
                invoke: {
                  src({ selectedPlaylist }, event) {
                    return renamePlaylist({
                      name: event.value.name,
                      type: selectedPlaylist.subtype,
                      id: selectedPlaylist.id
                    });
                  },
                  onDone: [
                    {
                      target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PHOTO,
                      cond: 'hasSelectedDifferentFile'
                    },
                    {
                      target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PUBLIC_PRIVATE,
                      cond: 'publicPrivateHasChanged'
                    },
                    {
                      target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                      actions: ['loadPlaylists', 'loadPlaylist'],
                      cond: ({ showBack }) => {
                        return showBack;
                      }
                    },
                    {
                      target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                      actions: [
                        'loadPlaylists',
                        'loadPlaylist',
                        showNotification({ type: 'success', messageName: 'playlist-updated' }),
                        send({ type: EVENT_NAMES.PLAYLIST.CLOSE })
                      ]
                    }
                  ],
                  onError: {
                    target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              },

              [STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PHOTO]: {
                invoke: {
                  src({ selectedFile, selectedPlaylist }) {
                    const { id } = selectedPlaylist;

                    return playlistCover({
                      photo: selectedFile,
                      id
                    });
                  },
                  onDone: [
                    {
                      target: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PUBLIC_PRIVATE,
                      cond: 'publicPrivateHasChanged'
                    },
                    {
                      target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                      actions: ['loadPlaylists', 'loadPlaylist'],
                      cond: ({ showBack }) => {
                        return showBack;
                      }
                    },
                    {
                      target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                      actions: [
                        'loadPlaylists',
                        'loadPlaylist',
                        showNotification({ type: 'success', messageName: 'playlist-updated' }),
                        send({ type: EVENT_NAMES.PLAYLIST.CLOSE })
                      ]
                    }
                  ],
                  onError: {
                    target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              },

              [STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PUBLIC_PRIVATE]: {
                invoke: {
                  id: STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.PUBLIC_PRIVATE,
                  src({ selectedPlaylist, formData }) {
                    return playlistPrivatePublic({
                      private: formData.private,
                      type: selectedPlaylist.subtype,
                      id: selectedPlaylist.id
                    });
                  },
                  onDone: {
                    target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                    actions: [
                      'loadPlaylists',
                      'loadPlaylist',
                      showNotification({ type: 'success', messageName: 'playlist-updated' }),
                      send({ type: EVENT_NAMES.PLAYLIST.CLOSE })
                    ]
                  },
                  onError: {
                    target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              }
            }
          },

          [STATES.PLAYLIST.EDIT_PLAYLIST.CROPPING_PHOTO]: {
            on: {
              [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: {
                target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                actions: ['setSelectedFile']
              },
              [EVENT_NAMES.PLAYLIST.BACK]: {
                target: `#${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}`,
                actions: ['resetSelectedFile']
              }
            }
          }
        }
      },

      [STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX]: {
        id: STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX,
        entry: ['setShowBack'],
        exit: ['resetShowBack'],
        initial: STATES.PLAYLIST.REMOVE_PLAYLIST.IDLE,
        states: {
          [STATES.PLAYLIST.REMOVE_PLAYLIST.IDLE]: {
            on: {
              [EVENT_NAMES.PLAYLIST.BACK]: [
                {
                  target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                  cond: (_, __, { state }) => {
                    return wasAddingToPlaylist(state);
                  }
                }
              ],
              [EVENT_NAMES.PLAYLIST.ON_SUBMIT]: {
                target: STATES.PLAYLIST.REMOVE_PLAYLIST.SUBMITTING
              }
            }
          },
          [STATES.PLAYLIST.REMOVE_PLAYLIST.SUBMITTING]: {
            invoke: {
              src({ selectedPlaylist }) {
                return removePlaylist(selectedPlaylist);
              },
              onDone: [
                {
                  target: `#${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}`,
                  actions: [showNotification({ type: 'success', messageName: 'playlist-removed' })],
                  cond: ({ showBack }) => {
                    return showBack;
                  }
                },
                {
                  target: `#${MACHINE_NAMES.PLAYLIST}.${STATES.PLAYLIST.IDLE}`,
                  actions: [
                    'resetData',
                    'resetSelectedPlaylist',
                    'loadPlaylists',
                    showNotification({ type: 'success', messageName: 'playlist-removed' })
                  ]
                }
              ],
              onError: {
                target: `#${STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX}`,
                actions: [showNotification({ type: 'error' })]
              }
            }
          }
        }
      }
    }
  },
  {
    actions,
    guards
  }
);
