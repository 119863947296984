/**
 * Media Query Min Width
 */
export const MIN_WIDTH = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XL_2: 1536,
  XL_3: 1792,
  XL_4: 2048,
  XL_5: 2304,
  XL_6: 2560,
  XL_7: 2816,
  XL_8: 3072
};
