import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/app';
import { EVENT_NAMES as APP_SETTINGS_EVENT_NAMES } from '@constants/machines/app-settings';
import { EVENT_NAMES as LIBRARY_EVENT_NAMES } from '@constants/machines/library';
import { EVENT_NAMES as NOW_PLAYING_EVENT_NAMES } from '@constants/machines/now-playing';
import { EVENT_NAMES as SEARCH_HISTORY_EVENT_NAMES } from '@constants/machines/search-history';

import { isNative } from '@functions/env';

import { createMachine, assign, spawn, send, actions as xstateActions } from 'xstate';
import { configMachine } from '@machines/app/config/config-machine';
import { authMachine } from '@machines/auth/auth-machine';
import { appSettingsMachine } from '@machines/app/app-settings/app-settings-machine';
import { libraryMachine } from '@machines/library/library-machine';
import { nowPlayingMachine } from '@machines/now-playing/now-playing-machine';
import { searchHistoryMachine } from '@machines/app/search-history/search-history-machine';
import { autoUpdateMachine } from '@machines/auto-update/auto-update-machine';

import * as actions from './actions';

const { pure } = xstateActions;

export const appMachine = initialContext => {
  return createMachine(
    {
      preserveActionOrder: true,
      predictableActionArguments: true,
      id: MACHINE_NAMES.APP,
      context() {
        return {
          // coming from ssr - only ssr
          user: initialContext.user,
          config: initialContext.config,

          // this comes from each component page - it defines which a route is protected or not! Component.requireAuthentication
          requireAuthentication: initialContext.requireAuthentication,

          appConfigService: null,
          searchHistoryService: null,
          // ACTIVE ON ENTRY
          autoUpdateService: spawn(autoUpdateMachine),
          authService: (() => {
            if (isNative()) {
              return null;
            }

            const machine = authMachine.withContext({
              ...authMachine.context,
              user: initialContext.user
            });
            return spawn(machine);
            // .onTransition((...args) =>
            //   console.log(name, ...args)
            // );
          })(),
          appSettingsService: (() => {
            const machine = appSettingsMachine.withContext({
              ...appSettingsMachine.context,
              user: initialContext.user
            });

            return spawn(machine);
          })(),
          libraryService: (() => {
            const machine = libraryMachine;

            return spawn(machine);
            // .onTransition((...args) => console.log(name, ...args));
          })(),
          nowPlayingService: (() => {
            return spawn(nowPlayingMachine);
            // .onTransition(state =>
            //   console.log('nowplaying', JSON.stringify(state.value), state.context.skipLimit)
            // );
          })()
        };
      },
      on: {
        [EVENT_NAMES.APP.UPDATE_REQUIRE_AUTHENTICATION]: {
          actions: ['updateRequireAuthenticationState']
        }
      },
      initial: STATES.APP.LOADING.INDEX,
      states: {
        [STATES.APP.LOADING.INDEX]: {
          id: STATES.APP.LOADING.INDEX,
          initial: STATES.APP.LOADING.CONFIG,
          states: {
            [STATES.APP.LOADING.CONFIG]: {
              entry: [
                assign({
                  appConfigService({ config }) {
                    const machine = configMachine.withContext({
                      ...configMachine.context,
                      config: {
                        ...configMachine.context.config,
                        ...(config || {})
                      }
                    });

                    return spawn(machine);
                    // .onTransition((...args) => console.log(name, ...args));
                  }
                })
              ],
              on: {
                [EVENT_NAMES.APP.INITIAL_APP_CONFIG_LOADED]: [
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.ACTIVATED}`,
                    cond: ({ user }) => {
                      return user;
                    }
                  },
                  {
                    target: 'loadingAuth',
                    cond: isNative
                  },
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.DEACTIVATE}`
                  }
                ]
              }
            },
            loadingAuth: {
              entry: [
                assign({
                  authService() {
                    return spawn(authMachine);
                  }
                })
              ],
              on: {
                [EVENT_NAMES.APP.REDIRECT_TO_APP]: [
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.ACTIVATED}`,
                    actions: ['redirectToApp'],
                    cond: isNative
                  },
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.ACTIVATED}`,
                    actions: ['redirectToAppInAuth']
                  }
                ],
                [EVENT_NAMES.APP.REDIRECT_TO_AUTH]: [
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.DEACTIVATE}`,
                    actions: ['redirectToAuth'],
                    cond: ({ requireAuthentication }) => {
                      return isNative() || requireAuthentication;
                    }
                  },
                  {
                    target: `#${MACHINE_NAMES.APP}.${STATES.APP.DEACTIVATE}`
                  }
                ]
              }
            }
          }
        },

        [STATES.APP.ACTIVATED]: {
          entry: [
            pure(({ appSettingsService, nowPlayingService, libraryService }) => {
              return [
                send(APP_SETTINGS_EVENT_NAMES.APP_SETTINGS.AUTHENTICATED, {
                  to: appSettingsService,
                  delay: 100
                }),
                send(NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.AUTHENTICATED, { to: nowPlayingService }),
                send(LIBRARY_EVENT_NAMES.LIBRARY.AUTHENTICATED, { to: libraryService })
              ];
            }),
            assign({
              searchHistoryService() {
                return spawn(searchHistoryMachine);
              }
            })
          ],
          on: {
            [EVENT_NAMES.APP.REDIRECT_TO_AUTH]: [
              {
                actions: ['redirectToAuth'],
                cond: ({ requireAuthentication }) => {
                  return isNative() || requireAuthentication;
                }
              }
            ],
            [EVENT_NAMES.APP.DESTROY]: {
              target: STATES.APP.DEACTIVATE,
              actions: [
                pure(
                  ({
                    appSettingsService,
                    libraryService,
                    nowPlayingService,
                    searchHistoryService
                  }) => {
                    return [
                      send(
                        { type: APP_SETTINGS_EVENT_NAMES.APP_SETTINGS.UNAUTHENTICATED },
                        { to: appSettingsService }
                      ),
                      send({ type: LIBRARY_EVENT_NAMES.LIBRARY.DESTROY }, { to: libraryService }),
                      send(
                        { type: NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.DESTROY },
                        { to: nowPlayingService }
                      ),
                      send(
                        { type: SEARCH_HISTORY_EVENT_NAMES.SEARCH_HISTORY.DESTROY },
                        { to: searchHistoryService }
                      )
                    ];
                  }
                )
              ]
            }
          }
        },

        [STATES.APP.DEACTIVATE]: {
          after: {
            5: {
              actions: [
                assign({
                  nowPlayingService() {
                    return spawn(nowPlayingMachine);
                    // .onTransition((...args) =>
                    //   console.log('now playing 2', name, ...args)
                    // );
                  }
                }),
                assign({
                  libraryService() {
                    return spawn(libraryMachine);
                    // .onTransition((...args) => console.log(name, ...args));
                  }
                })
              ],

              cond: context => {
                return context.nowPlayingService.state.done;
              }
            }
          },
          on: {
            [EVENT_NAMES.APP.REDIRECT_TO_APP]: {
              target: STATES.APP.ACTIVATED,
              actions: ['redirectToApp']
            },
            [EVENT_NAMES.APP.REDIRECT_TO_REQUIRE_AUTH]: {
              actions: ['redirectToRequireAuth']
            },
            // used in reset pass
            [EVENT_NAMES.APP.REDIRECT_TO_AUTH]: {
              actions: ['redirectToAuth']
            }
          }
        }
      }
    },
    {
      actions
    }
  );
};
