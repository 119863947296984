export const COMPONENTS = {
  HEADER: 'header',
  HOME_GRID_ADS: 'home_grid_ads',
  SLIDER_SQUARE: 'slider_square',
  SLIDER: 'slider',
  SLIDER_ROWS: 'slider_rows',
  BOX: 'box',
  GRID: 'grid',
  MENU: 'menu',
  FEATURED_SONGS_CHOICE: 'featured_mp3s_choice',
  RECENTLY_PLAYED: 'recently_played',
  STORIES: 'selfies',
  // custom
  TRACK_LIST_GRID_SLIDER: 'tracklist_grid_slider',
  TRACK_LIST_GRID: 'tracklist_grid',
  UPGRADE_TO_RJ_PREMIUM_BANNER: 'upgrade_to_rj_premium_banner'
};
