import { getAppService } from '@state/services/app-service';
import { getLibraryService, getNowPlayingService } from '@machines/app/selectors';
import { getItem } from '@machines/library/selectors';
import * as selectors from '@machines/now-playing/selectors';
import * as matchers from '@machines/now-playing/matchers';
import { getID } from '@machines/library/data-helpers';
import { isSynced } from '@machines/library/machines/item/matchers';

export const hasDownloadedItem = context => {
  const id = getID(context);

  const appService = getAppService();
  const libraryService = getLibraryService(appService.state);
  const item = getItem(id)(libraryService.state);

  if (item && item.ref) {
    return isSynced(item.ref.state);
  }

  return false;
};

export const isInQueue = context => {
  const appService = getAppService();
  const nowPlayingService = getNowPlayingService(appService.state);
  const isItemInQueue = selectors.isItemInQueue(context)(nowPlayingService.state);

  if (isItemInQueue) {
    return true;
  }

  const isItemInUpNext = selectors.isItemInUpNext(context)(nowPlayingService.state);

  if (isItemInUpNext) {
    return true;
  }

  return false;
};

export const isNotPlayingMiddleAds = () => {
  const appService = getAppService();
  const nowPlayingService = getNowPlayingService(appService.state);
  const isMiddleAdsStreaming = matchers.isMiddleAdsStreaming(nowPlayingService.state);

  return !isMiddleAdsStreaming;
};

export const hasUserReachedSkipLimit = () => {
  const appService = getAppService();
  const nowPlayingService = getNowPlayingService(appService.state);

  return matchers.hasUserReachedSkipLimit(nowPlayingService.state);
};
