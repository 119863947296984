import cx from 'classnames';

import React from 'react';
import Box from './Box';

const AspectRatio = ({
  children,
  className,
  innerClassName,
  ratio,
  overflowHidden,
  style,
  ...rest
}) => {
  const pt = 100 / ratio;

  return (
    <Box
      className={cx('aspect-ratio', 'relative', className)}
      style={{ paddingTop: `${pt}%`, ...style }}
      {...rest}
    >
      <Box
        className={cx('w-full absolute inset-0', innerClassName, {
          'overflow-hidden': overflowHidden
        })}
      >
        {React.cloneElement(children, {
          className: cx(
            'absolute inset-0 flex justify-center align-center w-full h-full object-cover overflow-hidden',
            children?.props?.className
          )
        })}
      </Box>
    </Box>
  );
};

AspectRatio.defaultProps = {
  ratio: 1,
  overflowHidden: true
};

export default AspectRatio;
