export const VERY_FADE_FAST = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.1 }
};

export const FADE_FAST = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

export const FADE = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

export const FADE_SLOW = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.4 }
};

export const FADE_VERY_SLOW = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.8 }
};

export const FADE_Y = {
  initial: { opacity: 0, y: 5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 }
};

export const _FADE_Y = {
  initial: { opacity: 0, y: -5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -5 }
};

export const FADE_X = {
  initial: { opacity: 0, x: 5 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 5 }
};

export const FADE_X_SLOW = {
  initial: { opacity: 0, x: 5 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 5 },
  transition: {
    duration: 0.5
  }
};

export const SLIDE = {
  initial: { opacity: 0.5, y: '100%' },
  animate: { opacity: 1, y: 0.1 },
  exit: { opacity: 1, y: '100%' },
  transition: {
    type: 'spring',
    stiffness: 200,
    damping: 29
  }
};

export const _SLIDE_X = {
  initial: { opacity: 0.8, x: '-100%' },
  animate: { opacity: 1, x: 0.5 },
  exit: { opacity: 1, x: '-100%' },
  transition: {
    type: 'spring',
    stiffness: 200,
    damping: 29
  }
};

export const SCALE = {
  initial: { opacity: 0, scale: 0.4 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.4 },
  transition: {
    duration: 0.1
  }
};

export const COLLAPSED = {
  WRAPPER: {
    initial: 'collapsed',
    animate: 'open',
    exit: 'collapsed',
    variants: {
      open: { opacity: 1, height: 'auto' },
      collapsed: { opacity: 0, height: 0 }
    },
    transition: {
      duration: 0.4,
      ease: [0.04, 0.62, 0.23, 0.98]
    }
  },
  CONTENT: {
    variants: { collapsed: { scale: 0.8 }, open: { scale: 1 } },
    transition: { duration: 0.4 }
  }
};
