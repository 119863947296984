export const EVENTS = {
  ERROR: {
    CATEGORY: 'Error',
    ACTIONS: {
      SOMETHING_WENT_WRONG: 'Something went wrong!'
    }
  },
  NOW_PLAYING: {
    CATEGORY: 'Now Playing',
    ACTIONS: {
      PLAYING: 'Playing',
      FULL_SCREEN: {
        ENTER: 'Enter Full Screen',
        EXIT: 'Exit Full Screen'
      }
    }
  },
  AUTH: {
    CATEGORY: 'Auth',
    ACTIONS: {
      LOGIN: 'Login',
      REGISTER: 'Register',
      LOGOUT: 'Logout'
    }
  }
};
