import { EVENT_NAMES as APP_EVENT_NAMES } from '@constants/machines/app';
import { EVENT_NAMES, MACHINE_NAMES } from '@constants/machines/auth';
import { EVENTS } from '@constants/analytics';

import ms from 'ms';

import { ga } from '@helpers/ga4';
import { googleTag } from '@helpers/google-tag';
import { getRecaptchaToken } from '@helpers/reacptcha';
import { sentrySetUser, sentryResetUser } from '@helpers/sentry';
import { deleteDataCaches } from '@functions/cache';
import { isDev, isNative, isWeb } from '@functions/env';
import { touchBar, menu } from '@helpers/native/events';

import { createMachine, assign, spawn, sendParent, actions as xstateActions } from 'xstate';
import { userMachine } from '@machines/user/user-machine';

import { login, appRegister, register, forgotPass, logout } from '@api/auth';
import { showNotification } from '@machines/actions';

import { updateUserProfileMachine } from './update-user-profile-machine';
import * as actions from './actions';

const { choose, pure } = xstateActions;

export const authMachine = createMachine(
  {
    id: 'auth-machine',
    predictableActionArguments: true,
    context: {
      formData: {
        login: null,
        register: null
      },
      user: null,
      notifyingUserToSubscribe: false,
      notifyingUserToSubscribeBecauseReachedSkipsLimit: false,
      notifyingUserToSubscribeToViewLyrics: false,
      actionsRequiringAuth: null,
      updateUserProfileRef: null
    },
    initial: 'firstLoading',
    states: {
      firstLoading: {
        always: [
          {
            target: '#auth-machine.user is authenticated',
            cond: ({ user }) => user
          },
          /**
           * SSR is disabled on native app
           * We check manually on initializing application to check if user is login or not
           */
          {
            target: 'loading',
            cond: isNative
          },
          /**
           * SSR is active on web app
           * We call user profile on server side and if there is not user it means user is not login and it has to transition to idle state to login or signup!
           */
          {
            target: 'user is not authenticated',
            actions: ['resetUser', sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)]
          }
        ]
      },

      loading: {
        invoke: {
          src: userMachine,
          onDone: {
            target: '#auth-machine.user is authenticated',
            actions: ['setUser']
          },
          onError: {
            target: '#auth-machine.user is not authenticated',
            actions: ['resetUser', sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)]
          }
        }
      },

      'user is not authenticated': {
        entry: choose([
          {
            actions: [touchBar.unAuthenticated, menu.window.inactive],
            cond: isNative
          }
        ]),
        after: {
          1_000: [
            {
              actions: [() => googleTag().initializeInterstitial()],
              cond: isWeb
            }
          ]
        },
        initial: 'idle',
        states: {
          idle: {},
          'user wants to login': {
            exit: choose([
              {
                actions: ({ user }) => {
                  ga().event({
                    category: EVENTS.AUTH.CATEGORY,
                    action: EVENTS.AUTH.ACTIONS.LOGIN,
                    label: user.email
                  });
                },
                cond: ({ user }) => user
              }
            ]),
            initial: 'idle',
            states: {
              idle: {
                on: {
                  [EVENT_NAMES.SUBMIT]: {
                    target: 'submitting',
                    actions: ['setFormData']
                  }
                }
              },
              submitting: {
                invoke: {
                  src: ({ formData }) => {
                    if (isNative() || isDev()) {
                      return login(formData.login);
                    }

                    return getRecaptchaToken().then(token => {
                      return login({ ...formData.login, token });
                    });
                  },
                  onDone: {
                    target: 'loading profile'
                  },
                  onError: {
                    target: 'idle',
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              },
              'loading profile': {
                invoke: {
                  src: userMachine,
                  onDone: {
                    target: '#auth-machine.user is authenticated',
                    actions: ['setUser', 'reloadAppConfig']
                  },
                  onError: {
                    target: 'idle',
                    actions: ['resetUser', sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)]
                  }
                }
              }
            }
          },
          'user wants to register': {
            initial: 'idle',
            states: {
              idle: {
                on: {
                  [EVENT_NAMES.SUBMIT]: [
                    {
                      target: 'submitting form in native app',
                      actions: ['setFormData'],
                      cond: isNative
                    },
                    {
                      target: 'submitting form in play app',
                      actions: ['setFormData']
                    }
                  ]
                }
              },
              'submitting form in native app': {
                invoke: {
                  src: ({ formData }) => {
                    return appRegister(formData.register);
                  },
                  onDone: [
                    {
                      target:
                        '#auth-machine.user is not authenticated.user wants to login.submitting',
                      actions: [
                        'setRegisterDataToLogin',
                        ({ formData }) => {
                          ga().event({
                            category: EVENTS.AUTH.CATEGORY,
                            action: EVENTS.AUTH.ACTIONS.REGISTER,
                            label: formData.register.email
                          });
                        }
                      ]
                    }
                  ],
                  onError: {
                    target: 'idle',
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              },
              'submitting form in play app': {
                invoke: {
                  src: ({ formData }) => {
                    if (isDev()) {
                      return register({ ...formData.register });
                    }

                    return getRecaptchaToken().then(token => {
                      return register({ ...formData.register, token });
                    });
                  },
                  onDone: [
                    {
                      target: 'waiting for user to confirm email',
                      actions: [
                        'setRegisterDataToLogin',
                        ({ formData }) => {
                          ga().event({
                            category: EVENTS.AUTH.CATEGORY,
                            action: EVENTS.AUTH.ACTIONS.REGISTER,
                            label: formData.register.email
                          });
                        }
                      ]
                    }
                  ],
                  onError: {
                    target: 'idle',
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              },
              'waiting for user to confirm email': {
                on: {
                  [EVENT_NAMES.SUBMIT]: {
                    target: 'idle'
                  }
                }
              }
            }
          },
          'user wants to recover account': {
            initial: 'idle',
            states: {
              idle: {
                on: {
                  [EVENT_NAMES.SUBMIT]: {
                    target: 'submitting',
                    actions: ['setFormData']
                  }
                }
              },
              submitting: {
                invoke: {
                  src: ({ formData }) => {
                    if (isNative() || isDev()) {
                      return forgotPass(formData.recover);
                    }

                    return getRecaptchaToken().then(token => {
                      return forgotPass({ ...formData.recover, token });
                    });
                  },
                  onDone: {
                    target: 'idle',
                    actions: [
                      showNotification({ type: 'info', messageName: 'reset-password-email-sent' }),
                      sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)
                    ]
                  },
                  onError: {
                    target: 'idle',
                    actions: [showNotification({ type: 'error' })]
                  }
                }
              }
            }
          }
        },
        on: {
          [EVENT_NAMES.LOGIN]: {
            target: '.user wants to login'
          },
          [EVENT_NAMES.REGISTER]: {
            target: '.user wants to register'
          },
          [EVENT_NAMES.RECOVER_ACCOUNT]: {
            target: '.user wants to recover account'
          },
          [EVENT_NAMES.SET_ACTIONS_REQUIRING_DATA]: {
            actions: [
              'setActionsRequiringAuth',
              sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_REQUIRE_AUTH)
            ]
          },
          [EVENT_NAMES.RESET_ACTIONS_REQUIRING_DATA]: {
            actions: ['resetActionsRequiringAuth']
          }
        }
      },

      'user is authenticated': {
        entry: [
          sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_APP),
          ({ user }) => {
            ga().set({ userId: user.email });
            sentrySetUser(user.email);
          },
          pure(() => {
            if (!isNative()) {
              return [];
            }

            return [menu.window.active];
          }),
          pure(({ user }) => {
            if (isWeb()) {
              if (user.hasSubscription) {
                if (!googleTag().initialized) {
                  return [];
                }

                return [() => googleTag().destroyAllSlots()];
              }

              return [() => googleTag().initializeInterstitial()];
            }

            return [];
          })
        ],
        initial: 'idle',
        states: {
          idle: {
            entry: [
              assign({
                updateUserProfileRef({ updateUserProfileRef }) {
                  if (updateUserProfileRef) {
                    return updateUserProfileRef;
                  }

                  return spawn(updateUserProfileMachine, MACHINE_NAMES.UPDATE_USER_PROFILE);
                  // .onTransition((...args) => console.log(MACHINE_NAMES.UPDATE_USER_PROFILE, ...args));
                }
              })
            ],
            initial: 'idle',
            states: {
              idle: {
                after: {
                  [ms('5min')]: {
                    target: 'loading user profile'
                  }
                },
                on: {
                  [EVENT_NAMES.RELOAD_PROFILE]: {
                    target: 'loading user profile'
                  }
                }
              },
              'loading user profile': {
                exit: [
                  pure(({ user }) => {
                    if (isWeb()) {
                      if (user.hasSubscription) {
                        if (!googleTag().initialized) {
                          return [];
                        }

                        return [() => googleTag().destroyAllSlots()];
                      }
                      return [() => googleTag().initializeInterstitial()];
                    }

                    return [];
                  })
                ],
                invoke: {
                  src: ({ user }) => {
                    return userMachine.withContext({ ...userMachine.context, user });
                  },
                  onDone: {
                    target: 'idle',
                    actions: ['setUser']
                  },
                  onError: {
                    target: '#auth-machine.user is authenticated.logging out the user'
                  }
                }
              },
              'deactivating user account': {
                entry: [sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)],
                target: '#auth-machine.user is authenticated.cleaning up user data'
              }
            },
            on: {
              [EVENT_NAMES.ACCOUNT_DEACTIVATED]: {
                target: '.deactivating user account'
              },
              [EVENT_NAMES.LOGOUT]: {
                target: 'logging out the user'
              }
            }
          },
          'logging out the user': {
            exit: [
              ({ user }) => {
                ga().event({
                  category: EVENTS.AUTH.CATEGORY,
                  action: EVENTS.AUTH.ACTIONS.LOGOUT,
                  label: user?.email
                });
              }
            ],
            invoke: {
              src: () => logout(),
              onDone: {
                actions: [sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)],
                target: 'cleaning up user data'
              },
              onError: {
                actions: [sendParent(APP_EVENT_NAMES.APP.REDIRECT_TO_AUTH)],
                target: 'cleaning up user data'
              }
            }
          },
          'cleaning up user data': {
            exit: [
              'resetUser',
              sendParent(APP_EVENT_NAMES.APP.DESTROY),
              assign({
                updateUserProfileRef() {
                  return null;
                }
              }),
              () => {
                ga().set({ userId: null });
                sentryResetUser();
              }
            ],
            invoke: {
              src: deleteDataCaches,
              onDone: {
                target: '#auth-machine.user is not authenticated'
              },
              onError: {
                target: '#auth-machine.user is not authenticated'
              }
            }
          }
        },
        on: {
          [EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE]: {
            actions: ['toggleNotifyingUserToSubscribe']
          },
          [EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_BECAUSE_REACHED_SKIP_LIMITS]: {
            actions: ['toggleNotifyingUserToSubscribeBecauseReachedSkipLimits']
          },
          [EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_TO_VIEW_LYRICS]: {
            actions: ['toggleNotifyingUserToSubscribeToViewLyrics']
          },
          [EVENT_NAMES.DESTROY_INTERSTITIAL_ADS]: {
            actions: [() => googleTag().destroyInterstitial()],
            cond: ({ user }) => {
              return isWeb() && !user.hasSubscription;
            }
          },
          [EVENT_NAMES.INITIAL_INTERSTITIAL_ADS]: {
            actions: [() => googleTag().initializeInterstitial()],
            cond: ({ user }) => {
              return isWeb() && !user.hasSubscription;
            }
          }
        }
      }
    }
  },
  {
    actions,
    guards: {}
  }
);
