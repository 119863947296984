import dynamic from 'next/dynamic';
import cx from 'classnames';

import { isWeb } from '@functions/env';

import { Box } from '@ui';

import ErrorBoundary from '@components/sentry/ErrorBoundary';

const GlobalProviders = dynamic(() => import('@contexts/providers/Global'));
const RouteListener = dynamic(() => import('@components/analytics/RouteListener'), { ssr: false });
const Notifications = dynamic(() => import('@components/notification/Notifications'), {
  ssr: false
});
const CookieConsent = dynamic(() => import('@components/web/cookie-consent/CookieConsent'), {
  ssr: false
});

const AppLayout = props => {
  const { children, data, platform, requireAuthentication } = props;

  const { isTouch, isBot } = data;

  return (
    <ErrorBoundary>
      <GlobalProviders
        data={data}
        platform={platform}
        requireAuthentication={requireAuthentication}
      >
        <Box
          as="main"
          role="main"
          className={cx('flex w-full text-th-primary-foreground', {
            'overflow-hidden min-h-full max-h-full': !isTouch,
            'flex-1': isTouch
          })}
        >
          {children}
          {isWeb() && !isBot ? <CookieConsent /> : null}
        </Box>
        <Notifications />
      </GlobalProviders>
      <RouteListener />
    </ErrorBoundary>
  );
};

export default AppLayout;
