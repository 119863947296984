const charRange = [
  '[\u06A9\u06AF\u06C0\u06CC\u060C',
  '\u062A\u062B\u062C\u062D\u062E\u062F',
  '\u063A\u064A\u064B\u064C\u064D\u064E',
  '\u064F\u067E\u0670\u0686\u0698\u200C',
  '\u0621-\u0629\u0630-\u0639\u0641-\u0654]'
].join('');
const numberRange = '[\u06F0-\u06F9]';
const rtlPunctuations = '(،|؟|«|»|؛|٬)';
const ltrPunctuations = '(\\.|:|\\!|\\-|\\[|\\]|\\(|\\)|/)';

function combineRegExps(...rest) {
  let combined = '(';
  for (let i = 0; i < rest.length; i++) {
    combined += '(';
    if (i !== rest.length - 1) combined += `${rest[i]})|`;
    else combined += `${rest[i]})`;
  }
  return `${combined})`;
}

export const persianRex = {
  number: new RegExp(`^${numberRange}+$`),
  letter: new RegExp(`^${charRange}+$`),
  punctuation: new RegExp(`^${combineRegExps(rtlPunctuations, ltrPunctuations)}+$`),
  text: new RegExp(
    `^${combineRegExps(numberRange, charRange, rtlPunctuations, ltrPunctuations, '\\s')}+$`
  ),
  rtl: new RegExp(`^${combineRegExps(charRange, numberRange, rtlPunctuations, '\\s')}+$`),
  hasNumber: new RegExp(numberRange),
  hasLetter: new RegExp(charRange),
  hasPunctuation: new RegExp(combineRegExps(rtlPunctuations, ltrPunctuations)),
  hasText: new RegExp(combineRegExps(numberRange, charRange, rtlPunctuations, ltrPunctuations)),
  hasRtl: new RegExp(combineRegExps(numberRange, charRange, rtlPunctuations)),
  numbersASCIRange: numberRange,
  lettersASCIRange: charRange,
  rtlPunctuationsASCIRange: rtlPunctuations,
  ltrPunctuationsASCIRange: ltrPunctuations
};

export function capitalize(letters) {
  return letters
    .split(' ')
    .map(letter => {
      const [first, ...rest] = letter;
      return [first.toUpperCase(), ...rest].join('');
    })
    .join(' ');
}

export function pluralize(data, noun, suffix = 's') {
  if (typeof data === 'number') {
    return `${noun}${data === 1 ? '' : suffix}`;
  }

  if (!Array.isArray(data)) {
    return noun;
  }

  return `${noun}${data.length === 1 ? '' : suffix}`;
}

export function formatNumber(number, symbol = ',') {
  if (!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
}

export function isPersian(str) {
  return persianRex.hasRtl.test(str);
}
