import { MACHINE_NAMES, EVENT_NAMES } from '@constants/machines/notification';

import { createMachine } from 'xstate';

import * as actions from './actions';

export const notificationMachine = createMachine(
  {
    id: MACHINE_NAMES.NOTIFICATION,
    context: {
      notifications: []
    },
    on: {
      [EVENT_NAMES.NOTIFICATION.ADD]: {
        actions: ['addNotification']
      },
      [EVENT_NAMES.NOTIFICATION.REMOVE]: {
        actions: ['removeNotification']
      },
      [EVENT_NAMES.NOTIFICATION.REMOVE_ALL]: {
        actions: ['removeNotifications']
      }
    }
  },
  {
    actions
  }
);
