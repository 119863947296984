import Router from 'next/router';

import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/auth';

import { createMachine, assign, sendParent } from 'xstate';

import { updateProfile, updateUserNotifications, resetPass, deactivateAccount } from '@api/auth';
import { mutateUserProfile, mutateUserNotificationSettings } from '@data/auth';

import { showNotification } from '@machines/actions';
import { getLink } from '@functions/get-link';

const setSelectedFile = assign({
  selectedFile(_, event) {
    return event.value;
  }
});

export const updateUserProfileMachine = createMachine(
  {
    id: MACHINE_NAMES.UPDATE_USER_PROFILE,

    context: {
      selectedFile: null
    },

    initial: STATES.UPDATE_USER_PROFILE.IDLE,

    on: {
      [EVENT_NAMES.UPDATE_USER_PROFILE.BACK]: {
        target: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.INDEX
      },
      [EVENT_NAMES.UPDATE_USER_PROFILE.TOGGLE_MODAL]: {
        target: STATES.UPDATE_USER_PROFILE.IDLE
      }
    },

    states: {
      // modals is close
      [STATES.UPDATE_USER_PROFILE.IDLE]: {
        id: STATES.UPDATE_USER_PROFILE.IDLE,
        on: {
          [EVENT_NAMES.UPDATE_USER_PROFILE.TOGGLE_MODAL]: {
            target: `#${STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.INDEX}`
          },
          [EVENT_NAMES.UPDATE_USER_PROFILE.RESET_PASS]: {
            target: `#${STATES.UPDATE_USER_PROFILE.RESET_PASS.INDEX}`
          },
          [EVENT_NAMES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT]: {
            target: `#${STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.INDEX}`
          }
        }
      },

      // modals first page
      [STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.INDEX]: {
        id: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.INDEX,
        initial: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.IDLE,
        states: {
          [STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.IDLE]: {
            on: {
              [EVENT_NAMES.UPDATE_USER_PROFILE.ON_SUBMIT]: {
                target: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.SUBMITTING
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.SUBMITTING]: {
            invoke: {
              src: async (_, event) => {
                const data = await updateProfile(event.value);
                if (
                  typeof event.value.music_email === 'boolean' ||
                  typeof event.value.artists_email === 'boolean'
                ) {
                  await updateUserNotifications(event.value);
                  await mutateUserNotificationSettings();
                }
                const userProfile = await mutateUserProfile(event.value);

                return {
                  ...data,
                  userProfile,
                  formData: event.value
                };
              },
              onDone: [
                {
                  target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE,
                  cond: (_, event) => event.data.formData.username,
                  actions: (_, event) => {
                    Router.replace(getLink({ ...event.data.userProfile, type: 'user' }));
                  }
                },
                {
                  target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE
                }
              ],
              onError: {
                target: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.IDLE,
                actions: [showNotification({ type: 'error' })]
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.REMOVING_USER_AVATAR]: {
            invoke: {
              src: (_, event) => {
                return updateProfile({
                  ...event.value,
                  photo: undefined,
                  remove_photo: true
                });
              },
              onDone: {
                target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE
              },
              onError: {
                target: STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.IDLE,
                actions: [showNotification({ type: 'error' })]
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE]: {
            entry: [sendParent('RELOAD_PROFILE'), showNotification({ type: 'info' })],
            always: {
              target: `#${STATES.UPDATE_USER_PROFILE.IDLE}`
            }
          }
        },
        on: {
          [EVENT_NAMES.UPDATE_USER_PROFILE.REMOVE_USER_AVATAR]: {
            target: `.${STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.REMOVING_USER_AVATAR}`
          },
          [EVENT_NAMES.UPDATE_USER_PROFILE.SELECT_FILE]: {
            target: `#${STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.INDEX}`,
            actions: ['setSelectedFile']
          },
          [EVENT_NAMES.UPDATE_USER_PROFILE.RESET_PASS]: {
            target: `#${STATES.UPDATE_USER_PROFILE.RESET_PASS.INDEX}`
          },
          [EVENT_NAMES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT]: {
            target: `#${STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.INDEX}`
          }
        }
      },

      [STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.INDEX]: {
        id: STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.INDEX,
        initial: STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.IDLE,
        states: {
          [STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.IDLE]: {
            on: {
              [EVENT_NAMES.UPDATE_USER_PROFILE.ON_SUBMIT]: {
                target: STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.SUBMITTING
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.SUBMITTING]: {
            invoke: {
              src: (_, event) => {
                return updateProfile(event.value);
              },
              onDone: {
                target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE
              },
              onError: {
                target: STATES.UPDATE_USER_PROFILE.UPLOAD_USER_AVATAR.IDLE,
                actions: [showNotification({ type: 'error' })]
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE]: {
            entry: [sendParent('RELOAD_PROFILE'), showNotification({ type: 'info' })],
            always: {
              target: `#${STATES.UPDATE_USER_PROFILE.IDLE}`
            }
          }
        }
      },

      [STATES.UPDATE_USER_PROFILE.RESET_PASS.INDEX]: {
        id: STATES.UPDATE_USER_PROFILE.RESET_PASS.INDEX,
        initial: STATES.UPDATE_USER_PROFILE.RESET_PASS.IDLE,
        states: {
          [STATES.UPDATE_USER_PROFILE.RESET_PASS.IDLE]: {
            on: {
              [EVENT_NAMES.UPDATE_USER_PROFILE.ON_SUBMIT]: {
                target: STATES.UPDATE_USER_PROFILE.RESET_PASS.SUBMITTING
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.RESET_PASS.SUBMITTING]: {
            invoke: {
              src: (_, event) => {
                return resetPass(event.value);
              },
              onDone: {
                target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE
              },
              onError: {
                target: STATES.UPDATE_USER_PROFILE.RESET_PASS.IDLE,
                actions: [showNotification({ type: 'error' })]
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE]: {
            entry: [sendParent('RELOAD_PROFILE'), showNotification({ type: 'info' })],
            always: {
              target: `#${STATES.UPDATE_USER_PROFILE.IDLE}`
            }
          }
        }
      },

      [STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.INDEX]: {
        id: STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.INDEX,
        initial: STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.IDLE,
        states: {
          [STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.IDLE]: {},

          [STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.SUBMITTING]: {
            invoke: {
              src: () => {
                return deactivateAccount();
              },
              onDone: {
                target: STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE
              },
              onError: {
                target: STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.IDLE,
                actions: [showNotification({ type: 'error' })]
              }
            }
          },

          [STATES.UPDATE_USER_PROFILE.RELOADING_PROFILE]: {
            entry: [sendParent('ACCOUNT_DEACTIVATED')],
            always: {
              target: `#${STATES.UPDATE_USER_PROFILE.EDIT_PROFILE.INDEX}`
            }
          }
        },
        on: {
          [EVENT_NAMES.UPDATE_USER_PROFILE.ON_SUBMIT]: {
            target: `.${STATES.UPDATE_USER_PROFILE.DEACTIVATE_ACCOUNT.SUBMITTING}`
          }
        }
      }
    }
  },
  {
    actions: {
      setSelectedFile
    },
    guards: {}
  }
);
