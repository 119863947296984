import { useMemo } from 'react';

import { useAuthService } from '@hooks';
import { usePartial, useMatchers } from '@state/hooks';
import { getUpdateUserProfileRef } from '@machines/auth/selectors';

export const useUpdateUserProfileRef = () => {
  const service = useAuthService();
  const ref = usePartial(service, getUpdateUserProfileRef);

  return ref;
};

export const useUpdateUserProfile = selector => {
  const ref = useUpdateUserProfileRef();
  const value = usePartial(ref, selector);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useUpdateUserProfileMatch = matcher => {
  const ref = useUpdateUserProfileRef();

  const value = useMatchers(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};
