import { cn } from '@functions/styles';

import { forwardRef } from 'react';

import Box from './Box';

const weights = {
  h1: 'font-semibold',
  h2: 'font-semibold',
  h3: 'font-medium',
  h4: 'font-medium',
  h5: 'font-medium'
};

const Heading = forwardRef(({ as, className, ...props }, ref) => {
  return (
    <Box className={cn(weights[as], 'leading-tight', className)} as={as} {...props} ref={ref} />
  );
});

Heading.defaultProps = {
  as: 'h1'
};

export default Heading;
