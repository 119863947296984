import { MIN_WIDTH } from '@constants/media-query';

import cx from 'classnames';

import { useRef, useMemo } from 'react';
import { useDebouncedLayoutSize } from '@hooks';

import Heading from '@ui/Heading';

const debounceProps = {
  delay: 10
};

const getClassNames = ({ children, width }) => {
  if (typeof children !== 'string') {
    return null;
  }

  const { length } = children;

  // console.log(length);

  // if (width <= MIN_WIDTH.SM) {
  //   // console.log('_____SM_____');

  //   if (length <= 10) {
  //     return 'text-4xl';
  //   }

  //   if (length <= 20) {
  //     return 'text-3xl';
  //   }

  //   if (length <= 50) {
  //     return 'text-2xl';
  //   }

  //   return 'text-xl';
  // }

  if (width <= MIN_WIDTH.MD) {
    if (length <= 20) {
      return 'text-4xl';
    }

    if (length <= 80) {
      return 'text-2xl';
    }

    return 'text-xl';
  }

  if (width <= MIN_WIDTH.LG) {
    // console.log('_____LG_____');

    if (length <= 20) {
      return 'text-5xl';
    }

    // if (length <= 50) {
    //   return 'text-4xl';
    // }

    if (length <= 80) {
      return 'text-3xl';
    }

    return 'text-2xl';
  }

  if (width <= MIN_WIDTH.XL) {
    // console.log('_____XL_____');

    if (length <= 20) {
      return 'text-6xl';
    }

    if (length <= 80) {
      return 'text-4xl';
    }

    return 'text-3xl';
  }

  // console.log('_____2XL_____');

  if (length <= 20) {
    return 'text-6xl';
  }

  if (length <= 80) {
    return 'text-4xl';
  }

  if (length <= 150) {
    return 'text-3xl';
  }

  return 'text-2xl';
};

const SmartHeading = ({ children, className, ...props }) => {
  const ref = useRef(null);

  const { width } = useDebouncedLayoutSize(debounceProps);

  return useMemo(() => {
    return (
      <Heading
        ref={ref}
        {...props}
        className={cx(className, getClassNames({ children, width }), 'leading-tight')}
      >
        {children}
      </Heading>
    );
  }, [width, children]);
};

export default SmartHeading;
