/* eslint-disable prettier/prettier */
import { STATES } from '@constants/machines/playlist';

export const isOpen = state => {
  return !state.matches(STATES.PLAYLIST.IDLE);
};

// ADD TO PLAYLIST
export const isAddingToPlaylist = state => {
  return state.matches(STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX);
};

export const wasAddingToPlaylist = state => {
  if (state?.history?.matches) {
    return state.history.matches(STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX);
  }
  return false
};

export const isSelectingPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.INDEX}`);
};

export const isSubmittingSelectedPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.SUBMITTING}`);
};

export const isCreatingNewPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}`);
};

export const isEditingInfoToCreateNewPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX}`);
};

export const isCroppingPhotoForNewPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.CROPPING_PHOTO.INDEX}`);
};

export const isSubmittingCreatingNewPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.ADD_TO_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.INDEX}.${STATES.PLAYLIST.ADD_TO_PLAYLIST.CREATING_PLAYLIST.EDITING.SUBMITTING.INDEX}`);
};

/**
 * Remove playlist
 */
export const isRemovingPlaylist = state => {
  return state.matches(STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX);
};

export const isSubmittingRemovingPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.REMOVE_PLAYLIST.INDEX}.${STATES.PLAYLIST.REMOVE_PLAYLIST.SUBMITTING}`);
};

/**
 * Edit playlist
 */
export const isEditingPlaylist = state => {
  return state.matches(STATES.PLAYLIST.EDIT_PLAYLIST.INDEX);
};

export const isCroppingPhotoInEditPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}.${STATES.PLAYLIST.EDIT_PLAYLIST.CROPPING_PHOTO}`);
};

export const isSubmittingEditingPlaylist = state => {
  return state.matches(`${STATES.PLAYLIST.EDIT_PLAYLIST.INDEX}.${STATES.PLAYLIST.EDIT_PLAYLIST.SUBMITTING.INDEX}`);
};