import { useEffect } from 'react';

import { Box } from '@ui';

import StaticInputRadio from './StaticInputRadio';
import StaticInputRadioCheck from './StaticInputRadioCheck';

const InputRadio = ({
  input,
  meta: { error, touched },
  afterChanged,
  className,
  check,
  ...props
}) => {
  useEffect(() => {
    if (afterChanged) {
      afterChanged(input.value);
    }
  }, [afterChanged, input.value]);

  return (
    <Box>
      <Box
        as={check ? StaticInputRadioCheck : StaticInputRadio}
        {...props}
        {...input}
        checked={input.checked}
        isInvalid={error && touched}
      />
    </Box>
  );
};

export default InputRadio;
