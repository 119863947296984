import { MACHINE_NAMES } from '@constants/machines/now-playing';

import findIndex from 'lodash/findIndex';
import { reorder } from '@functions/array';
import { getID, getNowPlayingItem } from '@functions/get';

import { spawn } from 'xstate';
import { nowPlayingItemMachine } from '@machines/now-playing/item/now-playing-item-machine';
import { liveStreamingMachine } from '@machines/now-playing/live-streaming/live-streaming-machine';
import { adMachine } from '@machines/now-playing/ad/ad-machine';
import { recentlyPlayedMachine } from '@machines/now-playing/recently-played/recently-played-machine';
import { nowPlayingStateStore } from '@machines/now-playing/store/now-playing-store-machine';
import { failedUserPlaysMachine } from '@machines/now-playing/callbacks/failed-user-plays-subscriber';

export const createItemMachine = ({ item }) => {
  const { selectedId, ...rest } = item;

  const machine = nowPlayingItemMachine.withContext({
    ...nowPlayingItemMachine.context,
    ...rest
  });

  const ref = spawn(machine);
  // .onTransition((...args) => {
  //   console.log('nowPlayingItemMachine', item.title, ...args);
  // });

  return ref;
};

export const createLiveStreamingMachine = () => {
  const ref = spawn(liveStreamingMachine);
  // .onTransition((...args) => {
  //   console.log('liveStreamingMachine', ...args);
  // });

  return ref;
};

export const createAdMachine = ({ item }) => {
  const ref = spawn(adMachine.withContext(item));
  // .onTransition((...args) => {
  //   console.log('adMachine', item.title, ...args);
  // });

  return ref;
};

export const createRecentlyPlayedMachine = () => {
  const ref = spawn(recentlyPlayedMachine(), MACHINE_NAMES.RECENTLY_PLAYED);

  return ref;
};

export const createNowPlayingStateStoreMachine = () => {
  const ref = spawn(nowPlayingStateStore(), MACHINE_NAMES.NOW_PLAYING_STATE_STORE);

  return ref;
};

export const createFailedUserPlaysMachine = () => {
  const ref = spawn(failedUserPlaysMachine(), MACHINE_NAMES.FAILED_USER_PLAYS);

  return ref;
};

export const getMachines = ({ machines, items }) => {
  return items.reduce(
    (acc, item) => {
      const _id = item._id || getID(item);

      if (acc[_id]) {
        return acc;
      }

      const machine = createItemMachine({ item });

      acc[_id] = machine;

      return acc;
    },
    { ...machines }
  );
};

export const findSelectedIndex = (items, selected) => {
  if (!selected) {
    return -1;
  }

  if (typeof selected.selectedId === 'string') {
    return findIndex(items, { id: selected.id, selectedId: selected.selectedId });
  }

  /**
   * In case of selectedId wasn't defined we check for types.
   * This is used in guards in now-playing-item-machine's guars to check is items is available in queue or up next
   */
  return findIndex(items, { _id: selected._id, type: selected.type });
};

export const getCorrectSelectedFromState = ({ selected, lastSelectedFromUpNext }) => {
  if (!selected) {
    return -1;
  }

  if (selected.playingFromQueue) {
    if (lastSelectedFromUpNext) {
      return lastSelectedFromUpNext;
    }
  }

  return selected;
};

export const reorderItems = ({ items, destination, source }) => {
  const fromIndex = findSelectedIndex(items, source.data);
  let toIndex = findSelectedIndex(items, destination.data);

  if (fromIndex > toIndex && destination.after) {
    toIndex += 1;
  }

  return reorder(items, fromIndex, toIndex);
};

export const createQueueItem = data => {
  return {
    ...data,
    playingFromQueue: true
  };
};

export const getNowPlayingItems = data => {
  return data.map(getNowPlayingItem);
};
