import { useEffect } from 'react';

import { Box } from '@ui';

import StaticSwitch from './StaticSwitch';

const Switch = ({ input, meta: { error, touched }, afterChanged, className, ...props }) => {
  useEffect(() => {
    if (afterChanged) {
      afterChanged(input.value);
    }
  }, [afterChanged, input.value]);

  return (
    <Box>
      <StaticSwitch {...props} {...input} checked={input.checked} isInvalid={error && touched} />
    </Box>
  );
};

export default Switch;
