import { EVENT_NAMES } from '@constants/machines/app-settings';

export const nativeSettingsMachine = () => {
  return (sendParent, receiver) => {
    const request = () => {
      window.native.sendCommand('config:state');
    };

    const onChange = data => {
      try {
        sendParent({
          type: EVENT_NAMES.APP_SETTINGS.UPDATE_NATIVE_SETTINGS,
          data: JSON.parse(data)
        });
      } catch (e) {
        console.log(e);
      }
    };

    request();
    // eslint-disable-next-line prettier/prettier
    const unsubscribe = window.native.registerCommand('config:change', onChange);

    const interval = window.setInterval(() => {
      request();
    }, 100_000);

    receiver(event => {
      if (event.type === EVENT_NAMES.NATIVE_SETTINGS.SET) {
        const { key, value } = event;

        if (key === 'hardware-acceleration') {
          window.native.sendCommand('application:hardware-acceleration');
          return;
        }

        if (key === 'open-at-login') {
          window.native.sendCommand('application:open-at-login', value);
        }

        window.native.sendCommand('config:set', key, value);
        setTimeout(() => {
          request();
        }, 100);
      }
    });

    // disposal
    return () => {
      unsubscribe();
      if (interval) {
        window.clearInterval(interval);
      }
    };
  };
};
