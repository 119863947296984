export function isFullScreen(ref) {
  const element = ref || document;

  return !!(
    element.fullscreenElement ||
    element.webkitFullscreenElement ||
    element.mozFullscreenElement ||
    element.msFullscreenElement
  );
}

export function openFullscreen(ref) {
  const element = ref || document.documentElement;

  try {
    if (element.requestFullscreen) {
      element.requestFullscreen();
      return;
    }

    if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      return;
    }

    if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  } catch (e) {
    console.log(e);
  }
}

export function closeFullscreen() {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      return;
    }

    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      return;
    }

    if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}

export const toggleFullScreen = ref => {
  if (isFullScreen(ref)) {
    openFullscreen(ref);
    return;
  }

  closeFullscreen(ref);
};
