import { SEARCH_URL, SEARCH_HIT, SEARCH_ARTISTS } from '@constants/urls';

import api from './api';
import { formatSearch } from './helpers';

export const loadSearch = ({ baseURL, headers, query, tag }) => {
  return api({
    baseURL,
    headers,
    url: SEARCH_URL,
    method: 'GET',
    params: {
      query,
      tag: tag ? 1 : undefined
    }
  }).then(formatSearch);
};

export const loadSearchHit = ({ data }) => {
  return api({
    url: SEARCH_HIT,
    method: 'POST',
    data
  });
};

export const loadSearchArtist = ({ query }) => {
  return api({
    url: SEARCH_ARTISTS,
    method: 'GET',
    params: {
      query
    }
  }).then(data => {
    return data.artists;
  });
};
