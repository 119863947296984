import { EVENT_NAMES } from '@constants/machines/now-playing';
import orderBy from 'lodash/orderBy';
import { getID, getRecentlyPlayedItem } from '@functions/get';

import { sendCommandToWorker } from '@helpers/storages/helpers';

export const recentlyPlayedMachine = () => {
  return (sendParent, receiver) => {
    const options = {
      type: 'module'
    };
    const worker = new Worker(
      new URL('@workers/storages/recently-played-storage.worker.js', import.meta.url),
      options
    );
    let cache = {};
    let cacheArray = [];

    // METHODS
    const updateParent = () => {
      cacheArray = Object.values(cache);

      sendParent({
        type: EVENT_NAMES.NOW_PLAYING.SET_RECENTLY_PLAYED_ITEMS,
        items: cacheArray
      });
    };

    const addToCache = newCache => {
      const newItems = newCache.reduce((acc, item) => {
        const id = getID(item);
        acc[id] = item;
        return acc;
      }, {});

      cache = {
        ...cache,
        ...newItems
      };

      updateParent();
    };

    const removeFromCache = item => {
      const id = getID(item);
      const { [id]: shouldBeRemoved, ...rest } = cache;

      cache = {
        ...rest
      };
      updateParent();
    };

    const clearCache = () => {
      cache = {};
      updateParent();
    };

    const loadData = () => {
      sendCommandToWorker(worker, 'load')
        .then(({ items }) => {
          addToCache(items);
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const mount = () => {
      loadData();
    };

    const removeOldItem = () => {
      const items = orderBy(cache, ['dateAdded'], ['desc']);
      const item = items[items.length - 1];

      sendCommandToWorker(worker, 'remove', item).then(() => {
        removeFromCache(item);
      });
    };

    const add = ({ data }) => {
      const value = getRecentlyPlayedItem(data);

      sendCommandToWorker(worker, 'add', value)
        .then(() => {
          addToCache([value]);

          if (cacheArray.length >= 50) {
            removeOldItem();
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const clearAll = () => {
      clearCache();
      sendCommandToWorker(worker, 'clear')
        .then(() => {
          loadData();
        })
        .catch(() => {
          loadData();
        });
    };

    const destroy = () => {
      console.log('destroying recently played database & worker....');

      sendCommandToWorker(worker, 'destroy')
        .then(() => {
          worker.terminate();

          console.log('recently played worker terminated...');
        })
        .catch(() => {
          worker.terminate();

          console.log('recently played worker terminated...');
        });
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.ADD:
          add(event);
          break;

        case EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.CLEAR_ALL:
          clearAll();
          break;

        case EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.DESTROY:
          destroy();
          break;

        default:
          break;
      }
    });

    // disposal
    // return () => {
    //   console.log('this will be called on refresh.....');
    // };
  };
};
