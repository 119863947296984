import isUndefined from 'lodash/isUndefined';

import { isSafari } from '@functions/browser';

const swing = p => 0.5 - Math.cos(p * Math.PI) / 2;

// @cancelable promise
const adjustVolume = (
  media,
  newVolume,
  onDone,
  { duration = 60, easing = swing, interval = 1 } = {}
) => {
  const originalVolume = media.volume;
  const delta = newVolume - originalVolume;
  const ticks = Math.floor(duration / interval);
  let tick = 1;
  let timeout;
  const promise = new Promise(resolve => {
    timeout = setInterval(() => {
      media.volume = originalVolume + easing(tick / ticks) * delta;
      // eslint-disable-next-line
      if (++tick === ticks) {
        clearInterval(timeout);
        resolve();
        if (onDone) {
          onDone();
        }
      }
    }, interval);
  });
  return {
    promise,
    cancel: () => clearInterval(timeout)
  };
};

let pro;
export const fadePlay = (media, play, { volume, onDone } = {}) => {
  if (!media) {
    return;
  }
  const playPromise = media.play();

  if (playPromise) {
    playPromise
      .then(() => {
        if (pro && pro.cancel) {
          pro.cancel();
        }
        pro = adjustVolume(
          media,
          // eslint-disable-next-line
          play ? (!isUndefined(volume) ? volume : 1) : 0,
          onDone
        );
        pro.promise.then(() => {
          pro = null;
          if (play) {
            media.play();
          } else {
            media.pause();
          }
        });
      })
      .catch(e => e);
  }
};

export class FadePlay {
  constructor(options) {
    this.options = options;
    this.cache = null;
  }

  to(play, { volume, onDone, onError } = {}) {
    if (!this.options.media) {
      return;
    }

    const playPromise = this.options.media.play();

    if (playPromise) {
      playPromise
        .then(() => {
          if (this.cache && this.cache.cancel) {
            this.cache.cancel();
          }

          // eslint-disable-next-line no-nested-ternary
          const newVolume = play ? (!isUndefined(volume) ? volume : 1) : 0;

          // disable fade play/pause
          // videos
          // touch devices doesn't support cross fade volume
          const isDisabled = this.options.disabled || isSafari();

          if (isDisabled) {
            this.options.media.volume = newVolume;

            if (play) {
              this.options.media.play();
              setTimeout(onDone, 0);

              return;
            }

            this.options.media.pause();

            // safari doesn't execute this on background
            if (isDisabled) {
              onDone();
            } else {
              setTimeout(onDone, 0);
            }

            return;
          }

          this.cache = adjustVolume(this.options.media, newVolume, onDone);

          this.cache.promise.then(() => {
            this.cache = null;
            if (play) {
              this.options.media.play();
            } else {
              this.options.media.pause();
            }
          });
        })
        .catch(e => {
          if (onError) {
            onError(e);
          }
        });
    }
  }
}
