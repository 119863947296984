import { interpret } from 'xstate';

import { gridMachine } from '@machines/grid/grid-machine';

let service;
export const startGridService = () => {
  service = interpret(gridMachine)
    // .onTransition((...args) => {
    //   console.log('Grid-service', ...args);
    // })
    .start();
};

export const stopGridService = () => {
  if (!service) {
    return;
  }

  service.stop();

  service = undefined;
};

export const getGridService = () => {
  if (service) {
    return service;
  }

  startGridService();

  return service;
};
