import { EVENT_NAMES, MACHINE_NAMES, STATES } from '@constants/machines/now-playing';
import { EVENTS } from '@constants/analytics';

import isEqual from 'react-fast-compare';
import ms from 'ms';
import { isNative } from '@functions/env';
import { touchBar, menu } from '@helpers/native/events';
import { ga } from '@helpers/ga4';

import { createMachine, send, actions as xstateActions, forwardTo } from 'xstate';

import { setActionsRequiringAuth } from '@machines/actions';
import * as actions from '@machines/now-playing/actions';
import * as guards from '@machines/now-playing/guards';

const { choose } = xstateActions;

export const nowPlayingMachine = createMachine(
  {
    preserveActionOrder: true,
    id: MACHINE_NAMES.NOW_PLAYING,
    context: {
      machines: {},
      adsMachines: {},
      liveStreamingRef: null,
      recentlyPlayedRef: null,
      stateStoreRef: null,
      failedUserPlaysRef: null,
      metadata: null,
      upnext: [],
      queue: [],
      selected: null,
      lastSelectedFromUpNext: null,
      shuffle: false,
      repeat: false,
      volume: 1,
      adsQueue: [],
      middleAdsQueue: [],
      fullScreenUpNext: false,
      fullScreenLyrics: false,
      noneFullScreenLyrics: false,
      showLiveStream: false,
      fullscreen: false,
      videoPageReference: null,
      recentlyPlayed: [],
      skipLimit: {
        skippedCount: 0,
        lastTimestampAfterLocked: null,
        lastSkipTimestamp: null
      },
      isRestoreNowPlayingStateAllowed: true
    },
    on: {
      [EVENT_NAMES.NOW_PLAYING.ADD_TO_QUEUE]: {
        actions: ['setQueue', 'setLastSelectedItemFromUpNext', 'storeNowPayingState']
      },
      [EVENT_NAMES.NOW_PLAYING.REMOVE_FROM_QUEUE]: {
        actions: ['removeFromQueue']
      },
      [EVENT_NAMES.NOW_PLAYING.REORDER_QUEUE]: {
        actions: ['reorderQueue']
      },
      [EVENT_NAMES.NOW_PLAYING.INSERT_TO_QUEUE]: {
        actions: ['insertToQueue']
      },
      [EVENT_NAMES.NOW_PLAYING.CLEAR_QUEUE]: {
        actions: ['clearQueue']
      },
      [EVENT_NAMES.NOW_PLAYING.REORDER_UP_NEXT]: {
        actions: ['reorderUpNext']
      },
      [EVENT_NAMES.NOW_PLAYING.INSERT_TO_UP_NEXT]: {
        actions: ['insertToUpNext']
      },
      [EVENT_NAMES.NOW_PLAYING.REMOVE_FROM_UP_NEXT]: {
        actions: ['removeFromUpNext']
      },
      [EVENT_NAMES.NOW_PLAYING.REMOVE_ITEM]: {
        actions: ['removeItemFromMachines']
      },
      [EVENT_NAMES.NOW_PLAYING.TOGGLE_FULLSCREEN]: [
        {
          actions: [
            'toggleFullscreen',
            ({ fullscreen }) => {
              ga().event({
                category: EVENTS.NOW_PLAYING.CATEGORY,
                action: fullscreen
                  ? EVENTS.NOW_PLAYING.ACTIONS.FULL_SCREEN.ENTER
                  : EVENTS.NOW_PLAYING.ACTIONS.FULL_SCREEN.EXIT,
                label: fullscreen
                  ? EVENTS.NOW_PLAYING.ACTIONS.FULL_SCREEN.ENTER
                  : EVENTS.NOW_PLAYING.ACTIONS.FULL_SCREEN.EXIT
              });
            }
          ],
          cond: () => !window.isTouch
        }
      ],
      [EVENT_NAMES.NOW_PLAYING.SET_VIDEO_PAGE_REFERENCE]: {
        actions: ['setVideoPageReference']
      },
      [EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.ADD]: {
        actions: [forwardTo(MACHINE_NAMES.RECENTLY_PLAYED)]
      },
      [EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.CLEAR_ALL]: {
        actions: [forwardTo(MACHINE_NAMES.RECENTLY_PLAYED)]
      },
      [EVENT_NAMES.NOW_PLAYING.SET_RECENTLY_PLAYED_ITEMS]: {
        actions: ['setRecentlyPlayedItems']
      },
      [EVENT_NAMES.NOW_PLAYING.SET_IS_RESTORE_NOW_PLAYING_STATE_ALLOWED]: {
        actions: ['setIsRestoreNowPlayingStateAllowed']
      },
      [EVENT_NAMES.NOW_PLAYING.FAILED_USER_PLAYS.ADD]: {
        actions: [forwardTo(MACHINE_NAMES.FAILED_USER_PLAYS)]
      }
    },

    initial: STATES.NOW_PLAYING.UN_AUTHENTICATED,
    states: {
      [STATES.NOW_PLAYING.UN_AUTHENTICATED]: {
        on: {
          [EVENT_NAMES.NOW_PLAYING.AUTHENTICATED]: {
            target: STATES.NOW_PLAYING.AUTHENTICATED.INDEX
          },
          [EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT]: {
            actions: [
              (_, event) => {
                setActionsRequiringAuth(event)();
              }
            ]
          },
          [EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING]: {
            actions: [
              (_, event) => {
                setActionsRequiringAuth(event)();
              }
            ]
          }
        }
      },

      [STATES.NOW_PLAYING.AUTHENTICATED.INDEX]: {
        initial: STATES.NOW_PLAYING.AUTHENTICATED.IDLE,
        entry: ['setRecentlyPlayedRef', 'setNowPlayingStoreState'],
        states: {
          [STATES.NOW_PLAYING.AUTHENTICATED.IDLE]: {
            entry: choose([
              {
                actions: [touchBar.authenticateWithoutNowPlaying, menu.playback.inactive],
                cond: isNative
              }
            ]),
            on: {
              [EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT]: {
                actions: [
                  'setUpNext',
                  'setPlayContext',
                  'setSelectedMachine',
                  'startSelected',
                  'redirectToMedia'
                ],
                target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX
              },
              /**
               * Almost save as SET_PLAY_CONTEXT
               */
              [EVENT_NAMES.NOW_PLAYING.RESTORE_NOW_PLAYING_STATE]: [
                {
                  actions: ['setInitialSkipLimit'],
                  cond: context => {
                    return !context.isRestoreNowPlayingStateAllowed;
                  }
                },
                {
                  target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX,
                  actions: [
                    'setInitialSkipLimit',
                    'setUpNext',
                    'setSelectedFromItem',
                    'setSelectedMachine',
                    'setFailedUserPlaysRef',
                    'startSelectedSilent'
                  ],
                  /**
                   * Means items is available in queue not in up next
                   */
                  cond: (_, event) => {
                    return event.item.playingFromQueue;
                  }
                },
                {
                  target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX,
                  actions: [
                    'setInitialSkipLimit',
                    'setUpNext',
                    'setPlayContext',
                    'setSelectedMachine',
                    'setFailedUserPlaysRef',
                    'startSelectedSilent'
                  ]
                }
              ],
              [EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING]: [
                {
                  actions: ['toggleShowLiveStream'],
                  target: `${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}`,
                  cond: 'isNowPlayingFooter'
                },
                {
                  target: `${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}`
                }
              ],
              [EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME]: {
                actions: ['setVolume']
              }
            }
          },

          [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX]: {
            entry: choose([
              {
                actions: [touchBar.authenticatedWithNowPlaying, menu.playback.active],
                cond: isNative
              }
            ]),
            id: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX,
            initial: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.VALIDATING,
            states: {
              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.VALIDATING]: {
                always: [
                  {
                    target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX
                  }
                ]
              },

              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX]: {
                entry: ['storeNowPayingState'],
                exit: ['storeNowPayingState'],
                type: 'parallel',
                states: {
                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.INDEX]: {
                    initial: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.IDLE,
                    states: {
                      /**
                       * Playing & Paused states
                       */
                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.IDLE]: {
                        entry: choose([
                          {
                            actions: [touchBar.playback.playing, menu.playback.media.playing],
                            cond: isNative
                          }
                        ]),
                        on: {
                          [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PAUSED.INDEX
                          },
                          [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PLAYING.INDEX
                          }
                        }
                      },

                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PLAYING.INDEX]: {
                        entry: choose([
                          {
                            actions: [touchBar.playback.playing, menu.playback.media.playing],
                            cond: isNative
                          },
                          {
                            actions: ['sendDestroyInterstitialAds']
                          }
                        ]),
                        on: {
                          [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PAUSED.INDEX
                          }
                        }
                      },

                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PAUSED.INDEX]: {
                        entry: choose([
                          {
                            actions: [touchBar.playback.paused, menu.playback.media.paused],
                            cond: isNative
                          },
                          {
                            actions: ['sendInitialInterstitialAds']
                          }
                        ]),
                        on: {
                          [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PLAYING.INDEX
                          }
                        }
                      }
                    },

                    on: {
                      /**
                       * Destroy the current machine
                       */
                      [EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT]: [
                        {
                          actions: ['sendPlayFromStart', 'redirectToMedia', 'resetMiddleAdsQueue'],
                          cond: (
                            { selected, metadata: currentMetadata, upnext },
                            { item, shuffle, metadata, items }
                          ) => {
                            /**
                             * If user select shuffle then the queue should be set again
                             */
                            if (selected.id === item.id) {
                              // example: two playlist same first songs
                              if (currentMetadata && metadata) {
                                if (!isEqual(currentMetadata, metadata)) {
                                  return false;
                                }
                              }

                              if (!isEqual(upnext, items)) {
                                return false;
                              }

                              if (
                                shuffle ||
                                // when selecting same select from queue or upnext it should be removed from the list
                                selected.playingFromQueue ||
                                item.playingFromQueue
                              ) {
                                return false;
                              }

                              return true;
                            }

                            return false;
                          }
                        },
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          in: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_NOT_ALLOWED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_NOT_ALLOWED.VALIDATING}`,
                          cond: 'isItemInQueueOrUpNext'
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}`,
                          actions: [
                            'sendDestroyToSelected',
                            'setPlayContextFromQueue',
                            'removeFromQueue',
                            'resetAdsQueue',
                            'resetMiddleAdsQueue',
                            'resetRepeat',
                            'setSelectedMachine',
                            'startSelected',
                            'redirectToMedia',
                            () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                          ],
                          cond: (_, { item }) => {
                            return item.playingFromQueue;
                          }
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}`,
                          actions: [
                            'sendDestroyToSelected',
                            'setUpNext',
                            'setPlayContext',
                            'resetAdsQueue',
                            'resetMiddleAdsQueue',
                            'resetRepeat',
                            'setSelectedMachine',
                            'startSelected',
                            'redirectToMedia',
                            () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT]: [
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX}`,
                          actions: ['resetMiddleAdsQueue'],
                          cond: ({ repeat, adsQueue }, { finished }) => {
                            return repeat && adsQueue.length && finished;
                          }
                        },
                        {
                          actions: [
                            'setSelectedMachine',
                            'startSelected',
                            'redirectToMedia',
                            'resetMiddleAdsQueue'
                          ],
                          cond: ({ repeat }, { finished }) => {
                            return repeat && finished;
                          }
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX}`,
                          actions: [
                            'selectFirstItemFromQueue',
                            'setSelectedMachine',
                            'removeFirstItemFromQueue',
                            'resetRepeat',
                            'resetMiddleAdsQueue',
                            'storeNowPayingState',
                            'redirectToMedia',
                            () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                          ],
                          cond: ({ adsQueue, queue }) => adsQueue.length && queue.length
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX}`,
                          actions: [
                            'increaseSkippedCount',
                            'next',
                            'setSelectedMachine',
                            'resetMiddleAdsQueue'
                          ],
                          cond: ({ adsQueue }) => adsQueue.length
                        },
                        {
                          actions: [
                            'increaseSkippedCount',
                            'selectFirstItemFromQueue',
                            'removeFirstItemFromQueue',
                            'resetRepeat',
                            'setSelectedMachine',
                            'startSelected',
                            'resetMiddleAdsQueue',
                            'storeNowPayingState',
                            'redirectToMedia',
                            () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                          ],
                          cond: ({ queue }) => queue.length
                        },
                        {
                          actions: [
                            'increaseSkippedCount',
                            'next',
                            'resetRepeat',
                            'setSelectedMachine',
                            'startSelected',
                            'resetMiddleAdsQueue',
                            'storeNowPayingState',
                            'redirectToMedia',
                            () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS]: {
                        actions: [
                          'previous',
                          'resetRepeat',
                          'setSelectedMachine',
                          'startSelected',
                          'resetMiddleAdsQueue',
                          'redirectToMedia',
                          'increaseSkippedCount',
                          () => (isNative() ? menu.playback.repeat.inactive() : undefined)
                        ]
                      },

                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PRE_LOAD_NEXT]: [
                        {
                          actions: ['setMachineFirstItemInQueue', 'preloadNextFromQueue'],
                          cond: (...args) => {
                            return guards.canPreLoadQueue(...args);
                          }
                        },
                        {
                          actions: ['setMachineForNextFromUpNext', 'preloadNextFromUpNext'],
                          cond: (...args) => {
                            return guards.canPreLoadUpNext(...args);
                          }
                        }
                      ],

                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.TOGGLE_REPEAT]: [
                        {
                          actions: [
                            'toggleRepeat',
                            'storeNowPayingState',
                            ({ repeat }) => {
                              if (repeat) {
                                menu.playback.repeat.active();
                                return;
                              }
                              menu.playback.repeat.inactive();
                            }
                          ],
                          cond: isNative
                        },
                        {
                          actions: ['toggleRepeat', 'storeNowPayingState']
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.SET_ADS]: {
                        actions: ['setAdsMachines', 'setAdsQueue']
                      },
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.SET_MIDDLE_ADS]: {
                        actions: ['setAdsMachines', 'setMiddleAdsQueue']
                      },
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.START_MIDDLE_ADS]: {
                        target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.INDEX}`,
                        cond: ({ middleAdsQueue }) => middleAdsQueue.length
                      },
                      [EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_UP_NEXT]: {
                        actions: ['toggleFullScreenUpNext']
                      },
                      [EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_LYRICS]: [
                        {
                          actions: ['toggleFullScreenLyrics'],
                          cond: 'isUserAllowToViewLyrics'
                        },
                        {
                          actions: ['toggleNotifyUserToSubscribeToViewLyrics']
                        }
                      ],

                      [EVENT_NAMES.NOW_PLAYING.HIDE_FULL_SCREEN_LYRICS]: {
                        actions: ['setFullscreenLyricsFalse']
                      },
                      [EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING]: [
                        {
                          actions: [
                            'toggleShowLiveStream',
                            'sendDestroyToSelected',
                            'storeNowPayingState'
                          ],
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}`,
                          cond: 'isNowPlayingFooter'
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}`,
                          actions: ['sendDestroyToSelected']
                        }
                      ]
                    }
                  },
                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.INDEX]: {
                    initial:
                      STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.VALIDATING,
                    states: {
                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                        .VALIDATING]: {
                        always: [
                          {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .IDLE,
                            cond: 'hasUserSubscription'
                          },
                          {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .CHECKING.INDEX,
                            cond: () => {
                              return (
                                typeof window.appConfig.skips_time_limit === 'number' &&
                                typeof window.appConfig.skips_limit === 'number' &&
                                window.appConfig.skips_time_limit > 0 &&
                                window.appConfig.skips_limit > 0
                              );
                            }
                          },
                          {
                            target:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .IDLE
                          }
                        ]
                      },
                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.IDLE]: {},
                      [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING
                        .INDEX]: {
                        initial:
                          STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                            .CHECKING.SKIPPING_IS_ALLOWED.INDEX,
                        states: {
                          [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                            .CHECKING.SKIPPING_IS_ALLOWED.INDEX]: {
                            id: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                              .CHECKING.SKIPPING_IS_ALLOWED.INDEX,
                            initial:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .CHECKING.SKIPPING_IS_ALLOWED.VALIDATING,
                            states: {
                              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .CHECKING.SKIPPING_IS_ALLOWED.VALIDATING]: {
                                after: {
                                  [ms('1secs')]: [
                                    {
                                      target:
                                        STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING
                                          .CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_ALLOWED.VALIDATING,
                                      actions: ['resetSkippedCount'],
                                      cond: 'hasPassedLastSkipTimestamp'
                                    },
                                    {
                                      target:
                                        STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING
                                          .CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_ALLOWED.VALIDATING,
                                      cond({ skipLimit: { skippedCount } }) {
                                        return skippedCount < window.appConfig.skips_limit;
                                      }
                                    },
                                    {
                                      /// need to go to cannot skip state
                                      target: `#${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_NOT_ALLOWED.INDEX}`
                                    }
                                  ]
                                }
                              }
                            }
                          },
                          [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                            .CHECKING.SKIPPING_IS_NOT_ALLOWED.INDEX]: {
                            id: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                              .CHECKING.SKIPPING_IS_NOT_ALLOWED.INDEX,
                            entry: ['setLastTimestampAfterLocked'],
                            initial:
                              STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .CHECKING.SKIPPING_IS_NOT_ALLOWED.VALIDATING,
                            states: {
                              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT
                                .CHECKING.SKIPPING_IS_NOT_ALLOWED.VALIDATING]: {
                                after: {
                                  [ms('1secs')]: [
                                    {
                                      target: `#${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_ALLOWED.INDEX}`,
                                      actions: ['resetSkippedCount'],
                                      cond: 'hasPassedLastSkipTimestampAfterLock'
                                    },
                                    {
                                      target:
                                        STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING
                                          .CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_NOT_ALLOWED
                                          .VALIDATING
                                    }
                                  ]
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },

              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX]: {
                entry: [
                  'setLiveStreamingMachine',
                  'setFullscreenLyricsFalse',
                  'resetMiddleAdsQueue'
                ],
                exit: ['sendDestroyToLiveStreaming'],
                id: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX,
                initial: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.IDLE,
                states: {
                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.IDLE]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.liveStream.playing],
                        cond: isNative
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PAUSED
                      },
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PLAYING
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PLAYING]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.liveStream.playing],
                        cond: isNative
                      },
                      {
                        actions: ['sendDestroyInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PAUSED
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PAUSED]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.paused, menu.playback.liveStream.paused],
                        cond: isNative
                      },
                      {
                        actions: ['sendInitialInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PLAYING
                      }
                    }
                  }
                },
                on: {
                  [EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT]: [
                    {
                      target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}`,
                      actions: ['sendPlayFromStart', 'redirectToMedia'],
                      cond: (
                        { selected, metadata: currentMetadata, upnext },
                        { item, shuffle, metadata, items }
                      ) => {
                        /**
                         * If user select shuffle then the queue should be set again
                         */
                        if (selected?.id === item.id) {
                          // example: two playlist same first songs
                          if (currentMetadata && metadata) {
                            if (!isEqual(currentMetadata, metadata)) {
                              return false;
                            }
                          }

                          if (!isEqual(upnext, items)) {
                            return false;
                          }

                          if (
                            shuffle ||
                            // when selecting same select from queue or upnext it should be removed from the list
                            selected.playingFromQueue ||
                            item.playingFromQueue
                          ) {
                            return false;
                          }

                          return true;
                        }

                        return false;
                      }
                    },
                    {
                      target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}`,
                      actions: [
                        'sendDestroyToSelected',
                        'setUpNext',
                        'setPlayContext',
                        'setSelectedMachine',
                        'startSelected',
                        'redirectToMedia'
                      ]
                    }
                  ],
                  [EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING]: {
                    actions: ['toggleShowLiveStream'],
                    cond: 'isNowPlayingFooter'
                  }
                }
              },

              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.INDEX]: {
                entry: ['startFirstMiddleAd', 'setFullscreenLyricsFalse', 'resetAdsQueue'],
                exit: ['playSelected', 'resetMiddleAdsQueue'],
                initial: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.IDLE,
                states: {
                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.IDLE]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.ads.playing],
                        cond: isNative
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PAUSED
                      },
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PLAYING
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PLAYING]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.ads.playing],
                        cond: isNative
                      },
                      {
                        actions: ['sendDestroyInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PAUSED
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PAUSED]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.paused, menu.playback.ads.paused],
                        cond: isNative
                      },
                      {
                        actions: ['sendInitialInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PLAYING
                      }
                    }
                  }
                },

                on: {
                  // remove ads and play next
                  [EVENT_NAMES.NOW_PLAYING.ACTIVE.ADS_STREAMING.FINISHED]: [
                    {
                      target: `.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.IDLE}`,
                      actions: ['removeFirstMiddleAd', 'startFirstMiddleAd'],
                      cond: ({ middleAdsQueue }) => middleAdsQueue.length > 1,
                      internal: true
                    },
                    {
                      target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}`,
                      internal: true
                    }
                  ],
                  [EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_UP_NEXT]: {
                    actions: ['toggleFullScreenUpNext']
                  }
                }
              },

              [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX]: {
                entry: ['startFirstAd', 'setFullscreenLyricsFalse'],
                exit: ['startSelected', 'resetAdsQueue'],
                id: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX,
                initial: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.IDLE,
                states: {
                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.IDLE]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.ads.playing],
                        cond: isNative
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PAUSED
                      },
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PLAYING
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PLAYING]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.playing, menu.playback.ads.playing],
                        cond: isNative
                      },
                      {
                        actions: ['sendDestroyInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PAUSED
                      }
                    }
                  },

                  [STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PAUSED]: {
                    entry: choose([
                      {
                        actions: [touchBar.playback.paused, menu.playback.ads.paused],
                        cond: isNative
                      },
                      {
                        actions: ['sendInitialInterstitialAds']
                      }
                    ]),
                    on: {
                      [EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY]: {
                        target: STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PLAYING
                      }
                    }
                  }
                },

                on: {
                  // remove ads and play next
                  [EVENT_NAMES.NOW_PLAYING.ACTIVE.ADS_STREAMING.FINISHED]: [
                    {
                      target: `.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.IDLE}`,
                      actions: ['removeFirstAd', 'startFirstAd'],
                      cond: ({ adsQueue }) => adsQueue.length > 1,
                      internal: true
                    },
                    {
                      target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}`,
                      internal: true
                    }
                  ],
                  [EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_UP_NEXT]: {
                    actions: ['toggleFullScreenUpNext']
                  }
                }
              }
            },

            on: {
              [EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME]: {
                actions: [
                  'setVolume',
                  'sendVolumeToSelectedLiveStream',
                  'sendVolumeToSelected',
                  'sendVolumeToSelectedAd',
                  'sendVolumeToSelectedMiddleAd',
                  'storeNowPayingState'
                ]
              },

              [EVENT_NAMES.NOW_PLAYING.GET_VOLUME]: {
                actions: [
                  'sendVolumeToSelectedLiveStream',
                  'sendVolumeToSelected',
                  'sendVolumeToSelectedAd',
                  'sendVolumeToSelectedMiddleAd'
                ]
              }
            }
          }
        },
        on: {
          [EVENT_NAMES.NOW_PLAYING.DESTROY]: {
            target: `#${MACHINE_NAMES.NOW_PLAYING}.${STATES.NOW_PLAYING.DESTROYED}`,
            actions: [
              send(EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.DESTROY, {
                to: MACHINE_NAMES.RECENTLY_PLAYED
              }),
              send(EVENT_NAMES.NOW_PLAYING.STORE.DESTROY, {
                to: MACHINE_NAMES.NOW_PLAYING_STATE_STORE
              })
            ]
          },
          [EVENT_NAMES.NOW_PLAYING.TOGGLE_NONE_FULL_SCREEN_LYRICS]: [
            {
              actions: ['toggleNoneFullScreenLyrics'],
              cond: 'isUserAllowToViewLyrics'
            },
            {
              actions: ['toggleNotifyUserToSubscribeToViewLyrics']
            }
          ]
        }
      },
      [STATES.NOW_PLAYING.DESTROYED]: {
        type: 'final'
      }
    }
  },
  {
    actions,
    guards
  }
);
