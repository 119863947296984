import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { APP_SETTINGS } from '@constants';
import { EVENT_NAMES } from '@constants/machines/now-playing';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService, getNowPlayingService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';
import { getVolume } from '@machines/now-playing/selectors';

import { FadePlay } from '@helpers/dom/fade-play';
import { MediaSession } from '@helpers/dom/media-session';
import { initHLS } from '@helpers/hls';
import { parsePercentage, getPercentage } from '@functions/math/percentage';
import { isMediaSessionSupported } from '@functions/browser';
import { isPodcast, isVideo } from '@functions/is';
import { isCloseToEnd } from '@functions/math/is';
import { getSRC, getFallbackSRC, getID } from '@functions/get';

const { AUDIO_QUALITY } = APP_SETTINGS.PLAYBACK;
const { LAST_PLAYED_TIME, LAST_PLAYED_TIME_BEFORE_CLOSING_APP } = APP_SETTINGS.NOW_PLAYING;

export const streamMachine = context => {
  return (sendParent, receiver) => {
    const appService = getAppService();
    const appSettingsService = getAppSettingsService(appService.state);
    const nowPlayingService = getNowPlayingService(appService.state);

    let volume = getVolume(nowPlayingService.state);
    let isManuallyPaused = false;
    let mediaSession;

    const { streamSource, buffer, ...restContext } = context;

    const playControl = new FadePlay({
      media: streamSource,
      disabled: window.isTouch || isVideo(context)
    });

    const initMediaSession = () => {
      if (isMediaSessionSupported()) {
        if (mediaSession) {
          return;
        }

        mediaSession = new MediaSession({
          media: streamSource,
          data: restContext,
          onPlay() {
            sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE);
          },
          onPause() {
            sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE);
          },
          onStop() {
            sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE);
          },
          onSeekForward(value) {
            const options = {
              value: typeof value === 'number' ? value : undefined
            };

            sendParent({
              type: EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_FORWARD,
              ...options
            });
          },
          onSeekBackward(value) {
            const options = {
              value: typeof value === 'number' ? value : undefined
            };

            sendParent({
              type: EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_BACKWARD,
              ...options
            });
          },
          onSeek(value) {
            sendParent({
              type: EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK,
              value
            });
          },
          onPrevious() {
            sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS);
          },
          onNext() {
            sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.NEXT);
          }
        });
      }
    };

    const quality = getBrowserSettings(AUDIO_QUALITY)(appSettingsService.state);
    const src = getSRC({ data: context, quality });
    const fallbackSRC = getFallbackSRC({ data: context, quality });

    const onPlayFromLastCurrentTime = () => {
      const currentTime = getBrowserSettings(LAST_PLAYED_TIME_BEFORE_CLOSING_APP)(
        appSettingsService.state
      );

      if (typeof currentTime === 'number') {
        if (!isCloseToEnd(currentTime, streamSource.duration)) {
          streamSource.currentTime = currentTime;
        }
      }

      streamSource.removeEventListener('loadedmetadata', onPlayFromLastCurrentTime);
    };

    const onPlayFromLastCurrentTimePodcast = () => {
      const id = getID(context);

      if (isPodcast(context)) {
        const currentTime = getBrowserSettings(LAST_PLAYED_TIME)(appSettingsService.state)?.[id];

        if (typeof currentTime === 'number') {
          if (!isCloseToEnd(currentTime, streamSource.duration)) {
            streamSource.currentTime = currentTime;
          }
        }
      }

      streamSource.addEventListener('loadedmetadata', onPlayFromLastCurrentTimePodcast);
    };

    streamSource.preload = 'metadata';

    const destroyHLS = (async () => {
      const hls = await initHLS(streamSource, {
        src,
        fallbackSRC,
        buffer,
        onManifestParsed() {
          // if (isDev()) {
          // streamSource.muted = true;
          // }

          if (isPodcast(context)) {
            // safari had issue with setting current time
            streamSource.addEventListener('loadedmetadata', onPlayFromLastCurrentTimePodcast);
          }

          if (context.silent) {
            mediaSession?.setMetaData();
            mediaSession?.setPlaybackState(true);
            mediaSession?.updatePositionState();
            mediaSession?.initEventListeners();

            if (!isPodcast(context)) {
              // safari had issue with setting current time
              streamSource.addEventListener('loadedmetadata', onPlayFromLastCurrentTime);
            }
          }

          if (!isManuallyPaused && !context.preload) {
            playControl.to(true, {
              volume,
              onDone() {
                mediaSession?.setMetaData();
                mediaSession?.setPlaybackState(true);
                mediaSession?.updatePositionState();
                mediaSession?.initEventListeners();
              },
              onError() {
                sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE);

                mediaSession?.setMetaData();
                mediaSession?.setPlaybackState(false);
                mediaSession?.updatePositionState();
                mediaSession?.initEventListeners();
              }
            });
          }
        }
      });

      return hls;
    })();

    // METHODS
    // example: when user click on native ios play pause controls on video
    const onPlayExternally = () => {
      sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAYED_EXTERNALLY);
    };

    const onPauseExternally = () => {
      sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PAUSED_EXTERNALLY);
    };

    const onPlay = () => {
      initMediaSession();

      mediaSession?.setMetaData();
      mediaSession?.setPlaybackState(false);
      mediaSession?.updatePositionState();
      mediaSession?.initEventListeners();

      playControl.to(true, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(true);
          mediaSession?.updatePositionState();
          mediaSession?.initEventListeners();
        }
      });
    };

    const onPause = () => {
      isManuallyPaused = true;
      playControl.to(false, {
        volume,
        onDone() {
          initMediaSession();

          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(false);
          mediaSession?.updatePositionState();
        }
      });
    };

    const onSeek = value => {
      if (typeof value === 'undefined') {
        return;
      }

      // if (getPercentage(streamSource.currentTime, streamSource.duration) < 10 && value > 90) {
      //   console.log({ value });
      // }

      const currentTime = parsePercentage(streamSource.duration || 0, value);

      streamSource.currentTime = currentTime;

      mediaSession?.updatePositionState();
    };

    const onSeekCurrentTime = value => {
      if (typeof value === 'undefined') {
        return;
      }

      streamSource.currentTime = value;

      mediaSession?.updatePositionState();
    };

    const onSeekForward = (skipDuration = 5) => {
      streamSource.currentTime += skipDuration;

      mediaSession?.updatePositionState();
    };

    const onSeekBackward = (skipDuration = 5) => {
      if (!(streamSource.currentTime >= 0)) {
        return;
      }
      streamSource.currentTime -= skipDuration;

      mediaSession?.updatePositionState();
    };

    const onWaiting = debounce(() => {
      sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.BUFFERING);
      mediaSession?.setPlaybackState(false);
    }, 2_000);

    const onPlaying = () => {
      if (onWaiting.cancel) {
        onWaiting.cancel();
      }
      sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAYING);
      mediaSession?.setPlaybackState(true);
    };

    const onEnd = () => {
      sendParent({
        type: EVENT_NAMES.NOW_PLAYING_ITEM.NEXT,
        finished: true
      });
    };

    const onVolumeChange = value => {
      volume = value;
      streamSource.volume = value;
    };

    const onRateChange = () => {
      mediaSession?.updatePositionState();
    };

    const onTimeUpdate = throttle(() => {
      sendParent(EVENT_NAMES.NOW_PLAYING_ITEM.PLAY_FROM_LAST_CURRENT_TIME);

      /**
       * START PRE LOADING NEXT ITEM
       */
      if (getPercentage(streamSource.currentTime, streamSource.duration) > 5) {
        sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PRE_LOAD_NEXT);
      }
    }, 2_000);

    const cleanup = () => {
      streamSource.removeEventListener('play', onPlayExternally);
      streamSource.removeEventListener('pause', onPauseExternally);
      streamSource.removeEventListener('playing', onPlaying);
      streamSource.removeEventListener('waiting', onWaiting);
      streamSource.removeEventListener('ended', onEnd);
      streamSource.removeEventListener('ratechange', onRateChange);
      streamSource.removeEventListener('timeupdate', onTimeUpdate);

      if (!streamSource?.paused) {
        onPause();
      }

      destroyHLS.then(fn => fn());
    };

    const mount = () => {
      streamSource.addEventListener('play', onPlayExternally);
      streamSource.addEventListener('pause', onPauseExternally);
      streamSource.addEventListener('playing', onPlaying);
      streamSource.addEventListener('waiting', onWaiting);
      streamSource.addEventListener('ended', onEnd);
      streamSource.addEventListener('ratechange', onRateChange);
      streamSource.addEventListener('timeupdate', onTimeUpdate);

      if (!isManuallyPaused && !context.preload) {
        initMediaSession();

        mediaSession?.setMetaData();
        mediaSession?.setPlaybackState(false);
        mediaSession?.updatePositionState();
        mediaSession?.initEventListeners();
      }
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case 'play':
          onPlay();
          break;

        case 'pause':
          onPause();
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.PLAY_FROM_LAST_CURRENT_TIME:
          onPlayFromLastCurrentTimePodcast();
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK:
          onSeek(event.value);
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_CURRENT_TIME:
          onSeekCurrentTime(event.value);
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_FORWARD:
          onSeekForward(event.value);
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_BACKWARD:
          onSeekBackward(event.value);
          break;

        case EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START:
          onSeek(0);
          break;

        case EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME:
          onVolumeChange(event.value);
          break;

        default:
          break;
      }
    });

    // disposal
    return () => {
      cleanup();
    };
  };
};
