import { EVENT_NAMES } from '@constants/machines/library';
import { isAlbum, isPlaylist } from '@functions/is';

import { useCallback } from 'react';

import { useLibraryRef, useDownloaderRef } from '@hooks';

export const useLibraryMethods = props => {
  const ref = useLibraryRef();

  const save = useCallback(
    params => {
      const data = props?.data || params?.data;
      const sync = props?.sync || params?.sync;
      const metadata = props?.metadata || params?.metadata;

      let items = Array.isArray(data) ? data : [data];

      if (isPlaylist(data)) {
        items = data.items;
      }

      if (isAlbum(data) && data?.album_tracks?.length) {
        const { album_tracks: tracks } = data;
        items = [...tracks];
      }

      // Album item should only be saved in library
      if (props?.mainData) {
        ref.send(EVENT_NAMES.LIBRARY.SAVE, { items: [props.mainData], metadata });
      }

      ref.send(EVENT_NAMES.LIBRARY.SAVE, { items, sync, metadata });
    },
    [ref, props?.data, props?.sync]
  );

  const removeItemsFromSync = useCallback(
    params => {
      const data = props?.data || params?.data;
      let items = Array.isArray(data) ? data : [data];

      if (isPlaylist(data)) {
        items = data.items;
      }

      if (isAlbum(data) && data?.album_tracks?.length) {
        const { album_tracks: tracks } = data;
        items = [data, ...tracks];
      }

      ref.send(EVENT_NAMES.LIBRARY.REMOVE_ITEMS_FROM_SYNC, { items });
    },
    [ref]
  );

  const removeSyncedItems = useCallback(() => {
    ref.send(EVENT_NAMES.LIBRARY.REMOVE_SYNCED_ITEMS);
  }, [ref]);

  const deleteAll = useCallback(() => {
    ref.send(EVENT_NAMES.LIBRARY.DELETE_ALL);
  }, [ref]);

  return {
    save,
    removeItemsFromSync,
    removeSyncedItems,
    deleteAll
  };
};

export const useDownloaderMethods = () => {
  const ref = useDownloaderRef();

  const cancelAll = useCallback(() => {
    ref.send(EVENT_NAMES.DOWNLOADER.CANCEL_ALL);
  }, []);

  const cancelItems = useCallback(items => {
    ref.send(EVENT_NAMES.DOWNLOADER.CANCEL_SELECTED_ITEMS, { items });
  }, []);

  return {
    cancelAll,
    cancelItems
  };
};

export const useLibraryItemMethods = ({ itemRef }) => {
  const cancel = useCallback(() => {
    itemRef.send(EVENT_NAMES.ITEM.CANCEL);
  }, [itemRef]);

  const unSync = useCallback(() => {
    itemRef.send(EVENT_NAMES.ITEM.UN_SYNC);
  }, [itemRef]);

  return {
    cancel,
    unSync
  };
};
