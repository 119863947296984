import cx from 'classnames';

// import { isMp3, isVideo, isAlbum } from '@functions/is';

import { Fragment } from 'react';

import { Box, Link, Text } from '@ui';

import { getArtistStaticLink } from '@functions/get-link';
import { getTrackArtists } from '@functions/get';

const Artist = ({ data, linkProps }) => {
  if (data.link) {
    return (
      <Link
        to={getArtistStaticLink(data.link)}
        className={cx(linkProps?.className, 'hover:underline focus:underline !inline')}
      >
        {data.name}
      </Link>
    );
  }

  return (
    <Text as="span" className={cx(linkProps?.className, '!inline')}>
      {data.name}
    </Text>
  );
};

const ArtistLink = ({ data, separator, linkProps, className }) => {
  const artists = getTrackArtists(data);

  return (
    <Box
      as="span"
      className={cx(
        'flex justify-center space-x-1 truncate line-clamp-1 whitespace-normal',
        className
      )}
    >
      {artists.map((item, index) => {
        const isLast = artists.length - 1 === index;

        return (
          <Fragment key={item.name}>
            <Artist data={item} linkProps={linkProps} />
            {isLast ? null : <Box as="span">{separator}</Box>}
          </Fragment>
        );
      })}
    </Box>
  );
};

ArtistLink.defaultProps = {
  separator: '&'
};

export default ArtistLink;
