import { useIsNowPlaying } from '@hooks';

const Body = ({ Component, ...props }) => {
  const isNowPlaying = useIsNowPlaying();

  return <Component.Seo {...props} isNowPlaying={isNowPlaying} />;
};

const SeoLayout = ({ Component, data, pageProps, query }) => {
  // return useMemo(() => {
  if (Component.Seo) {
    if (data.isWeb) {
      if (Component.requireAuthentication && !data.user) {
        return null;
      }
    }

    return (
      <Body
        Component={Component}
        {...pageProps}
        query={query}
        isTouch={data.isTouch}
        isWeb={data.isWeb}
        isNative={data.isNative}
        isBot={data.isBot}
      />
    );
  }

  return null;
  // }, [isNowPlaying]);
};

export default SeoLayout;
