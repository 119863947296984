import { isDev } from '@functions/env';
import { isSafari } from '@functions/browser';

const createBlobLinkFromBinary = buff => {
  const blob = new Blob([buff]);
  const url = URL.createObjectURL(blob);

  return url;
};

export const initHLS = async (streamSource, options) => {
  const { src, fallbackSRC, buffer, onManifestParsed } = options;

  const isStatic =
    src.includes('.mp4') || src.includes('.mp3') || src.includes('.m4a') || src.includes('.m4v');

  if (buffer) {
    streamSource.src = createBlobLinkFromBinary(buffer);

    // WAIT FOR VOLUME TO GET UPDATE IN MACHINE
    setTimeout(onManifestParsed, 0);

    return () => {
      if (streamSource && streamSource.src) {
        try {
          URL.revokeObjectURL(streamSource.src);
        } catch (e) {
          console.log(e);
        }
      }
    };
  }

  if (isStatic) {
    streamSource.src = src;

    onManifestParsed();

    return () => {};
  }

  if (isSafari()) {
    streamSource.src = src;
    streamSource.type = 'application/vnd.apple.mpegurl';

    setTimeout(() => {
      onManifestParsed();
    }, 0);

    return () => {};
  }

  /**
   * Lazy load videojs since it's not required sometimes like Saffari!
   */
  try {
    let player = null;
    const videojs = (await import('video.js')).default;

    const createPlayer = () => {
      const vjs = videojs(streamSource, {
        autoplay: false,
        controls: false,
        responsive: false,
        children: ['MediaLoader'],
        html5: {
          overrideNative: true,
          nativeCaptions: false,
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false
        }
      });

      vjs.ready(function () {
        this.src({
          src,
          type: 'application/x-mpegURL'
        });
      });

      let isFirstLoad = true;

      vjs.on('loadeddata', function () {
        if (isFirstLoad) {
          isFirstLoad = false;

          onManifestParsed();
        }
      });

      vjs.on('error', function () {
        if (this.error().code === 2) {
          setTimeout(() => {
            if (!player || player.isDisposed()) {
              return;
            }

            player.dispose();

            player = createPlayer();
          }, 1_000);
        }
      });

      return vjs;
    };

    player = createPlayer();

    player.reloadSourceOnError({
      errorInterval: 100,
      errorRetryInterval: 5000,
      maxRetryTimeout: 60000,
      maxRetryLimit: 8000 // Maximum num
    });

    return () => {
      if (!player || player.isDisposed()) {
        return;
      }

      player.dispose();
    };
  } catch (e) {
    if (isDev()) {
      console.log(e);
    }

    console.log('failed to load hls.js');
  }

  /**
   * HLS.js doesn't support some browsers!
   */
  if (fallbackSRC) {
    streamSource.src = fallbackSRC;

    onManifestParsed();

    return () => {};
  }

  return () => {};
};
