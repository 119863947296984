import { createMachine } from 'xstate';
import { isWeb } from '@functions/env';
import { loadAppVersion } from '@api/app';
import { showNotification, removeAllNotifications } from '@machines/actions';
import { application } from '@helpers/native/events';

import ms from 'ms';

const nativeEvents = {
  ON_CHECKING_FOR_NATIVE_UPDATES: {
    actions: [
      showNotification({
        type: 'info',
        messageName: 'checking-for-update',
        icon: 'loading'
      })
    ]
  },
  ON_NATIVE_UPDATE_NOT_AVAILABLE: {
    actions: [
      showNotification({
        type: 'info',
        messageName: 'update-is-not-available',
        icon: 'check'
      })
    ]
  },
  ON_NATIVE_UPDATE_AVAILABLE: {
    actions: [
      showNotification({
        type: 'info',
        messageName: 'update-is-available',
        icon: 'check'
      })
    ]
  },
  ON_NATIVE_UPDATE_DOWNLOADED: {
    target: 'app has new native version'
  }
};

function nativeUpdateCallback({ clearNotifications }) {
  return () => {
    return sendParent => {
      if (isWeb()) {
        return () => {};
      }

      const unsubscribe1 = window.native.registerCommand('application:checking', () => {
        sendParent('ON_CHECKING_FOR_NATIVE_UPDATES');
      });
      const unsubscribe2 = window.native.registerCommand('application:update-not-available', () => {
        if (clearNotifications) {
          removeAllNotifications();
        }

        setTimeout(() => {
          sendParent('ON_NATIVE_UPDATE_NOT_AVAILABLE');
        }, 1_000);
      });
      const unsubscribe3 = window.native.registerCommand('application:update-available', () => {
        if (clearNotifications) {
          removeAllNotifications();
        }

        setTimeout(() => {
          sendParent('ON_NATIVE_UPDATE_AVAILABLE');
        }, 1_000);
      });
      const unsubscribe4 = window.native.registerCommand('application:update-downloaded', () => {
        if (clearNotifications) {
          removeAllNotifications();
        }

        setTimeout(() => {
          sendParent('ON_NATIVE_UPDATE_DOWNLOADED');
        }, 1_000);
      });

      return () => {
        unsubscribe1();
        unsubscribe2();
        unsubscribe3();
        unsubscribe4();
      };
    };
  };
}

export const autoUpdateMachine = createMachine({
  predictableActionArguments: true,
  preserveActionOrder: true,
  id: 'auto-update-machine',
  context: {
    version: process.env.UI_APP_VERSION
  },
  invoke: {
    src: nativeUpdateCallback({ clearNotifications: true })
  },
  on: {
    ...nativeEvents
  },
  initial: 'idle',
  states: {
    idle: {
      after: {
        [ms('10secs')]: [
          {
            target: 'loading',
            cond: () => !window.isTouch
          }
        ]
      }
    },
    loading: {
      invoke: {
        src: loadAppVersion,
        onDone: [
          {
            target: 'app has no new version',
            cond: (context, event) => {
              return event.data.version === context.version;
            }
          },
          {
            target: 'app has new ui version'
          }
        ],
        onError: {
          target: 'failure'
        }
      }
    },
    failure: {
      after: {
        [ms('2min')]: {
          target: 'loading'
        }
      }
    },
    'app has no new version': {
      after: {
        [ms('10mins')]: {
          target: 'loading'
        }
      }
    },
    'app has new ui version': {
      initial: 'show update notification',
      states: {
        'show update notification': {
          invoke: {
            src: () => {
              return sendParent => {
                removeAllNotifications();

                showNotification({
                  type: 'dark-info',
                  messageName: 'update-is-available',
                  descriptionName: 'update-is-available',
                  position: 'top-right',
                  icon: 'info',
                  closable: true,
                  timeout: -1,
                  onClose() {
                    sendParent('ON_CLOSE');
                  },
                  actions: [
                    {
                      variant: 'info',
                      label: '🎉 Restart Now',
                      isRound: false,
                      className: '!min-w-full',
                      size: 'sm-wide',
                      onClick() {
                        sendParent('UPDATE_APP');
                      }
                    }
                  ]
                })();

                return nativeUpdateCallback({ clearNotifications: false })()(sendParent);
              };
            }
          },
          on: {
            ON_CLOSE: {
              target: 'user ignored notification ignored'
            },
            ON_CHECKING_FOR_NATIVE_UPDATES: {},
            ON_NATIVE_UPDATE_NOT_AVAILABLE: {},
            ON_NATIVE_UPDATE_AVAILABLE: {},
            ON_NATIVE_UPDATE_DOWNLOADED: {}
          }
        },
        'user ignored notification ignored': {
          after: {
            [ms('10mins')]: {
              target: 'show update notification'
            }
          },
          on: {
            ON_CHECKING_FOR_NATIVE_UPDATES: {
              target: 'show update notification'
            },
            ON_NATIVE_UPDATE_NOT_AVAILABLE: {
              target: 'show update notification'
            },
            ON_NATIVE_UPDATE_AVAILABLE: {
              target: 'show update notification'
            },
            ON_NATIVE_UPDATE_DOWNLOADED: {
              target: 'show update notification'
            }
          }
        }
      },
      on: {
        UPDATE_APP: [
          {
            actions: () => {
              window.location.reload();
            },
            cond: isWeb
          },
          {
            actions: [application.relaunch]
          }
        ]
      }
    },
    'app has new native version': {
      entry: [removeAllNotifications],
      on: {
        UPDATE_APP: {
          actions: [application.quitAndInstallUpdate]
        }
      }
    }
  }
});
