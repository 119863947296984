import { DATA } from '@constants';

import { filter } from '@functions/filter';
import { isEmpty } from '@functions/is';
import { realTypeIs } from '@helpers/type-is';

import { getID } from './data-helpers';
import { isSyncing, isSynced, isWaitingForDownload } from './machines/item/matchers';

export const downloaderRef = state => state.context.downloaderRef;
export const downloaderItemRef = state => state.context.downloaderItemRef;
export const saveToLibraryRef = state => state.context.saveToLibraryRef;

export const getRaw = state => {
  return state.context.raw;
};

export const getItem = id => state => {
  return getRaw(state)[id];
};

/**
 *
 * Download selectors
 */
export const getDownloadingItems = state => {
  const { songs, videos, podcasts } = state.context.data;

  let data = [];
  if (Array.isArray(songs)) {
    data = [...data, ...songs];
  }
  if (Array.isArray(videos)) {
    data = [...data, ...videos];
  }
  if (Array.isArray(podcasts)) {
    data = [...data, ...podcasts];
  }

  return data.filter(item => isSyncing(item.ref.state));
};

export const getDownloadedItems = state => {
  const { songs, videos, podcasts } = state.context.data;

  let data = [];
  if (Array.isArray(songs)) {
    data = [...data, ...songs];
  }
  if (Array.isArray(videos)) {
    data = [...data, ...videos];
  }
  if (Array.isArray(podcasts)) {
    data = [...data, ...podcasts];
  }

  return data.filter(item => isSynced(item.ref.state));
};

export const getDownloadedItemsCount = state => {
  return getDownloadedItems(state).length;
};

export const getWaitingToDownloadItems = state => {
  const { songs, videos, podcasts } = state.context.data;

  let data = [];
  if (Array.isArray(songs)) {
    data = [...data, ...songs];
  }
  if (Array.isArray(videos)) {
    data = [...data, ...videos];
  }
  if (Array.isArray(podcasts)) {
    data = [...data, ...podcasts];
  }

  return data.filter(item => isWaitingForDownload(item.ref.state));
};

/**
 *
 * @param {sort, query} formValues
 * @returns filtered list
 */
/**
 *
 *
 * LISTS
 *
 */
export const songs = formValues => state => {
  let items = state.context?.data?.songs ?? DATA.EMPTY_ARRAY;

  if (!isEmpty(formValues) && items?.length) {
    items = filter(items, formValues);
  }

  return items;
};

export const podcasts = formValues => state => {
  const items = state.context?.data?.podcasts ?? DATA.EMPTY_ARRAY;

  if (!isEmpty(formValues) && items?.length) {
    return filter(items, formValues);
  }

  return items;
};

export const videos = formValues => state => {
  const items = state.context?.data?.videos ?? DATA.EMPTY_ARRAY;

  if (!isEmpty(formValues) && items?.length) {
    return filter(items, formValues);
  }

  return items;
};

export const albums = formValues => state => {
  const items = state.context?.data?.albums ?? DATA.EMPTY_ARRAY;

  if (!isEmpty(formValues) && items?.length) {
    return filter(items, formValues);
  }

  return items;
};

/**
 *
 *
 * SINGLE ITEMS
 *
 */
export const song = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const video = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const podcast = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const show = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const album = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const artist = query => state => {
  const id = getID(query);
  const { context } = state;

  return context.raw?.[id] ?? null;
};

export const selectors = {
  mp3: song,
  video,
  podcast,
  show,
  album,
  artist
};

/**
 *
 *
 * Bulk items
 *
 */
export const isAllItemsInLibrary = items => state => {
  if (!items?.length) {
    return false;
  }

  return items.every(item => {
    const type = realTypeIs(item);

    if (!selectors[type]) {
      return false;
    }

    return !!selectors[type](item)(state);
  });
};

export const findRefs = items => state => {
  if (!items?.length) {
    return [];
  }

  return items
    .map(item => {
      const type = realTypeIs(item);

      if (!selectors[type]) {
        return false;
      }

      return selectors[type](item)(state)?.ref;
    })
    .filter(Boolean);
};
