import { interpret } from 'xstate';

import { appMachine } from '@machines/app/app-machine';

let __singletonInstance;
class AppService {
  constructor() {
    this.service = null;
  }

  init(context) {
    const machine = appMachine(context);

    this.service = interpret(machine).start();

    return this.service;
  }

  stopAppService() {
    if (!this.service) {
      return;
    }

    this.service.stop();

    this.service = undefined;
  }
}

export const getAppService = (...args) => {
  if (!__singletonInstance) {
    __singletonInstance = new AppService();

    return __singletonInstance.init(...args);
  }

  return __singletonInstance.service;
};

export const stopAppService = () => {
  if (!__singletonInstance) {
    return;
  }

  __singletonInstance.stopAppService();
};
