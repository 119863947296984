/* eslint-disable prefer-promise-reject-errors */
import { isDev } from '@functions/env';

const fallback = {
  ready() {}
};

let clientId;
export async function getRecaptchaToken() {
  window.grecaptcha = window.grecaptcha || fallback;

  const sitekey = isDev()
    ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    : process.env.RECAPTCHA_SITE_KEY;

  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      const params = {
        badge: 'inline',
        size: 'invisible',
        sitekey
      };

      if (typeof clientId === 'undefined') {
        clientId = window.grecaptcha.render('grecaptcha-placeholder', params);
      }

      window.grecaptcha
        .execute(clientId, { action: 'submit' })
        .then(token => {
          if (token) {
            resolve(token);

            return;
          }

          console.log('recaptcha token not found!');
          reject('recaptcha token not found!');
        })
        .catch(e => {
          console.log('cannot get token!', e);

          reject('cannot get token!');
        });
    });
  });
}
