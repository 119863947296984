import { useMemo } from 'react';

import { useAppService } from '@hooks';
import { usePartial, useMatchers } from '@state/hooks';
import { getAuthService } from '@machines/app/selectors';

export const useAuthService = () => {
  const service = useAppService();

  const ref = usePartial(service, getAuthService);

  return ref;
};

export const useAuthContext = selector => {
  const ref = useAuthService();
  const value = usePartial(ref, selector);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useAuthMatch = matcher => {
  const ref = useAuthService();
  const value = useMatchers(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};
