import { useCallback } from 'react';
import { useCommand } from '@hooks/native/useCommand';

import { navigate, toMedia, toHome, toBrowse, toSearch, toPlaylists } from './helpers';

const AppCommands = () => {
  const handleNavigateToMedia = useCallback((...args) => {
    setTimeout(() => {
      toMedia(...args);
    }, 1_000);
  }, []);

  useCommand('window:navigate', navigate);
  useCommand('application:navigate:media', handleNavigateToMedia);
  useCommand('window:home', toHome);
  useCommand('window:browse', toBrowse);
  useCommand('application:search', toSearch);
  useCommand('window:playlists', toPlaylists);

  return null;
};

export default AppCommands;
