import { assign } from 'xstate';

export function preventDefault(_, { e }) {
  if (!e) {
    return;
  }
  e.preventDefault();
  e.stopPropagation();
}

export const setDraggingContext = assign({
  draggingContext(_, event) {
    return {
      ...event.value
    };
  }
});

export const resetDraggingContext = assign({
  draggingContext() {
    return null;
  }
});

export const setDraggingOverContext = assign({
  draggingOverContext(_, event) {
    return event.value;
  }
});

export const resetDraggingOverContext = assign({
  draggingOverContext() {
    return null;
  }
});

export const setDraggingContextToWindow = ({ draggingContext }) => {
  if (process.browser) {
    window.draggingContext = draggingContext;
  }
};

export const setDraggingOverContextToWindow = ({ draggingOverContext }) => {
  if (process.browser) {
    window.draggingOverContext = draggingOverContext;
  }
};
