import { useMemo } from 'react';
import { useSelector } from '@xstate/react';

export const usePartial = (service, selector, compare) => {
  const state = useSelector(service, selector, compare);

  return useMemo(() => {
    return state;
  }, [state]);
};
