import { DESCRIPTIONS } from '@constants/app';
import { EVENT_NAMES as NOW_PLAYING_EVENT_NAMES } from '@constants/machines/now-playing';
import { EVENT_NAMES as LIBRARY_EVENT_NAMES } from '@constants/machines/library';

import {
  isPlaylist,
  isAlbum,
  isVideo,
  isMp3,
  isPodcast,
  isPodcastShow,
  isCustomMetadata,
  isArtist
} from '@functions/is';
import { getArtistName } from '@functions/get';
import { getTitle } from '@components/track/helpers';

export const getUser = state => state.context.user;
export const getUserHasSubscription = state => state.context.user?.hasSubscription;
export const getAllowSync = state => state.context.user && state.context.user.allow_sync;
export const getAllowLyrics = state => state.context.user && state.context.user.allow_lyrics;
export const getAllowSyncNewItems = state =>
  state.context.user && state.context.user.allow_sync_new_items;
export const getNotifyingUserToSubscribeValue = state => state.context.notifyingUserToSubscribe;
export const getNotifyingUserToSubscribeBecauseReachedSkipsLimitValue = state =>
  state.context.notifyingUserToSubscribeBecauseReachedSkipsLimit;
export const getNotifyingUserToSubscribeToViewLyricsValue = state =>
  state.context.notifyingUserToSubscribeToViewLyrics;

export const getActionsRequiringAuth = state => state.context.actionsRequiringAuth;

export const getActionsRequiringAuthSelectedData = state => {
  const data = getActionsRequiringAuth(state);

  if (!data) {
    return null;
  }

  const { actionType } = data;

  if (actionType === NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT) {
    const { metadata } = data;
    if (metadata) {
      if (!isCustomMetadata(metadata)) {
        return metadata;
      }
    }
  }

  if (actionType === NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING) {
    return {};
  }

  if (actionType === LIBRARY_EVENT_NAMES.LIBRARY.SAVE) {
    const { metadata, items } = data;

    if (metadata) {
      return metadata;
    }

    if (items?.length === 1) {
      return items[0];
    }

    // TODO: FIND A WAY TO SET CORRECT DATA OR HANDLE TO SHOW A LIST
    if (items?.length > 1) {
      return items[0];
    }

    return data;
  }

  if (
    actionType === 'LIKE' ||
    actionType === 'FOLLOW_ARTIST' ||
    actionType === 'FOLLOW_PLAYLIST' ||
    actionType === 'FOLLOW_PODCAST_SHOW'
  ) {
    return data.data;
  }

  return data?.item;
};

export const getActionsRequiringAuthTitle = state => {
  const data = getActionsRequiringAuth(state);
  const selectedData = getActionsRequiringAuthSelectedData(state);

  if (!selectedData) {
    return '';
  }

  const { actionType } = data;

  if (actionType === NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT) {
    if (isPlaylist(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.PLAYLIST(getTitle(selectedData));
    }

    if (isAlbum(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.ALBUM(getTitle(selectedData));
    }

    if (isVideo(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.VIDEO(title);
    }

    if (isMp3(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.SONG(title);
    }

    if (isPodcast(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.PODCAST(title);
    }

    if (isPodcastShow(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.PODCAST_SHOW(getTitle(selectedData));
    }

    if (isArtist(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.PLAY.ARTIST(getArtistName(selectedData));
    }

    return '';
  }

  if (actionType === NOW_PLAYING_EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING) {
    return DESCRIPTIONS.REQUIRE_AUTH.PLAY.LIVE_STREAMING;
  }

  if (actionType === LIBRARY_EVENT_NAMES.LIBRARY.SAVE) {
    if (isPlaylist(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.PLAYLIST(getTitle(selectedData));
    }

    if (isAlbum(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.ALBUM(getTitle(selectedData));
    }

    if (isVideo(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.VIDEO(title);
    }

    if (isMp3(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.SONG(title);
    }

    if (isPodcast(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.PODCAST(title);
    }

    if (isPodcastShow(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.PODCAST_SHOW(getTitle(selectedData));
    }

    if (isArtist(selectedData)) {
      return DESCRIPTIONS.REQUIRE_AUTH.DOWNLOAD.ARTIST(getArtistName(selectedData));
    }

    return '';
  }

  if (actionType === 'LIKE') {
    if (isVideo(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.LIKE.VIDEO(title);
    }

    if (isMp3(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.LIKE.SONG(title);
    }

    if (isPodcast(selectedData)) {
      const title = `${getArtistName(selectedData)} - ${getTitle(selectedData)}`;

      return DESCRIPTIONS.REQUIRE_AUTH.LIKE.PODCAST(title);
    }
  }

  if (actionType === 'FOLLOW_ARTIST') {
    return DESCRIPTIONS.REQUIRE_AUTH.FOLLOW.ARTIST(getArtistName(selectedData));
  }

  if (actionType === 'FOLLOW_PLAYLIST') {
    return DESCRIPTIONS.REQUIRE_AUTH.FOLLOW.PLAYLIST(getTitle(selectedData));
  }

  if (actionType === 'FOLLOW_PODCAST_SHOW') {
    return DESCRIPTIONS.REQUIRE_AUTH.FOLLOW.PODCAST_SHOW(getTitle(selectedData));
  }

  return '';
};

// UPDATE USER PROFILE SELECTORS
export const getUpdateUserProfileRef = state => state.context.updateUserProfileRef;
export const getSelectedFile = state => state.context.selectedFile;
export const getShowModalValue = state => state.context.showModal;
