import { useMemo } from 'react';

import { useAppService } from '@hooks';
import { usePartial } from '@state/hooks';
import { getAppConfigService } from '@machines/app/selectors';

export const useAppConfigContext = selector => {
  const appService = useAppService();
  const appConfigService = usePartial(appService, getAppConfigService);
  const value = usePartial(appConfigService, selector);

  return useMemo(() => {
    return value;
  }, [value]);
};
