import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';

export function isMp3(data) {
  return data.type === 'mp3';
}

export function isStory(data) {
  return data.type === 'selfie';
}

export function isStoryVideo(data) {
  return data.type === 'story_video';
}

export function isSpecial(data) {
  return data.type === 'special';
}

export function isLikedSongs(data) {
  return data.type === 'liked_music';
}

export function isPlaylist(data) {
  return data.type === 'playlist';
}

export function isPlaylistMp3Category(data) {
  return data.type === 'mp3s_category';
}

export function isPlaylistVideoCategory(data) {
  return data.type === 'videos_category';
}

export function isPlaylistCategory(data) {
  return isPlaylistMp3Category(data) || isPlaylistVideoCategory(data);
}

export function isMp3Playlist(data) {
  return data.subtype === 'mp3' || data.subType === 'mp3';
}

export function isArtist(data) {
  return data.type === 'artist' || (data.query && data.name);
}

export function isVideo(data) {
  return data.type === 'video';
}

export function isLiveStream(data) {
  return data.type === 'live-stream';
}

export function isPodcast(data) {
  return data.type === 'podcast';
}

export function isAd(data) {
  return data.type === 'ad';
}

export function isAdLink(data) {
  return data?.cta?.type === 'link';
}

export function isAdSubscription(data) {
  return data?.cta?.type === 'subscription';
}

export function isExplicit(data) {
  return get(data, 'item.explicit', data.explicit || false);
}

export function isPodcastShow(data) {
  if (data.show_permlink && data.permlink && data.show_permlink === data.permlink) {
    return true;
  }

  return (
    (data.podcasts &&
      data.podcasts.length &&
      data.permlink &&
      data.title &&
      data.type === 'show') ||
    data.type === 'show'
  );
}

export function isMp3Album(data) {
  return data.type === 'mp3_album';
}

export function isLibraryAlbum(data) {
  return data.type === 'album';
}

export function isAlbum(media) {
  const data = media.item?.id ? media.item : media;

  return !!(
    (isMp3(data) && data.album_album && data.album_artist) ||
    isMp3Album(media) ||
    isLibraryAlbum(data)
  );
}

export function isMyPlaylist(data) {
  return isPlaylist(data) && data.myplaylist;
}

export function isNotMyPlaylist(data) {
  return isPlaylist(data) && !data.myplaylist;
}

export function isNotPlaylist(data) {
  return !isPlaylist(data);
}

export function isComingSoon(data) {
  return data.type === 'comingsoon';
}

export function isPhotoAlbum(data) {
  return data.type === 'album' && Array.isArray(data.thumbs);
}

export function isUser(data) {
  if (data.type === 'user') {
    return true;
  }

  if (
    typeof data.followers_count !== 'undefined' &&
    typeof data.artists_count !== 'undefined' &&
    typeof data.display_name !== 'undefined' &&
    // typeof data.username !== 'undefined' &&
    typeof data.email !== 'undefined'
  ) {
    return true;
  }

  return false;
}

export function isPhotoThumb(data) {
  return data.type === 'photo_thumb';
}

export function isEvent(data) {
  return data.type === 'event';
}

export function isSync(data) {
  return !!get(data, 'synced');
}

export function isPodcastTalk(data) {
  return isPodcast(data) && data.talk;
}

export function isLink(data) {
  return data.type === 'link';
}

export function isHeaderLink(data) {
  return data.show_link && data.link;
}

export function isArtistFullyLoaded(data) {
  if (!isArtist(data)) {
    return false;
  }

  return !isUndefined(data.mp3s) && !isUndefined(data.videos) && data.query;
}

export function isVoted(data) {
  return data && !!data.vote;
}

export function isLiked(data) {
  return isVoted(data) && data?.vote === '5';
}

export function isNoneLiked(data) {
  return !isVoted(data) || data.vote === '1';
}

export function isUnLiked(data) {
  return isVoted(data) && data.vote === '1';
}

export function isNoneUnlike(data) {
  return !isVoted(data) || data.vote === '5';
}

export function isLikedStory(data) {
  return data.vote === '5';
}

export function isFollowingArtist(data) {
  return data?.followers?.following ?? false;
}

export function isFollowingPlaylist(data) {
  return data?.following;
}

export function isFollowingPodcastShow(data) {
  return isPodcastShow(data) && data?.followers?.following;
}

export function isFollowingUser(data) {
  return !!data?.following;
}

export function isItemInPlaylist(playlist, item) {
  return !!find(playlist.items, { id: item.id });
}

export function isPublicPlaylist(data) {
  return data.public;
}

export function isCustomMetadata(data) {
  return data.type === 'custom';
}

export function isHiddenPlaylistItem(data) {
  return typeof data.hidden === 'boolean' && data.hidden;
}

export function isDraggable(data) {
  if (isMyPlaylist(data) || isStory(data)) {
    return false;
  }

  return (
    isMp3(data) ||
    isAlbum(data) ||
    isVideo(data) ||
    isArtist(data) ||
    isPodcast(data) ||
    isPlaylist(data)
  );
}

export function isEmpty(object) {
  if (!object || typeof object !== 'object') {
    return true;
  }

  const { toggler, ...rest } = object;

  return Object.keys(rest).length === 0;
}
/**
 * Sections type
 */
export function isHeaderSection({ type }) {
  return type === 'header';
}

export function isRecentlyPlayedHeader(data) {
  return isHeaderSection(data) && data.id === 'recently_played_header';
}

export function isSlider(data) {
  return data.type === 'slider';
}

export function isHomeSlider(data) {
  return data.type === 'slider' && data?.id?.includes('home_slider');
}

export function isAlbumSlider(data) {
  return data.type === 'slider' && data?.id?.includes('slider_album');
}

export function isVideoSlider(data) {
  // return data.type === 'slider' && data.id === 'videos';
  return data.id === 'videos';
}

export function isRecentlyPlayedSlider(data) {
  // return data.type === 'slider' && data.id === 'videos';
  return data.id === 'recently_played';
}

export function isCategoriesSection(data) {
  return data.id === 'categories';
}

export function isTopResult(data) {
  return data.id === 'top_result';
}

export function isPlaylistCreatedByRadioJavan(data) {
  return data.created_by?.includes(process.env.APP_NAME);
}
