import { assign } from 'xstate';

export const setQueueKeys = assign({
  queueKeys({ queue }) {
    return queue.map(item => item._id);
  }
});

export const updateQueue = assign({
  queue({ queue, queueKeys }, { items }) {
    if (!items) {
      return queue;
    }

    const filteredItems = items.filter(item => {
      return !queueKeys.includes(item._id);
    });

    return [...queue, ...filteredItems];
  }
});

export const removeItem = assign({
  queue({ queue }, event) {
    return queue.filter(item => item._id !== event.item._id);
  }
});
