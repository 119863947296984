import { Component, createElement } from 'react';

class Delay extends Component {
  unMount = false;

  constructor(props) {
    super(props);
    this.state = {
      show: false || props.disable
    };
  }

  componentDidMount() {
    const { delay, disable } = this.props;
    if (!disable) {
      setTimeout(() => {
        if (!this.unMount) {
          this.setState({
            show: true
          });
        }
      }, delay);
    }
  }

  componentWillUnmount() {
    this.unMount = true;
  }

  render() {
    const { show } = this.state;
    if (show) {
      const { children, component } = this.props;

      if (component) {
        return createElement(component);
      }

      return children;
    }
    return null;
  }
}

Delay.defaultProps = {
  delay: 0,
  disable: false
};

export default Delay;
