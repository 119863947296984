import { MACHINE_NAMES, STATES, EVENT_NAMES } from '@constants/machines/search-history';

import { loadSearchHit } from '@api/search';
import { getSearchHitParams } from '@functions/get';
import { queuefy } from '@functions/http';

import { createMachine, forwardTo, send, actions as xstateActions } from 'xstate';

import * as actions from './actions';

const { pure } = xstateActions;

export const searchHistoryMachine = createMachine(
  {
    id: MACHINE_NAMES.SEARCH_HISTORY,
    context: {
      storageRef: null,
      items: [],
      focused: false
    },
    on: {
      [EVENT_NAMES.SEARCH_HISTORY.DESTROY]: {
        target: STATES.DESTROYING
      },
      [EVENT_NAMES.SEARCH.TOGGLE_INPUT_FOCUS]: {
        actions: ['toggleFocus']
      }
    },
    initial: STATES.LOADING,
    states: {
      [STATES.LOADING]: {
        after: {
          1_000: {
            actions: ['setStorageRef']
          }
        },
        on: {
          [EVENT_NAMES.SEARCH_HISTORY.SET_ITEMS]: {
            actions: ['setItems'],
            target: STATES.ACTIVE
          }
        }
      },
      [STATES.ACTIVE]: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              [EVENT_NAMES.SEARCH_HISTORY_STORAGE.SEARCH_HIT]: {
                target: 'loading'
              }
            }
          },
          loading: {
            invoke: {
              src: (_, { data }) => {
                if (Array.isArray(data)) {
                  const promises = data.map(item => {
                    const params = getSearchHitParams(item);

                    return loadSearchHit({ data: params });
                  });

                  return queuefy({
                    promises,
                    onError: error => {
                      throw new Error(error);
                    }
                  });
                }

                const params = getSearchHitParams(data);

                return loadSearchHit({ data: params });
              },
              onDone: {
                target: 'idle'
              },
              onError: [
                {
                  target: 'idle',
                  cond: () => window?.navigator?.onLine
                },
                {
                  target: 'idle',
                  actions: [
                    pure((_, { data }) => {
                      // coming from axios options
                      if (data?.config?.data) {
                        const parsedData = JSON.parse(data.config.data);

                        return [
                          send(
                            {
                              type: EVENT_NAMES.SEARCH_HISTORY_STORAGE.ADD_FAILED_SEARCH_HIT,
                              data: parsedData
                            },
                            { to: MACHINE_NAMES.SEARCH_HISTORY_STORAGE }
                          )
                        ];
                      }

                      return [];
                    })
                  ]
                }
              ]
            }
          }
        },
        on: {
          [EVENT_NAMES.SEARCH_HISTORY.SET_ITEMS]: {
            actions: ['setItems'],
            target: STATES.ACTIVE
          },
          [EVENT_NAMES.SEARCH_HISTORY_STORAGE.ADD]: {
            actions: [forwardTo(MACHINE_NAMES.SEARCH_HISTORY_STORAGE)]
          },

          [EVENT_NAMES.SEARCH_HISTORY_STORAGE.REMOVE]: {
            actions: [forwardTo(MACHINE_NAMES.SEARCH_HISTORY_STORAGE)]
          },
          [EVENT_NAMES.SEARCH_HISTORY_STORAGE.CLEAR]: {
            actions: [forwardTo(MACHINE_NAMES.SEARCH_HISTORY_STORAGE)]
          }
        }
      },
      [STATES.DESTROYING]: {
        entry: [
          send(EVENT_NAMES.SEARCH_HISTORY_STORAGE.DESTROY, {
            to: MACHINE_NAMES.SEARCH_HISTORY_STORAGE
          })
        ],
        always: {
          target: STATES.DESTROYED
        }
      },
      [STATES.DESTROYED]: {
        type: 'final'
      }
    }
  },
  {
    actions
  }
);
