export const EVENT_NAMES = {
  SEARCH: {
    TOGGLE_INPUT_FOCUS: 'SEARCH_HISTORY__TOGGLE_INPUT_FOCUS'
  },
  SEARCH_HISTORY: {
    DESTROY: 'SEARCH_HISTORY__DESTROY',
    SET_ITEMS: 'SEARCH_HISTORY__SET_ITEMS'
  },
  SEARCH_HISTORY_STORAGE: {
    DESTROY: 'SEARCH_HISTORY_STORAGE__DESTROY',
    ADD: 'SEARCH_HISTORY_STORAGE__ADD',
    ADD_FAILED_SEARCH_HIT: 'SEARCH_HISTORY_STORAGE__ADD_FAILED_SEARCH_HIT',
    REMOVE: 'SEARCH_HISTORY_STORAGE__REMOVE',
    CLEAR: 'SEARCH_HISTORY_STORAGE__CLEAR',
    SEARCH_HIT: 'SEARCH_HISTORY_STORAGE__SEARCH_HIT'
  }
};

export const STATES = {
  LOADING: 'LOADING',
  ACTIVE: 'ACTIVE',
  DESTROYING: 'DESTROYING',
  DESTROYED: 'DESTROYED'
};

export const MACHINE_NAMES = {
  SEARCH_HISTORY: 'SEARCH_HISTORY_MACHINE',
  SEARCH_HISTORY_STORAGE: 'SEARCH_HISTORY_STORAGE_MACHINE'
};
