import debounce from 'lodash/debounce';

import { useState, useCallback, useMemo, useEffect } from 'react';

const useScroll = ({ ref, debounceTime = 300, maxRange }) => {
  const [state, setState] = useState(() => ({ top: 0, end: false }));

  const handleSetState = useCallback(
    debounce(() => {
      const scroller = ref.current?.getScrollElement() || ref.current;

      if (!scroller) {
        return;
      }

      const top = scroller.scrollY || scroller.scrollTop;
      const elHeight = scroller.clientHeight;
      const end =
        elHeight + top === scroller.scrollHeight ||
        Math.ceil(elHeight + top) === scroller.scrollHeight;

      setState(currentState => {
        if (maxRange && top > maxRange && currentState.top !== 0 && currentState.end === end) {
          return currentState;
        }

        return {
          top,
          end
        };
      });
    }, debounceTime),
    [state.top, ref.current]
  );

  useEffect(() => {
    const scroller = ref.current?.getScrollElement() || ref.current;

    if (!scroller?.addEventListener) {
      return () => {};
    }

    handleSetState();

    scroller.addEventListener('scroll', handleSetState);
    window.addEventListener('resize', handleSetState);

    return () => {
      scroller.removeEventListener('scroll', handleSetState);
      window.removeEventListener('resize', handleSetState);
    };
  }, [ref, ref.current]);

  return useMemo(() => state, Object.values(state));
};

export default useScroll;
