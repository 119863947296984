import {
  MY_MUSIC_GET_LIBRARY_URL,
  MY_MUSIC_SAVE_TO_LIBRARY_URL,
  MY_MUSIC_REMOVE_FROM_LIBRARY_URL,
  MY_MUSIC_GET_LIBRARY_URL_CLEAR_ALL
} from '@constants/urls';
import api from './api';

export const loadLibrary = updatedSince => {
  return api({
    url: MY_MUSIC_GET_LIBRARY_URL,
    method: 'POST',
    params: {
      full: 1,
      updated_since: updatedSince
    }
  });
};

export const saveToLibrary = data => {
  return api({
    url: MY_MUSIC_SAVE_TO_LIBRARY_URL,
    method: 'POST',
    data: {
      items: data.items.map(item => ({
        item_id: item.item_id || item.id,
        item_type: item.item_type || item.type
      }))
    }
  });
};

export const removeFromLibrary = data => {
  return api({
    url: MY_MUSIC_REMOVE_FROM_LIBRARY_URL,
    method: 'POST',
    data: {
      items: data.items.map(item => ({
        item_id: item.item_id || item.id,
        item_type: item.item_type || item.type
      }))
    }
  });
};

export const clearAllLibrary = () => {
  return api({
    url: MY_MUSIC_GET_LIBRARY_URL_CLEAR_ALL,
    method: 'POST'
  });
};
