import isBoolean from 'lodash/isBoolean';

import { useState, useEffect, useCallback } from 'react';

import {
  isFullScreen,
  toggleFullScreen,
  openFullscreen,
  closeFullscreen
} from '@helpers/dom/full-screen';

const useFullScreen = () => {
  const [state, setIsFullScreen] = useState(() => {
    return process.browser ? isFullScreen() : false;
  });

  const onFullscreenChanged = useCallback(() => {
    setIsFullScreen(isFullScreen());
  }, []);

  const toggle = useCallback(value => {
    if (isBoolean(value)) {
      if (value) {
        openFullscreen();
        return;
      }
      closeFullscreen();
      return;
    }

    toggleFullScreen();
  }, []);

  useEffect(() => {
    setIsFullScreen(isFullScreen());

    document.addEventListener('fullscreenchange', onFullscreenChanged);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChanged);
    };
  }, []);

  return {
    toggle,
    isFullScreen: state
  };
};

export default useFullScreen;
