import { EVENT_NAMES } from '@constants/machines/app-settings';
import isUndefined from 'lodash/isUndefined';

import { useState, useMemo, useCallback } from 'react';
import { useAppSettingsRef, useAppSettingsContext, useUpdateEffect } from '@hooks';
import { getBrowserSettings, getNativeSettings } from '@machines/app/app-settings/selectors';
import { isDev } from '@functions/env';

const empty = () => null;

export const useBrowserPersistedState = (key, defaultValue) => {
  const service = useAppSettingsRef();

  const selector = useMemo(() => {
    if (!key) {
      return empty;
    }
    return getBrowserSettings(key);
  }, [key]);

  const value = useAppSettingsContext(selector);

  const [state, setState] = useState(() => {
    return isUndefined(value) ? defaultValue : value;
  });

  const set = useCallback(
    newValue => {
      if (!key) {
        return;
      }
      service.send(EVENT_NAMES.BROWSER_SETTINGS.SET, { key, value: newValue });
    },
    [key]
  );

  useUpdateEffect(() => {
    if (isDev()) {
      if (isUndefined(key)) {
        console.warn('You may forget to export your storage name in useBrowserPersistedState');
      }
    }
    if (!key) {
      return;
    }

    set(state);
  }, [key, state]);

  return [isUndefined(value) ? defaultValue : value, setState, set];
};

export const useNativePersistedState = (key, defaultValue) => {
  const service = useAppSettingsRef();

  const selector = useMemo(() => {
    return getNativeSettings(key);
  }, [key]);

  const value = useAppSettingsContext(selector);

  const [state, setState] = useState(() => {
    return isUndefined(value) ? defaultValue : value;
  });

  useUpdateEffect(() => {
    service.send(EVENT_NAMES.NATIVE_SETTINGS.SET, { key, value: state });
  }, [key, state]);

  return [isUndefined(value) ? defaultValue : value, setState];
};
