import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/library';
import { isWeb } from '@functions/env';

import { createMachine, send, sendParent, actions as xstateActions } from 'xstate';

import * as actions from './actions';

const { choose } = xstateActions;

/**
 * !IMPORTANT: Used in DownloadLinkRequiredLink.js
 */

export const downloaderMachine = createMachine(
  {
    id: MACHINE_NAMES.DOWNLOADER_ITEM,
    context: {
      downloaderRef: null,
      progress: 0,

      // will be passed .withContext
      link: null
    },
    initial: STATES.DOWNLOADER_ITEM.IDLE,
    states: {
      [STATES.DOWNLOADER_ITEM.IDLE]: {
        on: {
          [EVENT_NAMES.DOWNLOADER_ITEM.SYNC]: {
            target: STATES.DOWNLOADER_ITEM.SYNCING,
            actions: ['setDownloaderRef']
          }
        }
      },

      [STATES.DOWNLOADER_ITEM.SYNCING]: {
        on: {
          /**
           * Receiving from MACHINE_NAMES.FETCHER
           */
          [EVENT_NAMES.DOWNLOADER_ITEM.STARTED]: {},
          [EVENT_NAMES.DOWNLOADER_ITEM.PROGRESS]: {
            actions: ['updateProgress']
          },
          [EVENT_NAMES.DOWNLOADER_ITEM.COMPLETED]: {
            target: STATES.DOWNLOADER_ITEM.SYNCED
          },
          [EVENT_NAMES.DOWNLOADER_ITEM.FAILED]: {
            target: STATES.DOWNLOADER_ITEM.IDLE
          },
          /**
           * Receiving from MACHINE_NAMES.ITEM
           */
          [EVENT_NAMES.DOWNLOADER_ITEM.CANCEL]: {
            target: STATES.DOWNLOADER_ITEM.CANCELING
          }
        }
      },

      [STATES.DOWNLOADER_ITEM.CANCELING]: {
        entry: send(EVENT_NAMES.FETCHER.CANCEL, {
          to: MACHINE_NAMES.FETCHER
        }),
        on: {
          [EVENT_NAMES.DOWNLOADER_ITEM.CANCELLED]: {
            target: STATES.DOWNLOADER_ITEM.CANCELLED
          }
        }
      },

      [STATES.DOWNLOADER_ITEM.CANCELLED]: {
        target: STATES.DOWNLOADER_ITEM.IDLE
      },

      [STATES.DOWNLOADER_ITEM.SYNCED]: {
        entry: choose([
          {
            actions: ['onDownloadFinished'],
            cond: () => isWeb()
          },
          {
            actions: [
              sendParent((_, event) => {
                return { type: EVENT_NAMES.DOWNLOADER_ITEM.SYNCED, ...event.data };
              })
            ]
          }
        ])
      }
    }
  },
  {
    actions: {
      ...actions,
      // this is external event listener used in download file component
      onDownloadFinished() {}
    },
    guards: {}
  }
);
