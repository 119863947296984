import platform from 'platform';

import { isWeb, isTest } from '@functions/env';

function getClientName() {
  if (isTest()) {
    return isWeb() ? 'com.radioJavan.rj.web.staging' : 'com.radioJavan.rj.desktop.staging';
  }

  return isWeb() ? 'com.radioJavan.rj.web' : 'com.radioJavan.rj.desktop';
}

export function getXRJHeader() {
  // you have access to user agent
  const appFullVersion = `Radio Javan/${process.env.APP_VERSION}/${process.env.UI_APP_VERSION}`;

  return [appFullVersion, getClientName()].join(' ');
}

export function isQuotaSupported() {
  return typeof window?.navigator?.storage?.estimate === 'function';
}

const mobiles = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i
];

export function isTouch({ userAgent: userAgentProp } = {}) {
  try {
    const userAgent = process.browser ? window.navigator.userAgent : userAgentProp;

    return mobiles.some(item => {
      return userAgent.match(item);
    });
  } catch (e) {
    return false;
  }
}

/**
 * @param {parsedPlatform} param0 coming from server side
 * @returns check if platform is safari
 */
export function isIOS({ parsedPlatform } = {}) {
  if (parsedPlatform) {
    return parsedPlatform?.os?.family?.toLowerCase() === 'ios';
  }

  return platform?.os?.family?.toLowerCase() === 'ios';
}

export function isAndroid({ parsedPlatform } = {}) {
  if (parsedPlatform) {
    return parsedPlatform?.os?.family?.toLowerCase() === 'android';
  }

  return platform?.os?.family?.toLowerCase() === 'android';
}

export function isSafari({ parsedPlatform } = {}) {
  if (parsedPlatform) {
    return parsedPlatform.name?.toLowerCase() === 'safari';
  }

  return platform.name?.toLowerCase() === 'safari';
}

export function isMediaSessionSupported() {
  return typeof window.MediaMetadata === 'function' && 'mediaSession' in navigator;
}
