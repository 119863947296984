import { isDev } from '@functions/env';

export function getCookieDomainRewrite() {
  if (isDev()) {
    return '192.168.1.239';
  }

  /**
   * Check if app is host on sub domain of radiojavan.com set same domain to force the authentication to work.
   */
  if (process.env.SERVE_URL?.includes('.radiojavan')) {
    return `.radiojavan.com`;
  }

  return `.${process.env.SERVE_URL.replace('https://', '')}`;
}

export function getWhiteListEmails() {
  return process.env.WHITELIST_EMAILS?.split(',') || [];
}
