import { EVENT_NAMES as APP_SETTINGS_EVENT_NAMES } from '@constants/machines/app-settings';
import { EVENT_NAMES as AUTH_EVENT_NAMES } from '@constants/machines/auth';
import { APP_SETTINGS } from '@constants';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService, getAuthService } from '@machines/app/selectors';

import { streamMachine } from '@machines/now-playing/item/stream-machine';

import { isVideo, isPodcast } from '@functions/is';
import { isCloseToEnd } from '@functions/math/is';
import { getID } from '@functions/get';

import { send, actions, assign, spawn } from 'xstate';

const { pure } = actions;

const { LAST_PLAYED_TIME, LAST_PLAYED_TIME_BEFORE_CLOSING_APP } = APP_SETTINGS.NOW_PLAYING;

export const setLastPlayedCurrentTimeForPodcast = pure(({ streamSource, ...context }) => {
  if (!isPodcast(context)) {
    return [];
  }

  const appService = getAppService();
  const appSettingsService = getAppSettingsService(appService.state);

  const id = getID(context);

  if (isCloseToEnd(streamSource.currentTime, streamSource.duration)) {
    return [
      send(
        {
          type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
          key: LAST_PLAYED_TIME,
          subKey: id
        },
        { to: appSettingsService }
      )
    ];
  }

  return [
    send(
      {
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.SET,
        /**
         * Key: name of the property in storage
         */
        key: LAST_PLAYED_TIME,
        subKey: id,
        value: streamSource.currentTime
      },
      { to: appSettingsService }
    )
  ];
});

export const removeLastPlayedCurrentTimePodcast = pure(context => {
  if (!isPodcast(context)) {
    return [];
  }

  const appService = getAppService();
  const appSettingsService = getAppSettingsService(appService.state);

  const id = getID(context);

  return [
    send(
      {
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: LAST_PLAYED_TIME,
        subKey: id
      },
      { to: appSettingsService }
    )
  ];
});

export const setLastPlayedCurrentTime = pure(({ streamSource }) => {
  const appService = getAppService();
  const appSettingsService = getAppSettingsService(appService.state);

  const value = isCloseToEnd(streamSource.currentTime, streamSource.duration)
    ? 0
    : streamSource.currentTime;

  return [
    send(
      {
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.SET,
        key: LAST_PLAYED_TIME_BEFORE_CLOSING_APP,
        value
      },
      { to: appSettingsService }
    )
  ];
});

export const removeLastPlayedCurrentTime = pure(() => {
  const appService = getAppService();
  const appSettingsService = getAppSettingsService(appService.state);

  return [
    send(
      {
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.REMOVE,
        key: LAST_PLAYED_TIME_BEFORE_CLOSING_APP
      },
      {
        to: appSettingsService
      }
    )
  ];
});

export const setStreamSource = assign({
  streamSource(context) {
    if (isVideo(context)) {
      const video = document.createElement('video');
      video.setAttribute('playsinline', 'playsinline');
      return video;
    }

    const audio = document.createElement('audio');
    return audio;
  }
});

export const setStreamMachine = assign({
  streamRef(context) {
    return spawn(streamMachine(context), 'streamRef');
  }
});

export const setSilent = assign({
  silent() {
    return true;
  }
});

export const resetSilent = assign({
  silent() {
    return false;
  }
});

export const setPreload = assign({
  preload() {
    return true;
  }
});

export const resetPreload = assign({
  preload() {
    return false;
  }
});

export const toggleNotifyUserToSubscribeBecauseReachedSkipLimits = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return [
    send(
      {
        type: AUTH_EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_BECAUSE_REACHED_SKIP_LIMITS,
        value: true
      },
      { to: authService }
    )
  ];
});
