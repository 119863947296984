import useSWR from 'swr';
import { useBooleanState } from '@hooks';
import { useCallback, useMemo } from 'react';

export const getDataKey = ({ key, params }) => {
  const newKey = [key];

  if (params) {
    const keys = Object.keys(params);

    const isValid = Object.values(params).some(item => !!item);

    if (!isValid) {
      return null;
    }

    for (let i = 0; i < keys.length; i++) {
      const element = `${keys[i]}_${params[keys[i]]}`;

      newKey.push(element);
    }
  }

  return newKey.join('-');
};

export const useData = ({ key, loader, params, modifier, modifierProps, ...options }) => {
  const [isLoadingSlow, onLoadingSlow] = useBooleanState(false);

  const fetcher = useCallback(() => {
    return loader(params);
  }, [params]);

  const newKey = getDataKey({ key, params });

  const { data, ...rest } = useSWR(key ? newKey : null, fetcher, {
    ...options,
    onLoadingSlow
  });

  return useMemo(() => {
    const modifierResult = modifier ? modifier({ data, ...modifierProps }) : {};

    if (!data) {
      return {
        ...rest,
        isLoading: !data,
        isLoadingSlow: isLoadingSlow && !data,
        data,
        ...modifierResult
      };
    }

    return {
      data,
      ...rest,
      ...modifierResult
    };
  }, [isLoadingSlow, data, modifierProps, rest.isLoading, rest.isValidating]);
};

export { mutate } from 'swr';
