import get from 'lodash/get';
import isObject from 'lodash/isObject';

import { getArtistName } from '@functions/get';

export const hasMore = data => {
  return !!data.show_link;
};

export const hasAlbum = (media = {}) => {
  const data = isObject(media.item) ? media.item : media;

  return !!get(data, 'album.album', get(data, 'album_tracks[0].album'));
};

export const hasSameArtist = items => {
  if (!items) {
    return true;
  }

  const instance = getArtistName(items[0]);

  return items.every(item => {
    return getArtistName(item) === instance;
  });
};

export const hasBackgroundVideo = data => {
  return data.background_video_hide_cover && data.background_video_url;
};

export const hasSyncedLyrics = data => {
  return data?.lyric_synced?.length >= 0;
};

export const hasLyrics = data => {
  return typeof data?.lyric !== 'undefined';
};

export const hasAnyLyrics = data => {
  return hasSyncedLyrics(data) || hasLyrics(data);
};

export const hasBackgroundColors = data => {
  return Array.isArray(data.bg_colors);
};

export const hasSubscription = ({ user }) => {
  return user?.hasSubscription;
};
