import isBoolean from 'lodash/isBoolean';

import { COMMANDS } from '@shared/constants';

import { useState, useEffect, useCallback } from 'react';
import { useMultipleCommand } from '@hooks/native/useCommand';

const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onFullscreenChanged = useCallback(({ state }) => {
    setIsFullScreen(state);
  }, []);

  const toggle = useCallback(value => {
    const state = isBoolean(value) ? value : undefined;

    window.native.sendCommand(
      COMMANDS.WINDOW.FULLSCREEN.TOGGLE,
      // window name will be focused window
      undefined,
      state
    );
  }, []);

  useMultipleCommand(COMMANDS.WINDOW.FULLSCREEN.CHANGED, onFullscreenChanged);

  useEffect(() => {
    window.native.sendCommand(COMMANDS.WINDOW.FULLSCREEN.STATE);

    setTimeout(() => {
      window.native.sendCommand(COMMANDS.WINDOW.FULLSCREEN.STATE);
    }, 500);
  }, []);

  return {
    toggle,
    isFullScreen
  };
};

export default useFullScreen;
