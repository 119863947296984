import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/app-settings';

import { createMachine, assign, spawn, forwardTo, send } from 'xstate';

import { isNative } from '@functions/env';

import { browserSettingsMachine } from './browser-settings-machine';
import { nativeSettingsMachine } from './native-settings-machine';

import * as actions from './actions';

export const appSettingsMachine = createMachine(
  {
    id: MACHINE_NAMES.APP_SETTINGS,
    initial: STATES.APP_SETTINGS.LOADING.INDEX,
    context: {
      // coming from ssr - static
      user: null,

      browserSettingsRef: null,
      nativeSettingsRef: null,

      nativeSettings: {},
      browserSettings: {}
    },
    on: {
      [EVENT_NAMES.APP_SETTINGS.AUTHENTICATED]: {
        actions: [
          send(
            {
              type: EVENT_NAMES.BROWSER_SETTINGS.AUTHENTICATED
            },
            {
              to: MACHINE_NAMES.BROWSER_SETTINGS
            }
          )
        ]
      },
      [EVENT_NAMES.APP_SETTINGS.UNAUTHENTICATED]: {
        actions: [
          send(
            {
              type: EVENT_NAMES.BROWSER_SETTINGS.UNAUTHENTICATED
            },
            {
              to: MACHINE_NAMES.BROWSER_SETTINGS
            }
          )
        ]
      }
    },
    states: {
      [STATES.APP_SETTINGS.LOADING.INDEX]: {
        id: STATES.APP_SETTINGS.LOADING.INDEX,
        initial: STATES.APP_SETTINGS.LOADING.LOADING_BROWSER_SETTINGS,
        states: {
          [STATES.APP_SETTINGS.LOADING.LOADING_BROWSER_SETTINGS]: {
            after: {
              50: {
                actions: [
                  assign({
                    browserSettingsRef({ user }) {
                      const name = MACHINE_NAMES.BROWSER_SETTINGS;

                      return spawn(browserSettingsMachine({ user }), name);
                    }
                  })
                ]
              }
            },
            on: {
              [EVENT_NAMES.APP_SETTINGS.UPDATE_BROWSER_SETTINGS]: [
                {
                  target: STATES.APP_SETTINGS.LOADING.LOADING_NATIVE_SETTINGS,
                  actions: ['updateBrowserSettings'],
                  cond: isNative
                },
                {
                  target: `#${MACHINE_NAMES.APP_SETTINGS}.${STATES.APP_SETTINGS.ACTIVE}`,
                  actions: ['updateBrowserSettings']
                }
              ]
            }
          },
          [STATES.APP_SETTINGS.LOADING.LOADING_NATIVE_SETTINGS]: {
            entry: [
              assign({
                nativeSettingsRef(context) {
                  const name = MACHINE_NAMES.NATIVE_SETTINGS;

                  return spawn(nativeSettingsMachine(context), name);
                }
              })
            ],
            on: {
              [EVENT_NAMES.APP_SETTINGS.UPDATE_NATIVE_SETTINGS]: {
                target: `#${MACHINE_NAMES.APP_SETTINGS}.${STATES.APP_SETTINGS.ACTIVE}`,
                actions: ['updateNativeSettings']
              }
            }
          }
        }
      },
      [STATES.APP_SETTINGS.ACTIVE]: {
        on: {
          [EVENT_NAMES.BROWSER_SETTINGS.SET]: {
            actions: [forwardTo(MACHINE_NAMES.BROWSER_SETTINGS)]
          },
          [EVENT_NAMES.BROWSER_SETTINGS.REMOVE]: {
            actions: [forwardTo(MACHINE_NAMES.BROWSER_SETTINGS)]
          },
          [EVENT_NAMES.NATIVE_SETTINGS.SET]: {
            actions: [forwardTo(MACHINE_NAMES.NATIVE_SETTINGS)],
            cond: isNative
          },
          [EVENT_NAMES.APP_SETTINGS.UPDATE_BROWSER_SETTINGS]: {
            actions: ['updateBrowserSettings']
          },
          [EVENT_NAMES.APP_SETTINGS.UPDATE_NATIVE_SETTINGS]: {
            actions: ['updateNativeSettings']
          }
        }
      }
    }
  },
  {
    actions,
    guards: {}
  }
);
