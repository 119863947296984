import { DOWNLOAD_APP_URL } from '@constants/urls';
import { PAGES } from '@shared/constants';

import { isiOS, isAndroid, isWeb, isDev } from '@functions/env';

import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import {
  isArtist,
  isPlaylist,
  isAlbum,
  isPlaylistCategory,
  isPlaylistVideoCategory,
  isAd,
  isAdLink,
  isAdSubscription,
  isSpecial,
  isLikedSongs,
  isPodcastShow,
  isStory,
  isStoryVideo,
  isComingSoon,
  // isPhotoAlbum,
  // isPhotoThumb,
  isHeaderLink,
  isRecentlyPlayedHeader,
  isLink,
  isMp3Album,
  isPodcast,
  isMp3,
  isVideo,
  isEvent,
  isUser,
  isLiveStream
} from '@functions/is';
import { getArtistName } from '@functions/get';

export const getPermLink = data => {
  if (isAlbum(data) || data.isLinkToAlbum) {
    if (data?.album?.permlink) {
      return data.album.permlink.toLowerCase();
    }

    if (data?.item?.album?.permlink) {
      return data.item.album.permlink.toLowerCase();
    }

    if (Array.isArray(data.album_tracks) && data.album_tracks[0]) {
      return getPermLink({ ...data.album_tracks[0], isLinkToAlbum: true });
    }
  }

  if (data.permlink) {
    return data.permlink?.toLowerCase();
  }

  if (data?.item?.permlink) {
    return data?.item?.permlink?.toLowerCase();
  }

  return data?.id?.toLowerCase();
};

export const getItemLinkById = data => {
  if (isAlbum(data)) {
    return `/album/${data.id}`;
  }

  return `/${data.type}/${data.id}`;
};

export function getLinkWithoutSpaces({ url }) {
  // Decode the URL to get rid of any encoded characters
  const decodedUrl = decodeURIComponent(url.trim());

  // Replace spaces with + and convert to lowercase
  return decodedUrl.replace(/ /g, '+').toLowerCase();
}

export function getLinkWithSpaces({ url }) {
  return url.replace(/\+/g, ' ').toLowerCase();
}

export const getArtistStaticLink = artist => {
  return getLinkWithoutSpaces({ url: `/artist/${artist}` });
};

export function getArtistLink(data) {
  if (isNumber(data.tagIndex) && data.artist_tags && data.artist_tags.length) {
    return getArtistStaticLink(data.artist_tags[data.tagIndex]);
  }

  if (isAlbum(data)) {
    return getArtistStaticLink(get(data, 'album.artist', data.artist));
  }

  if (isAd(data)) {
    if (isAdLink(data)) {
      return data?.cta?.link;
    }
    if (isAdSubscription(data)) {
      return PAGES.RJ_PREMIUM;
    }
  }

  // if (isEvent(data)) {
  //   return getLink(data);
  // }

  // if (isUser(data)) {
  //   return getLink(data);
  // }

  const artist = data.name || data.artist || data.podcast_artist || data.id;

  return getArtistStaticLink(artist);
}

export const getShowLink = data => {
  return `/podcast/show/${data.show_permlink.toLowerCase()}`;
};

export function getSearchLink({ query, type, tab, tag }) {
  let baseUrl = '/search';

  if (query) {
    baseUrl += `/${query}`;
  }

  if (type || tab) {
    baseUrl += `/${type || tab}`;
  }

  if (tag) {
    baseUrl += `?tag=1`;
  }

  return getLinkWithoutSpaces({ url: baseUrl });
}

export function getLink(data) {
  if (!data) {
    return '/';
  }

  if (isLink(data)) {
    return data.link;
  }

  // if (isPhotoAlbum(data) || isPhotoThumb(data)) {
  //   return `/photos/${data.fakeId || data.id}`;
  // }

  if (isAd(data)) {
    if (isAdLink(data)) {
      return data?.cta?.link;
    }
    if (isAdSubscription(data)) {
      return PAGES.RJ_PREMIUM;
    }
  }

  if (isEvent(data)) {
    return `/event/${data.id}`;
  }

  if (isUser(data)) {
    return `/u/${data.username}`;
  }

  if (isStory(data)) {
    if (data.isUserProfile) {
      if (data?.user?.username) {
        return `/u/${data.user.username}/stories/${data.hash_id || data.id}`;
      }
    }

    if (data.detail) {
      return `/song/${data.mp3}/stories/${data.hash_id || data.id}`;
    }

    return `/story/${data.hash_id || data.id}`;
  }

  if (isStoryVideo(data)) {
    // in home feed it comes as an array
    if (Array.isArray(data.items)) {
      return `/story/rj/${data.items[0].hash_id}`;
    }

    // data.id is useful in short link
    return `/story/rj/${data.hash_id || data.id}`;
  }

  if (isSpecial(data)) {
    return `/special/${data.link}`;
  }

  if (isLiveStream(data)) {
    return '/tv';
  }

  if (isLikedSongs(data)) {
    return '/library/liked/songs';
  }

  if (isArtist(data)) {
    return getArtistLink(data);
  }

  if (!data.type || data.artistPage) {
    return getArtistLink(data);
  }

  if (isPlaylist(data)) {
    return `/playlist/${data.subtype}/${data.id}`;
  }

  if (isPlaylistCategory(data)) {
    if (isPlaylistVideoCategory(data)) {
      return `/playlists/videos/categories/${data.id}`;
    }

    return `/playlists/mp3s/categories/${data.id}`;
  }

  if (isAlbum(data)) {
    return `/album/${getPermLink(data)}`;
  }

  // if (data.album) {
  //   return `/album/${getPermLink(data)}`;
  // }

  if (isPodcastShow(data)) {
    return `/podcast/show/${data.show_permlink || data.permlink || data.id}`;
  }

  if (isComingSoon(data)) {
    return data.html_link;
  }

  if (isHeaderLink(data)) {
    if (data.search) {
      return `/search/${data.link}`;
    }
    if (data.searchHistory) {
      return `/search/${data.link}`;
    }
    if (data.artist) {
      return `/artist/${data.link}`;
    }
    if (data.link === 'albums') {
      return '/browse/songs/albums';
    }
    if (data.playlist) {
      return `/playlists/${data.link}`;
    }
    if (data.playlistHome) {
      return '/playlists';
    }
    if (isRecentlyPlayedHeader(data)) {
      return data.link;
    }

    if (data.link === 'mp3s') {
      return '/browse/songs';
    }

    if (data.link === 'selfies') {
      return '/browse/stories';
    }

    return `/browse/${data.link}`;
  }

  // mp3 video podcast uses permlink
  if (isMp3(data)) {
    return `/song/${getPermLink(data)}`;
  }

  return `/${data.type}/${getPermLink(data)}`;
}

export const getMenuLink = data => {
  if (data.id === 'mp3s') {
    return '/browse/songs';
  }

  if (data.id === 'events') {
    return '/events';
  }

  return `/browse/${data.id}`;
};

export const getAlbumLink = data => {
  return `/album/${getPermLink({ ...data, isLinkToAlbum: true })}`;
};

export const getBrowseLink = ({ type, tab }) => {
  if (!tab) {
    return `/browse/${type}`;
  }

  return `/browse/${type}/${tab}`;
};

export const getProtocolLink = data => {
  const defaultProtocol = 'radiojavan:/';
  const protocol = defaultProtocol;
  let link = '';

  if (!data) {
    return `${protocol}/home`;
  }

  if (isMp3(data) || isPodcast(data) || isVideo(data)) {
    link = getItemLinkById(data);
  }

  if (isPlaylist(data)) {
    link = `/playlist/${data.subtype}/${data.id}`;
  }

  if (isArtist(data)) {
    link = getArtistStaticLink(getArtistName(data));
  }

  if (isPodcastShow(data)) {
    link = `/show/${data.permlink}`;
  }

  if (isAlbum(data) || isMp3Album(data)) {
    link = `/mp3_album/${getPermLink(data)}`;
  }

  if (isStory(data)) {
    link = `/story/${data.hash_id}`;
  }

  if (isStoryVideo(data)) {
    link = `/story_video/${data.hash_id}`;
  }

  if (isEvent(data)) {
    link = `/event/${data.id}`;
  }

  if (isUser(data)) {
    link = `/user/${data.username}`;
  }

  if (!link) {
    link = '/';
  }

  return [protocol, link].join('');
};

export const getServeURLProtocolLink = data => {
  if (isWeb() && !isDev()) {
    // will be play.radiojavan.com || staging.play.radiojavan.com
    return [`${process.env.SERVE_URL}`, '/redirect?r=', getProtocolLink(data)].join('');
  }

  return [`${PAGES.WEB_PLAYER_INDEX}`, '/redirect?r=', getProtocolLink(data)].join('');
};

// TODO:// THIS IS NOT CORRECT - SHOULD BE CHECKED WITH HELPERS.JS
/**
 * other way around of getProtocol
 * radiojavan://video/123 => /video/123
 */
export const getLinkFromProtocol = protocol => {
  if (!protocol) {
    return '/';
  }

  if (!protocol.includes('/home')) {
    if (protocol.includes('radiojavan://')) {
      return protocol.replace('radiojavan:/', '');
    }

    if (protocol.includes('rj://')) {
      return protocol.replace('rj:/', '');
    }
  }

  return '/';
};

export const getDownloadLink = ({ isTouch } = {}) => {
  if (isTouch) {
    if (isiOS()) {
      return PAGES.RJ_ITUNES_URL_IOS;
    }

    if (isAndroid()) {
      return PAGES.RJ_GOOGLE_PLAY;
    }
  }

  return DOWNLOAD_APP_URL;
};

export const getWebPlayerLink = data => {
  if (typeof data === 'string') {
    return [PAGES.WEB_PLAYER_INDEX, data].join('');
  }

  if (!data) {
    return PAGES.WEB_PLAYER_INDEX;
  }

  return [PAGES.WEB_PLAYER_INDEX, getLink(data)].join('');
};

export const getWebPlayerLinkAutoPlay = data => {
  return [getWebPlayerLink(data), '?autoPlay=true'].join('');
};

export const getWebPlayerArtistStaticLink = data => {
  return getWebPlayerLink(getArtistStaticLink(data));
};

export const getAlternateLink = data => {
  if (!data) {
    return 'android-app://com.radiojavan.androidradio';
  }

  return ['android-app://com.radiojavan.androidradio', getLink(data)].join('');
};

export const getAlternateLinkAutoPlay = data => {
  return [getAlternateLink(data), '?autoPlay=true'].join('');
};

export const getWebPlayerAlbumLink = data => {
  if (!data) {
    return getLink(data);
  }

  return [PAGES.WEB_PLAYER_INDEX, getAlbumLink(data)].join('');
};

export const getOpenGraphLink = data => {
  return [getWebPlayerLink(data), '/metadata'].join('');
};

export function getCreatorLink(data) {
  if (data.created_by?.includes(process.env.APP_NAME)) {
    return PAGES.WEB_PLAYER_INDEX;
  }

  if (data.created_by && data.username) {
    return getWebPlayerLink({ username: data.username, type: 'user' });
  }

  return PAGES.WEB_PLAYER_INDEX;
}
