import cx from 'classnames';

import { Box } from '@ui';

const variants = {
  solid: ['bg-th-foreground-mid'],
  outline: ['border-th-foreground-mid border-p-1']
};

const Explicit = ({ variant, full, className }) => {
  const text = full ? 'EXPLICIT' : 'E';

  return (
    <Box
      className={cx(variants[variant], className, 'px-1 text-xs rounded-sm')}
      aria-label="EXPLICIT"
    >
      <Box as="span">{text}</Box>
    </Box>
  );
};

Explicit.defaultProps = {
  full: false,
  variant: 'solid'
};

export default Explicit;
