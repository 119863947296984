import { STATES } from '@constants/machines/library';

export const isSyncing = state => {
  return state.matches(STATES.ITEM.SYNCING.INDEX);
};

export const isSynced = state => {
  return (
    state.matches(`${STATES.ITEM.SYNCED.INDEX}.${STATES.ITEM.SYNCED.IDLE}`) ||
    state.matches(`${STATES.ITEM.SYNCED.INDEX}.${STATES.ITEM.SYNCED.FETCHING_ATTACHMENT}`) ||
    state.matches(`${STATES.ITEM.SYNCED.INDEX}.${STATES.ITEM.SYNCED.FETCHED_ATTACHMENT}`)
  );
};

export const isWaitingForDownload = state => {
  return state.matches(STATES.ITEM.WAITING_FOR_DOWNLOADER);
};
