/* eslint-disable no-await-in-loop */
import * as Sentry from '@sentry/nextjs';

export async function deleteDataCaches() {
  try {
    const keys = await caches.keys();

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'apis') {
        await caches.delete(keys[i]);
      }
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
