/* eslint-disable no-extend-native */
if (typeof String.prototype.replaceAll !== 'function') {
  String.prototype.replaceAll = function (str, newStr) {
    try {
      const regex = new RegExp(str, 'g');

      return this.replace(regex, newStr);
    } catch (e) {
      console.log(e);

      return this;
    }
  };
}
