import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';
import { getAllowSync, getAllowSyncNewItems } from '@machines/auth/selectors';

export const isAllowedToSync = () => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return getAllowSync(authService.state);
};

export const isAllowedToSyncNewItems = () => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return getAllowSyncNewItems(authService.state);
};
