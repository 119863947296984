import { EVENT_NAMES } from '@constants/machines/playlist';

import React, { useContext, useCallback, useEffect } from 'react';
import { useInterpret } from '@xstate/react';
import { playlistMachine } from '@state/machines/playlist/playlist-machine';

export const PlaylistsContext = React.createContext(null);

export const PlaylistsProvider = ({ children }) => {
  const service = useInterpret(playlistMachine);

  const onAdd = useCallback((value, extra) => {
    service.send(EVENT_NAMES.PLAYLIST.ADD_TO_PLAYLIST.INDEX, {
      value,
      extra
    });
  }, []);

  const onBack = useCallback(() => {
    service.send(EVENT_NAMES.PLAYLIST.BACK);
  }, []);

  const onClose = useCallback(() => {
    service.send(EVENT_NAMES.PLAYLIST.CLOSE);
  }, []);

  const onSubmit = useCallback((value, extra) => {
    service.send(EVENT_NAMES.PLAYLIST.ON_SUBMIT, { value, extra });
  }, []);

  const onSelectFile = useCallback(value => {
    service.send(EVENT_NAMES.PLAYLIST.SELECT_FILE, { value });
  }, []);

  const onFormDataChange = useCallback(value => {
    service.send(EVENT_NAMES.PLAYLIST.FORM_DATA.ON_CHANGE, { value });
  }, []);

  const onSelectCreateNewPlaylist = useCallback(() => {
    const name = EVENT_NAMES.PLAYLIST.ADD_TO_PLAYLIST.SELECTING_PLAYLIST.CREATE_NEW_PLAYLIST;

    service.send(name);
  }, []);

  const onRemove = useCallback(value => {
    const name = EVENT_NAMES.PLAYLIST.REMOVE_PLAYLIST.INDEX;

    service.send(name, { value });
  }, []);

  const onEdit = useCallback(value => {
    const name = EVENT_NAMES.PLAYLIST.EDIT_PLAYLIST.INDEX;

    service.send(name, { value });
  }, []);

  const updatePlaylists = useCallback((value, extra) => {
    const name = EVENT_NAMES.PLAYLIST.UPDATE_PLAYLISTS;

    service.send(name, { value, extra });
  }, []);

  useEffect(() => {
    return () => {
      service.stop();
    };
  }, []);

  return (
    <PlaylistsContext.Provider
      value={{
        service,

        onAdd,
        onBack,
        onClose,
        onSubmit,
        onRemove,
        onEdit,
        onSelectFile,
        onFormDataChange,
        onSelectCreateNewPlaylist,
        updatePlaylists
      }}
    >
      {children}
    </PlaylistsContext.Provider>
  );
};

export const usePlaylistState = () => useContext(PlaylistsContext);
