import { CHARACTERS } from '@constants/app';

export const APP_NAME = 'Radio Javan';
export const APP_NAME_FA = 'رادیو جوان';
export const APP_DESCRIPTION =
  'Radio Javan provides you the best of Persian music. Listen and watch anything you like.';
export const APP_USER_NAME = '@radiojavan';
export const TITLE = `${APP_NAME} ${CHARACTERS.HEAD} The Best Persian Music 24/7`;
export const SLOGAN =
  'The largest source of Persian entertainment providing the best Persian and Iranian music 24/7';
export const KEYWORDS =
  'Persian Music, Iran Music, Iranian Music, Persian Radio, Iranian Radio, RadioJavan, Persian Music Videos, Iranian Music Videos, Radio Javan, Persian Events, Iranian Events, Persian Community, Iranian Community';

export const FARSI_DESCRIPTION_TEMPLATES = {
  mp3({ title }) {
    return `متن آهنگ ${title} ${CHARACTERS.HEAD} دانلود آهنگ ${title}`;
  },
  podcast({ title }) {
    return `متن پادکست ${title} ${CHARACTERS.HEAD} دانلود پادکست ${title}`;
  },
  video({ title }) {
    return `دانلود موزیک ویدیو ${title}`;
  },
  artist({ title }) {
    return `دانلود آهنگ های ${title} ${CHARACTERS.HEAD} دانلود آهنگ جدید ${title}`;
  },
  album({ title }) {
    return `آلبوم ${title} ${CHARACTERS.HEAD} دانلود آلبوم ${title}`;
  },
  events() {
    return `رادیوجوان ${CHARACTERS.HEAD} پارتی و مهمونی ایرانی ${CHARACTERS.HEAD} ایونت های ایرانی`;
  }
};

export const KEYWORDS_TEMPLATES = {
  artist({ artist }) {
    return `Download ${artist}, Free ${artist}, MP3 ${artist}, ahang ${artist}, Music ${artist} Persian Music, The newest music and videos by ${artist}, The newest music and videos by ${artist}`;
  },
  podcast({ artist }) {
    return `Download ${artist}, Free ${artist}, Podcast ${artist}, ahang ${artist}, Music ${artist}, Persian Music, Iran Music, Radio Javan, RadioJavan`;
  },
  events() {
    return `Persian & Iranian Events, Persian & Iranian Party, RJ Party, Radio Javan Party, Radio Javan Events, مهمونی رادیوجوان, پارتی رادیوجوان`;
  },
  event({ event, location }) {
    return `Radio Javan Party, RJ Party, Event, ${event}, ${location}, مهمونی رادیوجوان, پارتی رادیوجوان`;
  }
};
