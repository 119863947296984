// import { isNative } from '@functions/env';

export const touchBar = {
  unAuthenticated() {
    window.native.sendCommand('application:set-touch-bar', 'un-authenticated');
  },

  authenticateWithoutNowPlaying() {
    window.native.sendCommand('application:set-touch-bar', 'authenticated-without-now-playing');
  },

  authenticatedWithNowPlaying() {
    window.native.sendCommand('application:set-touch-bar', 'authenticated-with-now-playing');
  },

  playback: {
    playing() {
      window.native.sendCommand('application:update-touch-bar-icon-state', {
        'playback.toggle': 'playback-pause'
      });
    },
    paused() {
      window.native.sendCommand('application:update-touch-bar-icon-state', {
        'playback.toggle': 'playback-play'
      });
    },

    repeat: {
      active() {
        window.native.sendCommand('application:update-touch-bar-background', {
          'playback.repeat': '#909090'
        });
      },
      inactive() {
        window.native.sendCommand('application:update-touch-bar-background', {
          'playback.repeat': null
        });
      }
    }
  }
};

export const menu = {
  playback: {
    active() {
      window.native.sendCommand(
        'application:update-menu-state',
        null,
        {
          'playback.play': true,
          'playback.pause': true,
          'playback.next': true,
          'playback.previous': true,
          'playback.seek-forward': true,
          'playback.seek-backward': true,
          'playback.repeat': true,
          'playback.volume-up': true,
          'playback.volume-down': true
        },
        {
          'playback.repeat': false
        }
      );
    },
    inactive() {
      window.native.sendCommand(
        'application:update-menu-state',
        null,
        {
          'playback.play': false,
          'playback.pause': false,
          'playback.next': false,
          'playback.previous': false,
          'playback.seek-forward': false,
          'playback.seek-backward': false,
          'playback.repeat': false,
          'playback.volume-up': false,
          'playback.volume-down': false
        },
        {
          'playback.repeat': false
        }
      );
    },

    repeat: {
      active() {
        window.native.sendCommand('application:update-menu-state', null, null, {
          'playback.repeat': true
        });
      },
      inactive() {
        window.native.sendCommand('application:update-menu-state', null, null, {
          'playback.repeat': false
        });
      }
    },

    media: {
      playing() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': false,
            'playback.pause': true
          },
          {
            'playback.play': false,
            'playback.pause': true,
            'playback.next': true,
            'playback.previous': true,
            'playback.seek-forward': true,
            'playback.seek-backward': true,
            'playback.repeat': true,
            'playback.volume-up': true,
            'playback.volume-down': true
          }
        );
      },
      paused() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': true,
            'playback.pause': false
          },
          {
            'playback.play': true,
            'playback.pause': false
          }
        );
      }
    },
    ads: {
      playing() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': false,
            'playback.pause': true
          },
          {
            'playback.play': false,
            'playback.pause': true,
            'playback.next': false,
            'playback.previous': false,
            'playback.seek-forward': false,
            'playback.seek-backward': false,
            'playback.repeat': false,
            'playback.volume-up': true,
            'playback.volume-down': true
          },
          {
            'playback.repeat': false
          }
        );
      },
      paused() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': true,
            'playback.pause': false
          },
          {
            'playback.play': true,
            'playback.pause': false
          },
          {
            'playback.repeat': false
          }
        );
      }
    },
    liveStream: {
      playing() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': false,
            'playback.pause': true
          },
          {
            'playback.play': false,
            'playback.pause': true,
            'playback.next': false,
            'playback.previous': false,
            'playback.seek-forward': false,
            'playback.seek-backward': false,
            'playback.repeat': false,
            'playback.volume-up': true,
            'playback.volume-down': true
          },
          {
            'playback.repeat': false
          }
        );
      },
      paused() {
        window.native.sendCommand(
          'application:update-menu-state',
          {
            'playback.play': true,
            'playback.pause': false
          },
          {
            'playback.play': true,
            'playback.pause': false
          },
          {
            'playback.repeat': false
          }
        );
      }
    }
  },

  window: {
    active() {
      window.native.sendCommand(
        'application:update-menu-state',
        null,
        {
          'window.home': true,
          'window.browse': true,
          'window.playlists': true,
          'window.songs': true,
          'window.liked-songs': true,
          'window.podcasts': true,
          'window.videos': true,
          'window.artists': true,
          'window.upnext': true
        },
        {
          'playback.repeat': false
        }
      );
    },
    inactive() {
      window.native.sendCommand(
        'application:update-menu-state',
        null,
        {
          'window.home': false,
          'window.browse': false,
          'window.playlists': false,
          'window.songs': false,
          'window.liked-songs': false,
          'window.podcasts': false,
          'window.videos': false,
          'window.artists': false,
          'window.upnext': false
        },
        {
          'playback.repeat': false
        }
      );
    }
  }
};

export const application = {
  exit() {
    window.native.sendCommand('application:exit');
  },
  relaunch() {
    window.native.sendCommand('application:relaunch');
  },
  reportProblem() {
    window.native.sendCommand('help:report-problem');
  },
  openEternalLink(link) {
    window.native.sendCommand('application:open-link', link);
  },
  popupMenu() {
    window.native.sendCommand('application:menu:popup');
  },
  quitAndInstallUpdate() {
    window.native.sendCommand('application:quit-and-install-update');
  },
  minimize() {
    window.native.sendCommand('application:minimize');
  },
  edit: {
    filter: {
      enable() {
        window.native.sendCommand(
          'application:update-menu-state',
          {},
          {
            'edit.filter': true
          },
          {}
        );
      },
      disable() {
        window.native.sendCommand(
          'application:update-menu-state',
          {},
          {
            'edit.filter': false
          },
          {}
        );
      }
    }
  }
};
