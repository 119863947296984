import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/library';

import { isNative } from '@functions/env';

import { createMachine, sendParent, assign, spawn, send, actions as XStateActions } from 'xstate';

import { getLibraryStorage, getLibraryBlobStorage } from '@helpers/storages/library';
import { saveToLibrary, removeFromLibrary } from '@api/library';

import { downloaderMachine } from '@machines/library/machines/downloader-item/downloader-item-machine';
import { getAttachmentID } from '@machines/library/data-helpers';

import * as actions from './actions';
import * as guards from './guards';

const { pure } = XStateActions;

export const createItemMachine = ({ data }) => {
  return createMachine(
    {
      id: MACHINE_NAMES.ITEM,
      context: {
        downloaderItemRef: null,
        shouldSync: false
      },
      // initial: STATES.ITEM.IDLE,
      initial: STATES.ITEM.VALIDATING.INDEX,
      on: {
        [EVENT_NAMES.ITEM.SAVE]: {
          target: STATES.ITEM.ADDING.INDEX,
          actions: [
            assign({
              shouldSync(_, { sync }) {
                return sync;
              }
            })
          ]
        }
      },
      states: {
        /**
         * Sync checker, reading the file directly
         */
        [STATES.ITEM.VALIDATING.INDEX]: {
          id: STATES.ITEM.VALIDATING.INDEX,
          initial: STATES.ITEM.VALIDATING.BEFORE_WAITING_FOR_VALIDATE,
          states: {
            [STATES.ITEM.VALIDATING.BEFORE_WAITING_FOR_VALIDATE]: {
              always: [
                {
                  target: STATES.ITEM.VALIDATING.WAITING_FOR_VALIDATE,
                  actions: [
                    sendParent(() => {
                      return { type: EVENT_NAMES.VALIDATE.PUSH_TO_QUEUE, item: data };
                    })
                  ],
                  cond: 'isAllowedToSync'
                },
                {
                  target: `#${STATES.ITEM.IDLE}`
                }
              ]
            },
            [STATES.ITEM.VALIDATING.WAITING_FOR_VALIDATE]: {
              on: {
                [EVENT_NAMES.ITEM.VALIDATE]: [
                  {
                    target: STATES.ITEM.VALIDATING.PROCESSING,
                    cond: isNative
                  },
                  {
                    target: `#${STATES.ITEM.IDLE}`,
                    actions: [
                      sendParent(() => ({
                        type: EVENT_NAMES.VALIDATE.REMOVE_FROM_QUEUE,
                        item: data
                      }))
                    ]
                  }
                ]
              }
            },

            [STATES.ITEM.VALIDATING.PROCESSING]: {
              invoke: {
                id: STATES.ITEM.VALIDATING.PROCESSING,
                src: () => {
                  const id = getAttachmentID(data);

                  return getLibraryBlobStorage().has(id);
                },
                onDone: {
                  target: `#${STATES.ITEM.SYNCED.INDEX}`,
                  actions: [
                    sendParent(() => ({
                      type: EVENT_NAMES.VALIDATE.REMOVE_FROM_QUEUE,
                      item: data
                    }))
                  ]
                },
                onError: {
                  target: `#${STATES.ITEM.IDLE}`,
                  actions: [
                    sendParent(() => ({
                      type: EVENT_NAMES.VALIDATE.REMOVE_FROM_QUEUE,
                      item: data
                    }))
                  ]
                }
              }
            }
          }
        },

        [STATES.ITEM.IDLE]: {
          id: STATES.ITEM.IDLE,
          on: {
            [EVENT_NAMES.ITEM.SYNC]: [
              {
                target: STATES.ITEM.WAITING_FOR_DOWNLOADER,
                cond: 'isAllowedToSyncNewItems'
              },
              {
                actions: ['toggleNotifyUserToSubscribe']
              }
            ],

            [EVENT_NAMES.ITEM.ALLOW_SYNC_UPDATED]: [
              {
                target: STATES.ITEM.WAITING_FOR_DOWNLOADER,
                cond: ({ shouldSync }) => shouldSync
              },
              {
                target: STATES.ITEM.VALIDATING.INDEX
              }
            ],

            [EVENT_NAMES.ITEM.REMOVE]: {
              target: STATES.ITEM.REMOVING.INDEX
            }
          }
        },

        [STATES.ITEM.ADDING.INDEX]: {
          id: STATES.ITEM.ADDING.INDEX,
          initial: STATES.ITEM.ADDING.TO_API,
          states: {
            [STATES.ITEM.ADDING.TO_API]: {
              invoke: {
                id: STATES.ITEM.ADDING.TO_API,
                src: () => {
                  return saveToLibrary({ items: [data] });
                },
                onDone: {
                  target: STATES.ITEM.ADDING.TO_STORAGE
                },
                onError: {
                  target: `#${STATES.ITEM.IDLE}`
                }
              }
            },
            [STATES.ITEM.ADDING.TO_STORAGE]: {
              invoke: {
                id: STATES.ITEM.ADDING.TO_STORAGE,
                src: () => getLibraryStorage().add(data),
                onDone: {
                  target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.ADDED}`
                }
              }
            }
          }
        },

        [STATES.ITEM.ADDED]: {
          always: [
            {
              target: STATES.ITEM.WAITING_FOR_DOWNLOADER,
              cond: ({ shouldSync }) => shouldSync && guards.isAllowedToSyncNewItems()
            },
            {
              target: `#${STATES.ITEM.IDLE}`,
              actions: ['toggleNotifyUserToSubscribe'],
              cond: ({ shouldSync }) => shouldSync && !guards.isAllowedToSyncNewItems()
            },
            {
              target: STATES.ITEM.VALIDATING.INDEX
            }
          ],
          on: {
            [EVENT_NAMES.ITEM.REMOVE]: {
              target: STATES.ITEM.REMOVING.INDEX
            }
          }
        },

        /**
         * Push the item to receive the download event
         */
        [STATES.ITEM.WAITING_FOR_DOWNLOADER]: {
          entry: [
            sendParent(() => {
              return { type: EVENT_NAMES.DOWNLOADER.PUSH_TO_QUEUE, item: data };
            })
          ],
          on: {
            [EVENT_NAMES.ITEM.SYNC]: {
              target: STATES.ITEM.SYNCING.INDEX,
              actions: [
                assign({
                  downloaderItemRef() {
                    const machine = downloaderMachine.withContext({
                      ...downloaderMachine.context,
                      link: data.link
                    });
                    const name = MACHINE_NAMES.DOWNLOADER_ITEM;

                    return spawn(machine, name);
                  }
                }),
                send(EVENT_NAMES.DOWNLOADER_ITEM.SYNC, {
                  to: MACHINE_NAMES.DOWNLOADER_ITEM
                })
              ]
            },
            [EVENT_NAMES.ITEM.REMOVE]: {
              target: STATES.ITEM.REMOVING.INDEX,
              actions: [
                pure(() => {
                  return sendParent({
                    type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                    item: data
                  });
                })
              ]
            },
            [EVENT_NAMES.ITEM.CANCEL]: {
              target: STATES.ITEM.ADDED,
              actions: [
                pure(() => {
                  return sendParent({
                    type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                    item: data
                  });
                })
              ]
            },
            [EVENT_NAMES.ITEM.ALLOW_SYNC_UPDATED]: {
              target: STATES.ITEM.ADDED,
              actions: [
                sendParent({
                  type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                  item: data
                })
              ]
            }
          }
        },

        [STATES.ITEM.SYNCING.INDEX]: {
          id: STATES.ITEM.SYNCING.INDEX,
          initial: STATES.ITEM.SYNCING.DOWNLOADING,
          states: {
            [STATES.ITEM.SYNCING.DOWNLOADING]: {
              on: {
                /**
                 * File downloaded sign
                 */
                [EVENT_NAMES.DOWNLOADER_ITEM.SYNCED]: {
                  target: STATES.ITEM.SYNCING.TO_BLOB_STORAGE
                },
                /**
                 * Handle cancel the downloading item
                 */
                [EVENT_NAMES.ITEM.REMOVE]: {
                  target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.REMOVING.INDEX}`,
                  actions: [
                    send(EVENT_NAMES.DOWNLOADER_ITEM.CANCEL, {
                      to: MACHINE_NAMES.DOWNLOADER_ITEM
                    }),
                    sendParent(() => {
                      return {
                        type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                        item: data
                      };
                    })
                  ]
                },
                [EVENT_NAMES.ITEM.CANCEL]: {
                  target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.VALIDATING.INDEX}`,
                  actions: [
                    send(EVENT_NAMES.DOWNLOADER_ITEM.CANCEL, {
                      to: MACHINE_NAMES.DOWNLOADER_ITEM
                    }),
                    sendParent(() => {
                      return {
                        type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                        item: data
                      };
                    })
                  ]
                },
                [EVENT_NAMES.ITEM.ALLOW_SYNC_UPDATED]: {
                  target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.VALIDATING.INDEX}`,
                  actions: [
                    send(EVENT_NAMES.DOWNLOADER_ITEM.CANCEL, {
                      to: MACHINE_NAMES.DOWNLOADER_ITEM
                    }),
                    sendParent(() => {
                      return {
                        type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE,
                        item: data
                      };
                    })
                  ]
                }
              }
            },

            [STATES.ITEM.SYNCING.TO_BLOB_STORAGE]: {
              invoke: {
                id: STATES.ITEM.SYNCING.TO_BLOB_STORAGE,
                /**
                 * Store buffer to storage.
                 * @param {Object} event - Event transferred from downloader item machine.
                 * @param {buffer} data.buffer - The buffer of the file.
                 */
                src: (_, event) => {
                  const id = getAttachmentID(data);

                  return getLibraryBlobStorage().add(event.data, id);
                },
                onDone: {
                  target: STATES.ITEM.SYNCING.TO_STORAGE
                },
                onError: {
                  target: STATES.ITEM.SYNCING.TO_STORAGE
                }
              }
            },

            [STATES.ITEM.SYNCING.TO_STORAGE]: {
              invoke: {
                id: STATES.ITEM.SYNCING.TO_STORAGE,
                src: () => {
                  return getLibraryStorage().add(data);
                },
                onDone: {
                  target: STATES.ITEM.SYNCING.DONE
                }
              }
            },

            [STATES.ITEM.SYNCING.DONE]: {
              always: {
                target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.SYNCED.INDEX}`,
                actions: [
                  sendParent(() => ({
                    type: EVENT_NAMES.ITEM.SYNCED,
                    item: data
                  }))
                ]
              }
            }
          }
        },

        [STATES.ITEM.SYNCED.INDEX]: {
          id: STATES.ITEM.SYNCED.INDEX,
          initial: STATES.ITEM.SYNCED.IDLE,
          entry: [
            assign({
              shouldSync() {
                return false;
              }
            })
          ],
          states: {
            [STATES.ITEM.SYNCED.IDLE]: {}
          },
          on: {
            [EVENT_NAMES.ITEM.REMOVE]: {
              target: STATES.ITEM.REMOVING.INDEX
            },
            [EVENT_NAMES.ITEM.UN_SYNC]: {
              target: `#${STATES.ITEM.REMOVING.INDEX}.${STATES.ITEM.REMOVING.FROM_BLOB_STORAGE}`
            },
            [EVENT_NAMES.ITEM.ALLOW_SYNC_UPDATED]: {
              target: STATES.ITEM.IDLE
            }
          }
        },

        [STATES.ITEM.REMOVING.INDEX]: {
          id: STATES.ITEM.REMOVING.INDEX,
          initial: STATES.ITEM.REMOVING.FROM_API,

          states: {
            [STATES.ITEM.REMOVING.FROM_API]: {
              invoke: {
                id: STATES.ITEM.REMOVING.FROM_API,
                src: () => {
                  return removeFromLibrary({ items: [data] });
                },
                onDone: {
                  target: STATES.ITEM.REMOVING.FROM_STORAGE
                }
              }
            },

            [STATES.ITEM.REMOVING.FROM_STORAGE]: {
              invoke: {
                id: STATES.ITEM.REMOVING.FROM_STORAGE,
                src: () => getLibraryStorage().remove(data),
                onDone: {
                  target: STATES.ITEM.REMOVING.FROM_BLOB_STORAGE
                },
                onError: {
                  target: STATES.ITEM.REMOVING.FROM_BLOB_STORAGE
                }
              }
            },

            [STATES.ITEM.REMOVING.FROM_BLOB_STORAGE]: {
              invoke: {
                id: STATES.ITEM.REMOVING.FROM_BLOB_STORAGE,
                src: () => {
                  const id = getAttachmentID(data);

                  return getLibraryBlobStorage().remove(null, id);
                },
                onDone: [
                  {
                    target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.VALIDATING.INDEX}`,
                    /**
                     * In case of the event is coming from EVENT_NAMES.ITEM.UN_SYNC,
                     * no need to send to REMOVED
                     */
                    cond: (_, __, { state }) => {
                      return state.event.type === EVENT_NAMES.ITEM.UN_SYNC;
                    }
                  },
                  {
                    target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.REMOVED}`
                  }
                ],
                onError: [
                  {
                    target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.VALIDATING.INDEX}`,
                    /**
                     * In case of the event is coming from EVENT_NAMES.ITEM.UN_SYNC,
                     * no need to send to REMOVED
                     */
                    cond: (_, __, { state }) => {
                      return state.event.type === EVENT_NAMES.ITEM.UN_SYNC;
                    }
                  },
                  {
                    target: `#${MACHINE_NAMES.ITEM}.${STATES.ITEM.REMOVED}`
                  }
                ]
              }
            }
          }
        },

        [STATES.ITEM.REMOVED]: {
          type: 'final',
          // always: {
          //   target: STATES.ITEM.VALIDATING.INDEX
          // },
          entry: sendParent(() => {
            return {
              type: EVENT_NAMES.ITEM.REMOVED,
              _id: data._id
            };
          })
        }
      }
    },
    {
      actions,
      guards
    }
  );
};
