import { assign } from 'xstate';

export const setConfig = assign({
  config(context, event) {
    return {
      ...context.config,
      ...event.data
    };
  }
});

export const setConfigToWindow = ({ config }) => {
  window.appConfig = config;
};
