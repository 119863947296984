/* eslint-disable no-await-in-loop */
import axios from 'axios';

export const downloader = (link, { onStarted, onProgress, onCompleted, onError } = {}) => {
  return new Promise((resolve, reject) => {
    let contentLength = 0;

    return axios
      .head(link)
      .then(({ headers }) => {
        contentLength = headers['content-length'];
        const controller = new AbortController();

        return fetch(link, { signal: controller.signal })
          .then(async response => {
            const reader = response.body.getReader();

            if (onStarted) {
              onStarted({
                controller,
                link,
                content_type: headers['content-type'],
                percentage: 0
              });
            }

            let receivedLength = 0;
            const chunks = [];
            try {
              // eslint-disable-next-line no-constant-condition
              while (true) {
                const { done, value } = await reader.read();

                if (done) {
                  break;
                }

                chunks.push(value);
                receivedLength += value.length;
                if (onProgress) {
                  onProgress({
                    link,
                    contentLength,
                    receivedLength,
                    percentage: Math.floor((receivedLength / contentLength) * 100)
                  });
                }
                // if (dev) {
                // console.log(`Received ${receivedLength} of ${contentLength}`);
                // }
              }
            } catch (e) {
              return reject(e);
            }

            const chunksAll = new Uint8Array(receivedLength);
            let position = 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const chunk of chunks) {
              chunksAll.set(chunk, position);
              position += chunk.length;
            }
            if (onCompleted) {
              await onCompleted({
                link,
                content_type: headers['content-type'],
                data: chunksAll
              });
            }
            return resolve(chunksAll);
          })
          .catch(error => {
            if (onError) {
              onError(error);
            }
          });
      })
      .catch(error => {
        if (onError) {
          onError(error);
        }
      });
  });
};
