import { EVENT_NAMES } from '@constants/machines/app';

import React, { useContext } from 'react';

import { getAppService } from '@state/services/app-service';
import {
  // startNotificationService,
  stopNotificationService
} from '@state/services/notification-service';
import { startNetworkService, stopNetworkService } from '@state/services/network-service';

export const AppContext = React.createContext({});

export class AppProvider extends React.Component {
  constructor(props) {
    super(props);

    if (!process.browser) {
      this.state = {};

      return;
    }

    const { user, config } = props.data;

    const service = getAppService({
      user,
      config,
      requireAuthentication: props.requireAuthentication
    });
    // service.onTransition((...args) => {
    //   console.log('APP_MACHINE=>', ...args);
    // });

    // return () => {
    //   stopAppService();
    // };

    this.state = {
      service
    };
  }

  componentDidMount() {
    // starting notification service will create the service twice
    // startNotificationService();
    startNetworkService();

    this.onRequiredAuthenticationChanged();
  }

  componentDidUpdate(nextProps) {
    const { requireAuthentication } = this.props;

    if (nextProps.requireAuthentication !== requireAuthentication) {
      this.onRequiredAuthenticationChanged();
    }
  }

  componentWillUnmount() {
    stopNotificationService();
    stopNetworkService();
  }

  onRequiredAuthenticationChanged() {
    const { requireAuthentication } = this.props;
    const { service } = this.state;

    service.send(EVENT_NAMES.APP.UPDATE_REQUIRE_AUTHENTICATION, { value: requireAuthentication });
  }

  render() {
    const { children, data, platform } = this.props;
    const { isTouch, isBot } = data;
    const { service } = this.state;

    return (
      <AppContext.Provider
        value={{
          service,
          platform,
          isTouch,
          isBot
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export const useApp = () => useContext(AppContext);
