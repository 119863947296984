import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/library';
import { isWeb } from '@functions/env';

import { createMachine, send, actions as xstateActions } from 'xstate';

import * as actions from './actions';

const { pure } = xstateActions;

/**
 * Validate Machine,
 * Query to blob database to see if item is synced or not
 * Concurrent possible queue
 */
export const validateMachine = createMachine(
  {
    id: MACHINE_NAMES.VALIDATE,
    initial: STATES.VALIDATE.IDLE,
    context: {
      queueKeys: [],
      queue: [],
      concurrent: isWeb() ? 1 : 1
    },
    on: {
      [EVENT_NAMES.VALIDATE.PUSH_TO_QUEUE]: {
        actions: ['updateQueue', 'setQueueKeys'],
        target: STATES.VALIDATE.VALIDATING_QUEUE
      }
    },
    states: {
      [STATES.VALIDATE.VALIDATING_QUEUE]: {
        always: [
          {
            target: STATES.DOWNLOADER.IDLE,
            cond: ({ queue }) => queue.length === 0
          },
          {
            target: STATES.VALIDATE.VALIDATING
          }
        ]
      },
      [STATES.VALIDATE.IDLE]: {},
      [STATES.VALIDATE.VALIDATING]: {
        entry: pure(({ queue, concurrent }) => {
          const items = queue.slice(0, concurrent);

          const events = items.map(({ ref }) => {
            return send(EVENT_NAMES.ITEM.VALIDATE, { to: ref });
          });

          return events;
        }),
        on: {
          [EVENT_NAMES.VALIDATE.REMOVE_FROM_QUEUE]: [
            {
              actions: ['removeItem', 'setQueueKeys'],
              target: STATES.VALIDATE.IDLE,
              cond: ({ queue }) => {
                return queue.length === 1;
              }
            },
            {
              actions: ['removeItem', 'setQueueKeys'],
              target: STATES.VALIDATE.VALIDATING_QUEUE
            }
          ]
        }
      }
    }
  },
  {
    actions
  }
);
