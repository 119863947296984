import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/now-playing';
import { EVENTS } from '@constants/analytics';

import { createMachine, assign, spawn, send, sendParent, forwardTo } from 'xstate';
import { streamMachine } from '@machines/now-playing/live-streaming/stream-machine';

import { ga } from '@helpers/ga4';

// import * as actions from './actions';

export const liveStreamingMachine = createMachine(
  {
    id: MACHINE_NAMES.LIVE_STREAMING,
    context: {
      streamSource: null,
      id: 'live-stream',
      type: 'live-stream',
      bg_color: 'rgba(170, 39, 18, 1)'
    },
    initial: STATES.LIVE_STREAMING.ACTIVE.INDEX,
    states: {
      /**
       * Active State
       */
      [STATES.LIVE_STREAMING.ACTIVE.INDEX]: {
        entry: [
          assign({
            streamSource() {
              const video = document.createElement('video');
              video.setAttribute('playsinline', 'playsinline');
              return video;
            }
          }),
          assign({
            streamRef(context) {
              return spawn(streamMachine(context), 'streamRef');
            }
          }),
          sendParent(EVENT_NAMES.NOW_PLAYING.GET_VOLUME),
          () => {
            ga().event({
              category: EVENTS.NOW_PLAYING.CATEGORY,
              action: EVENTS.NOW_PLAYING.ACTIONS.PLAYING,
              label: 'Live Streaming'
            });
          }
        ],
        id: STATES.LIVE_STREAMING.ACTIVE.INDEX,
        initial: STATES.LIVE_STREAMING.ACTIVE.BUFFERING,

        on: {
          [EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME]: {
            actions: [forwardTo('streamRef')]
          }
        },

        states: {
          [STATES.LIVE_STREAMING.ACTIVE.BUFFERING]: {
            on: {
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.PLAYING]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PLAYING
              },
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PAUSED,
                actions: [send('pause', { to: 'streamRef' })]
              },
              [EVENT_NAMES.LIVE_STREAMING.DESTROY]: {
                target: `#${MACHINE_NAMES.LIVE_STREAMING}.${STATES.LIVE_STREAMING.DESTROYED}`,
                actions: [send('pause', { to: 'streamRef' })]
              }
            }
          },

          [STATES.LIVE_STREAMING.ACTIVE.PLAYING]: {
            entry: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY)],
            on: {
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PAUSED,
                actions: [send('pause', { to: 'streamRef' })]
              },
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.PAUSED_EXTERNALLY]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PAUSED
              },
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.BUFFERING]: {
                target: STATES.LIVE_STREAMING.ACTIVE.BUFFERING
              },
              [EVENT_NAMES.LIVE_STREAMING.DESTROY]: {
                target: `#${MACHINE_NAMES.LIVE_STREAMING}.${STATES.LIVE_STREAMING.DESTROYED}`,
                actions: [send('pause', { to: 'streamRef' })]
              }
            }
          },

          [STATES.LIVE_STREAMING.ACTIVE.PAUSED]: {
            entry: sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE),
            on: {
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PLAYING,
                actions: [send('play', { to: 'streamRef' })]
              },
              [EVENT_NAMES.LIVE_STREAMING.ACTIVE.PLAYED_EXTERNALLY]: {
                target: STATES.LIVE_STREAMING.ACTIVE.PLAYING
              },
              [EVENT_NAMES.LIVE_STREAMING.DESTROY]: {
                target: `#${MACHINE_NAMES.LIVE_STREAMING}.${STATES.LIVE_STREAMING.DESTROYED}`
              }
            }
          }
        }
      },

      [STATES.LIVE_STREAMING.DESTROYED]: {
        type: 'final'
      }
    }
  },
  {}
);
