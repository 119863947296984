/* eslint-disable react/destructuring-assignment */
import cx from 'classnames';

import { Component } from 'react';
import { useNetworkState } from '@hooks/state/useNetwork';

import Box from './Box';

class Body extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: true,
      isLoadedManually: false,
      isLoadedByCallback: false,
      networkState: props.networkState
    };

    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.networkState !== prevState.networkState) {
      if (nextProps.networkState && !prevState.networkState && !prevState.isLoaded) {
        return {
          ...prevState,
          isLoadedManually: true,
          networkState: true
        };
      }
    }

    return {
      ...prevState,
      networkState: nextProps.networkState
    };
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     nextProps.src !== this.props.src ||
  //     nextProps.alt !== this.props.alt ||
  //     nextProps.className !== this.props.className ||
  //     nextProps.title !== this.props.title ||
  //     nextState.isLoadedManually !== this.state.isLoadedManually ||
  //     nextState.isLoaded !== this.state.isLoaded ||
  //     nextState.networkState !== this.state.networkState
  //   );
  // }

  onLoad() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad();
    }

    this.setState({
      isLoaded: true,
      isLoadedByCallback: true
    });
  }

  onError() {
    const { onError } = this.props;
    if (onError) {
      onError();
    }

    this.setState({
      isLoaded: false
    });
  }

  render() {
    const { children, src, className, alt, title, loading, draggable, fadeIn } = this.props;
    const { isLoaded, isLoadedManually, isLoadedByCallback } = this.state;

    if (isLoaded || isLoadedManually) {
      return (
        <Box
          as="img"
          className={cx('max-w-full block', className, {
            'opacity-0': fadeIn,
            'img-intro': fadeIn && isLoadedByCallback
          })}
          src={src}
          alt={alt}
          title={title}
          loading={loading}
          draggable={draggable}
          onLoad={this.onLoad}
          onError={this.onError}
        />
      );
    }

    return children;
  }
}

const Image = props => {
  const networkState = useNetworkState();

  return <Body networkState={networkState} {...props} />;
};

Image.defaultProps = {
  // failed state
  children: null,
  draggable: false,
  fadeIn: true
};

export default Image;
