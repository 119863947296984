import { EVENT_NAMES } from '@constants/machines/now-playing';
import debounce from 'lodash/debounce';

import { getAppService } from '@state/services/app-service';
import { getNowPlayingService } from '@machines/app/selectors';
import { getVolume } from '@machines/now-playing/selectors';

import { isMediaSessionSupported } from '@functions/browser';

import { FadePlay } from '@helpers/dom/fade-play';
import { MediaSession } from '@helpers/dom/media-session';

export const adStreamMachine = context => {
  return (sendParent, receiver) => {
    const appService = getAppService();
    const nowPlayingService = getNowPlayingService(appService.state);

    let volume = getVolume(nowPlayingService.state);
    let mediaSession;

    const { streamSource, ...restContext } = context;
    const playControl = new FadePlay({ media: streamSource, disabled: window.isTouch });

    if (isMediaSessionSupported()) {
      mediaSession = new MediaSession({
        media: streamSource,
        data: restContext,
        onPlay() {
          sendParent(EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE);
        },
        onPause() {
          sendParent(EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE);
        },
        onStop() {
          sendParent(EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE);
        }
      });
    }

    streamSource.src = context.link;
    if (!context.shouldPreload) {
      playControl.to(true, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(true);
          mediaSession?.updatePositionState();
          mediaSession?.initEventListeners();
        },
        onError() {
          sendParent(EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE);

          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(false);
          mediaSession?.updatePositionState();
          mediaSession?.initEventListeners();
        }
      });
    }
    // streamSource.muted = true;

    // METHODS
    const onPlay = () => {
      playControl.to(true, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(true);
          mediaSession?.updatePositionState();
          mediaSession?.initEventListeners();
        }
      });
    };

    const onPause = () => {
      playControl.to(false, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(false);
          mediaSession?.updatePositionState();
        }
      });
    };

    const resetCurrentTime = () => {
      streamSource.currentTime = 0;
    };

    const onWaiting = debounce(() => {
      sendParent(EVENT_NAMES.AD.ACTIVE.BUFFERING);

      mediaSession?.setPlaybackState(false);
    }, 3000);

    const onPlaying = () => {
      if (onWaiting.cancel) {
        onWaiting.cancel();
      }

      sendParent(EVENT_NAMES.AD.ACTIVE.PLAYING);
      mediaSession?.setPlaybackState(true);
    };

    // example: when user click on native ios play pause controls on video
    const onPlayExternally = () => {
      sendParent(EVENT_NAMES.AD.ACTIVE.PLAYED_EXTERNALLY);
    };

    const onPauseExternally = () => {
      sendParent(EVENT_NAMES.AD.ACTIVE.PAUSED_EXTERNALLY);
    };

    const onEnd = () => {
      sendParent(EVENT_NAMES.AD.ACTIVE.FINISHED);
    };

    const onVolumeChange = value => {
      volume = value;
      streamSource.volume = value;
    };

    const cleanup = () => {
      streamSource.removeEventListener('play', onPlayExternally);
      streamSource.removeEventListener('pause', onPauseExternally);
      streamSource.removeEventListener('playing', onPlaying);
      streamSource.removeEventListener('waiting', onWaiting);
      streamSource.removeEventListener('ended', onEnd);
      //   streamSource.removeEventListener('timeupdate', onTimeUpdate);

      if (!streamSource?.paused) {
        onPause();
      }
    };

    const mount = () => {
      streamSource.addEventListener('play', onPlayExternally);
      streamSource.addEventListener('pause', onPauseExternally);
      streamSource.addEventListener('playing', onPlaying);
      streamSource.addEventListener('waiting', onWaiting);
      streamSource.addEventListener('ended', onEnd);
      //   streamSource.addEventListener('timeupdate', onTimeUpdate);

      mediaSession?.setMetaData();
      mediaSession?.setPlaybackState(false);
      mediaSession?.updatePositionState();
      mediaSession?.initEventListeners();
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case 'play':
          onPlay();
          break;

        case 'pause':
          onPause();
          break;

        case EVENT_NAMES.AD.ACTIVE.PLAY_FROM_START:
          resetCurrentTime();
          break;

        case EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME:
          onVolumeChange(event.value);
          break;

        default:
          break;
      }
    });

    // disposal
    return () => {
      cleanup();
    };
  };
};
