import { STATES } from '@constants/machines/now-playing';

export const isActive = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}`
  );
};

export const isMediaStreaming = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}`
  );
};

export const isMiddleAdsStreaming = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.INDEX}`
  );
};

export const isBetweenMediaAdsStreaming = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX}`
  );
};

export const isAdsStreaming = state => {
  return [isBetweenMediaAdsStreaming, isMiddleAdsStreaming].some(fn => fn(state));
};

export const isLiveStreaming = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}`
  );
};

export const isMediaStreamingAndPlaying = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.MEDIA.PLAYING.INDEX}`
  );
};

export const isMiddleAdStreamingAndPlaying = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.MIDDLE_ADS_STREAMING.PLAYING}`
  );
};

export const isAdStreamingAndPlaying = state => {
  return (
    state.matches(
      `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.ADS_STREAMING.PLAYING}`
    ) || isMiddleAdStreamingAndPlaying(state)
  );
};

export const isLiveStreamingAndPlaying = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.LIVE_STREAMING.PLAYING}`
  );
};

export const hasUserReachedSkipLimit = state => {
  return state.matches(
    `${STATES.NOW_PLAYING.AUTHENTICATED.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.INDEX}.${STATES.NOW_PLAYING.AUTHENTICATED.ACTIVE.STREAMING.CHECK_SKIP_LIMIT.CHECKING.SKIPPING_IS_NOT_ALLOWED.INDEX}`
  );
};
