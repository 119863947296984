import '@public/fonts/index.css';
import '@styles/tailwind.css';
import '@styles/index.scss';
import 'cropperjs/dist/cropper.css';
import '@functions/polyfill/string';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import NextApp from 'next/app';
import Script from 'next/script';
import platform from 'platform';
import { redirect } from '@functions/server/redirect';
import { isWhiteListEmail } from '@functions/server/is';

// import { DEFAULT_SERVER_URL } from '@constants/urls';
import { isDev, isNative, isWeb } from '@functions/env';
import { isSupportedBrowser, isTouch, isBot } from '@functions/server';
import { loadServerConfig } from '@api/config';
import { loadProfile } from '@api/auth';
import { getWebsiteJson, getRadioJavanOrganizationJson } from '@functions/schema';

import AppLayout from '@components/layouts/AppLayout';
import SeoLayout from '@components/layouts/SeoLayout';
import AppCommands from '@components/native/AppCommands';

const LayoutsWrapper = dynamic(() => import('@components/layouts/LayoutsWrapper'), { ssr: false });
const DisableDevTool = dynamic(() => import('@components/browser/DisableDevTool'), { ssr: false });
const JsonLdScript = dynamic(() => import('@components/head/seo/JsonLdScript'));

export default class App extends NextApp {
  static async getInitialProps({ Component, ctx }) {
    const data = {
      isSupportedBrowser: true,
      isDevToolsAllowed: true
    };

    /**
     * We only need to detect platform is touch or not only when running on web env
     */
    if (!ctx.req) {
      return {
        data
      };
    }
    /**
     * This part will be run on server
     */
    const { req, res, pathname } = ctx;
    const headers = ctx?.req?.headers;

    data.isNative = isNative();
    data.isWeb = isWeb();

    if (!data.isNative) {
      data.isBot = isBot({ headers });

      if (!data.isBot) {
        data.isSupportedBrowser = isSupportedBrowser({ headers });

        if (!data.isSupportedBrowser) {
          redirect({ res, to: '/unsupported-browser.html' });

          return {
            data
          };
        }
      }
    }

    if (!data.isBot) {
      if (data.isWeb) {
        data.isTouch = isTouch({ headers });

        if (!data.isTouch) {
          data.isDevToolsAllowed = false;
        }
      }
    }

    // check platform only once at server
    const userAgent = headers['user-agent'];
    data.platform = platform.parse(userAgent);

    /**
     * Load server endpoint url
     * Force to use local apis from next
     */
    if (!data.isWeb) {
      try {
        const config = await loadServerConfig();
        data.config = config;
      } catch (e) {
        console.log(e);
      }
      // data.config = {
      //   server: process.env.DEFAULT_API_ENDPOINT
      // };
    }

    /**
     * Disable SSR on native app because of the following reasons
     * 1. app is hosting on different domains and cookie is not accessible on server side if domain of host and serve url is different!
     * 2. if we enable ssr it takes more time to load the app
     * 3. we could use proxy /api/json but it increases the costs of hosting on aws
     *
     * IMPORTANT NOTE: For any reasons decided to enable ssr on native app you should change some config in machines as well!
     */
    if (data.isNative) {
      return {
        data
      };
    }

    /**
     * Authentication and Redirection
     */

    if (!data.isBot) {
      const cookie = req?.headers?.cookie;

      if (cookie) {
        try {
          data.user = await loadProfile({
            baseURL: data?.config?.server,
            headers: {
              cookie,
              userAgent
            }
          });

          if (!data.isDevToolsAllowed) {
            data.isDevToolsAllowed = isWhiteListEmail({ email: data?.user?.email });
          }

          if (pathname.includes('auth')) {
            redirect({ res, to: '/' });
          }
        } catch (e) {
          if (data.isNative || Component.requireAuthentication) {
            if (!pathname.includes('auth')) {
              redirect({ res, to: `/auth/login?r=${pathname}` });
            }
          }
        }
      } else if (data.isNative || Component.requireAuthentication) {
        if (!pathname.includes('auth')) {
          redirect({ res, to: `/auth/login?r=${pathname}` });
        }
      }
    }

    if (data.isBot) {
      if (Component.requireAuthentication) {
        redirect({ res, to: '/' });
      }
    }

    if (Component.getInitialProps) {
      const pageProps = await Component.getInitialProps(ctx, { config: data.config });

      return {
        data,
        pageProps
      };
    }

    return {
      data
    };
  }

  ssrData = null;

  componentDidMount() {
    window.isTouch = this.props.data.isTouch;
    // window.platform = this.props.data.platform;

    this.ssrData = this.props.data;

    if (isDev()) {
      return;
    }

    document.oncontextmenu = e => {
      e.preventDefault();
    };
  }

  getData() {
    if (this.ssrData) {
      return this.ssrData;
    }

    return this.props.data;
  }

  render() {
    const { data: _, ...props } = this.props;

    const {
      Component,
      router: { pathname, query }
    } = props;

    const data = this.getData();

    if (!data.isSupportedBrowser) {
      return null;
    }

    return (
      <>
        <Head>
          <title key="title">{process.env.APP_NAME}</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
          />
          {data.isTouch ? (
            <meta
              name="apple-itunes-app"
              key="apple-itunes-app"
              content="app-id=286225933, app-argument=radiojavan://home"
            />
          ) : null}
        </Head>

        {data.isNative ? <AppCommands /> : null}

        <AppLayout
          data={data}
          platform={data.platform}
          requireAuthentication={Component.requireAuthentication}
        >
          <SeoLayout
            key={pathname}
            Component={Component}
            data={data}
            pageProps={props.pageProps}
            query={query}
          />
          <LayoutsWrapper isTouch={data.isTouch} isBot={data.isBot} {...props} />
        </AppLayout>
        {data.isDevToolsAllowed ? null : <DisableDevTool />}
        {data.isWeb ? (
          <JsonLdScript scriptId="radiojavan-org" jsonLd={getRadioJavanOrganizationJson()} />
        ) : null}
        {data.isWeb ? (
          <JsonLdScript scriptId="radiojavan-website" jsonLd={getWebsiteJson()} />
        ) : null}
        <Script
          id="gtag"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
        />
        {data.isWeb && !isDev() ? (
          <>
            <Script
              id="gpt"
              strategy="afterInteractive"
              src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            />
            <Script
              id="recaptcha"
              strategy="afterInteractive"
              src={`https://google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
            />
          </>
        ) : null}
        {data.isWeb ? <div id="grecaptcha-placeholder" /> : null}
      </>
    );
  }
}
