import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

const defaultCompare = (a, b) => a === b;

function getServiceSnapshot(service) {
  return service.status !== 0 ? service.getSnapshot() : service.machine.initialState;
}

function isService(actor) {
  return 'state' in actor && 'machine' in actor;
}

function isActorWithState(actorRef) {
  return 'state' in actorRef;
}

const defaultGetSnapshot = (a, initialStateCacheRef) => {
  if (isService(a)) {
    // A status of 0 = interpreter not started
    if (a.status === 0 && initialStateCacheRef?.current) {
      return initialStateCacheRef?.current;
    }

    const snapshot = getServiceSnapshot(a);

    initialStateCacheRef.current = a.status === 0 ? snapshot : null;

    return snapshot;
  }
  return isActorWithState(a) ? a.state : undefined;
};

export const useMatchers = (service, matcher, compare = defaultCompare, getSnapshot) => {
  const initialStateCacheRef = useRef(null);

  const subscribe = useCallback(
    handleStoreChange => {
      const { unsubscribe } = service.subscribe(handleStoreChange);

      return unsubscribe;
    },
    [service]
  );

  const boundGetSnapshot = useCallback(() => {
    if (getSnapshot) {
      return getSnapshot(service);
    }

    return defaultGetSnapshot(service, initialStateCacheRef);
  }, [service, getSnapshot]);

  const selectedSnapshot = useSyncExternalStoreWithSelector(
    subscribe,
    boundGetSnapshot,
    boundGetSnapshot,
    matcher,
    compare
  );

  return useMemo(() => {
    return selectedSnapshot;
  }, [selectedSnapshot]);
};

export const useSafeMatchers = (service, matcher, compare = defaultCompare, getSnapshot) => {
  const initialStateCacheRef = useRef(null);

  const subscribe = useCallback(
    handleStoreChange => {
      if (!service) {
        return () => {};
      }

      const { unsubscribe } = service.subscribe(handleStoreChange);

      return unsubscribe;
    },
    [service]
  );

  const boundGetSnapshot = useCallback(() => {
    if (!service) {
      return null;
    }

    if (getSnapshot) {
      return getSnapshot(service);
    }

    return defaultGetSnapshot(service, initialStateCacheRef);
  }, [service, getSnapshot]);

  const safeMatcher = useCallback(
    (...args) => {
      if (!service) {
        return () => {};
      }

      return matcher(...args);
    },
    [service, matcher]
  );

  const selectedSnapshot = useSyncExternalStoreWithSelector(
    subscribe,
    boundGetSnapshot,
    boundGetSnapshot,
    safeMatcher,
    compare
  );

  return useMemo(() => {
    return selectedSnapshot;
  }, [selectedSnapshot]);
};

const getBulkResult = (services, matcher) => {
  return services.every(service => {
    const state = service?.state ?? service?.initialState;

    return matcher(state);
  });
};

export const useBulkMatchers = (services, matcher) => {
  const [matches, setMatches] = useState(() => {
    return getBulkResult(services, matcher);
  });

  useEffect(() => {
    const unsubscribes = services.map(service => {
      const { unsubscribe } = service.subscribe(state => {
        if (!state.changed) return;

        const result = getBulkResult(services, matcher);
        setMatches(result);
      });

      return unsubscribe;
    });

    return () => {
      unsubscribes.map(fn => fn());
    };
  }, []);

  return useMemo(() => {
    return matches;
  }, [matches]);
};
