import dynamic from 'next/dynamic';
import cx from 'classnames';
// import { FADE_FAST } from '@theme/framer-variants';

import { cloneElement, useRef, useState, useEffect } from 'react';
import { useScroll } from '@hooks';

import { Box, Heading, IconButton, ScrollKit, IF } from '@ui';

const CloseIcon = dynamic(() => import('@public/vectors/close.svg'));
const ArrowLeftIcon = dynamic(() => import('@public/vectors/arrow-left-flat.svg'));

const Background = props => {
  return <Box className="fixed inset-0 z-10 bg-black-800 backdrop-blur-sm" {...props} />;
};

const NoneFullScreen = ({
  title,
  onClose,
  onBack,
  width,
  leftFooter,
  footer,
  customFooter,
  hasBackground,
  headerBody,
  rightHeader,
  scrollerKey,
  children
}) => {
  const ref = useRef(null);
  const [, reRender] = useState(1);
  const { top, end } = useScroll({ ref, debounceTime: 0 });

  useEffect(() => {
    setTimeout(() => {
      reRender(Math.random());
    }, 0);
  }, []);

  return (
    <Box className="fixed inset-0 flex items-center justify-center p-8 z-30">
      {hasBackground ? <Background onClick={onClose} /> : null}
      <Box
        style={{ maxWidth: width }}
        className="flex flex-col flex-1 relative overflow-hidden rounded-md border-p-1 max-h-full w-full border-th-foreground-low-solid bg-th-primary-background h-auto z-20"
      >
        <Box
          className={cx('flex flex-col py-4 px-8 transition-colors duration-200 border-b-p-1 ', [
            top > 0 ? 'border-th-foreground-low-solid' : 'border-transparent'
          ])}
        >
          <Box className="flex items-center">
            <IF when={!!onBack}>
              <Box className="mr-2">
                <IconButton size="sm" variant="ghost" onClick={onBack}>
                  <ArrowLeftIcon className="w-5 h-5" />
                </IconButton>
              </Box>
            </IF>
            <Box className="flex items-center flex-1 truncate">
              <Box className="flex-1 truncate">
                <Heading className="text-2xl truncate" as="h3">
                  {title}
                </Heading>
              </Box>
              {rightHeader}
            </Box>
            <Box>
              <IconButton onClick={onClose} size="sm" variant="ghost">
                <CloseIcon className="w-5 h-5" />
              </IconButton>
            </Box>
          </Box>
          {headerBody}
        </Box>

        <ScrollKit
          ref={ref}
          className="flex-1 transparent-rail"
          id="none-f-modal-content"
          key={scrollerKey}
        >
          <Box className="px-8 pb-1">{children}</Box>
        </ScrollKit>

        <IF when={footer}>
          <Box
            style={{ minHeight: 70 }}
            className={cx(
              'flex px-8 py-6 items-center justify-between border-t-p-1 transition-colors duration-200',
              [!end && top >= 0 ? 'border-th-foreground-low-solid' : 'border-transparent']
            )}
            as="footer"
          >
            <Box className="flex space-x-4 items-center flex-1 mr-4">{leftFooter}</Box>
            <Box className="flex space-x-4 items-center">{footer}</Box>
          </Box>
        </IF>
        {customFooter
          ? cloneElement(customFooter, {
              className: cx('px-8 py-6 border-t-p-1 transition-colors duration-200', [
                !end && top >= 0 ? 'border-th-foreground-low-solid' : 'border-transparent'
              ])
            })
          : null}
      </Box>
    </Box>
  );
};

NoneFullScreen.Background = Background;

NoneFullScreen.defaultProps = {
  width: 700,
  hasBackground: true,
  title: '',
  titleClassNames: '',
  bodyClassNames: '',
  footerClassNames: ''
};

export default NoneFullScreen;
