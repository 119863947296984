import { APP_SETTINGS } from '@constants';
import { isNative } from '@functions/env';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

const { PLAYBACK, SIDEBAR, NOW_PLAYING, PLAYLIST } = APP_SETTINGS;
const { CONCURRENCY } = APP_SETTINGS.DOWNLOAD_MANAGER;

export const getInitialBrowserSettings = ({ storage, user }) => {
  const data = storage;

  if (isNumber(data[CONCURRENCY])) {
    if (isNative()) {
      if (data[CONCURRENCY] >= 8) {
        data[CONCURRENCY] = 8;
      } else if (data[CONCURRENCY] <= 1) {
        data[CONCURRENCY] = 1;
      }
    }
  } else if (isNative()) {
    data[CONCURRENCY] = 2;
  } else {
    data[CONCURRENCY] = 1;
  }

  if (!isBoolean(storage?.[SIDEBAR.PLAYLISTS_IMAGES])) {
    data[SIDEBAR.PLAYLISTS_IMAGES] = true;
  }

  // TODO: right a better condition
  if (isString(storage?.[NOW_PLAYING.STYLE]) && storage?.[NOW_PLAYING.STYLE] === 'sidebar') {
    data[NOW_PLAYING.STYLE] = 'sidebar';
  } else {
    data[NOW_PLAYING.STYLE] = 'footer';
  }

  if (user.hasSubscription) {
    if (storage?.[PLAYBACK.AUDIO_QUALITY] === 'high') {
      data[PLAYBACK.AUDIO_QUALITY] = 'high';
    } else {
      data[PLAYBACK.AUDIO_QUALITY] = 'normal';
    }
    if (storage?.[PLAYBACK.AUDIO_DOWNLOAD_QUALITY] === 'high') {
      data[PLAYBACK.AUDIO_DOWNLOAD_QUALITY] = 'high';
    } else {
      data[PLAYBACK.AUDIO_DOWNLOAD_QUALITY] = 'normal';
    }
    if (storage?.[PLAYBACK.VIDEO_DOWNLOAD_QUALITY] === 'high') {
      data[PLAYBACK.VIDEO_DOWNLOAD_QUALITY] = 'high';
    } else {
      data[PLAYBACK.VIDEO_DOWNLOAD_QUALITY] = 'normal';
    }
  } else {
    data[PLAYBACK.AUDIO_QUALITY] = 'normal';
    data[PLAYBACK.AUDIO_DOWNLOAD_QUALITY] = 'normal';
    data[PLAYBACK.VIDEO_DOWNLOAD_QUALITY] = 'normal';
  }

  if (isUndefined(data[PLAYBACK.VISUAL_COVERS])) {
    data[PLAYBACK.VISUAL_COVERS] = true;
  }

  if (isUndefined(data[PLAYLIST.ADD_TO_TOP_OF_PLAYLIST])) {
    data[PLAYLIST.ADD_TO_TOP_OF_PLAYLIST] = true;
  }

  return data;
};
