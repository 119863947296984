import Router from 'next/router';
import * as Sentry from '@sentry/react';

export const getID = ({ pathname }) => {
  if (!pathname) {
    return null;
  }

  try {
    return pathname
      .split('/')
      .filter(Boolean)
      .map(item => {
        const withoutPlus = item.replace(/\+/g, '%20');
        return decodeURIComponent(withoutPlus);
      })
      .join('/');
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

export const toMedia = ({ host, pathname }) => {
  const id = getID({ pathname });

  if (!id) {
    return;
  }

  // new host should be added to main/configs/protocol.js
  switch (host) {
    case 'song':
    case 'mp3':
      Router.push(`/song/${id}?autoPlay=true`);
      break;
    case 'video':
      Router.push(`/video/${id}?autoPlay=true`);
      break;
    case 'podcast':
      Router.push(`/podcast/${id}?autoPlay=true`);
      break;
    case 'mp3_album':
    case 'album':
      Router.push(`/album/${id}`);
      break;
    case 'show':
      Router.push(`/podcast/show/${id}`);
      break;
    case 'playlist':
      Router.push(`/playlist/${id}`);
      break;
    case 'artist':
      Router.push(`/artist/${id}`);
      break;
    case 'story':
      Router.push(`/story/${id}`);
      break;
    case 'story_video':
      Router.push(`/s/${id}`);
      break;
    case 'event':
      Router.push(`/event/${id}`);
      break;
    case 'user':
      Router.push(`/u/${id}`);
      break;
    default:
  }
};

export const navigate = params => {
  if (params.redirect) {
    Router.push(params.redirect);
  }
};

export const toHome = () => {
  Router.push('/');
};

export const toBrowse = () => {
  Router.push('/browse');
};

export const toSearch = () => {
  Router.push('/search');
};

export const toPlaylists = () => {
  Router.push('/playlists');
};

export const toSongs = () => {
  Router.push('/library/songs');
};

export const toLikedSongs = () => {
  Router.push('/library/liked/songs');
};

export const toPodcasts = () => {
  Router.push('/library/podcasts');
};

export const toVideos = () => {
  Router.push('/library/videos');
};

export const toArtists = () => {
  Router.push('/library/artists');
};

export const toPreferences = () => {
  Router.push('/preferences');
};
