import dynamic from 'next/dynamic';
import { ga, titleToDashedURL } from '@helpers/ga4';

import Router from 'next/router';
import { ROOTS } from '@constants';

import React, { useCallback, useEffect } from 'react';

import { useKeyboard } from '@hooks';

import NoneFullScreen from './NoneFullScreen';
import FullScreen from './FullScreen';

const Portal = dynamic(() => import('@components/portal/Portal'), {
  ssr: false
});

const components = {
  default: NoneFullScreen,
  fullscreen: FullScreen
};

const Body = ({ type, children, onClose, closeOnRouteChange, ...rest }) => {
  useKeyboard(['Escape'], onClose, 'keyup');

  const onChanged = useCallback(() => {
    if (!closeOnRouteChange) {
      return;
    }
    onClose();
  }, [closeOnRouteChange]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', onChanged);

    return () => {
      Router.events.off('routeChangeComplete', onChanged);
    };
  }, [closeOnRouteChange]);

  return (
    <Portal rootID={ROOTS.MODAL_PORTAL}>
      {React.createElement(components[type], { onClose, ...rest }, children)}
    </Portal>
  );
};

const Modal = ({ isOpen, ...rest }) => {
  if (!isOpen) {
    return null;
  }

  return <Body {...rest} />;
};

Modal.defaultProps = {
  type: 'default',
  onClose() {},
  closeOnRouteChange: false
};

Modal.Background = NoneFullScreen.Background;

Modal.Analytics = ({ title }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (title) {
      ga().send({
        hitType: 'pageview',
        page: titleToDashedURL(title),
        title: `Modal - ${title}`
      });
    }
  }, []);

  return null;
};

export default Modal;
