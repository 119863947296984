import { getNetworkService } from '@state/services/network-service';
import { getNetworkState } from '@state/machines/network/selectors';

import { useMemo, useEffect, useState } from 'react';

export const useNetworkState = () => {
  const [value, setState] = useState(true);

  useEffect(() => {
    const service = getNetworkService();

    const { unsubscribe } = service.subscribe(state => {
      if (!state.changed) return;

      setState(getNetworkState(state));
    });

    return unsubscribe;
  }, []);

  return useMemo(() => {
    return value;
  }, [value]);
};
