export const EVENT_NAMES = {
  APP: {
    INITIAL_APP_CONFIG_LOADED: 'APP__INITIAL_APP_CONFIG_LOADED',
    DESTROY: 'APP__DESTROY',
    REDIRECT_TO_APP: 'APP__REDIRECT_TO_APP',
    REDIRECT_TO_AUTH: 'APP__REDIRECT_TO_AUTH',
    REDIRECT_TO_REQUIRE_AUTH: 'APP__REDIRECT_TO_REQUIRE_AUTH',
    UPDATE_REQUIRE_AUTHENTICATION: 'APP__UPDATE_REQUIRE_AUTHENTICATION'
  }
};

export const STATES = {
  APP: {
    LOADING: {
      INDEX: 'LOADING',

      CONFIG: 'CONFIG'
    },

    // user has logged in
    ACTIVATED: 'ACTIVATED',
    DEACTIVATE: 'DEACTIVATE'
  }
};

export const MACHINE_NAMES = {
  APP: 'APP_MACHINE',
  APP_CONFIG: 'APP_CONFIG_MACHINE'
};
