import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/now-playing';
import { EVENTS } from '@constants/analytics';

import { ga } from '@helpers/ga4';

import { createMachine, assign, spawn, send, sendParent, forwardTo } from 'xstate';
import { adStreamMachine } from '@machines/now-playing/ad/ad-stream-machine';
import { adPlayed } from '@api/media';

import * as actions from './actions';

export const adMachine = createMachine(
  {
    id: MACHINE_NAMES.AD,
    context: {
      streamSource: null
    },
    initial: STATES.AD.IDLE,
    states: {
      [STATES.AD.IDLE]: {
        on: {
          [EVENT_NAMES.AD.START]: {
            target: STATES.AD.ACTIVE.INDEX
          }
        }
      },

      /**
       * Active State
       */
      [STATES.AD.ACTIVE.INDEX]: {
        id: STATES.AD.ACTIVE.INDEX,
        initial: STATES.AD.ACTIVE.LOADING,
        states: {
          [STATES.AD.ACTIVE.LOADING]: {
            always: [
              {
                target: STATES.AD.ACTIVE.VALIDATING,
                actions: [
                  assign({
                    streamSource() {
                      const video = document.createElement('video');
                      video.setAttribute('playsinline', 'playsinline');
                      return video;
                    }
                  }),
                  assign({
                    streamRef(context) {
                      return spawn(adStreamMachine(context), 'streamRef');
                    }
                  })
                ],
                cond: ({ streamRef, streamSource }) => !streamRef && !streamSource
              },
              {
                target: STATES.AD.ACTIVE.VALIDATING
              }
            ]
          },

          [STATES.AD.ACTIVE.VALIDATING]: {
            always: [
              {
                target: STATES.AD.ACTIVE.PRE_LOADING.INDEX,
                cond: ({ shouldPreload }) => shouldPreload
              },
              {
                target: STATES.AD.ACTIVE.PLAYING.INDEX
              }
            ]
          },

          /**
           * Preloading State
           */
          [STATES.AD.ACTIVE.PRE_LOADING.INDEX]: {},

          /**
           * Playing State: When an ad is selected, Can be in playing, paused, buffering state
           */
          [STATES.AD.ACTIVE.PLAYING.INDEX]: {
            entry: [
              sendParent(EVENT_NAMES.NOW_PLAYING.GET_VOLUME),
              ({ title }) => {
                ga().event({
                  category: EVENTS.NOW_PLAYING.CATEGORY,
                  action: EVENTS.NOW_PLAYING.ACTIONS.PLAYING,
                  label: title
                });
              }
            ],
            id: STATES.AD.ACTIVE.PLAYING.INDEX,
            initial: STATES.AD.ACTIVE.PLAYING.PLAYING,
            on: {
              [EVENT_NAMES.AD.ACTIVE.FINISHED]: {
                target: `#${MACHINE_NAMES.AD}.${STATES.AD.DESTROYING}`,
                actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.ADS_STREAMING.FINISHED)]
              },
              [EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME]: {
                actions: [forwardTo('streamRef')]
              }
            },
            states: {
              [STATES.AD.ACTIVE.PLAYING.BUFFERING]: {
                on: {
                  [EVENT_NAMES.AD.ACTIVE.PLAYING]: {
                    target: STATES.AD.ACTIVE.PLAYING.PLAYING
                  },
                  [EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                    target: STATES.AD.ACTIVE.PLAYING.PAUSED,
                    actions: [send('pause', { to: 'streamRef' })]
                  },
                  [EVENT_NAMES.AD.DESTROY]: {
                    target: `#${MACHINE_NAMES.AD}.${STATES.AD.DESTROYING}`,
                    actions: [send('pause', { to: 'streamRef' })]
                  }
                }
              },

              [STATES.AD.ACTIVE.PLAYING.PLAYING]: {
                entry: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY)],
                on: {
                  [EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                    target: STATES.AD.ACTIVE.PLAYING.PAUSED,
                    actions: [send('pause', { to: 'streamRef' })]
                  },
                  [EVENT_NAMES.AD.ACTIVE.PAUSED_EXTERNALLY]: {
                    target: STATES.AD.ACTIVE.PLAYING.PAUSED
                  },
                  [EVENT_NAMES.AD.ACTIVE.BUFFERING]: {
                    target: STATES.AD.ACTIVE.PLAYING.BUFFERING
                  },
                  [EVENT_NAMES.AD.DESTROY]: {
                    target: `#${MACHINE_NAMES.AD}.${STATES.AD.DESTROYING}`,
                    actions: [send('pause', { to: 'streamRef' })]
                  }
                }
              },

              [STATES.AD.ACTIVE.PLAYING.PAUSED]: {
                entry: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE)],
                on: {
                  [EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                    target: STATES.AD.ACTIVE.PLAYING.PLAYING,
                    actions: [send('play', { to: 'streamRef' })]
                  },
                  [EVENT_NAMES.AD.ACTIVE.PLAYED_EXTERNALLY]: {
                    target: STATES.AD.ACTIVE.PLAYING.PLAYING
                  },
                  [EVENT_NAMES.AD.DESTROY]: {
                    target: `#${MACHINE_NAMES.AD}.${STATES.AD.DESTROYING}`
                  }
                }
              }
            }
          }
        }
      },

      [STATES.AD.DESTROYING]: {
        invoke: {
          src({ id }) {
            return adPlayed({ id });
          },
          onDone: {},
          onError: {}
        },
        on: {
          [EVENT_NAMES.AD.START]: [
            {
              target: STATES.AD.ACTIVE.INDEX,
              actions: [
                send(EVENT_NAMES.AD.ACTIVE.PLAY_FROM_START, { to: 'streamRef' }),
                send('play', { to: 'streamRef' })
              ],
              cond: ({ streamRef }) => streamRef
            },
            {
              target: STATES.AD.ACTIVE.INDEX
            }
          ]
        }
      }
    }
  },
  { actions }
);
