export const EVENT_NAMES = {
  SUBMIT: 'SUBMIT',
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  LOGOUT: 'LOGOUT',
  RELOAD_PROFILE: 'RELOAD_PROFILE',
  ACCOUNT_DEACTIVATED: 'ACCOUNT_DEACTIVATED',
  RECOVER_ACCOUNT: 'RECOVER_ACCOUNT',
  TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE: 'TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE',
  TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_BECAUSE_REACHED_SKIP_LIMITS:
    'TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_BECAUSE_REACHED_SKIP_LIMITS',
  TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_TO_VIEW_LYRICS:
    'TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_TO_VIEW_LYRICS',
  SET_ACTIONS_REQUIRING_DATA: 'SET_ACTIONS_REQUIRING_DATA',
  RESET_ACTIONS_REQUIRING_DATA: 'RESET_ACTIONS_REQUIRING_DATA',
  DESTROY_INTERSTITIAL_ADS: 'DESTROY_INTERSTITIAL_ADS',
  INITIAL_INTERSTITIAL_ADS: 'INITIAL_INTERSTITIAL_ADS',

  UPDATE_USER_PROFILE: {
    BACK: 'UPDATE_USER_PROFILE__BACK',
    RELOAD_PROFILE: 'UPDATE_USER_PROFILE__RELOAD_PROFILE',
    TOGGLE_MODAL: 'UPDATE_USER_PROFILE__TOGGLE_MODAL',

    ON_SUBMIT: 'UPDATE_USER_PROFILE__ON_SUBMIT',
    SELECT_FILE: 'UPDATE_USER_PROFILE__SELECT_FILE',
    RESET_PASS: 'UPDATE_USER_PROFILE__RESET_PASS',
    REMOVE_USER_AVATAR: 'UPDATE_USER_PROFILE__REMOVE_USER_AVATAR',
    DEACTIVATE_ACCOUNT: 'UPDATE_USER_PROFILE__DEACTIVATE_ACCOUNT'
  }
};

export const STATES = {
  UPDATE_USER_PROFILE: {
    IDLE: 'IDLE',
    RELOADING_PROFILE: 'RELOADING_PROFILE',

    EDIT_PROFILE: {
      INDEX: 'EDIT_PROFILE',
      IDLE: 'IDLE',
      SUBMITTING: 'SUBMITTING',
      REMOVING_USER_AVATAR: 'REMOVING_USER_AVATAR'
    },

    UPLOAD_USER_AVATAR: {
      // CROPPING
      INDEX: 'UPLOAD_USER_AVATAR',
      IDLE: 'IDLE',
      SUBMITTING: 'SUBMITTING'
    },

    RESET_PASS: {
      INDEX: 'RESET_PASS',
      IDLE: 'IDLE',
      SUBMITTING: 'SUBMITTING'
    },

    DEACTIVATE_ACCOUNT: {
      INDEX: 'DEACTIVATE_ACCOUNT',
      IDLE: 'IDLE',
      SUBMITTING: 'SUBMITTING'
    }
  }
};

export const MACHINE_NAMES = {
  AUTH: 'AUTH_MACHINE',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE_MACHINE'
};
