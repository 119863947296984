import { EVENT_NAMES } from '@constants/machines/now-playing';
import { isMp3, isPlaylist } from '@functions/is';

import { useCallback, useMemo, useState, useEffect } from 'react';

import { useApp } from '@contexts';
import { useAppService } from '@hooks';
import { usePartial, useMatchers, useSafeMatchers } from '@state/hooks';
import { getNowPlayingService } from '@machines/app/selectors';
import {
  getSelected,
  getSelectedAd,
  getLiveStreamingRef,
  getRecentlyPlayed,
  getSelectedMachine
} from '@machines/now-playing/selectors';
import { getStreamSource } from '@machines/now-playing/item/selectors';
import { isPlaying } from '@machines/now-playing/item/matchers';
import { filter } from '@functions/filter';
import * as nowPlayingMatchers from '@machines/now-playing/matchers';

export const useNowPlayingService = () => {
  const { service } = useApp();
  const ref = usePartial(service, getNowPlayingService);

  return ref;
};

export const useNowPlayingContext = selector => {
  const ref = useNowPlayingService();
  const value = usePartial(ref, selector);

  return value;
};

export const useNowPlayingMatch = matcher => {
  const ref = useNowPlayingService();
  const value = useMatchers(ref, matcher);

  return value;
};

export const useNowPlayingHelpers = () => {
  const service = useNowPlayingService();

  const setPlayContext = useCallback(({ metadata, items, item, shuffle }) => {
    const options = {
      metadata,
      items,
      item,
      shuffle
    };

    service.send(EVENT_NAMES.NOW_PLAYING.SET_PLAY_CONTEXT, options);
  }, []);

  const toggleRepeat = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING.ACTIVE.TOGGLE_REPEAT);
  }, []);

  const addToQueue = useCallback(({ items }) => {
    service.send(EVENT_NAMES.NOW_PLAYING.ADD_TO_QUEUE, { items });
  }, []);

  const clearQueue = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING.CLEAR_QUEUE);
  }, []);

  const removeFromQueue = useCallback(({ item }) => {
    service.send(EVENT_NAMES.NOW_PLAYING.REMOVE_FROM_QUEUE, { item });
  }, []);

  const reorderQueue = useCallback(value => {
    service.send(EVENT_NAMES.NOW_PLAYING.REORDER_QUEUE, { value });
  }, []);

  const insertToQueue = useCallback(value => {
    service.send(EVENT_NAMES.NOW_PLAYING.INSERT_TO_QUEUE, { value });
  }, []);

  const toggleFullScreenUpNext = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_UP_NEXT, options);
    },
    [service]
  );

  const toggleFullScreenLyrics = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_FULL_SCREEN_LYRICS, options);
    },
    [service]
  );

  const toggleNoneFullScreenLyrics = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_NONE_FULL_SCREEN_LYRICS, options);
    },
    [service]
  );

  const toggleLyricsBox = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_LYRICS_BOX, options);
    },
    [service]
  );

  const toggleLiveStreaming = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_SHOW_LIVE_STREAMING, options);
    },
    [service]
  );

  const toggleFullScreen = useCallback(
    value => {
      const options = {
        value: typeof value === 'boolean' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING.TOGGLE_FULLSCREEN, options);
    },
    [service]
  );

  const updateVolume = useCallback(
    value => {
      service.send(EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME, { value });
    },
    [service]
  );

  const reorderUpNext = useCallback(
    value => {
      service.send(EVENT_NAMES.NOW_PLAYING.REORDER_UP_NEXT, { value });
    },
    [service]
  );

  const insertToUpNext = useCallback(
    value => {
      service.send(EVENT_NAMES.NOW_PLAYING.INSERT_TO_UP_NEXT, { value });
    },
    [service]
  );

  const removeFromUpNext = useCallback(
    ({ item }) => {
      service.send(EVENT_NAMES.NOW_PLAYING.REMOVE_FROM_UP_NEXT, { item });
    },
    [service]
  );

  return useMemo(() => {
    return {
      setPlayContext,
      toggleRepeat,

      addToQueue,
      clearQueue,
      removeFromQueue,
      reorderQueue,
      insertToQueue,
      updateVolume,
      reorderUpNext,
      insertToUpNext,
      removeFromUpNext,

      toggleFullScreenUpNext,
      toggleFullScreenLyrics,
      toggleNoneFullScreenLyrics,
      toggleLyricsBox,
      toggleLiveStreaming,
      toggleFullScreen
    };
  }, []);
};

const isNowPlayingMatchers = [
  nowPlayingMatchers.isMediaStreamingAndPlaying,
  nowPlayingMatchers.isAdStreamingAndPlaying,
  nowPlayingMatchers.isLiveStreamingAndPlaying
];

export const useIsNowPlaying = () => {
  const [isNowPlaying, setIsNowPlaying] = useState(false);
  const service = useAppService();

  useEffect(() => {
    if (!service || !process.browser) {
      return () => {};
    }

    const nowPlayingService = getNowPlayingService(service.state);

    if (!nowPlayingService) {
      return () => {};
    }

    setIsNowPlaying(
      isNowPlayingMatchers.some(matcher => {
        return matcher(nowPlayingService.state);
      })
    );

    const { unsubscribe } = nowPlayingService.subscribe(state => {
      // if (!state.changed) return;

      setIsNowPlaying(
        isNowPlayingMatchers.some(matcher => {
          return matcher(state);
        })
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return useMemo(() => {
    return isNowPlaying;
  }, [isNowPlaying]);
};

export const useSelected = () => {
  const selected = useNowPlayingContext(getSelected);

  return selected;
};

export const useSelectedRef = () => {
  const machine = useNowPlayingContext(getSelectedMachine);

  return machine;
};

export const useSelectedMatch = matcher => {
  const ref = useSelectedRef();
  const value = useMatchers(ref, matcher);

  return value;
};

export const useSelectedSafeMatch = matcher => {
  const ref = useSelectedRef();
  const value = useSafeMatchers(ref, matcher);

  return value;
};

export const useIsSelected = data => {
  const selected = useSelected();

  if (data?.selectedId) {
    return (
      selected?.id === data?.id &&
      selected?.type === data?.type &&
      selected?.selectedId === data?.selectedId
    );
  }

  return selected?.id === data?.id && selected?.type === data?.type;
};

export const useIsSelectedAndPlaying = data => {
  const isSelected = useIsSelected(data);
  const isPlayingState = useSelectedSafeMatch(isPlaying);

  return isSelected && isPlayingState;
};

export const useSelectedStreamSource = () => {
  const machine = useSelectedRef();
  const streamSource = usePartial(machine, getStreamSource);

  return streamSource;
};

export const useSelectedType = () => {
  const selected = useSelected();

  return useMemo(() => {
    return selected?.type;
  }, [selected?.type]);
};

export const useSelectedHelpers = () => {
  const service = useSelectedRef();

  const togglePlayPause = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE);
  }, [service]);

  const next = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING_ITEM.NEXT);
  }, [service]);

  const previous = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS);
  }, [service]);

  const seek = useCallback(
    value => {
      service.send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK, { value });
    },
    [service]
  );

  const seekCurrentTime = useCallback(
    value => {
      service.send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_CURRENT_TIME, { value });
    },
    [service]
  );

  const seekForward = useCallback(
    value => {
      const options = {
        value: typeof value === 'number' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_FORWARD, options);
    },
    [service]
  );

  const seekBackward = useCallback(
    value => {
      const options = {
        value: typeof value === 'number' ? value : undefined
      };

      service.send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_BACKWARD, options);
    },
    [service]
  );

  return {
    togglePlayPause,
    next,
    previous,
    seek,
    seekCurrentTime,
    seekForward,
    seekBackward
  };
};

/**
 * Now Playing Ads
 */
export const useSelectedAd = () => {
  const selected = useNowPlayingContext(getSelectedAd);

  return selected;
};

export const useSelectedAdRef = () => {
  const selected = useSelectedAd();

  return selected?.ref;
};

export const useSelectedAdMatch = matcher => {
  const ref = useSelectedAdRef();
  const value = useSafeMatchers(ref, matcher);

  return value;
};

export const useSelectedAdStreamSource = () => {
  const machine = useSelectedAdRef();
  const streamSource = usePartial(machine, getStreamSource);

  return streamSource;
};

export const useSelectedAdHelpers = () => {
  const service = useSelectedAdRef();

  const togglePlayPause = useCallback(
    value => {
      service.send(EVENT_NAMES.AD.ACTIVE.TOGGLE_PLAY_PAUSE, { value });
    },
    [service]
  );

  return {
    togglePlayPause
  };
};

/**
 * Now Playing Live Streaming
 */
export const useLiveStreamingRef = () => {
  const ref = useNowPlayingContext(getLiveStreamingRef);

  return ref;
};

export const useLiveStreamingMatch = matcher => {
  const ref = useLiveStreamingRef();
  const value = useMatchers(ref, matcher);

  return value;
};

export const useLiveStreamingSource = () => {
  const machine = useLiveStreamingRef();
  const streamSource = usePartial(machine, getStreamSource);

  return streamSource;
};

export const useLiveStreamingHelpers = () => {
  const service = useLiveStreamingRef();

  const togglePlayPause = useCallback(
    value => {
      service.send(EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE, { value });
    },
    [service]
  );

  return {
    togglePlayPause
  };
};

/**
 * Recently Played
 */
export const useRecentlyPlayed = ({ formValues } = {}) => {
  const value = useNowPlayingContext(getRecentlyPlayed);

  return useMemo(() => {
    return filter(value, formValues);
  }, [value?.[0]?.id, value?.length, formValues?.query]);
};

export const useRecentlyPlayedSongs = (...args) => {
  const data = useRecentlyPlayed(...args);

  return data.filter(isMp3);
};

export const useRecentlyPlayedPlaylists = (...args) => {
  const data = useRecentlyPlayed(...args);

  return data.filter(isPlaylist);
};

export const useHasRecentlyPlayed = () => {
  const value = useRecentlyPlayed();

  return useMemo(() => {
    return value.length;
  }, [value.length]);
};

export const useRecentlyPlayedHelpers = () => {
  const service = useNowPlayingService();

  const clearAll = useCallback(() => {
    service.send(EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.CLEAR_ALL);
  }, []);

  return {
    clearAll
  };
};
