/* eslint-disable no-await-in-loop */
import { EVENT_NAMES } from '@constants/machines/now-playing';
import { EVENT_NAMES as APP_SETTINGS_EVENT_NAMES } from '@constants/machines/app-settings';
import { APP_SETTINGS } from '@constants';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';

import { userPlays } from '@api/media';

const { FAILED_USER_PLAYS } = APP_SETTINGS.NOW_PLAYING;

export const failedUserPlaysMachine = () => {
  return (sendParent, receiver) => {
    const appService = getAppService();
    const appSettingsService = getAppSettingsService(appService.state);
    const initialValue = getBrowserSettings(FAILED_USER_PLAYS)(appSettingsService.state);

    let items = initialValue || [];

    const save = () => {
      appSettingsService.send({
        type: APP_SETTINGS_EVENT_NAMES.BROWSER_SETTINGS.SET,
        key: FAILED_USER_PLAYS,
        value: items
      });
    };

    const add = item => {
      items.push(item);

      save();
    };

    const reset = () => {
      items = [];
      save();
    };

    const retry = async () => {
      if (!items.length) {
        return;
      }

      try {
        await userPlays(items);

        /**
         * Reset all after sending the failed plays
         */
        reset();
      } catch (e) {
        console.log(e);
      }
    };

    const onStatusChanged = () => {
      retry();
    };

    const mount = () => {
      if (typeof navigator.onLine === 'boolean') {
        /**
         * Only when the network is available
         */
        if (navigator.onLine) {
          retry();
        }
      } else {
        /**
         * Support all browsers
         */
        retry();
      }

      window.addEventListener('online', onStatusChanged);
    };

    mount();

    receiver(event => {
      switch (event.type) {
        case EVENT_NAMES.NOW_PLAYING.FAILED_USER_PLAYS.ADD:
          add(event.data);
          break;
        default:
          break;
      }
    });

    // disposal
    return () => {
      window.removeEventListener('online', onStatusChanged);
    };
  };
};
