import { useMemo } from 'react';

import { useAppService } from '@hooks';
import { usePartial, useMatchers } from '@state/hooks';
import { getAppSettingsService } from '@machines/app/selectors';

export const useAppSettingsRef = () => {
  const service = useAppService();
  const ref = usePartial(service, getAppSettingsService);

  return ref;
};

export const useAppSettingsContext = selector => {
  const ref = useAppSettingsRef();
  const value = usePartial(ref, selector);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useAppSettingsMatch = matcher => {
  const ref = useAppSettingsRef();

  const value = useMatchers(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};
