import orderBy from 'lodash/orderBy';
import isUndefined from 'lodash/isUndefined';

import { MACHINE_NAMES } from '@constants/machines/search-history';

import { assign, spawn } from 'xstate';

import { searchHistoryStorageMachine } from '@machines/app/search-history/callbacks/search-history-storage';

export const setStorageRef = assign({
  storageRef() {
    const ref = spawn(searchHistoryStorageMachine(), MACHINE_NAMES.SEARCH_HISTORY_STORAGE);

    return ref;
  }
});

export const setItems = assign({
  items(_, { items }) {
    const orderedItems = orderBy(items, ['dateAdded'], ['desc']);

    return orderedItems;
  }
});

export const toggleFocus = assign({
  focused({ focused }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }

    return !focused;
  }
});
