export const EVENT_NAMES = {
  NOW_PLAYING: {
    DESTROY: 'NOW_PLAYING___DESTROY',
    AUTHENTICATED: 'NOW_PLAYING___AUTHENTICATED',
    SET_PLAY_CONTEXT: 'NOW_PLAYING___SET_PLAY_CONTEXT',
    RESTORE_NOW_PLAYING_STATE: 'NOW_PLAYING___RESTORE_NOW_PLAYING_STATE',
    REMOVE_ITEM: 'NOW_PLAYING___REMOVE_ITEM',
    TOGGLE_FULL_SCREEN_UP_NEXT: 'NOW_PLAYING___TOGGLE_FULL_SCREEN_UP_NEXT',
    TOGGLE_FULL_SCREEN_LYRICS: 'NOW_PLAYING___TOGGLE_FULL_SCREEN_LYRICS',
    TOGGLE_NONE_FULL_SCREEN_LYRICS: 'NOW_PLAYING___TOGGLE_NONE_FULL_SCREEN_LYRICS',
    HIDE_FULL_SCREEN_LYRICS: 'NOW_PLAYING___DISABLE_FULL_SCREEN_LYRICS',
    TOGGLE_SHOW_LIVE_STREAMING: 'NOW_PLAYING___TOGGLE_SHOW_LIVE_STREAMING',
    TOGGLE_FULLSCREEN: 'NOW_PLAYING___TOGGLE_FULLSCREEN',
    TOGGLE_FULLSCREEN_RECENTLY_PLAYED: 'NOW_PLAYING___TOGGLE_FULLSCREEN_RECENTLY_PLAYED',
    SET_VIDEO_PAGE_REFERENCE: 'NOW_PLAYING___SET_VIDEO_PAGE_REFERENCE',
    UPDATE_VOLUME: 'NOW_PLAYING___UPDATE_VOLUME',
    GET_VOLUME: 'NOW_PLAYING___GET_VOLUME',
    ADD_TO_QUEUE: 'NOW_PLAYING__ADD_TO_QUEUE',
    REMOVE_FROM_QUEUE: 'NOW_PLAYING__REMOVE_FROM_QUEUE',
    REORDER_QUEUE: 'NOW_PLAYING__REORDER_QUEUE',
    INSERT_TO_QUEUE: 'NOW_PLAYING__INSERT_TO_QUEUE',
    CLEAR_QUEUE: 'NOW_PLAYING__CLEAR_QUEUE',
    REORDER_UP_NEXT: 'NOW_PLAYING__REORDER_UP_NEXT',
    INSERT_TO_UP_NEXT: 'NOW_PLAYING__INSERT_TO_UP_NEXT',
    REMOVE_FROM_UP_NEXT: 'NOW_PLAYING__REMOVE_FROM_UP_NEXT',
    SET_RECENTLY_PLAYED_ITEMS: 'NOW_PLAYING__SET_RECENTLY_PLAYED_ITEMS',
    SET_IS_RESTORE_NOW_PLAYING_STATE_ALLOWED:
      'NOW_PLAYING__SET_IS_RESTORE_NOW_PLAYING_STATE_ALLOWED',

    RECENTLY_PLAYED: {
      CLEAR_ALL: 'NOW_PLAYING__CLEAR_ALL',
      ADD: 'NOW_PLAYING__RECENTLY_PLAYED___ADD',
      DESTROY: 'NOW_PLAYING__RECENTLY_PLAYED___DESTROY'
    },

    FAILED_USER_PLAYS: {
      ADD: 'NOW_PLAYING__FAILED_USER_PLAYS___ADD'
    },

    STORE: {
      SAVE_STATE: 'NOW_PLAYING__STORE__SAVE_STATE',
      DESTROY: 'NOW_PLAYING__STORE__DESTROY'
    },

    ACTIVE: {
      HANDLE_SET_PLAY_CONTEXT: 'NOW_PLAYING__ACTIVE___HANDLE_SET_PLAY_CONTEXT',
      TOGGLE_PLAY_PAUSE: 'NOW_PLAYING__ACTIVE__TOGGLE_PLAY_PAUSE',
      PLAY: 'NOW_PLAYING__ACTIVE__PLAY',
      PAUSE: 'NOW_PLAYING__ACTIVE__PAUSE',
      TOGGLE_REPEAT: 'NOW_PLAYING__ACTIVE___TOGGLE_REPEAT',
      NEXT: 'NOW_PLAYING__ACTIVE__NEXT',
      PREVIOUS: 'NOW_PLAYING__ACTIVE__PREVIOUS',
      SET_ADS: 'NOW_PLAYING__ACTIVE__SET_ADS',
      SET_MIDDLE_ADS: 'NOW_PLAYING__ACTIVE__SET_MIDDLE_ADS',
      START_MIDDLE_ADS: 'NOW_PLAYING__ACTIVE__START_MIDDLE_ADS',
      PRE_LOAD_NEXT: 'NOW_PLAYING__PRE_LOAD_NEXT',

      ADS_STREAMING: {
        FINISHED: 'NOW_PLAYING__ACTIVE__ADS_STREAMING__FINISHED'
      }
    }
  },

  NOW_PLAYING_ITEM: {
    START: 'NOW_PLAYING_ITEM__START',
    START_SILENT: 'NOW_PLAYING_ITEM__START_SILENT',
    PRE_LOAD: 'NOW_PLAYING_ITEM__PRE_LOAD',
    NEXT: 'NOW_PLAYING_ITEM__NEXT',
    PREVIOUS: 'NOW_PLAYING_ITEM__PREVIOUS',
    DESTROY: 'NOW_PLAYING_ITEM__DESTROY',
    FORCE_DESTROY: 'NOW_PLAYING_ITEM__FORCE_DESTROY',
    PLAY_FROM_LAST_CURRENT_TIME: 'NOW_PLAYING_ITEM__PLAY_FROM_LAST_CURRENT_TIME',
    START_MIDDLE_ADS: 'NOW_PLAYING_ITEM__START_MIDDLE_ADS',

    ACTIVE: {
      // PRE_LOADING: {},

      // INTERACTING: {},

      TOGGLE_PLAY_PAUSE: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__TOGGLE_PLAY_PAUSE',
      PLAYED_EXTERNALLY: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__PLAYED_EXTERNALLY',
      PAUSED_EXTERNALLY: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__PAUSED_EXTERNALLY',
      BUFFERING: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__BUFFERING',
      PLAYING: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__PLAYING',
      SEEK: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__SEEK',
      SEEK_CURRENT_TIME: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__SEEK_CURRENT_TIME',
      SEEK_FORWARD: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__SEEK_FORWARD',
      SEEK_BACKWARD: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__SEEK_BACKWARD',
      PLAY_FROM_START: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__PLAY_FROM_START',
      RESET_ADS_CHECKING_STATE: 'NOW_PLAYING_ITEM__ACTIVE_INTERACTING__RESET_ADS_CHECKING_STATE'
    }
  },

  AD: {
    START: 'AD__START',
    DESTROY: 'AD__DESTROY',
    FORCE_DESTROY: 'AD__FORCE_DESTROY',

    ACTIVE: {
      TOGGLE_PLAY_PAUSE: 'AD__ACTIVE__TOGGLE_PLAY_PAUSE',
      PLAYED_EXTERNALLY: 'AD__ACTIVE__PLAYED_EXTERNALLY',
      PAUSED_EXTERNALLY: 'AD__ACTIVE__PAUSED_EXTERNALLY',
      BUFFERING: 'AD__ACTIVE__BUFFERING',
      PLAYING: 'AD__ACTIVE__PLAYING',
      FINISHED: 'AD__ACTIVE__FINISHED',
      PLAY_FROM_START: 'AD__ACTIVE__PLAY_FROM_START'
    }
  },

  LIVE_STREAMING: {
    DESTROY: 'LIVE_STREAMING__DESTROY',

    ACTIVE: {
      TOGGLE_PLAY_PAUSE: 'LIVE_STREAMING__ACTIVE__TOGGLE_PLAY_PAUSE',
      PLAYED_EXTERNALLY: 'LIVE_STREAMING__ACTIVE__PLAYED_EXTERNALLY',
      PAUSED_EXTERNALLY: 'LIVE_STREAMING__ACTIVE__PAUSED_EXTERNALLY',
      BUFFERING: 'LIVE_STREAMING__ACTIVE__BUFFERING',
      PLAYING: 'LIVE_STREAMING__ACTIVE__PLAYING'
    }
  }
};

export const STATES = {
  NOW_PLAYING: {
    UN_AUTHENTICATED: 'UN_AUTHENTICATED',

    AUTHENTICATED: {
      INDEX: 'AUTHENTICATED',

      IDLE: 'IDLE',

      ACTIVE: {
        INDEX: 'ACTIVE',

        VALIDATING: 'VALIDATING',

        STREAMING: {
          INDEX: 'STREAMING',

          MEDIA: {
            INDEX: 'MEDIA',

            IDLE: 'IDLE',

            PLAYING: {
              INDEX: 'PLAYING'
            },
            PAUSED: {
              INDEX: 'PAUSED'
            }
          },

          CHECK_SKIP_LIMIT: {
            INDEX: 'CHECK_SKIP_LIMIT',
            VALIDATING: 'VALIDATING',
            IDLE: 'IDLE',
            CHECKING: {
              INDEX: 'CHECKING',
              SKIPPING_IS_ALLOWED: {
                INDEX: 'SKIPPING_IS_ALLOWED',
                VALIDATING: 'VALIDATING'
              },
              SKIPPING_IS_NOT_ALLOWED: {
                INDEX: 'SKIPPING_IS_NO_ALLOWED',
                VALIDATING: 'VALIDATING'
              }
            }
          }
        },

        LIVE_STREAMING: {
          INDEX: 'LIVE_STREAMING',

          IDLE: 'IDLE',

          PLAYING: 'PLAYING',

          PAUSED: 'PAUSED'
        },

        ADS_STREAMING: {
          INDEX: 'ADS_STREAMING',

          IDLE: 'IDLE',

          PLAYING: 'PLAYING',

          PAUSED: 'PAUSED'
        },

        MIDDLE_ADS_STREAMING: {
          INDEX: 'MIDDLE_ADS_STREAMING',

          IDLE: 'IDLE',

          PLAYING: 'PLAYING',

          PAUSED: 'PAUSED'
        }
      }
    },

    DESTROYED: 'DESTROYED'
  },

  NOW_PLAYING_ITEM: {
    IDLE: 'IDLE',
    SILENT_MODE: 'SILENT_MODE',
    LOADING_FROM_LIBRARY: {
      INDEX: 'LOADING_FROM_LIBRARY',
      VALIDATING: 'VALIDATING',
      FETCHING: 'FETCHING'
    },

    ACTIVE: {
      INDEX: 'ACTIVE',

      LOADING: 'LOADING',

      VALIDATING: {
        INDEX: 'VALIDATING'
      },

      PRE_LOADING: {
        INDEX: 'PRE_LOADING'
      },

      INTERACTING: {
        INDEX: 'INTERACTING',

        EVENTING: {
          INDEX: 'EVENTING',
          IDLE: 'IDLE',

          WITH_MIDDLE_ADS: {
            INDEX: 'WITH_MIDDLE_ADS',

            ADS: {
              INDEX: 'ADS',

              ADS_CHECK: 'ADS_CHECK',
              WAITING: 'WAITING',
              WAITING_TO_PLAY_MIDDLE_ADS: {
                INDEX: 'WAITING_TO_PLAY_MIDDLE_ADS'
              }
            },

            NONE_ADS: {
              INDEX: 'NONE_ADS',

              WAITING: 'WAITING',
              USER_PLAYS: 'USER_PLAYS',
              ADD_TO_RECENTLY_PLAYED: 'ADD_TO_RECENTLY_PLAYED'
            }
          },

          WITHOUT_MIDDLE_ADS: {
            INDEX: 'WITHOUT_MIDDLE_ADS',

            IDLE: 'IDLE',
            ADS_CHECK: 'ADS_CHECK',
            WAITING: 'WAITING',
            ADD_TO_RECENTLY_PLAYED: 'ADD_TO_RECENTLY_PLAYED',
            USER_PLAYS: 'USER_PLAYS'
          }
        },

        MEDIA: {
          INDEX: 'MEDIA',

          BUFFERING: 'BUFFERING',
          PLAYING: 'PLAYING',
          PAUSED: 'PAUSED'
        }
      }
    },

    DESTROYING: 'DESTROYING',

    DONE: 'DONE'
  },

  AD: {
    IDLE: 'IDLE',

    ACTIVE: {
      INDEX: 'ACTIVE',

      LOADING: 'LOADING',

      VALIDATING: 'VALIDATING',

      PRE_LOADING: {
        INDEX: 'PRE_LOADING'
      },

      PLAYING: {
        INDEX: 'PLAYING',

        BUFFERING: 'BUFFERING',
        PLAYING: 'PLAYING',
        PAUSED: 'PAUSED'
      }
    },

    DESTROYING: 'DESTROYING'
  },

  LIVE_STREAMING: {
    ACTIVE: {
      INDEX: 'ACTIVE',
      BUFFERING: 'BUFFERING',
      PLAYING: 'PLAYING',
      PAUSED: 'PAUSED'
    },

    DESTROYED: 'DESTROYED'
  }
};

export const MACHINE_NAMES = {
  NOW_PLAYING: 'NOW_PLAYING_MACHINE',
  NOW_PLAYING_ITEM: 'NOW_PLAYING_ITEM_MACHINE',
  AD: 'AD_MACHINE',
  LIVE_STREAMING: 'LIVE_STREAMING_MACHINE',
  RECENTLY_PLAYED: 'NOW_PLAYING_RECENTLY_PLAYED_MACHINE',
  NOW_PLAYING_STATE_STORE: 'NOW_PLAYING_STATE_STORE_MACHINE',
  FAILED_USER_PLAYS: 'FAILED_USER_PLAYS_MACHINE'
};
