/* eslint-disable camelcase */
import { TITLE_BASED_ON_TYPES } from '@constants/app';

import get from 'lodash/get';
import isObject from 'lodash/isObject';
import {
  isPodcast,
  isArtist,
  isAlbum,
  isLiked,
  isNoneLiked,
  isUnLiked,
  isNoneUnlike,
  isPlaylist,
  isCustomMetadata,
  isMp3,
  isVideo,
  isPodcastTalk
} from '@functions/is';
import { getLink } from '@functions/get-link';
import { capitalize } from '@functions/string';
import { isSafari } from '@functions/browser';

// podcast
// HLS: hls_link - lq_hls - hq_hls
// fallback: link - lq_link - hq_link

// mp3
// HLS: hls_link - lq_hls - hq_hls
// fallback: link - lq_link - hq_link

// video
// HLS: hls - low - low_web - high - high_web
// fallback: link - lq_link - hq_link

// None HLS links
export const getFallbackSRC = ({ data, quality }) => {
  const isHighQuality = quality === 'high';

  if (isHighQuality) {
    if (data.hq_link) {
      return data.hq_link;
    }
  }

  if (data.lq_link) {
    return data.lq_link;
  }

  return data.link;
};

export const getSRC = ({ data, quality }) => {
  const isHighQuality = quality === 'high';

  if (isHighQuality) {
    if (isMp3(data) || isPodcast(data)) {
      if (data.hq_hls || data.hls_link) {
        return data.hq_hls || data.hls_link;
      }
    }

    if (isVideo(data)) {
      if (isSafari() && data.hls_hevc) {
        return data.hls_hevc;
      }

      if (data.hls || data.high || data.high_web) {
        return data.hls || data.high || data.high_web;
      }
    }

    return getFallbackSRC({ data, quality });
  }

  if (isMp3(data) || isPodcast(data)) {
    if (data.lq_hls) {
      return data.lq_hls;
    }
  }

  if (isVideo(data)) {
    if (data.low || data.low_web || data.hls) {
      return data.low || data.low_web || data.hls;
    }
  }

  return getFallbackSRC({ data, quality });
};

export const getBrowseTitle = ({ type, tab }) => {
  if (!tab) {
    return TITLE_BASED_ON_TYPES[type];
  }

  if (tab === 'albums') {
    return TITLE_BASED_ON_TYPES[tab];
  }

  if (type === 'podcasts') {
    if (tab === 'dj') {
      return 'DJ Mixes';
    }

    if (tab === 'popular') {
      return `${capitalize(tab)} ${TITLE_BASED_ON_TYPES[type]}`;
    }

    return capitalize(tab);
  }

  return `${capitalize(tab)} ${TITLE_BASED_ON_TYPES[type]}`;
};

export const getSearchTitle = ({ type, query }) => {
  if (!type) {
    return `Top Result for "${query}"`;
  }

  const currentType = TITLE_BASED_ON_TYPES[type] || type;

  return `${currentType} for "${query}"`;
};

export const getReleaseYear = data => {
  const date = get(data, 'date', '');
  return date.substring(date.indexOf(',') + 1, date.length);
};

export const getAlbumName = data => {
  return get(
    data,
    'album.album',
    get(data, 'album_album', get(data, 'album_tracks[0].album.album', data.album_name))
  );
};

export const getArtistName = data => {
  if (typeof data === 'string') {
    return data;
  }

  if (isPodcast(data)) {
    return data.date;
  }

  if (isArtist(data)) {
    if (data.artist) {
      return data.artist;
    }

    // in artist data for non-real artist api doesn't return "artist" so we have to parse query
    // query for multi words artist includes + e.g shadmehr+aghili
    if (data.query || data.name) {
      if ((data.query || data.name).includes('+')) {
        return (data.query || data.name).replaceAll('+', ' ');
      }

      return data.name || data.query;
    }

    return data.artist || data.name || data.query;
  }

  if (isAlbum(data)) {
    return data.album_artist;
  }

  return data.artist;
};

const getSplittedArtistNames = artist => {
  return artist
    .split(', ')
    .map(item => item.split(' & '))
    .flat()
    .map(item => item.split('& '))
    .flat()
    .filter(Boolean);
};

export const getTrackArtists = params => {
  const data = typeof params.item?.id !== 'undefined' ? params.item : params;

  if (isAlbum(data)) {
    if (data?.album_artist?.toLowerCase() === 'various artists') {
      return [
        {
          name: data.album_artist,
          link: data.album_artist
        }
      ];
    }
  }

  if (!data.artist) {
    return [];
  }

  if (data?.artist_tags?.includes(data.artist)) {
    return [
      {
        name: data.artist,
        link: data.artist
      }
    ];
  }

  const splittedArtists = getSplittedArtistNames(data.artist);

  const { artist_tags: tags } = data;

  if (!tags) {
    return [
      {
        name: data.artist,
        link: data.artist
      }
    ];
  }

  const lowerCaseTags = tags.map(item => item.toLowerCase());

  if (splittedArtists.length > tags?.length) {
    let hasDifferentTags = false;

    const filteredArtists = splittedArtists.map(item => {
      if (lowerCaseTags.includes(item.toLowerCase())) {
        return {
          name: item,
          link: item
        };
      }

      hasDifferentTags = true;

      return {
        name: item,
        link: tags[0]
      };
    });

    const hasSameLinks = filteredArtists.every(item => {
      return item.link === filteredArtists[0].link;
    });

    if (hasSameLinks) {
      return [
        {
          name: data.artist,
          link: filteredArtists[0].link
        }
      ];
    }

    if (hasDifferentTags) {
      return filteredArtists.map(item => {
        if (lowerCaseTags.includes(item.name.toLowerCase())) {
          return item;
        }

        return {
          name: item.name
        };
      });
    }

    return filteredArtists;
  }

  const filteredArtists = splittedArtists
    .map(item => {
      if (lowerCaseTags.includes(item.toLowerCase())) {
        return {
          name: item,
          link: item
        };
      }

      return null;
    })
    .filter(Boolean);

  // when tags and artist are completely different
  if (!filteredArtists.length) {
    return [
      {
        name: data.artist,
        link: tags[0]
      }
    ];
  }

  return filteredArtists;
};

export const getCreditTags = (media = {}) => {
  const data = isObject(media.item) ? media.item : media;
  return data.credit_tags || [];
};

export const getAlbumID = data => {
  const v1 = data?.album?.id;
  const v2 = get(data, 'album_tracks[0].album.id', v1);
  const value = get(data, 'item_id', v2);

  return value;
};

export const getPodcastShowID = data => {
  return data.show_permlink || data.permlink || data.id;
};

export const getArtistID = data => {
  return data.id || data.name || data.query;
};

export const getClearLikeParams = data => {
  return { id: data.id, type: data.type, vote: '5', remove: '1' };
};

export const getLikeParams_ = data => {
  return { id: data.id, type: data.type, vote: '5' };
};

export const getLikeParams = data => {
  if (isLiked(data)) {
    return getClearLikeParams(data);
  }
  if (isNoneLiked(data)) {
    return getLikeParams_(data);
  }
  return undefined;
};

export const getUnlikeParams = data => {
  if (isUnLiked(data)) {
    return { id: data.id, type: data.type, vote: '1', remove: '1' };
  }
  if (isNoneUnlike(data)) {
    return { id: data.id, type: data.type, vote: '1' };
  }
  return undefined;
};

export const getID = data => {
  if (isObject(data)) {
    if (data._id) {
      return data._id;
    }

    return `${data.id}_${data.type}`;
  }

  return data;
};

export const getMetadataLink = ({ metadata }) => {
  if (!metadata) {
    return undefined;
  }

  if (isCustomMetadata(metadata) && metadata.link) {
    return metadata.link;
  }

  try {
    return getLink(metadata);
  } catch (_) {
    return undefined;
  }
};

export const getMetadataTitle = ({ metadata }) => {
  if (!metadata) {
    return null;
  }

  if (isCustomMetadata(metadata)) {
    return metadata.title;
  }

  if (isPlaylist(metadata)) {
    return metadata.title;
  }

  if (isAlbum(metadata)) {
    return getAlbumName(metadata);
  }

  if (isArtist(metadata)) {
    return getArtistName(metadata);
  }

  return metadata.title || metadata.name;
};

export const getPlaylistsOrderedByRecentlyPlayed = ({ items, recentlyPlayed }) => {
  const playlists = recentlyPlayed.filter(isPlaylist).filter(playlist => {
    return items.find(item => {
      return item.id === playlist.id;
    });
  });
  const previousPlaylists = items.filter(playlist => {
    return !playlists.find(item => {
      return item.id === playlist.id;
    });
  });

  return [...playlists, ...previousPlaylists];
};

export const getProcessedDescription = ({ text }) => {
  return text
    .split(/\r?\n/)
    .map(item => {
      if (item === '') {
        return 'space';
      }

      return item;
    })
    .filter(Boolean);
};

export const getLyrics = data => {
  try {
    if (!data.lyric) {
      return [];
    }

    return getProcessedDescription({ text: data.lyric });
  } catch (e) {
    return [];
  }
};

export const getPodcastTrackList = data => {
  let text = [];

  if (data.tracklist) {
    text = data.tracklist;
  }

  if (data.description && isPodcastTalk(data)) {
    text = data.description;
  }

  try {
    return text.split(/\r?\n/).filter(item => item);
  } catch (e) {
    return [];
  }
};

export const getUUID = (length = 5) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDraggingOverId = ({ data, listId }) => {
  if (!data) {
    return null;
  }

  if (data) {
    const id = getID(data);

    return [listId, id, data.selectedId].join('-');
  }

  return '';
};

export const getNowPlayingStateItem = data => {
  if (isObject(data) || Array.isArray(data)) {
    if (Array.isArray(data)) {
      // store only first 20 items for any arrays
      return data.slice(0, 20).map(getNowPlayingStateItem);
    }

    const { buffer, synced, album_tracks, items, related, ...rest } = data;

    return rest;
  }

  return data;
};

export const getRecentlyPlayedItem = data => {
  const {
    album,
    album_album,
    album_artist,
    buffer,
    playingFromQueue,
    synced,
    album_tracks,
    related,
    items,
    filteredItems,
    lyric_synced,
    lyric,
    silent,
    preload,
    ...rest
  } = data;

  return {
    ...rest,
    dateAdded: +new Date()
  };
};

export const getSearchHistoryItem = data => {
  const { buffer, playingFromQueue, synced, album_tracks, items, filteredItems, ...rest } = data;

  return {
    ...rest,
    dateAdded: +new Date()
  };
};

export const getFailedUserPlaysItem = ({ id, type, ...rest }) => {
  const data = {
    item: id || rest.item,
    type
  };

  if (isObject(rest.parent)) {
    data.playlist = rest.parent.id || rest.playlist;
  }

  return data;
};

export const getNowPlayingItem = data => {
  const { buffer, playingFromQueue, synced, album_tracks, related, ref, ...rest } = data;
  const _id = getID(data);
  const selectedId = getUUID(); // can be renamed to runtimeId

  return {
    ...rest,
    _id,
    selectedId
  };
};

export const getFailedSearchHitID = data => {
  if (data.id) {
    return getID(data);
  }

  // same as getID, but in failed search hit for artist there is no id;
  return `${data.artist}_${data.type}`;
};

export const getSearchHitParams = data => {
  if (isArtist(data)) {
    return {
      type: 'artist',
      artist: getArtistName(data)
    };
  }

  if (isAlbum(data)) {
    return {
      type: 'album',
      id: getAlbumID(data)
    };
  }

  return {
    type: data.type,
    id: data.id
  };
};

export function getKey({ data, index }) {
  return `${
    data.id ||
    data.title ||
    data.name ||
    data.query ||
    data.username ||
    data.artist_farsi ||
    data.created_at
  }_${index}`;
}

export function getFarsiTitle({ data }) {
  if (data.artist_farsi && data.song_farsi) {
    return `${data.artist_farsi} ${data.song_farsi}`;
  }

  return null;
}

export function getAlbumTrackNumber({ data }) {
  const track = data.album_tracks.find(item => {
    return item.song === data.song;
  });

  if (track) {
    return track.album.track;
  }

  return 0;
}

export function getBackgroundColors({ data }) {
  const media = data.item?.id ? data.item : data;

  return media.bg_colors;
}
