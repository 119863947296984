import { TYPES_PLURAL } from '@constants/app';

import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { isAlbum, isPodcastShow, isArtist } from '@functions/is';
import { getAlbumID, getAlbumName, getPodcastShowID, getArtistID } from '@functions/get';

const defaultSort = data => orderBy(data, ['updated_at'], ['desc']);

const sorts = {
  mp3: defaultSort,
  video: defaultSort,
  podcast: defaultSort,
  albums: data => sortBy(data, ['album_album'], ['asc'])
};

export const getID = data => {
  if (isObject(data)) {
    if (data._id) {
      return data._id;
    }

    if (isAlbum(data) || data.item_type === 'album') {
      const id = getAlbumID(data);
      return `${id}_album`;
    }

    if (isPodcastShow(data)) {
      return `${getPodcastShowID(data)}_${data.type}`;
    }

    if (isArtist(data)) {
      return `${getArtistID(data)}_${data.type}`;
    }

    return `${data.id}_${data.type}`;
  }

  return data;
};

export const normalizeServerItem = data => {
  if (data?.item?.id) {
    const { item, ...rest } = data;
    const formattedData = {
      ...item,
      ...rest
    };

    return {
      ...formattedData,
      _id: getID(formattedData)
    };
  }

  return data;
};

/**
 * This is used for creating an item on client side
 * @param {*} data
 * @returns
 */
export const normalizeClientItem = data => {
  const formattedData = omit(data, 'related', 'album_tracks', 'selfies', 'parent');

  if (isAlbum(data)) {
    const id = getAlbumID(data);

    return {
      ...formattedData,
      album_name: getAlbumName(data),
      item_type: 'album',
      item_id: id
    };
  }

  if (isArtist(data)) {
    return {
      ...formattedData,
      item_type: formattedData.type,
      id: getArtistID(data),
      item_id: getArtistID(data)
    };
  }

  return {
    ...formattedData,
    item_type: formattedData.type,
    item_id: formattedData.id
  };
};

export const getAttachmentID = item => {
  return `.${getID(item)}.rj`;
};

/**
 * Used for normalizing data before setting to storage
 */
export const getSetStorageData = {
  artist(data) {
    const newData = pick(
      data,
      'id',
      'type',
      'item_id',
      'item_type',
      'thumbnail',
      'photo',
      '_id',
      'share_link',
      'query',
      'name'
    );

    return newData;
  }
};

const withAlbumsData = grouped => {
  const data = {
    ...grouped
  };

  // check if has albums
  const hasAlbums =
    Array.isArray(grouped.album) && Array.isArray(grouped.mp3) && grouped.album.length;

  if (hasAlbums) {
    const mainAlbumKeys = groupBy(grouped.album, 'album_name');
    const { undefined: ignoredKey, ...albums } = groupBy(grouped.mp3, 'album.album');

    const addedAlbumKeys = Object.keys(albums)
      // filter only added albums
      .filter(key => !!mainAlbumKeys[key])
      .sort((a, b) => a - b);

    if (addedAlbumKeys.length) {
      data.album = addedAlbumKeys.reduce((acc, key) => {
        const tracks = orderBy(albums[key], ['album.track'], ['asc']);
        const track = tracks[0];

        acc.push({
          ...mainAlbumKeys?.[key]?.[0],
          album_tracks: tracks,
          album_album: getAlbumName(track),
          album_artist: track.album.artist
        });

        return acc;
      }, []);
    }
  }

  return data;
};

export const getData = raw => {
  const data = Object.values(raw);
  const { undefined: something, ...rest } = groupBy(data, 'item_type');

  const grouped = withAlbumsData(rest);

  const groupedKeys = Object.keys(grouped);

  const sortedData = groupedKeys.reduce((acc, key) => {
    const name = TYPES_PLURAL[key] || key;
    acc[name] = sorts[key] ? sorts[key](grouped[key]) : grouped[key];
    return acc;
  }, {});

  return sortedData;
};
