import { MESSAGES } from '@constants/app';
import { isPublicPlaylist } from '@functions/is';
import { showNotification } from '@machines/actions';

import { fetchPlaylists, fetchPlaylist } from '@data/playlists';

import { assign } from 'xstate';

import { isAddingToPlaylist } from './matchers';

export const setError = assign({
  error(_, event) {
    return event?.data?.msg || MESSAGES.ERROR;
  }
});

export const resetError = assign({
  error() {
    return null;
  }
});

export const setData = assign({
  data(_, event) {
    if (Array.isArray(event.value)) {
      return event.value;
    }
    return [event.value];
  }
});

export const resetData = assign({
  data() {
    return [];
  }
});

export const setSelectedFile = assign({
  selectedFile(_, event) {
    return event.value;
  }
});

export const resetSelectedFile = assign({
  selectedFile() {
    return null;
  }
});

export const setFormData = assign({
  formData(_, event) {
    return event.value;
  }
});

export const setFormDataDefaultValue = assign({
  formData(_, event) {
    return {
      name: event.extra
    };
  }
});

export const setFormDataForEditPlaylist = assign({
  formData(_, event) {
    return {
      name: event.value.title,
      private: !isPublicPlaylist(event.value)
    };
  },
  selectedFile(_, event) {
    return event.value.photo;
  }
});

export const resetFormData = assign({
  formData() {
    return null;
  }
});

export const setShowBack = assign({
  showBack(_, __, { state }) {
    return isAddingToPlaylist(state);
  }
});

export const resetShowBack = assign({
  showBack() {
    return false;
  }
});

export const setPlaylists = assign({
  playlists(_, event) {
    return event.value;
  }
});

export const setSelectedPlaylist = assign({
  selectedPlaylist(_, event) {
    return event.value;
  }
});

export const resetSelectedPlaylist = assign({
  selectedPlaylist() {
    return null;
  }
});

export const loadPlaylist = ({ selectedPlaylist }) => {
  fetchPlaylist({ type: selectedPlaylist.subtype, id: selectedPlaylist.id });
};

export const loadPlaylists = () => {
  fetchPlaylists();
};

export const showNotificationPlaylistCreatedAndItemsAdded = (...args) => {
  const [{ data }] = args;
  const messageName =
    data.length === 1 ? 'playlist-created-and-item-added' : 'playlist-created-and-items-added';

  showNotification({ type: 'success', messageName })(...args);
};

export const showNotificationItemsAdded = (...args) => {
  const [{ data }] = args;
  const messageName = data.length === 1 ? 'playlist-item-added' : 'playlist-items-added';

  showNotification({ type: 'success', messageName, icon: 'check' })(...args);
};
