/* eslint-disable no-await-in-loop */
import chunk from 'lodash/chunk';

export async function queuefy({ promises, onNetworkError, onError }) {
  const chunkedPromises = chunk(promises, 5);

  try {
    for (let i = 0; i < chunkedPromises.length; i++) {
      await Promise.all(chunkedPromises[i]);
    }
  } catch (e) {
    if (e.toJSON().message === 'Network Error') {
      if (onNetworkError) {
        onNetworkError(e);
      } else if (onError) {
        onError(e);
      }

      return;
    }

    // notification.add({
    //   title: e?.msg,
    //   type: 'error'
    // });
    onError(e);
  }
}
