export const scrollPositionMap = {};

export const setScrollPosition = pathname => {
  if (!process.browser) {
    return;
  }

  if (window.isTouch) {
    scrollPositionMap[pathname] = window.scrollY;
  } else {
    const main = document.getElementById('main');

    if (!main) {
      return;
    }

    scrollPositionMap[pathname] = main.scrollTop;
  }
};

export const revertScrollPosition = pathname => {
  if (!process.browser) {
    return;
  }

  window.requestAnimationFrame(() => {
    if (window.isTouch) {
      if (scrollPositionMap[pathname]) {
        window.scrollTo(0, scrollPositionMap[pathname]);
        scrollPositionMap[pathname] = undefined;
        return;
      }

      window.scrollTo(0, 0);
    } else {
      const main = document.getElementById('main');

      if (main && scrollPositionMap[pathname]) {
        main.scrollTop = scrollPositionMap[pathname];
        scrollPositionMap[pathname] = undefined;
        return;
      }

      main.scrollTop = 0;
    }
  });
};

export const clearScrollPosition = e => {
  const target = e.currentTarget || e.target || e.srcElement;
  const link = target.getAttribute('href');

  scrollPositionMap[link] = 0;
};
