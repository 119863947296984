import { TITLE_BASED_ON_ITEM_TYPE } from '@constants/app';

import { isAlbum } from '@functions/is';

export const typeIs = data => {
  if (isAlbum(data)) {
    return 'Album';
  }
  return TITLE_BASED_ON_ITEM_TYPE[data.type];
};

export const realTypeIs = data => {
  if (isAlbum(data)) {
    return 'album';
  }
  return data.type;
};
