import { DEFAULT_SERVER_URL } from '@constants/urls';

import engine from './engine';

const formatPostFix = ({ baseURL, isLocalApi }) => {
  if (isLocalApi) {
    return `${process.env.SERVE_URL}/api`;
  }

  return `${baseURL}/api2`;
};

const formatBaseURLIncludingURL = ({ baseURL, url }) => {
  return `${baseURL}${url}`;
};

const getBaseURL = async ({ baseURL, url, isLocalApi }) => {
  if (baseURL) {
    if (url) {
      return formatBaseURLIncludingURL({
        baseURL: formatPostFix({ baseURL, isLocalApi }),
        url,
        isLocalApi
      });
    }

    return baseURL;
  }

  if (process.browser) {
    try {
      const { getAppService } = await import('@state/services/app-service');
      const { getAppConfigService } = await import('@machines/app/selectors');
      const { getServer } = await import('@machines/app/config/selectors');

      const service = getAppService();

      if (service) {
        const appConfigService = getAppConfigService(service.state);
        const serverURL = getServer(appConfigService.state);

        if (serverURL) {
          return formatPostFix({ baseURL: serverURL, isLocalApi });
        }
      }
    } catch (e) {
      return formatPostFix({ baseURL: DEFAULT_SERVER_URL, isLocalApi });
    }
  }

  return formatPostFix({ baseURL: DEFAULT_SERVER_URL, isLocalApi });
};

const api = async ({ baseURL, url, isLocalApi, ...props }) => {
  const finalBaseURL = await getBaseURL({ baseURL, url, isLocalApi });

  return engine({
    baseURL: finalBaseURL,
    // don't move this
    url: baseURL && url ? undefined : url,
    ...props
  });
};

export default api;
