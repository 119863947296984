import { MACHINE_NAMES, EVENT_NAMES } from '@constants/machines/app-settings';

import { assign, send } from 'xstate';

export const setUser = assign({
  user(_, event) {
    return event.data;
  }
});

export const updateBrowserSettings = assign({
  browserSettings(_, event) {
    return event.data;
  }
});

export const updateNativeSettings = assign({
  nativeSettings(_, event) {
    return event.data;
  }
});

export const destroyBrowserSettings = send(
  { type: EVENT_NAMES.BROWSER_SETTINGS.DESTROY },
  {
    to: MACHINE_NAMES.BROWSER_SETTINGS
  }
);
