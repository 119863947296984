import { isNative, isWeb, isWindows } from '@functions/env';
import { useFullScreen } from '@hooks';

const useTopNav = () => {
  const { isFullScreen } = useFullScreen();

  if (isWeb()) {
    if (!isFullScreen) {
      return {
        show: false
      };
    }
  }

  if (isNative()) {
    if (isWindows()) {
      return {
        show: true
      };
    }

    if (isFullScreen) {
      return {
        show: false
      };
    }
  }

  return {
    show: true
  };
};

export default useTopNav;
