import { isWeb } from '@functions/env';

import * as WebBlobVersion from './web/with-worker/library-blob-storage-with-worker';
import * as NativeBlobVersion from './native/library-blob-storage-native';

export * from './web/with-worker/library-storage-with-worker';

export const getLibraryBlobStorage = isWeb()
  ? WebBlobVersion.getLibraryBlobStorage
  : NativeBlobVersion.getLibraryBlobStorage;
