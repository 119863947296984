import cx from 'classnames';

import { forwardRef, useCallback, useEffect, useRef } from 'react';

import Box from '@ui/Box';

const SimpleBarF = forwardRef(
  (
    {
      children,
      scrollableNodeProps,
      contentNodeProps,
      id,
      className,
      size,
      disableClassNameForContent,
      removeMenuOnScroll,
      showTracks,
      ...props
    },
    ref
  ) => {
    const instance = useRef(null);
    const elRef = useRef(null);
    const contentNodeRef = useRef(null);
    const scrollableNodeRef = useRef(null);

    const load = useCallback(async () => {
      const SimpleBar = (await import('simplebar/dist/simplebar-core.esm')).default;

      const options = {
        ...props
      };

      if (scrollableNodeRef.current) {
        options.scrollableNode = scrollableNodeRef.current;
      }

      if (contentNodeRef.current) {
        options.contentNode = contentNodeRef.current;
      }

      instance.current = new SimpleBar(elRef.current, options);

      // if (removeMenuOnScroll) {
      //   instance.current.getScrollElement().addEventListener('scroll', hideMenu);
      // }

      if (ref) {
        ref.current = {
          ...instance.current,
          getScrollElement() {
            return scrollableNodeRef.current;
          }
        };
      }
    }, []);

    useEffect(() => {
      if (elRef.current) {
        load();

        return () => {
          // if (removeMenuOnScroll) {
          //   instance.current.getScrollElement().removeEventListener('scroll', hideMenu);
          // }
          instance.current.unMount();
          instance.current = null;
        };
      }

      return () => {};
    }, []);

    return (
      <Box
        ref={elRef}
        data-simplebar
        className={cx(
          'relative flex-col justify-start content-start items-start flex-wrap',
          className
        )}
      >
        <Box className="simplebar-wrapper">
          <Box className="simplebar-height-auto-observer-wrapper">
            <Box className="simplebar-height-auto-observer" />
          </Box>
          <Box className="simplebar-mask">
            <Box className="simplebar-offset">
              {typeof children === 'function' ? (
                children({ scrollableNodeRef, contentNodeRef })
              ) : (
                <Box
                  {...scrollableNodeProps}
                  ref={scrollableNodeRef}
                  className={cx(
                    'simplebar-content-wrapper min-h-full flex flex-col',
                    scrollableNodeProps.className
                  )}
                  id={id}
                >
                  <Box
                    className={
                      disableClassNameForContent
                        ? ''
                        : cx('simplebar-content flex flex-col flex-1', contentNodeProps.className)
                    }
                    ref={contentNodeRef}
                  >
                    {children}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="simplebar-placeholder" />
        </Box>
        {showTracks ? (
          <>
            <Box className={cx('simplebar-track simplebar-horizontal', size)}>
              <Box className="simplebar-scrollbar will-change-transform" />
            </Box>
            <Box className={cx('simplebar-track simplebar-vertical', size)}>
              <Box className="simplebar-scrollbar will-change-transform" />
            </Box>
          </>
        ) : null}
      </Box>
    );
  }
);

SimpleBarF.displayName = 'SimpleBar';

SimpleBarF.defaultProps = {
  scrollableNodeProps: {},
  contentNodeProps: {},
  size: 'md',
  showTracks: true
};

export default SimpleBarF;
