import { STATES } from '@constants/machines/app';

export const isLoadingApp = state => {
  return state.matches(`${STATES.APP.LOADING.INDEX}`);
};

export const isLoadingConfig = state => {
  return state.matches(`${STATES.APP.LOADING.INDEX}.${STATES.APP.LOADING.CONFIG}`);
};

export const isActive = state => {
  return state.matches(STATES.APP.ACTIVATED);
};
