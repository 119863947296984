import { PLAYLISTS_URL, PLAYLIST_SUGGESTION_MP3 } from '@constants/urls';

import { useMemo } from 'react';
import { useData, mutate, getDataKey } from '@data';
import { loadPlaylists, loadPlaylist, loadPlaylistSuggestion } from '@api/playlists';
import { formatPlaylists, formatPlaylistListing } from '@api/helpers';
import { isMyPlaylist } from '@functions/is';
import { filter } from '@functions/filter';
import { forceReturn } from '@functions/array';

const playlistsOptions = {
  modifier({ data, type, subType, id, formValues }) {
    const formattedData = formatPlaylists(data, { type, subType, id });
    formattedData.items = filter(formattedData?.items, formValues);

    return {
      data: formattedData,
      raw: data
    };
  }
};

export const usePlaylists = ({
  id,
  type,
  subType,
  formValues,
  fallbackData,
  revalidateOnMount
} = {}) => {
  return useData({
    key: PLAYLISTS_URL,
    loader: loadPlaylists,
    modifierProps: {
      type,
      subType,
      id,
      formValues
    },
    fallbackData,
    revalidateOnMount,
    ...playlistsOptions
  });
};

export const fetchPlaylists = () => {
  return mutate(PLAYLISTS_URL, loadPlaylists);
};

export const usePlaylistListing = (...args) => {
  const { raw, ...rest } = usePlaylists(...args);

  return {
    ...rest,
    data: formatPlaylistListing(raw?.listing)
  };
};

const playlistOptions = {
  modifier({ data, formValues }) {
    if (!data) {
      return null;
    }

    const newData = { ...data };

    if (formValues) {
      newData.filteredItems = filter(newData?.items, formValues);
    }

    return {
      data: newData
    };
  }
};

export const usePlaylist = ({ type, id, formValues, fallbackData, revalidateOnMount }) => {
  return useData({
    key: 'playlist',
    loader: loadPlaylist,
    params: {
      type,
      id
    },
    modifierProps: {
      formValues
    },
    fallbackData,
    revalidateOnMount,
    ...playlistOptions
  });
};

export const fetchPlaylist = ({ type, id }) => {
  const key = getDataKey({ key: 'playlist', params: { type, id } });

  return mutate(key, () => {
    return loadPlaylist({ type, id });
  });
};

// TODO: IMPROVE THIS PART
export const useMyPlaylists = ({ formValues, type, createdPlaylists, forceUpdateKey } = {}) => {
  const { raw, isLoading, mutate: mutatePlaylists } = usePlaylists();

  return useMemo(() => {
    if (!raw) {
      return {
        mutate: mutatePlaylists,
        isLoading,
        mp3s: [],
        videos: [],
        playlists: []
      };
    }

    const data = {
      mutate: mutatePlaylists,
      raw,
      isLoading,
      mp3s: raw?.mp3s?.myplaylists ?? [],
      videos: raw?.videos?.myplaylists ?? [],
      playlists: []
    };

    if (formValues?.query || formValues?.sort) {
      if (type) {
        data.playlists = filter(data[type], formValues).filter(
          createdPlaylists ? isMyPlaylist : forceReturn
        );
      }

      data.mp3s = filter(data.mp3s, formValues).filter(
        createdPlaylists ? isMyPlaylist : forceReturn
      );
      data.videos = filter(data.videos, formValues).filter(
        createdPlaylists ? isMyPlaylist : forceReturn
      );

      return data;
    }

    if (createdPlaylists) {
      if (type) {
        data.playlists = data[type].filter(isMyPlaylist);
      } else {
        data.mp3s = data.mp3s.filter(isMyPlaylist);
        data.videos = data.videos.filter(isMyPlaylist);
      }
    }

    return data;
  }, [formValues?.query, formValues?.sort, type, createdPlaylists, raw, isLoading, forceUpdateKey]);
};

export const usePlaylistSuggestion = ({ id, formValues }) => {
  return useData({
    key: PLAYLIST_SUGGESTION_MP3,
    loader: loadPlaylistSuggestion,
    params: {
      id
    },
    modifier({ data }) {
      if (!data) {
        return {};
      }

      if (data?.length && formValues) {
        return {
          data: filter(data, formValues)
        };
      }

      return {};
    }
  });
};
