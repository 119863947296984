import ms from 'ms';

let __singletonInstance;
class LibraryStorageWithWorker {
  constructor() {
    this.worker = null;

    this.afterDestroy = this.afterDestroy.bind(this);
  }

  init() {
    const options = {
      type: 'module'
    };
    return new Promise(resolve => {
      this.worker = new Worker(
        new URL('@workers/storages/library-storage.worker.js', import.meta.url),
        options
      );
      resolve();
    });
  }

  sendCommand(command, ...args) {
    return new Promise((resolve, reject) => {
      const channelID = Math.random();

      const params = {
        command,
        args,
        channelID
      };

      this.worker?.postMessage(params);

      const cb = e => {
        const { done, result } = e.data;

        if (e?.data?.channelID === channelID) {
          if (done) {
            resolve(result);
          } else {
            reject(result);
          }

          this.worker?.removeEventListener('message', cb);
        }
      };

      this.worker?.addEventListener('message', cb);

      setTimeout(() => {
        reject();
        this.worker?.removeEventListener('message', cb);
      }, ms('1m'));
    });
  }

  load() {
    return this.sendCommand('load');
  }

  getLastUpdatedDate() {
    return this.sendCommand('getLastUpdatedDate');
  }

  update(params) {
    return this.sendCommand('update', params);
  }

  add(params) {
    return this.sendCommand('add', params);
  }

  addBulk(params) {
    return this.sendCommand('addBulk', params);
  }

  remove(params) {
    return this.sendCommand('remove', params);
  }

  clear() {
    return this.sendCommand('clear');
  }

  async destroy() {
    try {
      await this.sendCommand('destroy');
    } catch (e) {
      console.log(e);
    } finally {
      this.afterDestroy();
    }
  }

  afterDestroy() {
    this.worker?.terminate();

    this.worker = null;
    __singletonInstance = null;
    console.log('LibraryStorageWithWorker worker terminated...');
  }
}

export const getLibraryStorage = () => {
  if (!__singletonInstance) {
    __singletonInstance = new LibraryStorageWithWorker();
    __singletonInstance.init();
  }
  return __singletonInstance;
};
