import { EVENT_NAMES } from '@constants/machines/library';

import { downloader } from '@functions/downloader';

export const fetcher = context => {
  return (sendParent, receiver) => {
    let controller;

    const downloaderListeners = {
      onStarted: params => {
        controller = params.controller;
        sendParent(EVENT_NAMES.DOWNLOADER_ITEM.STARTED);
      },
      onProgress: params => {
        sendParent({
          type: EVENT_NAMES.DOWNLOADER_ITEM.PROGRESS,
          data: params
        });
      },
      onCompleted: params => {
        sendParent({
          type: EVENT_NAMES.DOWNLOADER_ITEM.COMPLETED,
          data: params
        });
      },
      onError: () => {
        sendParent({
          type: EVENT_NAMES.DOWNLOADER_ITEM.FAILED
        });
      }
    };

    downloader(context.link, downloaderListeners);

    receiver(event => {
      // console.log('events received MACHINE_NAMES.FETCHER', event);

      if (event.type === EVENT_NAMES.FETCHER.CANCEL) {
        // console.log('Downloading got cancelled from MACHINE_NAMES.FETCHER');

        if (controller) {
          controller.abort();
        }

        sendParent(EVENT_NAMES.DOWNLOADER_ITEM.CANCELLED);
      }
    });

    // disposal
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  };
};
