import { EVENT_NAMES } from '@constants/machines/library';

import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';
import { getAllowSync } from '@machines/auth/selectors';

export const authSubscriber = () => {
  return sendParent => {
    const service = getAppService();
    const authService = getAuthService(service.state);

    let initialValue = getAllowSync(authService.state);

    const { unsubscribe } = authService.subscribe(state => {
      const newValue = getAllowSync(state);

      if (!state.changed || initialValue === newValue) {
        return;
      }

      initialValue = newValue;

      sendParent({
        type: EVENT_NAMES.LIBRARY.ALLOW_SYNC_UPDATED,
        value: newValue
      });
    });

    // disposal
    return () => {
      unsubscribe();
    };
  };
};
