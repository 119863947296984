export const EVENT_NAMES = {
  DRAG_START: 'DRAG_DROP__DRAG_START',
  DRAG_END: 'DRAG_DROP__DRAG_END',
  DRAG_OVER: 'DRAG_DROP__DRAG_OVER',
  DRAG_LEAVE: 'DRAG_DROP__DRAG_LEAVE',
  DRAG_ENTER: 'DRAG_DROP__DRAG_ENTER',
  DROP: 'DRAG_DROP__DROP'
};

export const STATES = {
  IDLE: 'IDLE',
  DRAGGING: 'DRAGGING'
};

export const MACHINE_NAMES = {
  DRAG_DROP: 'DRAG_DROP_MACHINE'
};
