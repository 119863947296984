import cx from 'classnames';
import { forwardRef } from 'react';

import { Box } from '@ui';
import SimpleBar from '@ui/SimpleBar';

export const ScrollKit = forwardRef(
  ({ className, scrollerClassName, mode, children, ...props }, ref) => {
    return (
      <Box className={cx('flex scroll-kit overflow-hidden', className)}>
        <SimpleBar ref={ref} className={cx('max-h-full flex-1', scrollerClassName)} {...props}>
          {children}
        </SimpleBar>
      </Box>
    );
  }
);

ScrollKit.defaultProps = {
  mode: 'default'
};
