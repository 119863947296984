import { typeIs } from '@helpers/type-is';

import { Text } from '@ui';

const Type = ({ data, ...rest }) => {
  return (
    <Text className="text-th-foreground-max text-xs sm:text-sm" {...rest}>
      {typeIs(data)}
    </Text>
  );
};

Type.defaultProps = {};

export default Type;
