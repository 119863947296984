import { createMachine } from 'xstate';

import { getPermLink } from '@functions/get-link';

import {
  isMediaStreaming,
  isLiveStreaming,
  isAdsStreaming,
  isMiddleAdsStreaming,
  isBetweenMediaAdsStreaming
} from '@machines/now-playing/matchers';

import { findSelectedIndex, getCorrectSelectedFromState } from './helpers';

const fakeActor = createMachine({ id: 'fake-actor', context: {} });

export const getMetadata = state => state.context.metadata;

export const getSelected = state => {
  const { selected } = state.context;

  return selected;
};

export const getSelectedMachine = state => {
  const { selected, machines } = state.context;

  if (!selected) {
    return selected;
  }

  if (!machines[selected._id]) {
    return fakeActor;
  }

  return machines[selected._id];
};

export const getUpNext = state => {
  const { upnext } = state.context;

  const selected = getCorrectSelectedFromState(state.context);

  const index = findSelectedIndex(upnext, selected) + 1;

  if (index === upnext.length) {
    return upnext;
  }

  return upnext.slice(index);
};

export const getUpNextIncludingSelected = state => {
  const { upnext, selected } = state.context;

  const index = findSelectedIndex(upnext, selected) + 1;

  if (index === upnext.length) {
    return upnext;
  }

  return upnext.slice(index - 1);
};

export const isItemInQueue = item => state => {
  const { queue, selected } = state.context;
  const { selectedId } = item;

  if (!item.playingFromQueue) {
    return false;
  }

  if (selectedId === selected?.selectedId) {
    return false;
  }

  if (queue.length) {
    const index = findSelectedIndex(queue, item);

    if (index >= 0) {
      return true;
    }
  }

  return false;
};

export const isItemInUpNext = item => state => {
  const { upnext, selected } = state.context;
  const { selectedId } = item;

  if (selectedId === selected?.selectedId) {
    return false;
  }

  if (upnext.length) {
    const index = findSelectedIndex(upnext, item);

    if (index >= 0) {
      return true;
    }
  }

  return false;
};

export const getNextItemFromUpNext = state => {
  const { selected, lastSelectedFromUpNext, upnext } = state.context;

  const data = selected.playingFromQueue ? lastSelectedFromUpNext : selected;

  const index = findSelectedIndex(upnext, data) + 1;

  return index < upnext.length ? upnext[index] : upnext[0];
};

export const getPreviousItemFromUpNext = state => {
  const { selected, lastSelectedFromUpNext, upnext } = state.context;

  const data = selected.playingFromQueue ? lastSelectedFromUpNext : selected;

  const index = findSelectedIndex(upnext, data) - 1;

  return index < 0 ? upnext[0] : upnext[index];
};

export const getQueue = state => state.context.queue;

export const getShuffle = state => state.context.shuffle;

export const getRepeat = state => state.context.repeat;

export const getFullScreenUpNext = state => state.context.fullScreenUpNext;

export const getFullScreenLyrics = state => state.context.fullScreenLyrics;
export const getNoneFullScreenLyricsValue = state => state.context.noneFullScreenLyrics;

export const getShowLiveStream = state => state.context.showLiveStream;

export const getSelectedAd = state => {
  if (isBetweenMediaAdsStreaming(state)) {
    return state.context.adsQueue?.[0];
  }

  if (isMiddleAdsStreaming(state)) {
    return state.context.middleAdsQueue?.[0];
  }

  return null;
};

export const getFirstMiddleAd = state => {
  return state.context.middleAdsQueue?.[0];
};

export const getLiveStreamingRef = state => state.context.liveStreamingRef;

export const getFullScreenValue = state => state.context.fullscreen;

export const getVolume = state => state.context.volume;

export const getRecentlyPlayed = state => {
  return state.context.recentlyPlayed;
};

export const getFullScreenRecentlyPlayed = state => state.context.fullScreenRecentlyPlayed;

export const getIsInSelectedVideoPage = state => {
  if (isAdsStreaming(state)) {
    return false;
  }

  if (isLiveStreaming(state)) {
    return getShowLiveStream(state);
  }

  if (isMediaStreaming(state)) {
    const { videoPageReference, selected } = state.context;

    const permLink = getPermLink(selected);

    if (!videoPageReference || !permLink) {
      return false;
    }

    return permLink === videoPageReference;
  }

  return false;
};

/**
 * Shared
 */
export const getStreamSource = state => state.context.streamSource;
