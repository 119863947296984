/* eslint-disable jsx-a11y/label-has-associated-control */
import CheckIcon from '@public/vectors/check.svg';

import cx from 'classnames';

import Box from '../Box';

const SIZES = {
  xs: 'w-2 h-2',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  lg: 'w-5 h-5'
};

const StaticInputRadioCheck = ({ className, name, size, variant, checked, ...props }) => {
  return (
    <Box className={cx('relative rj-radio-check', { checked })}>
      <input type="radio" className={cx('invisible', className)} checked={checked} {...props} />
      {checked ? (
        <Box className="absolute top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2">
          <CheckIcon className={cx(SIZES[size])} />
        </Box>
      ) : null}
    </Box>
  );
};

StaticInputRadioCheck.defaultProps = {
  size: 'md'
};

export default StaticInputRadioCheck;
