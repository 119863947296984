import { APP_SETTINGS } from '@constants';
import { EVENT_NAMES } from '@constants/machines/library';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';

const { CONCURRENCY } = APP_SETTINGS.DOWNLOAD_MANAGER;

export const appSettingsSubscriber = () => {
  return sendParent => {
    const service = getAppService();
    const appSettingsService = getAppSettingsService(service.state);
    let initialValue = getBrowserSettings(CONCURRENCY)(appSettingsService.state);

    sendParent({
      type: EVENT_NAMES.DOWNLOADER.UPDATE_CONCURRENCY,
      value: initialValue
    });

    const { unsubscribe } = appSettingsService.subscribe(state => {
      const newValue = getBrowserSettings(CONCURRENCY)(appSettingsService.state);

      if (!state.changed || initialValue === newValue) {
        return;
      }

      initialValue = newValue;

      sendParent({
        type: EVENT_NAMES.DOWNLOADER.UPDATE_CONCURRENCY,
        value: newValue
      });
    });

    // disposal
    return () => {
      unsubscribe();
    };
  };
};
