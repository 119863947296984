import { isNetworkError } from '@functions/http/is';

import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';

import { notification } from '@helpers/notification';

export const showNotification = defaultValues => {
  return (_, event) => {
    notification.add({
      title: event?.data?.msg,
      type: 'error',
      isNetworkError: event ? isNetworkError(event.data) : false,
      ...defaultValues
    });
  };
};

export const removeAllNotifications = () => {
  notification.removeAll();
};
/**
 *
 * @param {*} param0
 * blocked event/action
 * @returns
 */
export const setActionsRequiringAuth = ({ type, ...event }) => {
  return () => {
    const service = getAppService();
    const authService = getAuthService(service.state);

    authService.send('SET_ACTIONS_REQUIRING_DATA', {
      actionType: type,
      ...event
    });
  };
};
