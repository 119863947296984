import cx from 'classnames';

import { Box, Heading as MainHeading, Link, IF } from '@ui';

const Heading = ({ children, actions, link, to, className, ...props }) => {
  const hasActions = Array.isArray(actions) ? actions?.length : !!actions;

  return (
    <Box className="flex flex-1 items-center">
      <Box className={cx('flex flex-1 truncate', { 'pr-5': hasActions })}>
        <IF when={typeof children === 'string'} else={children}>
          <IF
            when={link}
            else={
              <MainHeading
                as="h3"
                {...props}
                className={cx(
                  'font-semibold sm:font-medium text-xl md:text-2xl truncate',
                  className
                )}
              >
                {children}
              </MainHeading>
            }
          >
            <MainHeading
              as="h3"
              {...props}
              className={cx('font-semibold sm:font-medium text-xl md:text-2xl truncate', className)}
            >
              <Link to={to}>{children}</Link>
            </MainHeading>
          </IF>
        </IF>
      </Box>
      <IF when={hasActions}>
        <Box>
          {Array.isArray(actions) ? (
            <Box className="flex space-x-2 items-center">
              {actions.map((item, i) => {
                // eslint-disable-next-line
                return <Box key={i}>{item}</Box>;
              })}
            </Box>
          ) : (
            actions
          )}
        </Box>
      </IF>
    </Box>
  );
};

Heading.defaultProps = {
  actions: []
};

export default Heading;
