/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import cx from 'classnames';
import NextLink from 'next/link';

import { isNative } from '@functions/env';
import { application } from '@helpers/native/events';
import { clearScrollPosition } from '@components/navigation/helpers';

import { useRouter } from 'next/router';

import { useMemo } from 'react';

const openExternal = e => {
  e.preventDefault();
  const target = e.currentTarget || e.target || e.srcElement;

  application.openEternalLink(target.getAttribute('href'));
};

const NextActiveClassLink = ({ children, activeClassName, ...props }) => {
  const { asPath } = useRouter();

  const { href, as, to, className } = props;

  const active = asPath === href || asPath === as || asPath === to;
  const classNames = cx(className, {
    [activeClassName]: active
  });

  return useMemo(() => {
    return (
      <NextLink passHref href={href || to} {...props}>
        <a onClick={clearScrollPosition} draggable="false" {...props} className={classNames}>
          {children}
        </a>
      </NextLink>
    );
  }, [href, classNames, as, to]);
};

const Link = ({ href, to, external, children, useActive, activeClassName, ...props }) => {
  if (external) {
    return (
      <a
        draggable="false"
        {...props}
        href={href || to}
        target={isNative() ? undefined : '_blank'}
        onClick={isNative() ? openExternal : undefined}
      >
        {children}
      </a>
    );
  }

  if (useActive) {
    return (
      <NextActiveClassLink href={href || to} activeClassName={activeClassName} {...props}>
        {children}
      </NextActiveClassLink>
    );
  }

  return (
    <NextLink passHref href={href || to}>
      <a onClick={clearScrollPosition} draggable="false" {...props}>
        {children}
      </a>
    </NextLink>
  );
};

Link.defaultProps = {
  activeClassName: 'active'
};

export default Link;
