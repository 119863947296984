import cx from 'classnames';

import { isArtist, isStory, isUser } from '@functions/is';
import { getBackgroundColors } from '@functions/get';

import { useMemo } from 'react';

import { AspectRatio, Image, Box } from '@ui';

import { getImageAlt } from './helpers';

const Cover = ({ data, loading, className, photoKey, onLoad, ...props }) => {
  return useMemo(() => {
    const bgColors = getBackgroundColors({ data });

    return (
      <AspectRatio
        ratio={1}
        {...props}
        className={cx('cover-wrapper overflow-hidden safari-mask', className, {
          'rounded-full': isArtist(data) || isStory(data) || isUser(data),
          'cover-gradient': bgColors,
          'bg-th-foreground-min': !bgColors
        })}
        style={
          bgColors
            ? {
                '--start-gradient': bgColors[0],
                '--end-gradient': bgColors[1]
              }
            : undefined
        }
      >
        <Image
          src={data[photoKey]}
          className="touch-callout-none"
          loading={loading}
          onLoad={onLoad}
          alt={getImageAlt(data)}
          draggable="false"
        >
          <Box className={cx('absolute inset-0 z-0 touch-callout-none')} />
        </Image>
      </AspectRatio>
    );
    // eslint-disable-next-line
  }, [data.photo, photoKey, data[photoKey]]);
};

Cover.defaultProps = {
  loading: 'lazy',
  photoKey: 'photo'
};

export default Cover;
