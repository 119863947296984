import { useMemo } from 'react';

import { useAuthService } from '@hooks';
import { usePartial } from '@state/hooks';
import { getUser } from '@machines/auth/selectors';

const useUser = ({ lightCompare } = {}) => {
  const service = useAuthService();
  const value = usePartial(service, getUser);

  return useMemo(() => {
    return value;
  }, [lightCompare ? value?.email : value]);
};

export default useUser;
