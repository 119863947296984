import dynamic from 'next/dynamic';

import { isArtist, isPlaylist, isPodcast, isAlbum, isMp3, isVideo, isEvent } from '@functions/is';

import { useMemo } from 'react';

const PlaylistIcon = dynamic(() => import('@public/vectors/playlist.svg'));
const MusicNoteIcon = dynamic(() => import('@public/vectors/note.svg'));
const VideoIcon = dynamic(() => import('@public/vectors/video.svg'));
const PodcastIcon = dynamic(() => import('@public/vectors/podcast.svg'));
const AlbumIcon = dynamic(() => import('@public/vectors/album.svg'));
const ArtistIcon = dynamic(() => import('@public/vectors/artist.svg'));
const EventsIcon = dynamic(() => import('@public/vectors/events.svg'));

export default function IconType({ data, forceHideArtist, className, videoClassName }) {
  return useMemo(() => {
    if (isPlaylist(data) || data.type === 'playlists') {
      return <PlaylistIcon className={className} />;
    }
    if (isAlbum(data)) {
      return <AlbumIcon className={className} />;
    }
    if (isMp3(data) || data.type === 'mp3s') {
      return <MusicNoteIcon className={className} />;
    }
    if (isVideo(data) || data.type === 'videos') {
      return <VideoIcon className={videoClassName} />;
    }
    if (isPodcast(data) || data.type === 'podcasts') {
      return <PodcastIcon className={className} />;
    }
    if (isArtist(data) || data.type === 'artists') {
      if (forceHideArtist) {
        return null;
      }

      return <ArtistIcon className={className} />;
    }
    if (isEvent(data) || data.type === 'events') {
      return <EventsIcon className={className} />;
    }
    return null;
  }, [data?.type, data?.id]);
}

IconType.defaultProps = {
  className: 'w-4 h-4',
  videoClassName: 'w-5 h-5'
};
