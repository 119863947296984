import { STATES } from '@constants/machines/now-playing';

export const isPreLoaded = state => {
  const states = [
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.PRE_LOADING.INDEX}`,
    /**
     * WE CONSIDER AS PRELOAD BECAUSE ITS ALREADY LOADED THE BUFFERS IN THE PAST
     */
    `${STATES.NOW_PLAYING_ITEM.DESTROYING}`
  ];

  return states.some(state.matches);
};

/**
 * isActive: Means it has source stream and stream machine
 */
export const isActive = state => {
  return state.matches(
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}`
  );
};

export const isBuffering = state => {
  return state.matches(
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.BUFFERING}`
  );
};

export const isPlaying = state => {
  return state.matches(
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
  );
};

export const isPaused = state => {
  return state.matches(
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED}`
  );
};

export const isSilentMode = state => {
  return state.matches(STATES.NOW_PLAYING_ITEM.SILENT_MODE);
};

export const isGoingToPlayMiddleAds = state => {
  return state.matches(
    `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS.WAITING_TO_PLAY_MIDDLE_ADS.INDEX}`
  );
};

export const isGoingToPlayMiddleAdsIn =
  (second = '1') =>
  state => {
    return state.matches(
      `${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS.WAITING_TO_PLAY_MIDDLE_ADS.INDEX}.${second}`
    );
  };
