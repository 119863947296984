/* eslint-disable jsx-a11y/label-has-associated-control */
import { FADE } from '@theme/framer-variants';

import cx from 'classnames';

import { AnimatePresence, motion } from 'framer-motion';

import Box from '../Box';

const VARIANT_CLASSNAMES = {
  solid: () => {
    return [
      'bg-transparent bg-th-foreground-min',
      'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
    ];
  },
  outline: ({ checked }) => {
    return cx(
      'bg-transparent border-p-1 transition-colors duration-100 border-th-foreground-mid ',
      {
        'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid':
          !checked
      }
    );
  },
  ghost: () => {
    return [
      'bg-transparent',
      'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
    ];
  },
  success: () => {
    return ['bg-green-800', 'er:bg-green-700 focus:bg-green-700 active:bg-green-600'];
  },
  rj: () => {
    return [
      'bg-roof-terracotta-500 ring-opacity-50 text-white-900',
      'hover:bg-roof-terracotta-400 active:bg-roof-terracotta-500'
    ];
  }
};

const SIZES = {
  xs: 'w-2 h-2',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  lg: 'w-5 h-5'
};

const LABEL_CLASSNAMES = 'rounded-full cursor-pointer absolute top-0 left-0';

const StaticInputRadio = ({ className, name, size, variant, checked, icon, ...props }) => {
  return (
    <Box className={cx('relative rj-radio', SIZES[size], { checked })}>
      <label
        className={cx(LABEL_CLASSNAMES, VARIANT_CLASSNAMES[variant]({ checked }), SIZES[size])}
      >
        <input type="radio" className={cx('invisible', className)} checked={checked} {...props} />
      </label>
      <AnimatePresence mode="wait" initial={false}>
        <motion.div key={checked ? 1 : 2} {...FADE}>
          {checked ? (
            <Box className="absolute top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 rounded-full w-1/2 h-1/2 bg-roof-terracotta-500" />
          ) : null}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

StaticInputRadio.defaultProps = {
  size: 'md',
  variant: 'outline'
};

export default StaticInputRadio;
