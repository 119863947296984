/* eslint-disable prefer-promise-reject-errors */
import isObject from 'lodash/isObject';
// import random from 'lodash/random';

import {
  PODCAST_SHOW_URL,
  PODCAST_FOLLOW_URL,
  PODCAST_UN_FOLLOW_URL,
  FOLLOWED_PODCASTS,
  MP3_VOTE_URL,
  VIDEO_VOTE_URL,
  PODCAST_VOTE_URL,
  USER_PLAYS,
  MP3_LIKED,
  AD
} from '@constants/urls';
// import ADS_DATA from './ads.json';

import api from './api';

export const loadMedia = ({ baseURL, headers, type, id, album, page }) => {
  return api({
    baseURL,
    headers,
    url: `/${type}`,
    method: 'GET',
    params: {
      id,
      page,
      album
    }
  }).then(data => {
    if ((isObject(data) && Object.keys(data).length === 0) || !data) {
      return Promise.reject({ error: 'Cannot find media' });
    }

    return Promise.resolve(data);
  });
};

export const loadShowPodcast = ({ baseURL, headers, id }) => {
  return api({
    baseURL,
    headers,
    url: PODCAST_SHOW_URL,
    method: 'GET',
    params: {
      id
    }
  });
};

export const loadFollowedPodcasts = ({ baseURL, headers } = {}) => {
  return api({
    baseURL,
    headers,
    url: FOLLOWED_PODCASTS,
    method: 'GET'
  });
};

export const loadLikedSongs = ({ baseURL, headers } = {}) => {
  return api({
    baseURL,
    headers,
    url: MP3_LIKED,
    method: 'GET'
  });
};

export const followPodcast = show => {
  return api({
    url: PODCAST_FOLLOW_URL,
    method: 'GET',
    params: { show }
  });
};

export const unFollowPodcast = show => {
  return api({
    url: PODCAST_UN_FOLLOW_URL,
    method: 'GET',
    params: { show }
  });
};

export const vote = params => {
  const { type } = params;
  let url;
  if (type === 'mp3') {
    url = MP3_VOTE_URL;
  } else if (type === 'podcast') {
    url = PODCAST_VOTE_URL;
  } else {
    url = VIDEO_VOTE_URL;
  }
  return api({
    url,
    method: 'GET',
    params
  });
};

export const userPlays = data => {
  const items = Array.isArray(data) ? data : [data];

  return api({
    url: USER_PLAYS,
    method: 'POST',
    data: {
      items
    }
  });
};

export const adCheckBreak = ({ podcast }) => {
  return api({
    url: AD.CHECK_BREAK,
    method: 'GET',
    params: {
      podcast
    }
  }).then(response => {
    if (Array.isArray(response) && response.length) {
      return Promise.resolve(response);
    }

    // if (random(0, 1000) <= 950) {
    //   return Promise.resolve(ADS_DATA);
    // }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ error: 'No ads to set in queue' });
  });
};

export const adPlayed = ({ id }) => {
  return api({
    url: AD.PLAYED,
    method: 'GET',
    params: {
      id
    }
  });
};
