import dynamic from 'next/dynamic';
import cx from 'classnames';

import { useTopNav } from '@hooks';

import { IF, Box, IconButton } from '@ui';
import { Heading } from '@components/track';

const CloseIcon = dynamic(() => import('@public/vectors/close.svg'));

const FullScreen = ({ showTopNav, ...props }) => {
  const { show: isTopNavVisible } = useTopNav();

  const { children, onClose, title, titleClassNames, bodyClassNames } = props;

  return (
    <Box
      className="fixed left-0 bottom-0 right-0 flex flex-col z-30 bg-th-secondary-background"
      style={{ top: isTopNavVisible ? 38 : 0 }}
    >
      <IF when={title}>
        <Box>
          <Box className="flex items-center justify-center py-2 px-4 shadow-header bg-th-primary-background shadow-xl">
            <Heading
              className={cx('flex-1 text-center text-th-primary-foreground', titleClassNames)}
            >
              {title}
            </Heading>
            <IconButton onClick={onClose} variant="ghost">
              <CloseIcon width="1.1rem" height="1.1rem" />
            </IconButton>
          </Box>
        </Box>
      </IF>
      <Box className={cx('flex flex-1', bodyClassNames)}>{children}</Box>
    </Box>
  );
};

FullScreen.defaultProps = {
  title: '',
  titleClassNames: '',
  bodyClassNames: '',
  showTopNav: false
};

export default FullScreen;
