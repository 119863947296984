import { createMachine, assign } from 'xstate';

export const gridMachine = createMachine(
  {
    id: 'grid-machine',
    predictableActionArguments: true,
    context: {
      main: 0,
      sidebar: 0
    },
    on: {
      SET_MAIN_WIDTH: {
        actions: assign({
          main: (_, e) => {
            return e.value;
          }
        })
      },
      SET_SIDEBAR_WIDTH: {
        actions: assign({
          sidebar: (_, e) => {
            return e.value;
          }
        })
      }
    },
    type: 'parallel',
    states: {
      main: {
        initial: 'validate',
        states: {
          validate: {
            always: {
              target: ['#grid-machine.main.idle', '#grid-machine.sidebar.visible'],
              cond: ({ main }) => {
                if (main !== 0) {
                  return true;
                }

                return false;
              }
            }
          },
          idle: {
            always: {
              target: ['#grid-machine.main.responsive', '#grid-machine.sidebar.hidden'],
              cond: ({ main }) => {
                return main <= 650;
              }
            }
          },
          responsive: {
            always: {
              target: ['#grid-machine.main.idle', '#grid-machine.sidebar.visible'],
              cond: ({ sidebar, main }) => {
                const mainWidthWhenSidebarGetOpen = main - sidebar;

                // don't change this it will get in infinite loop - number should be same
                return mainWidthWhenSidebarGetOpen > 650;
              }
            }
          }
        }
      },
      sidebar: {
        initial: 'visible',
        on: {
          HIDE_SIDEBAR_VISIBILITY_ON_RESPONSIVE: {
            target: '#grid-machine.sidebar.hidden'
          }
        },
        states: {
          visible: {
            on: {
              TOGGLE_SIDEBAR_VISIBILITY_ON_RESPONSIVE: {
                target: '#grid-machine.sidebar.hidden'
              }
            }
          },
          hidden: {
            on: {
              TOGGLE_SIDEBAR_VISIBILITY_ON_RESPONSIVE: {
                target: '#grid-machine.sidebar.visible'
              }
            }
          }
        }
      }
    }
  },
  {
    actions: {},
    guards: {}
  }
);
