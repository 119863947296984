import cx from 'classnames';

import { forwardRef, useEffect, useState, useCallback } from 'react';
import { useUpdateEffect } from '@hooks';

import RCSlider, { Handle as RCHandle } from 'rc-slider';

const Handle = forwardRef(({ touching, dragging, ...props }, ref) => {
  const [key, setKey] = useState(() => Math.random());

  useUpdateEffect(() => {
    if (touching) {
      return;
    }

    setKey(Math.random());
  }, [touching]);

  return <RCHandle key={key} ref={ref} {...props} />;
});

const StaticSlider = ({
  defaultValue,
  isReadOnly,
  size,
  className,
  afterChange,
  min,
  max,
  step,
  onBeforeChange: onBeforeChangeProp,
  onAfterChange: onAfterChangeProp,
  ...rest
}) => {
  const [value, setValue] = useState(() => defaultValue);
  const [touching, setTouching] = useState(false);

  const onBeforeChange = useCallback(() => {
    onBeforeChangeProp();
    setTouching(true);
  }, [onBeforeChangeProp]);

  const onAfterChange = useCallback(
    percentage => {
      setTouching(false);
      onAfterChangeProp(percentage);
    },
    [onAfterChangeProp]
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (afterChange) {
      afterChange(value);
    }
  }, [value]);

  return (
    <RCSlider
      value={value}
      onChange={setValue}
      disabled={isReadOnly}
      isReadOnly={isReadOnly}
      min={min}
      max={max}
      step={step}
      className={cx('slider', className, [size], {
        'is-read-only': isReadOnly,
        touching
      })}
      handle={info => {
        if (isReadOnly) {
          return null;
        }
        return <Handle {...info} touching={touching} />;
      }}
      {...rest}
      onBeforeChange={onBeforeChange}
      onAfterChange={onAfterChange}
    />
  );
};

StaticSlider.defaultProps = {
  size: 'md',
  onBeforeChange() {},
  onAfterChange() {}
};

export default StaticSlider;
