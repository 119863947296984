import {
  PLAYLISTS_URL,
  MP3_PLAYLIST_WITH_ITEMS_URL,
  VIDEO_PLAYLIST_WITH_ITEMS_URL,
  FOLLOW_PLAYLIST_MP3_URL,
  UN_FOLLOW_PLAYLIST_MP3_URL,
  CREATE_NEW_PLAYLIST_MP3_URL,
  CREATE_NEW_PLAYLIST_VIDEO_URL,
  REMOVE_PLAYLIST_MP3_URL,
  REMOVE_PLAYLIST_VIDEO_URL,
  REMOVE_ITEM_PLAYLIST_MP3_URL,
  REMOVE_ITEM_PLAYLIST_VIDEO_URL,
  RENAME_PLAYLIST_MP3_URL,
  RENAME_PLAYLIST_VIDEO_URL,
  REORDER_PLAYLIST_ITEMS_MP3_URL,
  REORDER_PLAYLIST_ITEMS_VIDEO_URL,
  MAKE_PLAYLIST_PRIVATE_MP3_URL,
  MAKE_PLAYLIST_PUBLIC_MP3_URL,
  PLAYLIST_SUGGESTION_MP3,
  MP3_PLAYLIST_COVER_URL,
  HIDE_PLAYLIST_SONG_URL,
  UNHIDE_PLAYLIST_SONG_URL
} from '@constants/urls';

import api from './api';
import { formatPlaylistsAfterFetch } from './helpers';

export const loadPlaylists = ({ baseURL, headers } = {}) => {
  return api({
    baseURL,
    headers,
    url: PLAYLISTS_URL,
    method: 'GET'
  }).then(formatPlaylistsAfterFetch);
};

export const loadPlaylist = ({ baseURL, headers, type, id }) => {
  const url = type === 'mp3' ? MP3_PLAYLIST_WITH_ITEMS_URL : VIDEO_PLAYLIST_WITH_ITEMS_URL;
  return api({
    baseURL,
    headers,
    url,
    params: {
      id
    }
  }).then(data => {
    const { items, ...rest } = data;
    return {
      ...rest,
      items: items.map(item => ({
        ...item,
        parent: rest
      }))
    };
  });
};

// just mp3s
export const followPlaylist = params => {
  return api({
    url: FOLLOW_PLAYLIST_MP3_URL,
    method: 'GET',
    params
  });
};

export const unFollowPlaylist = params => {
  return api({
    url: UN_FOLLOW_PLAYLIST_MP3_URL,
    method: 'GET',
    params
  });
};

export const createPlaylist = params => {
  const url = params.type === 'mp3' ? CREATE_NEW_PLAYLIST_MP3_URL : CREATE_NEW_PLAYLIST_VIDEO_URL;

  return api({
    url,
    method: 'GET',
    params
  });
};

export const removePlaylist = ({ subtype: type, id }) => {
  const url = type === 'mp3' ? REMOVE_PLAYLIST_MP3_URL : REMOVE_PLAYLIST_VIDEO_URL;

  return api({
    url,
    method: 'GET',
    params: {
      type,
      id
    }
  });
};

export const removeItemFromPlaylist = params => {
  const url = params.type === 'mp3' ? REMOVE_ITEM_PLAYLIST_MP3_URL : REMOVE_ITEM_PLAYLIST_VIDEO_URL;

  return api({
    url,
    method: 'GET',
    params
  });
};

export const renamePlaylist = params => {
  const url = params.type === 'mp3' ? RENAME_PLAYLIST_MP3_URL : RENAME_PLAYLIST_VIDEO_URL;

  return api({
    url,
    method: 'GET',
    params
  });
};

export const reorderPlaylistItems = ({ id, type, items }) => {
  return api({
    url: type === 'video' ? REORDER_PLAYLIST_ITEMS_VIDEO_URL : REORDER_PLAYLIST_ITEMS_MP3_URL,
    method: 'POST',
    data: {
      id,
      type,
      items: items.map(item => item.item).join(',')
    }
  });
};

// just mp3s
const makePlaylistPrivate = params => {
  return api({
    url: MAKE_PLAYLIST_PRIVATE_MP3_URL,
    method: 'GET',
    params
  });
};

// just mp3s
const makePlaylistPublic = params => {
  return api({
    url: MAKE_PLAYLIST_PUBLIC_MP3_URL,
    method: 'GET',
    params
  });
};

export const playlistPrivatePublic = ({ type, id, private: shouldBePrivate }) => {
  if (shouldBePrivate) {
    return makePlaylistPrivate({ type, id });
  }
  return makePlaylistPublic({ type, id });
};

export const playlistCover = ({ photo, id }) => {
  let headers = {};
  let data = {
    id,
    photo
  };
  if (photo) {
    data = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    headers = {
      'Content-Type': 'multipart/form-data'
    };
  }

  return api({
    url: MP3_PLAYLIST_COVER_URL,
    method: 'POST',
    data,
    headers
  });
};

export const loadPlaylistSuggestion = ({ id }) => {
  return api({
    url: PLAYLIST_SUGGESTION_MP3,
    method: 'GET',
    params: { id }
  });
};

export const hidePlaylistSong = ({ id, item }) => {
  return api({
    url: HIDE_PLAYLIST_SONG_URL,
    method: 'POST',
    params: { id, item }
  });
};

export const unhidePlaylistSong = ({ id, item }) => {
  return api({
    url: UNHIDE_PLAYLIST_SONG_URL,
    method: 'POST',
    params: { id, item }
  });
};
