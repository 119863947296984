import { EVENT_NAMES as AUTH_EVENT_NAMES } from '@constants/machines/auth';

import { actions, send } from 'xstate';

import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';
import { getNotifyingUserToSubscribeValue } from '@machines/auth/selectors';

const { pure } = actions;

export const toggleNotifyUserToSubscribe = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  if (getNotifyingUserToSubscribeValue(authService.state)) {
    return [];
  }

  return [send({ type: AUTH_EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE }, { to: authService })];
});
