import { DEFAULT_SERVER_URL } from '@constants/urls';

import engine from './engine';

const getApiURL = ({ baseURL, isLocalApi }) => {
  if (isLocalApi) {
    return `${DEFAULT_SERVER_URL}/api`;
  }

  if (!process.browser) {
    // direct request to rj server
    return `${process.env.DEFAULT_API_ENDPOINT}/api2`;
  }

  // point to proxy
  return `${baseURL || DEFAULT_SERVER_URL}/api/p`;
};

const formatBaseURLIncludingURL = ({ baseURL, url }) => {
  return `${baseURL}${url}`;
};

const getBaseURL = ({ baseURL, url, isLocalApi }) => {
  if (baseURL && url) {
    return formatBaseURLIncludingURL({
      baseURL: getApiURL({ baseURL, isLocalApi }),
      url
    });
  }

  return getApiURL({ baseURL, isLocalApi });
};

const api = ({ baseURL, url, headers: propHeaders = {}, isLocalApi, ...props }) => {
  const finalBaseURL = getBaseURL({ baseURL, url, isLocalApi });

  const headers = { ...propHeaders };

  if (process.env.API_KEY_SECRET) {
    headers[process.env.API_KEY_NAME] = process.env.API_KEY_SECRET;
  }

  return engine({
    baseURL: finalBaseURL,
    // don't move this
    url: baseURL && url ? undefined : url,
    headers,
    ...props
  });
};

export default api;
