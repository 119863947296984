import cx from 'classnames';

import { remTopx } from '@helpers/dom/unit';

const textStyle = {
  fill: 'var(--primary-foreground)',
  fontSize: '24px',
  dominantBaseline: 'central',
  textAnchor: 'middle'
};

const CircularProgress = ({
  className,
  strokeWidth,
  value,
  showProgress,
  behindTrackFillClassName,
  trackFillClassName,
  textClassName
}) => {
  const pxStroke = remTopx(strokeWidth);

  const radius = 50 - pxStroke / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    strokeLinecap: 'round',
    strokeDasharray: `${diameter} ${diameter}`,
    strokeDashoffset: `${((100 - value) / 100) * diameter}`
  };

  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={value}
    >
      <path
        className={behindTrackFillClassName}
        d={pathDescription}
        strokeWidth={`${strokeWidth}rem`}
        fillOpacity={0}
      />

      <path
        className={cx('transition-all duration-300', trackFillClassName)}
        d={pathDescription}
        strokeWidth={`${strokeWidth}rem`}
        fillOpacity={0}
        style={progressStyle}
      />

      {showProgress ? (
        <text x={50} y={50} style={textStyle} className={cx('text-sm', textClassName)}>
          {`${value}%`}
        </text>
      ) : null}
    </svg>
  );
};

CircularProgress.defaultProps = {
  className: 'w-4 h-4',
  value: 0,
  strokeWidth: 0.3,
  fill: 'transparent',
  behindTrackFillClassName: 'stroke-th-foreground-low',
  trackFillClassName: 'stroke-th-primary-foreground',
  showProgress: false
};

export default CircularProgress;
