import { APP_SETTINGS } from '@constants';
import { getID } from '@functions/get';

import { getAppService } from '@state/services/app-service';
import { getAppSettingsService, getAuthService } from '@machines/app/selectors';
import { getBrowserSettings } from '@machines/app/app-settings/selectors';
import { getUserHasSubscription, getAllowLyrics } from '@machines/auth/selectors';

import {
  getNextItemFromUpNext,
  isItemInQueue,
  isItemInUpNext
} from '@machines/now-playing/selectors';
import { isPreLoaded } from '@machines/now-playing/item/matchers';

const { NOW_PLAYING } = APP_SETTINGS;

export const isNowPlayingFooter = () => {
  const appService = getAppService();
  const appSettingsService = getAppSettingsService(appService.state);
  const style = getBrowserSettings(NOW_PLAYING.STYLE)(appSettingsService.state);

  return style === 'footer';
};

export const hasMachine = ({ machines }, event) => {
  const { data } = event;
  const id = getID(data);

  return !!machines[id];
};

export const canPreLoadUpNext = ({ machines, ...context }) => {
  const item = getNextItemFromUpNext({ context });
  const machine = machines[item._id];

  if (!machine) {
    return true;
  }

  if (isPreLoaded(machine.state)) {
    return false;
  }

  return true;
};

export const canPreLoadQueue = ({ machines, queue }) => {
  if (!queue.length) {
    return false;
  }

  const machine = machines[queue[0]._id];

  if (!machine) {
    return true;
  }

  if (isPreLoaded(machine.state)) {
    return false;
  }

  return true;
};

export const isItemInQueueOrUpNext = (context, { item }) => {
  return isItemInQueue(item)({ context }) || isItemInUpNext(item)({ context });
};

export const hasUserSubscription = () => {
  const appService = getAppService();
  const authService = getAuthService(appService.state);

  return getUserHasSubscription(authService.state);
};

export const isUserAllowToViewLyrics = () => {
  const appService = getAppService();
  const authService = getAuthService(appService.state);

  return getAllowLyrics(authService.state);
};

export const hasPassedLastSkipTimestamp = ({ skipLimit: { lastSkipTimestamp } }) => {
  const currentTimeStamp = +new Date();
  const timeDifference = currentTimeStamp - lastSkipTimestamp;
  const millisecondsVariable = window.appConfig.skips_time_limit * 1000;

  return timeDifference >= millisecondsVariable;
};

export const hasPassedLastSkipTimestampAfterLock = ({
  skipLimit: { lastTimestampAfterLocked }
}) => {
  const currentTimeStamp = +new Date();
  const timeDifference = currentTimeStamp - lastTimestampAfterLocked;
  const millisecondsVariable = window.appConfig.skips_time_limit * 1000;

  return timeDifference >= millisecondsVariable;
};
