import { EVENT_NAMES, STATES, MACHINE_NAMES } from '@constants/machines/library';

import { createMachine, spawn, assign, forwardTo } from 'xstate';
import { getLibraryStorage, getLibraryBlobStorage } from '@helpers/storages/library';
import { clearAllLibrary } from '@api/library';
import { notification } from '@helpers/notification';
import { isWeb } from '@functions/env';

import { downloaderMachine } from '@machines/library/machines/downloader/downloader-machine';
import { validateMachine } from '@machines/library/machines/validate/validate-machine';

import { loadLibraryData } from '@machines/library/invoke-helpers';
import { authSubscriber } from '@machines/library/callbacks/auth-subscriber';

import { setActionsRequiringAuth } from '@machines/actions';
import * as actions from '@machines/library/actions';
import * as guards from '@machines/library/guards';

export const libraryMachine = createMachine(
  {
    id: MACHINE_NAMES.LIBRARY,
    context: {
      // actors
      downloaderRef: null,
      validateRef: null,

      // state
      // a queue to spawn machines one by one - this cannot be separated machine
      spawnItemMachineQueue: [],

      updatedAt: null,
      raw: {},
      // you can show these list to each pages
      data: {},
      savingItems: {}
    },
    initial: STATES.LIBRARY.UN_AUTHENTICATED,
    on: {},
    states: {
      [STATES.LIBRARY.UN_AUTHENTICATED]: {
        on: {
          [EVENT_NAMES.LIBRARY.AUTHENTICATED]: {
            target: STATES.LIBRARY.AUTHENTICATED.INDEX
          },
          [EVENT_NAMES.LIBRARY.SAVE]: {
            actions: [
              (_, event) => {
                setActionsRequiringAuth(event)();
              }
            ]
          }
        }
      },

      [STATES.LIBRARY.AUTHENTICATED.INDEX]: {
        id: STATES.LIBRARY.AUTHENTICATED.INDEX,
        entry: [
          assign({
            authSubscriber() {
              return spawn(authSubscriber());
            }
          }),
          assign({
            downloaderRef() {
              const name = MACHINE_NAMES.DOWNLOADER;

              return spawn(downloaderMachine, name);
              // .onTransition((...args) => {
              //   console.log('DOWNLOADER___MACHINE', ...args);
              // });
            }
          })
        ],
        initial: STATES.LIBRARY.AUTHENTICATED.LOADING.INDEX,
        on: {
          [EVENT_NAMES.LIBRARY.DESTROY]: {
            target: `${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.DESTROYING.INDEX}`
          },
          [EVENT_NAMES.VALIDATE.PUSH_TO_QUEUE]: {
            actions: ['pushToValidateQueue']
          },
          [EVENT_NAMES.VALIDATE.REMOVE_FROM_QUEUE]: {
            actions: [forwardTo(MACHINE_NAMES.VALIDATE)]
          },
          [EVENT_NAMES.LIBRARY.ALLOW_SYNC_UPDATED]: {
            actions: ['sendAllowSyncUpdatedToItemMachines']
          }
        },
        states: {
          [STATES.LIBRARY.AUTHENTICATED.LOADING.INDEX]: {
            entry: [
              assign({
                validateRef() {
                  const name = MACHINE_NAMES.VALIDATE;

                  return spawn(validateMachine, name);
                  // .onTransition((...args) => {
                  //   console.log(MACHINE_NAMES.VALIDATE, ...args);
                  // });
                }
              })
            ],
            on: {
              [EVENT_NAMES.LIBRARY.SAVE]: [
                {
                  actions: ['redirectToDownloadPage'],
                  cond: (_, event) => {
                    if (event.sync) {
                      return isWeb();
                    }

                    return false;
                  }
                }
              ]
            },
            id: STATES.LIBRARY.AUTHENTICATED.LOADING.INDEX,
            initial: STATES.LIBRARY.AUTHENTICATED.LOADING.LAST_UPDATED_DATE,
            states: {
              [STATES.LIBRARY.AUTHENTICATED.LOADING.LAST_UPDATED_DATE]: {
                invoke: {
                  id: STATES.LIBRARY.AUTHENTICATED.LOADING.LAST_UPDATED_DATE,
                  src: () => getLibraryStorage().getLastUpdatedDate(),
                  onDone: {
                    target: STATES.LIBRARY.AUTHENTICATED.LOADING.API_DATA,
                    actions: ['setUpdatedAt']
                  },
                  onError: {
                    target: STATES.LIBRARY.AUTHENTICATED.LOADING.API_DATA
                  }
                }
              },

              [STATES.LIBRARY.AUTHENTICATED.LOADING.API_DATA]: {
                invoke: {
                  id: STATES.LIBRARY.AUTHENTICATED.LOADING.API_DATA,
                  src: loadLibraryData,
                  onDone: {
                    target: STATES.LIBRARY.AUTHENTICATED.LOADING.UPDATE_STORAGE
                  },
                  onError: {
                    target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`
                  }
                }
              },

              [STATES.LIBRARY.AUTHENTICATED.LOADING.UPDATE_STORAGE]: {
                invoke: {
                  id: STATES.LIBRARY.AUTHENTICATED.LOADING.UPDATE_STORAGE,
                  src: (_, event) => {
                    return getLibraryStorage().update(event.data);
                  },
                  onDone: {
                    target: STATES.LIBRARY.AUTHENTICATED.LOADING.STORAGE_DATA
                  },
                  onError: {
                    target: STATES.LIBRARY.AUTHENTICATED.LOADING.STORAGE_DATA
                  }
                }
              },

              [STATES.LIBRARY.AUTHENTICATED.LOADING.STORAGE_DATA]: {
                invoke: {
                  id: STATES.LIBRARY.AUTHENTICATED.LOADING.STORAGE_DATA,
                  src: () => getLibraryStorage().load(),
                  onDone: [
                    {
                      target: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.INDEX,
                      actions: ['setSpawnItemMachineQueue'],
                      cond: (_, event) => {
                        return event.data?.items?.length;
                      }
                    },
                    {
                      target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`
                    }
                  ],
                  onError: {
                    target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`
                  }
                }
              },

              [STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.INDEX]: {
                id: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.INDEX,
                initial: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.VALIDATING,
                states: {
                  [STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.VALIDATING]: {
                    always: [
                      {
                        target: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.SPAWNING,
                        cond: ({ spawnItemMachineQueue }) => {
                          return spawnItemMachineQueue.length;
                        }
                      },
                      {
                        target: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.DONE
                      }
                    ]
                  },
                  [STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.SPAWNING]: {
                    entry: [
                      'spawnItemMachineAndSetToRaw',
                      'removeFirstItemFromSpawnItemMachineQueue'
                    ],
                    after: {
                      1: {
                        target: STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.VALIDATING
                      }
                    }
                  },
                  [STATES.LIBRARY.AUTHENTICATED.LOADING.SPAWNING_ACTORS.DONE]: {
                    always: {
                      target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`,
                      actions: ['setData']
                    }
                  }
                }
              }
            }
          },

          [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX]: {
            id: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX,
            initial: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.IDLE,
            states: {
              [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.IDLE]: {
                on: {
                  [EVENT_NAMES.LIBRARY.DELETE_ALL]: {
                    target: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.INDEX,
                    actions: ['resetState']
                  }
                }
              },
              [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.INDEX]: {
                initial: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.API,
                states: {
                  [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.API]: {
                    invoke: {
                      id: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING,
                      src: clearAllLibrary,
                      onDone: {
                        target: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.BLOB_STORAGE
                      },
                      onError: {
                        target: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.BLOB_STORAGE
                      }
                    }
                  },
                  [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.BLOB_STORAGE]: {
                    invoke: {
                      id: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.BLOB_STORAGE,
                      src() {
                        return getLibraryBlobStorage().clear();
                      },
                      onDone: {
                        target: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.DATA_STORAGE
                      },
                      onError: {
                        target: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.DATA_STORAGE
                      }
                    }
                  },
                  [STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.DATA_STORAGE]: {
                    invoke: {
                      id: STATES.LIBRARY.AUTHENTICATED.ACTIVATED.DELETING.DATA_STORAGE,
                      src() {
                        return getLibraryStorage().clear();
                      },
                      onDone: {
                        target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`,
                        actions: [
                          () => {
                            notification.add({
                              type: 'success'
                            });
                          }
                        ]
                      },
                      onError: {
                        target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.AUTHENTICATED.INDEX}.${STATES.LIBRARY.AUTHENTICATED.ACTIVATED.INDEX}`,
                        actions: [
                          () => {
                            notification.add({
                              type: 'success'
                            });
                          }
                        ]
                      }
                    }
                  }
                }
              }
            },
            on: {
              [EVENT_NAMES.LIBRARY.SAVE]: [
                {
                  actions: ['redirectToDownloadPage'],
                  cond: (_, event) => {
                    if (event.sync) {
                      return isWeb();
                    }

                    return false;
                  }
                },
                {
                  actions: ['setSavingItems', 'saveItems', 'setData', 'sendSaveToNewItems']
                }
              ],
              [EVENT_NAMES.LIBRARY.REMOVE_ITEMS_FROM_SYNC]: {
                actions: ['removeItemsFromSync']
              },
              [EVENT_NAMES.LIBRARY.REMOVE_SYNCED_ITEMS]: {
                actions: ['removeSyncedItems']
              },

              // SPAWNED MACHINES EVENTS
              // ITEM MACHINE
              [EVENT_NAMES.ITEM.REMOVED]: {
                actions: ['removeItem', 'setData']
              },
              [EVENT_NAMES.ITEM.UPDATE]: {
                actions: ['updateItem', 'setData']
              },
              [EVENT_NAMES.ITEM.SYNCED]: {
                actions: ['removeItemsFromDownloaderQueueAndSetToDownloaded']
              },
              [EVENT_NAMES.DOWNLOADER.PUSH_TO_QUEUE]: {
                actions: ['pushToDownloaderQueue']
              },
              [EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE]: {
                actions: ['removeItemsFromDownloaderQueue']
              }
            }
          },

          [STATES.LIBRARY.AUTHENTICATED.DESTROYING.INDEX]: {
            id: STATES.LIBRARY.AUTHENTICATED.DESTROYING.INDEX,
            after: {
              2_000: {
                target: `#${MACHINE_NAMES.LIBRARY}.${STATES.LIBRARY.DESTROYED}`
              }
            },
            type: 'parallel',
            states: {
              [STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.INDEX]: {
                id: STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.INDEX,
                initial: STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.VALIDATING,
                states: {
                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.VALIDATING]: {
                    always: [
                      {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.DESTROY
                      }
                    ]
                  },

                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.DESTROY]: {
                    invoke: {
                      src: () => getLibraryBlobStorage().destroy(),
                      onDone: {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.DONE
                      },
                      onError: {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.DONE
                      }
                    }
                  },

                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.BLOB_STORAGE.DONE]: {}
                }
              },

              [STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.INDEX]: {
                id: STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.INDEX,
                initial: STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.VALIDATING,
                states: {
                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.VALIDATING]: {
                    always: [
                      {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.DESTROY
                      }
                    ]
                  },

                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.DESTROY]: {
                    invoke: {
                      src: () => getLibraryStorage().destroy(),
                      onDone: {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.DONE
                      },
                      onError: {
                        target: STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.DONE
                      }
                    }
                  },

                  [STATES.LIBRARY.AUTHENTICATED.DESTROYING.DATA_STORAGE.DONE]: {}
                }
              }
            }
          }
        }
      },

      [STATES.LIBRARY.DESTROYED]: {
        type: 'final'
      }
    }
  },
  {
    actions,
    guards
  }
);
