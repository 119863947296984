/* eslint-disable no-await-in-loop */
import isEqual from 'react-fast-compare';
import debounce from 'lodash/debounce';
import { isDev } from '@functions/env';

import { EVENT_NAMES } from '@constants/machines/now-playing';
import { sendCommandToWorker } from '@helpers/storages/helpers';
import { getNowPlayingStateItem } from '@functions/get';

export const nowPlayingStateStore = () => {
  return (sendParent, receiver) => {
    const options = {
      type: 'module'
    };
    const worker = new Worker(
      new URL('@workers/storages/now-playing-state-storage.worker.js', import.meta.url),
      options
    );
    let cache = {};

    const updateCache = value => {
      cache = value;
    };

    const getDiffs = value => {
      const keys = Object.keys(value);

      return keys.reduce((acc, key) => {
        if (!isEqual(value[key], cache[key])) {
          acc[key] = value[key];
        }

        return acc;
      }, {});
    };

    // METHODS
    const loadData = () => {
      sendCommandToWorker(worker, 'loadKeyValues').then(({ items }) => {
        updateCache(items);

        if (!items?.selected) {
          return;
        }

        const { selected, upnext, queue, repeat, metadata, volume, skipLimit } = items;

        if (queue?.length) {
          sendParent({
            type: EVENT_NAMES.NOW_PLAYING.ADD_TO_QUEUE,
            items: queue
          });
        }

        sendParent({
          type: EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME,
          value: volume
        });

        setTimeout(() => {
          sendParent({
            type: EVENT_NAMES.NOW_PLAYING.RESTORE_NOW_PLAYING_STATE,
            metadata,
            item: selected,
            items: upnext || [],
            repeat,
            skipLimit
          });
        }, 1_000);
      });
    };

    const mount = () => {
      loadData();
    };

    const saveState = debounce(async ({ value }) => {
      if (isDev()) {
        return;
      }

      const data = getDiffs(value);
      updateCache(value);

      if (!data) {
        return;
      }

      const keys = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        try {
          const key = keys[i];
          const item = getNowPlayingStateItem(value[key]);

          await sendCommandToWorker(worker, 'pureAdd', item, key);
        } catch (e) {
          console.log(e);
        }
      }
    }, 3_000);

    const destroy = () => {
      console.log('destroying now playing state database & worker....');

      sendCommandToWorker(worker, 'destroy')
        .then(() => {
          worker.terminate();

          console.log('now playing state worker terminated...');
        })
        .catch(() => {
          worker.terminate();

          console.log('now playing state worker terminated...');
        });
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case EVENT_NAMES.NOW_PLAYING.STORE.SAVE_STATE:
          saveState(event);
          break;

        case EVENT_NAMES.NOW_PLAYING.STORE.DESTROY:
          destroy();
          break;

        default:
          break;
      }
    });
  };
};
