import { useState, useCallback } from 'react';

const useBooleanState = initialValue => {
  const [value, setValue] = useState(() => initialValue);

  const active = useCallback(() => {
    setValue(true);
  }, []);

  const deactive = useCallback(() => {
    setValue(false);
  }, []);

  return [value, active, deactive];
};

export default useBooleanState;
