import { LIBRARY_VALID_TYPES } from '@constants/app';
import { EVENT_NAMES, MACHINE_NAMES } from '@constants/machines/library';
import Router from 'next/router';

import { assign, send, actions } from 'xstate';

import { getProtocolLink } from '@functions/get-link';
import { getData, getID, normalizeClientItem } from '@machines/library/data-helpers';

import { createItemWithMachine } from '@machines/library/create-machine';
import {
  isSyncing,
  isSynced,
  isWaitingForDownload
} from '@machines/library/machines/item/matchers';

const { pure } = actions;

export const setUpdatedAt = assign({
  updatedAt(_, event) {
    return event?.data?.updated_at || null;
  }
});

export const setSpawnItemMachineQueue = assign({
  spawnItemMachineQueue(_, event) {
    return event.data.items;
  }
});

export const removeFirstItemFromSpawnItemMachineQueue = assign({
  spawnItemMachineQueue({ spawnItemMachineQueue }) {
    const [, ...rest] = spawnItemMachineQueue;

    return rest;
  }
});

export const spawnItemMachineAndSetToRaw = assign({
  raw({ raw, spawnItemMachineQueue }) {
    const item = spawnItemMachineQueue[0];
    const id = getID(item);

    if (id === 'updated_at') {
      return raw;
    }

    const itemWithMachine = createItemWithMachine({
      item
    });

    const data = {
      [id]: itemWithMachine,
      ...raw
    };

    return data;
  }
});

export const setData = assign({
  data({ raw }) {
    return getData(raw);
  }
});

export const removeItem = assign({
  raw({ raw }, { _id }) {
    const { [_id]: omitted, ...rest } = { ...raw };

    return {
      ...rest
    };
  }
});

/**
 * Detect which items are new and which items are already in the raw/library data
 */
export const setSavingItems = assign({
  savingItems({ raw }, { items, sync }) {
    const result = items.map(normalizeClientItem).reduce(
      (acc, item) => {
        const hasValidType = LIBRARY_VALID_TYPES[item?.type];

        if (!hasValidType) {
          return acc;
        }

        const _id = getID(item);

        if (raw[_id]) {
          const { ref } = raw[_id];

          if (
            ref &&
            sync &&
            !isSynced(ref.state || ref.initialState) &&
            !isSyncing(ref.state || ref.initialState) &&
            !isWaitingForDownload(ref.state || ref.initialState)
          ) {
            acc.items.push(raw[_id]);
          }
          return acc;
        }

        const newItem = { ...item, _id };
        const newItemWithMachine = createItemWithMachine({
          item: newItem
        });

        acc.newItems.push(newItemWithMachine);

        return acc;
      },
      {
        newItems: [],
        items: []
      }
    );

    return result;
  }
});

/**
 * Set the new items to raw
 */
export const saveItems = assign({
  raw({ raw, savingItems }) {
    if (!savingItems?.newItems?.length) {
      return raw;
    }

    const items = savingItems?.newItems.reduce((acc, item) => {
      const id = getID(item);
      acc[id] = item;

      return acc;
    }, {});

    const result = {
      ...raw,
      ...items
    };

    return result;
  }
});

export const sendSaveToNewItems = pure(({ savingItems }, { sync }) => {
  const events = [];
  const { newItems, items } = savingItems;

  // new items, they need to be stored in db and then sync (if sync be true)
  if (newItems?.length) {
    for (let i = 0; i < newItems.length; i++) {
      const item = newItems[i];

      const event = { type: EVENT_NAMES.ITEM.SAVE, sync };
      // send items to save on db and sync
      events.push(send(event, { to: item.ref }));
    }
  }

  // need only to be sync
  if (items?.length) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      const event = { type: EVENT_NAMES.ITEM.SYNC, sync };
      events.push(send(event, { to: item.ref }));
    }

    // const event = { type: EVENT_NAMES.DOWNLOADER.PUSH_TO_QUEUE, items };
    // events.push(send(event, { to: MACHINE_NAMES.DOWNLOADER }));
  }

  return events;
});

export const resetSavingItems = assign({
  savingItems() {
    return {};
  }
});

export const updateItem = assign({
  raw({ raw }, event) {
    const id = getID(event.item);

    if (!raw[id]) {
      return raw;
    }

    return {
      ...raw,
      [id]: {
        ...event.item,
        ref: raw[id].ref
      }
    };
  }
});

/**
 * Item Machine
 */
export const removeItemsFromSync = pure(({ raw }, { items }) => {
  return items
    .map(item => {
      const id = getID(item);

      if (!raw[id]) {
        return null;
      }
      const { ref } = raw[id];

      return ref;
    })
    .filter(Boolean)
    .map(ref => {
      return send({ type: EVENT_NAMES.ITEM.UN_SYNC }, { to: ref });
    });
});

export const removeSyncedItems = pure(context => {
  const { songs, videos, podcasts } = context.data;

  let data = [];
  if (Array.isArray(songs)) {
    data = [...data, ...songs];
  }
  if (Array.isArray(videos)) {
    data = [...data, ...videos];
  }
  if (Array.isArray(podcasts)) {
    data = [...data, ...podcasts];
  }

  return data
    .filter(({ ref }) => isSynced(ref.state || ref.initialState))
    .map(({ ref }) => {
      return send({ type: EVENT_NAMES.ITEM.UN_SYNC }, { to: ref });
    });
});

export const sendAllowSyncUpdatedToItemMachines = pure(context => {
  const { raw } = context;

  const keys = Object.keys(context.raw);

  return keys.map(key => {
    return send(EVENT_NAMES.ITEM.ALLOW_SYNC_UPDATED, { to: raw[key].ref });
  });
});

/**
 * Downloader Machine
 */
export const removeItemsFromDownloaderQueue = send(
  (_, event) => {
    return {
      ...event,
      type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE
    };
  },
  { to: MACHINE_NAMES.DOWNLOADER }
);

export const removeItemsFromDownloaderQueueAndSetToDownloaded = send(
  (_, event) => {
    return {
      ...event,
      type: EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE_AND_SET_TO_DOWNLOADED
    };
  },
  { to: MACHINE_NAMES.DOWNLOADER }
);

export const pushToDownloaderQueue = send(
  ({ raw }, { item, items }) => {
    const data = [];

    if (items?.length) {
      for (let i = 0; i < items.length; i++) {
        const id = getID(items[i]);
        data.push(raw[id]);
      }
    }

    if (item) {
      const id = getID(item);
      data.push(raw[id]);
    }

    return {
      type: EVENT_NAMES.DOWNLOADER.PUSH_TO_QUEUE,
      items: data.filter(Boolean)
    };
  },
  { to: MACHINE_NAMES.DOWNLOADER }
);

/**
 * Validate Machine
 */
export const pushToValidateQueue = send(
  ({ raw }, { item, items }) => {
    const data = [];

    if (items?.length) {
      for (let i = 0; i < items.length; i++) {
        const id = getID(items[i]);
        data.push(raw[id]);
      }
    }

    if (item) {
      const id = getID(item);
      data.push(raw[id]);
    }

    return {
      type: EVENT_NAMES.VALIDATE.PUSH_TO_QUEUE,
      items: data.filter(Boolean)
    };
  },
  { to: MACHINE_NAMES.VALIDATE }
);

export const resetState = assign({
  updatedAt() {
    return null;
  },
  raw() {
    return {};
  },
  data() {
    return {};
  },
  savingItems() {
    return {};
  }
});

export const redirectToDownloadPage = (_, event) => {
  if (event.metadata) {
    const protocol = getProtocolLink(event.metadata);
    const link = `/download?r=${protocol}`;

    Router.push(link);
    return;
  }

  if (event?.items?.length === 1) {
    const protocol = getProtocolLink(event.items[0]);
    const link = `/download?r=${protocol}`;

    Router.push(link);
    return;
  }

  Router.push('/download?r=radiojavan://home');
};
