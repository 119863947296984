import { useEffect, useState, useMemo, useCallback } from 'react';
import { useGridConfig } from '@contexts/GridContext';
import { useDebounce } from '@hooks';
import { usePartial, useMatchers } from '@state/hooks';
import { main } from '@machines/grid/selectors';

export const useMainLayoutSize = () => {
  const { service } = useGridConfig();
  const width = usePartial(service, main);

  return useMemo(() => {
    return { width };
  }, [width]);
};

export const useDebouncedLayoutSize = ({ delay = 300 } = {}) => {
  const { width } = useMainLayoutSize();

  const [value, setState] = useState(() => width);

  const onChange = useCallback(v => {
    setState(v);
  }, []);

  const debouncedOnChange = useDebounce(onChange, delay);

  useEffect(() => {
    debouncedOnChange(width);
  }, [width]);

  return useMemo(() => {
    return {
      width: value
    };
  }, [value]);
};

export const useGridMatch = matcher => {
  const { service } = useGridConfig();

  const value = useMatchers(service, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useGridLayoutMethods = () => {
  const { service } = useGridConfig();

  const toggleResponsiveSidebar = useCallback(() => {
    service.send('TOGGLE_SIDEBAR_VISIBILITY_ON_RESPONSIVE');
  }, []);

  const hideResponsiveSidebar = useCallback(() => {
    service.send('HIDE_SIDEBAR_VISIBILITY_ON_RESPONSIVE');
  }, []);

  const setSidebarWidth = useCallback(value => {
    service.send('SET_SIDEBAR_WIDTH', { value });
  }, []);

  return {
    toggleResponsiveSidebar,
    hideResponsiveSidebar,
    setSidebarWidth
  };
};
