import ms from 'ms';
import { EVENT_NAMES } from '@constants/machines/app';
import { DEFAULT_SERVER_URL, DEFAULT_RJ_LIVE_STREAM } from '@constants/urls';
import { isNative } from '@functions/env';

import { createMachine, sendParent } from 'xstate';
import { loadServerConfig, loadAppConfig } from '@api/config';

import * as actions from './actions';

export const configMachine = createMachine(
  {
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: 'config-machine',
    context: {
      config: {
        server: DEFAULT_SERVER_URL,
        tv: {
          hls: DEFAULT_RJ_LIVE_STREAM
        }
      }
    },
    initial: 'firstLoading',
    states: {
      firstLoading: {
        always: [
          {
            target: 'loading',
            cond: isNative
          },
          {
            target: '#loading.loadAppConfig',
            actions: [sendParent(EVENT_NAMES.APP.INITIAL_APP_CONFIG_LOADED)]
          }
        ]
      },
      loading: {
        id: 'loading',
        initial: 'loadServerConfig',
        exit: ['setConfigToWindow'],
        states: {
          // TODO: REDIRECT TARGET TO FAILURE AFTER REACHING MAX COUNT OF FAIL
          loadServerConfig: {
            invoke: {
              id: 'load-server-config',
              src: loadServerConfig,
              onDone: {
                target: 'loadAppConfig',
                actions: ['setConfig', 'setConfigToWindow']
              },
              onError: {
                target: 'loadAppConfig',
                actions: ['setConfigToWindow']
              }
            }
          },
          loadAppConfig: {
            invoke: {
              id: 'load-app-config',
              src: loadAppConfig,
              onDone: {
                target: '#config-machine.done',
                actions: ['setConfig', 'setConfigToWindow']
              },
              onError: {
                target: '#config-machine.done',
                actions: ['setConfigToWindow']
              }
            }
          }
        }
      },
      failure: {
        after: {
          1000: {
            target: 'loading'
          }
        }
      },
      done: {
        entry: [sendParent(EVENT_NAMES.APP.INITIAL_APP_CONFIG_LOADED), 'setConfigToWindow'],
        after: {
          [ms('10mins')]: [
            {
              target: 'loading',
              cond: isNative
            }
          ]
        },
        on: {
          RELOAD: [
            {
              target: 'loading',
              cond: isNative
            },
            {
              target: '#loading.loadAppConfig'
            }
          ]
        }
      }
    }
  },
  {
    actions,
    guards: {}
  }
);
