import set from 'lodash/set';
import omit from 'lodash/omit';
import * as safeLocalStorage from '@helpers/local-storage';

import { isNative } from '@functions/env';

import { EVENT_NAMES } from '@constants/machines/app-settings';

import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';
import { getUser } from '@machines/auth/selectors';

import { getInitialBrowserSettings } from './helpers';

const initialUser = {
  username: 'anonymous'
};

const getUserFromState = () => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  if (!authService) {
    return initialUser;
  }

  const user = getUser(authService.state);

  if (!user) {
    return initialUser;
  }

  return user;
};

export const getStorageName = ({ user: userProp }) => {
  const user = userProp || getUserFromState();

  return `${user.username}:STATE___:v1`;
};

export const browserSettingsMachine = context => {
  return (sendParent, receiver) => {
    let user = context.user || getUserFromState();

    const persistedStorage = safeLocalStorage.get(getStorageName({ user }), {});

    let storage = getInitialBrowserSettings({ storage: persistedStorage, user });

    // METHODS
    const sendUpdate = () => {
      sendParent({
        type: EVENT_NAMES.APP_SETTINGS.UPDATE_BROWSER_SETTINGS,
        data: storage
      });
    };

    const save = ({ key, subKey, value }) => {
      if (subKey) {
        set(storage, `${key}.${subKey}`, value);

        sendUpdate();
        return;
      }

      storage[key] = value;
      sendUpdate();
    };

    const remove = ({ key, subKey }) => {
      if (subKey) {
        storage = omit(storage, `${key}.${subKey}`);

        sendUpdate();
        return;
      }

      storage = omit(storage, key);
      sendUpdate();
    };

    const setToStorage = () => {
      safeLocalStorage.set(getStorageName({ user }), storage);
    };

    const removeStorage = () => {
      safeLocalStorage.remove(getStorageName({ user }));
    };

    const updateUserInfoAndSwapStorageData = () => {
      const service = getAppService();
      const authService = getAuthService(service.state);

      if (authService) {
        const newUser = getUser(authService.state);

        if (newUser?.username !== user.username) {
          removeStorage();

          const newPersistedStorage = safeLocalStorage.get(getStorageName({ user: newUser }), {});

          let initialStorage;

          if (isNative()) {
            // on native the user is always anonymous on initial
            initialStorage = newPersistedStorage;
          } else {
            // current user was anonymous
            initialStorage = user.username === initialUser.username ? storage : newPersistedStorage;
          }

          user = newUser;

          if (!user) {
            user = initialUser;
          }

          storage = getInitialBrowserSettings({ storage: initialStorage, user });

          setToStorage();
          sendUpdate();
        }
      }
    };

    // LISTENERS
    const interval = window.setInterval(() => {
      setToStorage();
      sendUpdate();
    }, 20_000);

    window.addEventListener('beforeunload', setToStorage);

    const cleanup = () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };

    // ACTIONS
    sendUpdate();

    // RECEIVER
    receiver(event => {
      // eslint-disable-next-line default-case
      switch (event.type) {
        case EVENT_NAMES.BROWSER_SETTINGS.SET:
          save(event);
          break;
        case EVENT_NAMES.BROWSER_SETTINGS.REMOVE:
          remove(event);
          break;
        case EVENT_NAMES.BROWSER_SETTINGS.AUTHENTICATED:
          updateUserInfoAndSwapStorageData();
          break;
        case EVENT_NAMES.BROWSER_SETTINGS.UNAUTHENTICATED:
          updateUserInfoAndSwapStorageData();
          break;
        default:
          break;
      }
    });
    /**
     * @disposal
     * Will be called on page refresh + fast refresh + destroying machine
     */
    return () => {
      cleanup();
    };
  };
};
