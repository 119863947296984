import ms from 'ms';

export const sendCommandToWorker = (worker, command, ...args) => {
  return new Promise((resolve, reject) => {
    const channelID = Math.random();

    const params = {
      command,
      args,
      channelID
    };

    worker.postMessage(params);

    const cb = e => {
      const { done, result } = e.data;

      if (e?.data?.channelID === channelID) {
        if (done) {
          resolve(result);
        } else {
          reject(result);
        }

        worker.removeEventListener('message', cb);
      }
    };

    worker.addEventListener('message', cb);

    setTimeout(() => {
      reject();
      worker?.removeEventListener('message', cb);
    }, ms('1m'));
  });
};
