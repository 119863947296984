import { useReducer } from 'react';
import isBoolean from 'lodash/isBoolean';

const toggleReducer = (state, nextValue) => {
  return isBoolean(nextValue) ? nextValue : !state;
};

const useToggle = initialValue => {
  return useReducer(toggleReducer, initialValue);
};

export default useToggle;
