export const EVENT_NAMES = {
  LIBRARY: {
    AUTHENTICATED: 'LIBRARY___AUTHENTICATED',
    UN_AUTHENTICATED: 'LIBRARY___UN_AUTHENTICATED',
    DESTROY: 'LIBRARY__DESTROY',
    SAVE: 'LIBRARY__SAVE',
    REMOVE_ITEMS_FROM_SYNC: 'LIBRARY__REMOVE_ITEMS_FROM_SYNC',
    REMOVE_SYNCED_ITEMS: 'LIBRARY__REMOVE_SYNCED_ITEMS',
    DELETE_ALL: 'LIBRARY__DELETE_ALL',

    ALLOW_SYNC_UPDATED: 'LIBRARY__ALLOW_SYNC_UPDATED'
  },
  ITEM: {
    REMOVE: 'ITEM__REMOVE',
    SAVE: 'ITEM__SAVE',
    REMOVED: 'ITEM__REMOVED',
    SAVED: 'ITEM__SAVED',
    UPDATE: 'ITEM__UPDATE',
    SYNCED: 'ITEM__SYNCED',
    SYNC: 'ITEM__SYNC',
    CANCEL: 'ITEM__CANCEL',
    UN_SYNC: 'ITEM__UN_SYNC',
    VALIDATE: 'ITEM__VALIDATE',
    ALLOW_SYNC_UPDATED: 'ITEM__ALLOW_SYNC_UPDATED'
  },
  DOWNLOADER: {
    PUSH_TO_QUEUE: 'DOWNLOADER__PUSH_TO_QUEUE',
    REMOVE_FROM_QUEUE: 'DOWNLOADER__REMOVE_FROM_QUEUE',
    REMOVE_FROM_QUEUE_AND_SET_TO_DOWNLOADED: 'DOWNLOADER__REMOVE_FROM_QUEUE_AND_SET_TO_DOWNLOADED',
    CANCEL_ALL: 'DOWNLOADER__CANCEL_ALL',
    UPDATE_CONCURRENCY: 'DOWNLOADER__UPDATE_CONCURRENCY'
  },
  DOWNLOADER_ITEM: {
    STARTED: 'DOWNLOADER_ITEM__STARTED',
    PROGRESS: 'DOWNLOADER_ITEM__PROGRESS',
    COMPLETED: 'DOWNLOADER_ITEM__COMPLETED',
    CANCEL: 'DOWNLOADER_ITEM__CANCEL',
    CANCELLED: 'DOWNLOADER_ITEM__CANCELLED',
    FAILED: 'DOWNLOADER_ITEM__FAILED',
    SYNC: 'DOWNLOADER_ITEM__SYNC',
    SYNCED: 'DOWNLOADER_ITEM__SYNCED'
  },
  FETCHER: {
    CANCEL: 'FETCHER__CANCEL'
  },
  VALIDATE: {
    PUSH_TO_QUEUE: 'VALIDATE__PUSH_TO_QUEUE',
    REMOVE_FROM_QUEUE: 'VALIDATE__REMOVE_FROM_QUEUE'
  }
};

export const STATES = {
  LIBRARY: {
    UN_AUTHENTICATED: 'UN_AUTHENTICATED',

    AUTHENTICATED: {
      INDEX: 'AUTHENTICATED',

      LOADING: {
        INDEX: 'LOADING',

        LIKED_SONGS: 'LIKED_SONGS',

        LAST_UPDATED_DATE: 'LAST_UPDATED_DATE',
        API_DATA: 'API_DATA',
        UPDATE_STORAGE: 'UPDATE_STORAGE',

        STORAGE_DATA: 'STORAGE_DATA',

        SPAWNING_ACTORS: {
          INDEX: 'SPAWNING_ACTORS',
          VALIDATING: 'VALIDATING',
          SPAWNING: 'SPAWNING',
          DONE: 'DONE'
        }
      },

      ACTIVATED: {
        INDEX: 'ACTIVATED',
        IDLE: 'IDLE',
        DELETING: {
          INDEX: 'DELETING',
          API: 'API',
          BLOB_STORAGE: 'BLOB_STORAGE',
          DATA_STORAGE: 'DATA_STORAGE'
        }
      },

      // type: parallel
      DESTROYING: {
        INDEX: 'DESTROYING',

        BLOB_STORAGE: {
          INDEX: 'BLOB_STORAGE',

          VALIDATING: 'VALIDATING',
          DESTROY: 'DESTROY',
          DONE: 'DONE'
        },
        DATA_STORAGE: {
          INDEX: 'DATA_STORAGE',

          VALIDATING: 'VALIDATING',
          DESTROY: 'DESTROY',
          DONE: 'DONE'
        }
      }
    },

    DESTROYED: 'DESTROYED'
  },
  ITEM: {
    IDLE: 'IDLE',
    VALIDATING: {
      INDEX: 'VALIDATING',

      BEFORE_WAITING_FOR_VALIDATE: 'BEFORE_WAITING_FOR_VALIDATE',
      WAITING_FOR_VALIDATE: 'WAITING_FOR_VALIDATE',
      PROCESSING: 'PROCESSING'
    },
    ADDING: {
      INDEX: 'ADDING',

      TO_API: 'ADDING_TO_API',
      TO_STORAGE: 'ADDING_TO_STORAGE'
    },
    ADDED: 'ADDED',
    SYNCING: {
      INDEX: 'SYNCING',

      DOWNLOADING: 'DOWNLOADING',
      TO_BLOB_STORAGE: 'TO_BLOB_STORAGE',
      TO_STORAGE: 'TO_STORAGE',
      DONE: 'DONE'
    },
    SYNCED: {
      INDEX: 'SYNCED',

      IDLE: 'IDLE'
    },
    REMOVING: {
      INDEX: 'REMOVING',

      FROM_API: 'REMOVING_FROM_API',
      FROM_STORAGE: 'REMOVING_FROM_STORAGE',
      FROM_BLOB_STORAGE: 'REMOVING_FROM_BLOB_STORAGE'
    },
    REMOVED: 'REMOVED',
    WAITING_FOR_DOWNLOADER: 'WAITING_FOR_DOWNLOADER'
  },
  DOWNLOADER: {
    IDLE: 'IDLE',
    VALIDATING: 'VALIDATING',
    SYNCING: 'SYNCING'
  },
  DOWNLOADER_ITEM: {
    IDLE: 'IDLE',
    SYNCING: 'SYNCING',
    SYNCED: 'SYNCED',
    CANCELING: 'CANCELING',
    CANCELLED: 'CANCELLED'
  },

  VALIDATE: {
    VALIDATING_QUEUE: 'VALIDATING_QUEUE',
    IDLE: 'IDLE',
    VALIDATING: 'VALIDATING'
  }
};

export const MACHINE_NAMES = {
  LIBRARY: 'LIBRARY_MACHINE',
  ITEM: 'ITEM_MACHINE',
  ARTIST_ITEM: 'ARTIST_ITEM',
  DOWNLOADER: 'DOWNLOADER_MACHINE',
  DOWNLOADER_ITEM: 'DOWNLOADER_ITEM_MACHINE',
  FETCHER: 'FETCHER_MACHINE',
  VALIDATE: 'VALIDATE_MACHINE'
};
