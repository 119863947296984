import { interpret } from 'xstate';

import { networkMachine } from '@machines/network/network-machine';

let service;
export const startNetworkService = () => {
  service = interpret(networkMachine)
    // .onTransition((...args) => {
    //   console.log('Network-service', ...args);
    // })
    .start();
};

export const stopNetworkService = () => {
  if (!service) {
    return;
  }
  service.stop();

  service = undefined;
};

export const getNetworkService = () => {
  if (service) {
    return service;
  }

  startNetworkService();

  return service;
};
