import { realTypeIs } from '@helpers/type-is';

import { useMemo } from 'react';

import { useActor } from '@xstate/react';
import { useAppService } from '@hooks';
import { usePartial, useMatchers } from '@state/hooks';
import { getLibraryService } from '@machines/app/selectors';
import { downloaderRef, downloaderItemRef, selectors } from '@machines/library/selectors';

export const useLibraryRef = () => {
  const service = useAppService();
  const ref = usePartial(service, getLibraryService);

  return ref;
};

export const useLibrary = (selector, compare) => {
  const ref = useLibraryRef();
  const value = usePartial(ref, selector, compare);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useLibraryMemoizedContext = (selector, key) => {
  const value = useLibrary(selector);

  return useMemo(() => {
    return value;
  }, [value?.[key]]);
};

export const useLibraryMatch = matcher => {
  const ref = useLibraryRef();

  const value = useMatchers(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useDownloaderRef = () => {
  const service = useLibraryRef();
  const ref = usePartial(service, downloaderRef);

  return ref;
};

export const useDownloaderContext = selector => {
  const ref = useDownloaderRef();

  const value = usePartial(ref, selector);

  return value;
};

export const useDownloaderMatcher = matcher => {
  const ref = useDownloaderRef();

  const value = usePartial(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useDownloaderItemRef = service => {
  const ref = usePartial(service, downloaderItemRef);

  return ref;
};

export const useDownloaderItemActor = service => {
  const actor = useDownloaderItemRef(service);

  return useActor(actor);
};

export const useItemRef = data => {
  const type = realTypeIs(data);
  const value = useLibrary(selectors[type](data));

  if (value) {
    return value.ref;
  }

  return null;
};
