import { isDev } from '@functions/env';

class GoogleTag {
  constructor() {
    this.initialized = false;

    // SLOTS
    this.interstitialSlot = null;
    this.anchorSlot = null;
    this.adSlots = {};
    this.slotOnLoadCallbackQueue = {};

    // mapping
    this.defaultResponsiveAdsSizeMapping = null;

    this.slotOnLoad = this.slotOnLoad.bind(this);
  }

  get interstitialID() {
    if (isDev()) {
      return '/6355419/Travel/Europe/France/Paris';
    }

    return `/${process.env.GOOGLE_TAG_ID}/Interstitial`;
  }

  get bottomAnchorID() {
    if (isDev()) {
      return '/6355419/Travel/Europe/France/Paris';
    }

    return `/${process.env.GOOGLE_TAG_ID}/Anchor`;
  }

  get responsiveAdsID() {
    if (isDev()) {
      return '/6355419/Travel/Europe/France/Paris';
    }

    return `/${process.env.GOOGLE_TAG_ID}/Responsive`;
  }

  get playerBillboardID() {
    if (isDev()) {
      return '/6355419/Travel/Europe/France/Paris';
    }

    return `/${process.env.GOOGLE_TAG_ID}/PlayerBillboard`;
  }

  get mobileID() {
    if (isDev()) {
      return '/6355419/Travel/Europe/France/Paris';
    }

    return `/${process.env.GOOGLE_TAG_ID}/Mobile`;
  }

  initialize() {
    if (this.initialized) {
      return;
    }

    window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(() => {
      window.googletag.pubads().addEventListener('slotOnload', this.slotOnLoad);
    });

    this.initialized = true;
  }

  slotOnLoad(event) {
    Object.entries(this.slotOnLoadCallbackQueue).forEach(([, fn]) => {
      if (fn) {
        fn(event);
      }
    });
  }

  initializeInterstitial() {
    if (!this.initialized) {
      this.initialize();
    }

    if (this.interstitialSlot) {
      return;
    }

    window.googletag.cmd.push(() => {
      this.interstitialSlot = window.googletag.defineOutOfPageSlot(
        this.interstitialID,
        window.googletag.enums.OutOfPageFormat.INTERSTITIAL
      );
      if (this.interstitialSlot) {
        this.interstitialSlot.addService(window.googletag.pubads());

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display(this.interstitialSlot);
      }
    });
  }

  destroyInterstitial() {
    if (this.interstitialSlot) {
      window.googletag.destroySlots([this.interstitialSlot]);

      this.interstitialSlot = null;
    }
  }

  initializeBottomAnchor() {
    if (!this.initialized) {
      this.initialize();
    }

    if (this.anchorSlot) {
      return;
    }

    window.googletag.cmd.push(() => {
      this.anchorSlot = window.googletag.defineOutOfPageSlot(
        this.bottomAnchorID,
        window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
      );

      if (this.anchorSlot) {
        this.anchorSlot.addService(window.googletag.pubads());

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
        window.googletag.display(this.anchorSlot);
      }
    });
  }

  destroyBottomAnchor() {
    if (this.anchorSlot) {
      window.googletag.destroySlots([this.anchorSlot]);

      this.anchorSlot = null;
    }
  }

  defineResponsiveAdsSizeMapping() {
    // defined sizes
    // .addSize([0,0],[[336,280],[300,250],[250,250],[320,250],[320,100],[320,50],[300,100],[300,50]])
    // .addSize([750,200],[[728,90],[336,280],[300,250],[400,300],[480,320],[320,250]])

    this.defaultResponsiveAdsSizeMapping = window.googletag
      .sizeMapping()
      .addSize(
        [0, 0],
        [
          [320, 100],
          [320, 50],
          [300, 100],
          [300, 50]
        ]
      )
      .addSize([750, 200], [[728, 90]])
      .build();
  }

  addResponsiveSlot({ id, onLoad, size }) {
    if (!this.initialized) {
      this.initialize();
    }

    window.googletag.cmd.push(() => {
      if (!this.defaultResponsiveAdsSizeMapping) {
        this.defineResponsiveAdsSizeMapping();
      }

      const slot = window.googletag
        .defineSlot(this.responsiveAdsID, size || [970, 250], id)
        // .defineSizeMapping(this.defaultResponsiveAdsSizeMapping)
        .addService(window.googletag.pubads());

      if (slot) {
        this.adSlots[id] = slot;
        this.slotOnLoadCallbackQueue[id] = event => {
          if (event.slot === slot) {
            onLoad();
          }
        };

        window.googletag.pubads().setTargeting('site', ['radiojavan.com']);
        window.googletag.enableServices();
        window.googletag.display(this.adSlots[id]);
      }
    });
  }

  addAdSlot({ id, onLoad, size, isTouch }) {
    if (!this.initialized) {
      this.initialize();
    }

    window.googletag.cmd.push(() => {
      const adId = isTouch ? this.mobileID : this.playerBillboardID;
      const slot = window.googletag
        .defineSlot(adId, size || [970, 250], id)
        // .defineSizeMapping(this.defaultResponsiveAdsSizeMapping)
        .addService(window.googletag.pubads());

      if (slot) {
        this.adSlots[id] = slot;
        this.slotOnLoadCallbackQueue[id] = event => {
          if (event.slot === slot) {
            onLoad();
          }
        };

        window.googletag.pubads().setTargeting('site', ['radiojavan.com']);
        window.googletag.enableServices();
        window.googletag.display(this.adSlots[id]);
      }
    });
  }

  removeResponsiveSlot({ id }) {
    const slot = this.adSlots[id];

    if (slot) {
      window.googletag.destroySlots([slot]);

      this.adSlots[id] = null;

      if (this.slotOnLoadCallbackQueue[id]) {
        this.slotOnLoadCallbackQueue[id] = null;
      }
    }
  }

  destroyAlladSlots() {
    Object.entries(this.adSlots).forEach(([id]) => {
      this.removeResponsiveSlot({ id });
    });
  }

  destroyAllSlots() {
    this.destroyInterstitial();
    this.destroyBottomAnchor();
    this.destroyAlladSlots();

    window.googletag.cmd.push(() => {
      window.googletag.destroySlots();
      window.googletag.pubads().removeEventListener('slotOnload', this.slotOnLoad);
    });
  }
}

let instance;
export const googleTag = () => {
  //   if (isDev()) {
  //     return {};
  //   }

  if (instance) {
    return instance;
  }

  instance = new GoogleTag();

  return instance;
};
