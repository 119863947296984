import { useCallback, forwardRef } from 'react';
import { useUpdateEffect } from '@hooks';

import StaticInput from './StaticInput';

const Input = forwardRef(
  (
    {
      input,
      meta: { error, touched, active },
      showError,
      afterChanged,
      onCloseClicked,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      ...props
    },
    ref
  ) => {
    const onFocus = useCallback(
      e => {
        input.onFocus(e);
        onFocusProp(e);
      },
      [input.onFocus]
    );

    const onBlur = useCallback(
      e => {
        input.onBlur(e);
        onBlurProp(e);
      },
      [input.onBlur]
    );

    const onClean = useCallback(() => {
      input.onChange(undefined);

      document.getElementById(input.name)?.focus();

      if (onCloseClicked) {
        onCloseClicked();
      }
    }, [input.name]);

    useUpdateEffect(() => {
      if (afterChanged) {
        afterChanged(input.value);
      }
    }, [input.value]);

    return (
      <StaticInput
        ref={ref}
        {...props}
        {...input}
        onBlur={onBlur}
        onFocus={onFocus}
        onClean={onClean}
        isFocused={active}
        hasError={showError ? error && touched : false}
        errorMessage={error}
      />
    );
  }
);

Input.defaultProps = {
  showError: true,
  isRound: true,
  onFocus() {},
  onBlur() {},
  variant: 'outline-ghost'
};

export default Input;
