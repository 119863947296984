export const EVENT_NAMES = {
  APP_SETTINGS: {
    UPDATE_BROWSER_SETTINGS: 'APP_SETTINGS__UPDATE_BROWSER_SETTINGS',
    UPDATE_NATIVE_SETTINGS: 'APP_SETTINGS__UPDATE_NATIVE_SETTINGS',
    AUTHENTICATED: 'APP_SETTINGS__AUTHENTICATED',
    UNAUTHENTICATED: 'APP_SETTINGS__UNAUTHENTICATED'
  },
  BROWSER_SETTINGS: {
    SET: 'BROWSER_SETTINGS__SET',
    REMOVE: 'BROWSER_SETTINGS__REMOVE',
    AUTHENTICATED: 'BROWSER_SETTINGS__AUTHENTICATED',
    UNAUTHENTICATED: 'BROWSER_SETTINGS__UNAUTHENTICATED'
  },
  NATIVE_SETTINGS: {
    SET: 'NATIVE_SETTINGS__SET'
  }
};

export const STATES = {
  APP_SETTINGS: {
    LOADING: {
      INDEX: 'LOADING',

      LOADING_BROWSER_SETTINGS: 'LOADING_BROWSER_SETTINGS',
      LOADING_NATIVE_SETTINGS: 'LOADING_NATIVE_SETTINGS'
    },

    ACTIVE: 'ACTIVE'
  },
  BROWSER_SETTINGS: {},
  NATIVE_SETTINGS: {}
};

export const MACHINE_NAMES = {
  APP_SETTINGS: 'APP_SETTINGS_MACHINE',
  BROWSER_SETTINGS: 'BROWSER_SETTINGS_MACHINE',
  NATIVE_SETTINGS: 'NATIVE_SETTINGS_MACHINE'
};
