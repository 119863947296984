import { EVENT_NAMES } from '@constants/machines/now-playing';
import { EVENT_NAMES as AUTH_EVENT_NAMES } from '@constants/machines/auth';
import Router from 'next/router';

import orderBy from 'lodash/orderBy';
import shuffle from 'lodash/shuffle';
import isUndefined from 'lodash/isUndefined';
import { getID, getNowPlayingItem } from '@functions/get';
import { insertAt } from '@functions/array';

import { assign, actions, send } from 'xstate';
import { getAppService } from '@state/services/app-service';
import { getAuthService } from '@machines/app/selectors';
import { getLink } from '@functions/get-link';

import {
  createLiveStreamingMachine,
  createAdMachine,
  createRecentlyPlayedMachine,
  createNowPlayingStateStoreMachine,
  createFailedUserPlaysMachine,
  findSelectedIndex,
  getMachines,
  reorderItems,
  createQueueItem,
  getNowPlayingItems
} from '@machines/now-playing/helpers';

import {
  getSelectedMachine,
  getNextItemFromUpNext,
  getPreviousItemFromUpNext
} from '@machines/now-playing/selectors';

const { pure } = actions;

export const toggleFullScreenUpNext = assign({
  fullScreenUpNext({ fullScreenUpNext }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }
    return !fullScreenUpNext;
  }
});

export const toggleFullScreenLyrics = assign({
  fullScreenLyrics({ fullScreenLyrics }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }
    return !fullScreenLyrics;
  }
});

export const toggleNoneFullScreenLyrics = assign({
  noneFullScreenLyrics({ noneFullScreenLyrics }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }
    return !noneFullScreenLyrics;
  }
});

export const setFullscreenLyricsFalse = assign({
  fullScreenLyrics() {
    return false;
  }
});

export const toggleFullscreen = assign({
  fullscreen({ fullscreen }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }
    return !fullscreen;
  },
  fullScreenUpNext() {
    return false;
  }
});

export const toggleShowLiveStream = assign({
  showLiveStream({ showLiveStream }, { value }) {
    if (!isUndefined(value)) {
      return value;
    }
    return !showLiveStream;
  }
});

export const setUpNext = assign({
  upnext(_, event) {
    const items = event.shuffle ? shuffle(event.items) : event.items;

    return getNowPlayingItems(items);
  }
});

export const setSelectedFromItem = assign({
  selected(_, event) {
    return event.item;
  }
});

export const setSelectedMachine = assign({
  machines({ machines, selected }) {
    return getMachines({ machines, items: [selected] });
  }
});

export const setPlayContext = assign({
  metadata(_, { metadata }) {
    return metadata;
  },

  selected({ upnext }, event) {
    const item = event.shuffle ? upnext[0] : event.item;
    const id = getID(item);

    return upnext.find(upnextItem => {
      return id === upnextItem._id;
    });
  },

  /**
   * Used when initializing the now playing state
   */
  repeat({ repeat }, event) {
    if (!isUndefined(event.repeat)) {
      return event.repeat;
    }

    return repeat;
  }
});

export const setPlayContextFromQueue = assign({
  selected({ queue }, event) {
    const item = event.item ? event.item : queue[0];
    const id = getID(item);

    return queue.find(queueItem => {
      return id === queueItem._id;
    });
  }
});

export const toggleRepeat = assign({
  repeat({ repeat }) {
    return !repeat;
  }
});

export const resetRepeat = assign({
  repeat() {
    return false;
  }
});

export const setLastSelectedItemFromUpNext = assign({
  lastSelectedFromUpNext({ selected, lastSelectedFromUpNext }) {
    if (!selected) {
      return null;
    }

    if (selected.playingFromQueue) {
      return lastSelectedFromUpNext;
    }

    return selected;
  }
});

export const next = assign({
  selected(context) {
    return getNextItemFromUpNext({ context });
  }
});

export const previous = assign({
  selected(context) {
    return getPreviousItemFromUpNext({ context });
  }
});

/**
 * Queue
 */
export const setQueue = assign({
  queue({ queue }, { items }) {
    const newItems = getNowPlayingItems(items).map(createQueueItem);

    return [...queue, ...newItems];
  }
});

export const clearQueue = assign({
  queue() {
    return [];
  }
});

export const removeFromQueue = assign({
  queue({ queue }, { item }) {
    return queue.filter(queueItem => {
      if (queueItem._id === item._id) {
        if (item.selectedId) {
          return queueItem.selectedId !== item.selectedId;
        }
      }

      return queueItem._id !== item._id;
    });
  }
});

export const reorderQueue = assign({
  queue({ queue }, { value }) {
    return reorderItems({ items: queue, ...value });
  }
});

export const insertToQueue = assign({
  queue({ queue }, { value }) {
    const { destination, source } = value;

    let toIndex = findSelectedIndex(queue, destination.data);

    if (typeof destination.after === 'boolean' && toIndex > 0) {
      if (destination.after) {
        toIndex += 1;
      }
    }

    const { data } = source;

    return insertAt(queue, toIndex, createQueueItem(getNowPlayingItem(data)));
  }
});

export const selectFirstItemFromQueue = assign({
  selected({ queue }) {
    return queue[0];
  }
});

export const removeFirstItemFromQueue = assign({
  queue({ queue }) {
    const [, ...rest] = queue;

    return rest;
  }
});

export const reorderUpNext = assign({
  upnext({ upnext }, { value }) {
    return reorderItems({ items: upnext, ...value });
  }
});

export const insertToUpNext = assign({
  upnext({ upnext }, { value }) {
    const { destination, source } = value;

    let toIndex = findSelectedIndex(upnext, destination.data);

    if (typeof destination.after === 'boolean' && toIndex > 0) {
      if (destination.after) {
        toIndex += 1;
      }
    }

    const { data } = source;

    return insertAt(upnext, toIndex, getNowPlayingItem(data));
  }
});

export const removeFromUpNext = assign({
  upnext({ upnext }, { item }) {
    return upnext.filter(upnextItem => {
      if (upnextItem._id === item._id) {
        if (item.selectedId) {
          return upnextItem.selectedId !== item.selectedId;
        }
      }

      return upnextItem._id !== item._id;
    });
  }
});

export const setVideoPageReference = assign({
  videoPageReference(_, { value }) {
    return value;
  }
});

export const increaseSkippedCount = assign({
  skipLimit({ skipLimit }, event) {
    if (event.type === EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT && event.finished) {
      return skipLimit;
    }

    return {
      ...skipLimit,
      lastSkipTimestamp: +new Date(),
      skippedCount: skipLimit.skippedCount + 1
    };
  }
});

export const resetSkippedCount = assign({
  skipLimit({ skipLimit }) {
    return {
      ...skipLimit,
      lastSkipTimestamp: +new Date(),
      skippedCount: 0
    };
  }
});

export const setLastTimestampAfterLocked = assign({
  skipLimit({ skipLimit }) {
    return {
      ...skipLimit,
      lastTimestampAfterLocked: +new Date()
    };
  }
});

export const setInitialSkipLimit = assign({
  skipLimit({ skipLimit }, { skipLimit: initialSkipLimit }) {
    return {
      ...skipLimit,
      ...initialSkipLimit
    };
  }
});

/**
 * Volume
 */
export const setVolume = assign({
  volume(_, event) {
    if (event.value >= 1) {
      return 1;
    }

    if (event.value <= 0) {
      return 0;
    }

    return event.value;
  }
});

export const sendVolumeToSelected = pure(({ volume, ...context }) => {
  const machine = getSelectedMachine({ context });

  if (machine) {
    return send({ type: EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME, value: volume }, { to: machine });
  }

  return [];
});

export const sendVolumeToSelectedAd = pure(({ adsQueue, volume }) => {
  if (adsQueue[0]) {
    return send(
      { type: EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME, value: volume },
      { to: adsQueue[0]?.ref }
    );
  }

  return [];
});

export const sendVolumeToSelectedMiddleAd = pure(({ middleAdsQueue, volume }) => {
  if (middleAdsQueue[0]) {
    return send(
      { type: EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME, value: volume },
      { to: middleAdsQueue[0]?.ref }
    );
  }

  return [];
});

export const sendVolumeToSelectedLiveStream = pure(({ liveStreamingRef, volume }) => {
  if (liveStreamingRef) {
    return send(
      { type: EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME, value: volume },
      { to: liveStreamingRef }
    );
  }

  return [];
});

export const setRecentlyPlayedRef = assign({
  recentlyPlayedRef({ recentlyPlayedRef }) {
    if (recentlyPlayedRef) {
      return recentlyPlayedRef;
    }

    return createRecentlyPlayedMachine();
  }
});

export const setNowPlayingStoreState = assign({
  stateStoreRef({ stateStoreRef }) {
    if (stateStoreRef) {
      return stateStoreRef;
    }

    return createNowPlayingStateStoreMachine();
  }
});

export const setRecentlyPlayedItems = assign({
  recentlyPlayed(_, { items }) {
    const orderedItems = orderBy(items, ['dateAdded'], ['desc']);

    return orderedItems;
  }
});

export const setIsRestoreNowPlayingStateAllowed = assign({
  isRestoreNowPlayingStateAllowed() {
    return false;
  }
});

/**
 * Machines Manipulation
 *
 * Call before setPlayContext
 */
export const removeItemFromMachines = assign({
  machines({ machines }, { value }) {
    const id = getID(value);

    if (machines[id]) {
      const { [id]: machine, ...rest } = machines;

      return {
        ...rest
      };
    }

    return machines;
  }
});

/**
 * live streaming
 */
export const setLiveStreamingMachine = assign({
  liveStreamingRef() {
    return createLiveStreamingMachine();
  }
});

export const sendDestroyToLiveStreaming = pure(({ liveStreamingRef }) => {
  return send(EVENT_NAMES.LIVE_STREAMING.DESTROY, { to: liveStreamingRef });
});
/**
 * ads
 */
export const setAdsMachines = assign({
  adsMachines({ adsMachines }, { items }) {
    const newItems = items.reduce((acc, item) => {
      const _id = getID(item);
      let ref;

      if (adsMachines[_id]) {
        ref = adsMachines[_id]?.ref;
      } else {
        ref = createAdMachine({ item });
      }

      acc[_id] = {
        ...item,
        _id,
        ref
      };

      return acc;
    }, {});

    return {
      ...adsMachines,
      ...newItems
    };
  }
});

export const setAdsQueue = assign({
  adsQueue({ adsMachines }, { items }) {
    return items.map(item => {
      const id = getID(item);

      return adsMachines[id];
    });
  }
});

export const resetAdsQueue = assign({
  adsQueue() {
    return [];
  }
});

export const removeFirstAd = assign({
  adsQueue({ adsQueue }) {
    const [, ...rest] = adsQueue;

    return [...rest];
  }
});

export const startFirstAd = pure(({ adsQueue }) => {
  const firstAd = adsQueue[0];
  return send(EVENT_NAMES.AD.START, { to: firstAd.ref });
});

export const setMiddleAdsQueue = assign({
  middleAdsQueue({ middleAdsQueue, adsMachines }, { items }) {
    const newItems = items.map(item => {
      const id = getID(item);

      return adsMachines[id];
    });

    return [...middleAdsQueue, ...newItems];
  }
});

export const resetMiddleAdsQueue = assign({
  middleAdsQueue() {
    return [];
  }
});

export const removeFirstMiddleAd = assign({
  middleAdsQueue({ middleAdsQueue }) {
    const [, ...rest] = middleAdsQueue;

    return [...rest];
  }
});

export const startFirstMiddleAd = pure(({ middleAdsQueue }) => {
  const firstAd = middleAdsQueue[0];
  return send(EVENT_NAMES.AD.START, { to: firstAd.ref });
});

export const setFailedUserPlaysRef = assign({
  failedUserPlaysRef() {
    return createFailedUserPlaysMachine();
  }
});

export const redirectToMedia = ({ selected }) => {
  if (!window.isTouch) {
    return;
  }

  const link = getLink(selected);

  if (window.location.pathname === link) {
    return;
  }

  Router.push(link);
};

export const redirectToTV = () => {
  if (!window.isTouch) {
    return;
  }

  if (window.location.pathname === '/tv') {
    return;
  }

  Router.push('/tv');
};

/**
 * now-playing-item-machine events
 */
export const startSelected = pure(context => {
  const machine = getSelectedMachine({ context });

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.START, { to: machine });
});

export const playSelected = pure(context => {
  const machine = getSelectedMachine({ context });

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE, { to: machine });
});

export const startSelectedSilent = pure(context => {
  const machine = getSelectedMachine({ context });

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.START_SILENT, { to: machine });
});

export const setMachineForNextFromUpNext = assign({
  machines({ machines, ...context }) {
    const item = getNextItemFromUpNext({ context });

    return getMachines({ machines, items: [item] });
  }
});

export const preloadNextFromUpNext = pure(({ machines, ...context }) => {
  const item = getNextItemFromUpNext({ context });
  const machine = machines[item._id];

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.PRE_LOAD, { to: machine });
});

export const setMachineFirstItemInQueue = assign({
  machines({ machines, queue }) {
    return getMachines({ machines, items: [queue[0]] });
  }
});

export const preloadNextFromQueue = pure(({ queue, machines }) => {
  const item = queue[0];
  const machine = machines[item._id];

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.PRE_LOAD, { to: machine });
});

export const sendPlayFromStart = pure(context => {
  const machine = getSelectedMachine({ context });

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START, { to: machine });
});

export const sendDestroyToSelected = pure(context => {
  const machine = getSelectedMachine({ context });

  return send(EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY, { to: machine });
});

export const storeNowPayingState = pure(context => {
  const { stateStoreRef, selected, upnext, queue, repeat, metadata, volume, skipLimit } = context;

  const value = {
    selected,
    upnext,
    queue,
    repeat,
    metadata,
    volume,
    skipLimit
  };

  return [
    send(
      {
        type: EVENT_NAMES.NOW_PLAYING.STORE.SAVE_STATE,
        value
      },
      { to: stateStoreRef }
    )
  ];
});

export const sendDestroyInterstitialAds = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return [send({ type: AUTH_EVENT_NAMES.DESTROY_INTERSTITIAL_ADS }, { to: authService })];
});

export const sendInitialInterstitialAds = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return [send({ type: AUTH_EVENT_NAMES.INITIAL_INTERSTITIAL_ADS }, { to: authService })];
});

export const toggleNotifyUserToSubscribeBecauseReachedSkipLimits = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return [
    send(
      {
        type: AUTH_EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_BECAUSE_REACHED_SKIP_LIMITS,
        value: true
      },
      { to: authService }
    )
  ];
});

export const toggleNotifyUserToSubscribeToViewLyrics = pure(() => {
  const service = getAppService();
  const authService = getAuthService(service.state);

  return [
    send(
      {
        type: AUTH_EVENT_NAMES.TOGGLE_NOTIFYING_USER_TO_SUBSCRIBE_TO_VIEW_LYRICS
      },
      { to: authService }
    )
  ];
});
