import { DATE } from '@constants';

export const formatDuration = time => {
  if (time) {
    const secNum = parseInt(time, 10);
    // const hours = Math.floor(secNum / 3600) % 24;
    // const minutes = Math.floor(secNum / 60) % 60;
    const minutes = Math.floor(secNum / 60);
    const seconds = secNum % 60;
    // return [hours, minutes, seconds]
    return [minutes, seconds]
      .map((value, index) => {
        // min
        if (index === 1) {
          if (value < 10) {
            return `0${value}`;
          }
        }

        return value;
      })
      .join(':');
  }

  return '0:00';
};

export const secondsToHms = (milliseconds, { showSeconds = true } = {}) => {
  const d = Number(milliseconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hr' : ' hrs') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' min' : ' mins') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' sec' : ' secs') : '';

  return [hDisplay, mDisplay, showSeconds ? sDisplay : null].filter(Boolean).join('\xa0\xa0');
};

export const getIS8601Duration = seconds => {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? `${h}H` : '';
  const mDisplay = m > 0 ? `${m}M` : '';
  const sDisplay = s > 0 ? `${s}S` : '';
  const formatted = [hDisplay, mDisplay, sDisplay].filter(Boolean).join('');

  return `PT${formatted}`;
};

export const totalDuration = data => {
  if (!Array.isArray(data)) {
    return 0;
  }
  const duration = data.reduce((acc, item) => {
    // eslint-disable-next-line no-return-assign
    return (acc += item.duration);
  }, 0);

  return secondsToHms(duration, { showSeconds: false }).trim();
};

export const formatDateTime = date => {
  const d = new Date(date);
  return `${DATE.MONTHS[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const getISOString = date => {
  const d = new Date(date);

  return d.toISOString();
};

export const getISOStringDate = date => {
  return getISOString(date).split('T')[0];
};
