import isNumber from 'lodash/isNumber';
import isFinite from 'lodash/isFinite';

import { getTitle, getSubTitle } from '@components/track/helpers';

import { getAlbumName } from '@functions/get';
import { getPercentage } from '@functions/math/percentage';

const getPosition = currentTime => {
  if (!isNumber(currentTime)) {
    return 0;
  }

  if (currentTime < 0) {
    return 0;
  }

  return currentTime;
};

const isMediaPlaying = video => {
  return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
};

export class MediaSession {
  constructor(options) {
    this.options = options;
  }

  setMetaData() {
    const metadata = {
      artist: this.artist,
      artwork: this.artworks,
      title: this.title
    };

    if (this.album) {
      metadata.album = this.album;
    }

    window.navigator.mediaSession.metadata = new window.MediaMetadata(metadata);
  }

  get album() {
    const { data } = this.options;

    return getAlbumName(data);
  }

  get artist() {
    const { data } = this.options;

    return getSubTitle(data);
  }

  get title() {
    const { data } = this.options;

    return getTitle(data);
  }

  get artworks() {
    const { data } = this.options;

    return [
      {
        sizes: '640x640',
        src: data.photo,
        type: ''
      },
      {
        sizes: '300x300',
        src: data.photo,
        type: ''
      },
      {
        sizes: '64x64',
        src: data.photo,
        type: ''
      }
    ];
  }

  setPlaybackState(isPlaying) {
    navigator.mediaSession.playbackState = isPlaying ? 'playing' : 'paused';
  }

  updatePositionState() {
    const { media, data } = this.options;

    if ('setPositionState' in navigator.mediaSession) {
      const duration = isFinite(media.duration || data.duration)
        ? media.duration || data.duration
        : 0;
      const position = getPosition(media.currentTime);

      if (duration < position) {
        this.resetPositionState();
        return;
      }

      window.navigator.mediaSession.setPositionState({
        duration,
        position,
        playbackRate: media.playbackRate
      });
    }
  }

  resetPositionState() {
    if ('setPositionState' in navigator.mediaSession) {
      window.navigator.mediaSession.setPositionState(null);
    }
  }

  initEventListeners() {
    const {
      onPlay,
      onPause,
      onStop,
      onSeekForward,
      onSeekBackward,
      onSeek,
      onPrevious,
      onNext,
      media
    } = this.options;

    if ('setActionHandler' in navigator.mediaSession) {
      navigator.mediaSession.setActionHandler('play', () => {
        // !IMPORTANT: THIS FIXED ISSUE INFINITE LOOP OF SAFARI
        if (isMediaPlaying(media)) {
          return;
        }

        onPlay();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        if (!isMediaPlaying(media)) {
          return;
        }

        onPause();
      });
      navigator.mediaSession.setActionHandler('stop', () => {
        if (!isMediaPlaying(media)) {
          return;
        }

        onStop();
      });

      navigator.mediaSession.setActionHandler('seekforward', e => {
        const skipTime = e.seekOffset;

        if (onSeekForward) {
          onSeekForward(skipTime);

          return;
        }

        this.updatePositionState();
      });

      navigator.mediaSession.setActionHandler('seekbackward', e => {
        const skipTime = e.seekOffset;

        if (onSeekBackward) {
          onSeekBackward(skipTime);

          return;
        }

        this.updatePositionState();
      });

      navigator.mediaSession.setActionHandler('seekto', e => {
        const skipTime = e.seekTime;
        const percentage = getPercentage(skipTime, media.duration);

        if (onSeek) {
          onSeek(percentage);

          return;
        }

        this.updatePositionState();
      });

      navigator.mediaSession.setActionHandler('previoustrack', onPrevious || null);

      navigator.mediaSession.setActionHandler('nexttrack', onNext || null);
    }
  }
}
