// import cx from 'classnames';

import { useEffect } from 'react';
import { Box, Text } from '@ui';

import StaticSlider from './StaticSlider';

const Slider = ({ input, afterChanged, className, ...props }) => {
  const { min, max } = props;

  useEffect(() => {
    if (max && input.value) {
      if (input.value > max) {
        input.onChange(max);
      }
    }
  }, []);

  return (
    <Box className={className}>
      <Box className="flex items-center space-x-4">
        {min ? (
          <Box>
            <Text className="text-th-foreground-max">{min}</Text>
          </Box>
        ) : null}
        <Box className="flex-1">
          <StaticSlider
            className="rj-slider p-0 smooth show-handle"
            {...props}
            {...input}
            defaultValue={input.value}
          />
        </Box>
        {max ? (
          <Box>
            <Text className="text-th-foreground-max">{max}</Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

Slider.defaultProps = {
  size: 'md'
};

export default Slider;
