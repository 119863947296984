import { isWeb } from '@functions/env';

export const EN = {
  SECTION_LIST: {
    MORE: 'SEE ALL'
  },
  SEARCH_HISTORY: 'Recent Searches'
};

export const TITLE_BASED_ON_TYPES = {
  songs: 'Songs',
  mp3s: 'Songs',
  videos: 'Videos',
  podcasts: 'Podcasts',
  playlists: 'Playlists',
  albums: 'Albums',
  artists: 'Artists',
  lyrics: 'Lyrics',
  profiles: 'Profiles',
  selfies: 'Stories',
  PLAYLISTS: {
    mp3s: {
      categories: 'Categories',
      featured: 'Music Playlists',
      myplaylists: 'My Music Playlists'
    },
    videos: {
      categories: 'Categories',
      featured: 'Video Playlists',
      myplaylists: 'My Video Playlists'
    }
  }
};

export const LINKS_BASED_ON_TYPES = {
  mp3s: 'songs'
};

export const TYPES_BASED_ON_TITLE = {
  songs: 'mp3',
  mp3s: 'mp3',
  videos: 'video',
  albums: 'album',
  podcasts: 'podcast',
  playlists: 'playlist',
  photos: 'photo',
  events: 'event',
  artists: 'artist'
};

export const SELECT_TITLE_BASED_ON_ITEM_TYPE = {
  default: 'Item',
  mp3: 'Song',
  video: 'Video',
  podcast: 'Podcast',
  album: 'Song',
  artist: 'Artist'
};

export const TITLE_BASED_ON_ITEM_TYPE = {
  default: 'Item',
  mp3: 'Song',
  video: 'Video',
  podcast: 'Podcast',
  album: 'Album',
  artist: 'Artist',
  playlist: 'Playlist',
  show: 'Show',
  event: 'Event'
};

export const PLURAL_TYPES = {
  songs: 'mp3s'
};

export const TYPES_PLURAL = {
  artist: 'artists',
  mp3: 'songs',
  video: 'videos',
  album: 'albums',
  podcast: 'podcasts',
  show: 'shows',
  playlist: 'playlists',
  photo: 'photos',
  event: 'events'
};

export const MAIN_TYPES_PLURAL = {
  mp3: 'mp3s',
  video: 'videos'
};

export const LIBRARY_VALID_TYPES = {
  mp3: true,
  video: true,
  podcast: true,
  show: true,
  album: true,
  artist: true
};

export const SPECIAL_PAGE_TITLE_BY_TYPES = {
  newreleases: 'New Releases'
};

export const CHARACTERS = {
  HEAD: isWeb() ? '-' : '·'
};

export const DESCRIPTIONS = {
  COOKIE_CONSENT: 'This website uses cookies to ensure you get the best experience on our website.',
  WAITING_FOR_EMAIL_CONFIRMATION_TITLE: "You're almost there.",
  WAITING_FOR_EMAIL_CONFIRMATION: 'Check your email now for the confirmation and login details.',
  LOGIN: 'Log in to Radio Javan',
  LOGIN_OR_CREATE_NEW_ACCOUNT:
    'To access your library of music including your downloaded songs, please log in or sign up for a new account.',
  LOGOUT:
    'When you logout, all downloaded music will be removed from the local copy of the app. Your My Music library will not change.',
  SUBSCRIBE_TODAY: 'Subscribe today and try it for free',
  DEACTIVATE_ACCOUNT:
    'Are you sure? You will have 30 days to active your account by logging in again. After that, your account will be removed permanently.',
  DEACTIVATE_ACCOUNT_SUBTITLE:
    'You will have 30 days to active your account by logging in again. After that, your account will be removed permanently.',
  DELETE_DOWNLOADED_MUSIC:
    'Are you sure you want to remove all downloaded mp3s, podcasts, and videos?',
  REMOVE_ALL_FROM_MY_MUSIC:
    'Are you sure you want to delete everything from My Music library? This will also remove all your downloaded music that has been downloaded on this device.',
  REMOVE_ALL_FROM_MY_MUSIC_SUBTITLE:
    'Delete everything from My Music (this will also remove all your downloaded music)',
  REMOVE_SYNCED_MUSICS: 'Are you sure you want to remove all downloaded mp3s, podcasts and videos?',

  REMOVE_SYNCED_MUSICS_SUBTITLE: 'Delete all downloaded mp3s, podcasts and videos',

  REMOVE_CACHE_FILES: 'Are you sure you want to remove all cache file?',
  REMOVE_CACHE_FILES_SUBTITLE: 'Delete all cache files',

  REMOVE_RECENTLY_PLACED_HISTORY: 'Are you sure you want to clear your recently played history?',

  REMOVE_RECENTLY_PLACED_HISTORY_SUBTITLE: 'Clear recently played history',
  REMOVE_SEARCH_HISTORY: 'Are you sure you want to clear your search history?',
  REMOVE_SEARCH_HISTORY_SUBTITLE: 'Clear Search History',

  REMOVE_ALL_PLAYLISTS: 'Are you sure you want delete all created playlists (Music and Video)?',
  REMOVE_CACHE: 'Are you sure you want to remove all cache file?',
  VISUAL_COVERS: 'Show short video loops on songs.',
  AUDIO_STREAM_DESCRIPTION:
    'Normal streaming quality for MP3s and Podcasts is about 96kbs, and High is 256kbs.',
  AUDIO_DOWNLOAD_DESCRIPTION:
    'Normal downloaded quality for downloaded MP3s and Podcasts is about 128 kbs, and High is 256 kbs.',
  VIDEO_DOWNLOAD_DESCRIPTION:
    'Normal downloaded quality for downloaded Videos is 720p, and High is 1080p.',
  REMOVE_PLAYLIST_CONFIRMATION: 'Do you want to remove this playlist?',
  SYNCED_LYRICS_BOX:
    'When this is enabled, you will see the lyrics of the song while playing in the now playing sidebar.',
  LYRICS_IS_NOT_SYNCED: "These lyrics aren't synchronized with the track yet.",
  NO_LYRICS_FOUND: 'No lyrics found.',
  FEEDBACK: 'Help us to improve our service by reporting issues and requesting features.',
  ACCELERATION: {
    ENABLED:
      'This will disable the use of the graphics processor (GPU) in your computer, which can sometimes solve graphics issues, but can also make Radio Javan slower when redrawing the screen. \n For this setting to take effect, you need to restart Radio Javan.',
    DISABLED:
      'This will enable the use of the graphics processor (GPU) in your computer, which can make Radio Javan faster when redrawing the screen, but can also in rare cases introduce graphics issues. \n For this setting to take effect, you need to restart Radio Javan.'
  },
  DOWNLOAD_MANAGER_SUBTITLE: 'Maximum active tasks at the same time',
  DOWNLOAD_CONFIRMATION: {
    ARTIST: {
      DOWNLOAD: {
        SONGS: 'Are you sure you want to download all songs for this artist?',
        VIDEOS: 'Are you sure you want to download all videos for this artist?'
      },
      DELETE: {
        SONGS: 'Are you sure you want to delete all downloaded songs for this artist?',
        VIDEOS: 'Are you sure you want to delete all downloaded videos for this artist?'
      }
    }
  },
  OFFLINE: "Not available while you're offline.",
  AUTO_UPDATE: {
    AVAILABLE: 'Update to get latest features on Radio Javan.'
  },
  LIST: {
    NO_DATA: 'List is empty',
    NO_DOWNLOADED_ITEM: 'There is no downloaded item in list',
    NO_DOWNLOADED_ITEM_FOUND_FOR_QUERY: query => {
      return `No downloaded item found for "${query}"`;
    },
    NO_ITEM_FOUND_FOR_QUERY: query => {
      return `No result found for "${query}"`;
    }
  },
  EMPTY: {
    LIBRARY: {
      SONGS: 'Add songs to My Music and get started!',
      VIDEOS: 'Add videos to My Music and get started!',
      PODCASTS_EPISODES: 'Add podcast episodes to My Music and get started!',
      PODCASTS_SHOWS: 'Follow your favorite podcast shows!',
      ALBUMS: 'Add albums to your My Music and get started!',
      ARTISTS: 'Follow your favorite artists and get started!',
      LIKED_SONGS: 'Like some songs to get started!'
    },

    PLAYLIST: {
      MY_PLAYLIST: "Let's find something for your playlist!"
    }
  },
  REQUIRE_AUTH: {
    PLAY: {
      PLAYLIST: name => `You need to log in to listen to "${name}" playlist.`,
      ALBUM: name => `You need to log in to listen to "${name}" album.`,
      VIDEO: name => `You need to log in to watch "${name}" video.`,
      SONG: name => `You need to log in to listen to "${name}" song.`,
      PODCAST: name => `You need to log in to listen to "${name}" podcast.`,
      PODCAST_SHOW: name => `You need to log in to listen to "${name}" show.`,
      ARTIST: name => `You need to log in to listen to "${name}" songs.`,
      LIVE_STREAMING: 'You need to log in to watch RJTV.'
    },
    LIKE: {
      VIDEO: name => `You need to log in to like "${name}" video.`,
      SONG: name => `You need to log in to like to "${name}" song.`,
      PODCAST: name => `You need to log in to like to "${name}" podcast.`
    },
    FOLLOW: {
      ARTIST: name => `You need to log in to follow "${name}".`,
      PLAYLIST: name => `You need to log in to follow "${name}" playlist.`,
      PODCAST_SHOW: name => `You need to log in to follow "${name}" show.`
    },
    DOWNLOAD: {
      PLAYLIST: name => `You need to log in to download "${name}" playlist.`,
      ALBUM: name => `You need to log in to download "${name}" album.`,
      VIDEO: name => `You need to log in to watch "${name}" video.`,
      SONG: name => `You need to log in to download "${name}" song.`,
      PODCAST: name => `You need to log in to download "${name}" podcast.`,
      PODCAST_SHOW: name => `You need to log in to download "${name}" show.`,
      ARTIST: name => `You need to log in to download "${name}" songs.`
    }
  },
  PLAY_REDIRECTION: {
    SONG: 'Play this song with the Radio Javan app.',
    VIDEO: 'Watch this video with the Radio Javan app.',
    PODCAST: 'Watch this video with the Radio Javan app.',
    ALBUM: 'Play this album with the Radio Javan app.',
    ARTIST: name => `View ${name} on the Radio Javan app.`,
    PLAYLIST: `Play this playlist with the Radio Javan app.`,
    STORY: ({ username, title }) => `Watch @${username} - ${title} Story on Radio Javan app.`,
    STORY_VIDEO: title => `Watch ${title} on Radio Javan app.`,
    PODCAST_SHOW: 'Play this podcast with the Radio Javan app.',
    EVENT: title => `View Persian ${title} on the Radio Javan app.`,
    USER: name => `View ${name} on Radio Javan app.`
  }
};

export const MESSAGES = {
  AUTH: {
    RESET_PASSWORD_EMAIL_HAS_BEEN_SENT: 'Check your email to reset your password.'
  },
  AUTO_UPDATE: {
    CHECKING: 'Checking for updates...',
    AVAILABLE: 'New version of Radio Javan is available.',
    NOT_AVAILABLE: 'No updates available. You are running the latest version.'
  },
  COPY_TO_CLIPBOARD: 'Link copied to clipboard',
  NO_CONNECTION: {
    TITLE: 'No internet connection.',
    DESCRIPTION:
      "Make sure you're online to use all Radio Javan's features. you can listen to your downloaded musics."
  },
  ERROR: 'Oops. Something went wrong. Try later.',
  SUCCESS: 'Done.',
  ADDED_TO_PLAYLIST_SUCCESS: 'Added to Playlist.',
  PLAYLIST_REMOVED: 'Playlist removed.',
  PLAYLIST_UPDATED: 'Playlist successfully updated.',
  PLAYLIST_ITEM_ADDED: 'Added to Playlist.',
  PLAYLIST_ITEMS_ADDED: 'Items added to Playlist.',
  PLAYLIST_CREATED_AND_ITEM_ADDED: 'Playlist created successfully, Item added.',
  PLAYLIST_CREATED_AND_ITEMS_ADDED: 'Playlist created successfully, Items added.',
  PLAYLIST_FOLLOWED: 'Done.',
  PLAYLIST_ITEM_REMOVED: 'Item removed from Playlist.',
  CHOOSE_USERNAME_TO_SHARE_YOUR_LINK: 'Choose a username in your profile to share your link.',
  LIBRARY: {
    SAVED: {
      SONGS: 'Added to Songs.',
      LIKED_SONGS: 'Added to Liked Songs.',
      PODCASTS: 'Added to Podcasts.',
      VIDEOS: 'Added to Videos.',
      ALBUMS: 'Added to Albums.',
      ARTISTS: 'Added to Artists.'
    }
  }
};

export const MESSAGES_NAMES = {
  'reset-password-email-sent': MESSAGES.AUTH.RESET_PASSWORD_EMAIL_HAS_BEEN_SENT,
  'choose-username-to-share-your-link': MESSAGES.CHOOSE_USERNAME_TO_SHARE_YOUR_LINK,
  'checking-for-update': MESSAGES.AUTO_UPDATE.CHECKING,
  'update-is-available': MESSAGES.AUTO_UPDATE.AVAILABLE,
  'update-is-not-available': MESSAGES.AUTO_UPDATE.NOT_AVAILABLE,
  'link-copied': MESSAGES.COPY_TO_CLIPBOARD,
  'playlist-removed': MESSAGES.PLAYLIST_REMOVED,
  'playlist-updated': MESSAGES.PLAYLIST_UPDATED,
  'playlist-item-added': MESSAGES.PLAYLIST_ITEM_ADDED,
  'playlist-items-added': MESSAGES.PLAYLIST_ITEMS_ADDED,
  'playlist-created-and-item-added': MESSAGES.PLAYLIST_CREATED_AND_ITEMS_ADDED,
  'playlist-created-and-items-added': MESSAGES.PLAYLIST_CREATED_AND_ITEM_ADDED,
  'playlist-followed': MESSAGES.PLAYLIST_FOLLOWED,
  'playlist-item-removed': MESSAGES.PLAYLIST_ITEM_REMOVED,
  'library-saved-to-songs': MESSAGES.LIBRARY.SAVED.SONGS,
  'library-saved-to-liked-songs': MESSAGES.LIBRARY.SAVED.LIKED_SONGS,
  'library-saved-to-podcasts': MESSAGES.LIBRARY.SAVED.PODCASTS,
  'library-saved-to-videos': MESSAGES.LIBRARY.SAVED.VIDEOS,
  'library-saved-to-albums': MESSAGES.LIBRARY.SAVED.ALBUMS,
  'library-saved-to-artists': MESSAGES.LIBRARY.SAVED.ARTISTS
};

export const DESCRIPTION_NAMES = {
  'update-is-available': DESCRIPTIONS.AUTO_UPDATE.AVAILABLE
};
