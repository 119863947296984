export const DATE = {
  MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
};

export const EMAIL_CONTENTS = {
  RJ_DESKTOP_FEEDBACK_SUBJECT: () => encodeURIComponent('Radio Javan Desktop App Feedback')
};

export const REGEXP = {
  ENGLISH: /^[A-Za-z0-9]*$/,
  PERSIAN: /[آ-ی]|([a-zA-Z])/
};

export const DESCRIPTIONS = {};

export const MESSAGES = {};

export const ROOTS = {
  HEADER_PORTAL: 'header-portal',
  MODAL_PORTAL: '__next'
};

export const APP_SETTINGS = {
  LEFT_SIDE_BAR_POSITION: 'left-side-bar-position',
  RIGHT_SIDE_BAR_POSITION: 'right-side-bar-position',
  DISK_METRICS: {
    DISK_SIZE: 'disk-metrics__disk-size',
    DOWNLOADED_DIRECTORY_SIZE: 'disk-metrics__downloaded-directory-size',
    CACHE: 'disk-metrics__cache-files',
    QUOTA: 'disk-metrics__browser-quota'
  },
  DOWNLOAD_MANAGER: {
    CONCURRENCY: 'download-manager__concurrency'
  },
  PLAYBACK: {
    AUDIO_QUALITY: 'playback__audio-quality',
    AUDIO_DOWNLOAD_QUALITY: 'playback__audio-download-quality',
    VIDEO_DOWNLOAD_QUALITY: 'playback__video-download-quality',
    VISUAL_COVERS: 'playback__visual-covers'
  },

  NOW_PLAYING: {
    STYLE: 'now-playing__style',
    REMAINING_TIME: 'now-playing__remaining-time',
    LAST_PLAYED_TIME: 'now-playing__last-played-time',
    LAST_PLAYED_TIME_BEFORE_CLOSING_APP: 'now-playing__last-played-time-before-closing-app',
    THEATER_MODE: 'now-playing__theater-mode-video-page',
    FAILED_USER_PLAYS: 'now-playing__failed-user-plays',
    SYNCED_LYRICS_BOX: 'now-playing__synced-lyrics-box'
  },

  SIDEBAR: {
    PLAYLISTS_IMAGES: 'sidebar__playlists-show-images',
    PLAYLISTS_SORT: 'sidebar__playlists-sort',
    MENU_COLLAPSE: {
      LIBRARY: 'sidebar__MENU_COLLAPSE__library',
      MY_PLAYLISTS: 'sidebar__MENU_COLLAPSE__my-playlists'
    }
  },

  NATIVE: {
    OPEN_AT_LOGIN: 'open-at-login',
    KEEP_WINDOW_STATE: 'keep-window-state',
    HARDWARE_ACCELERATION: 'hardware-acceleration'
  },
  LIST: {
    FORM_VALUES: 'list__form-values'
  },

  SEARCH: {
    HISTORY: 'search__history',
    // analytics for search
    FAILED_SEARCH_HIT: 'search__failed-search-hit'
  },

  PLAYLIST: {
    ADD_TO_TOP_OF_PLAYLIST: 'playlist__add-to-top-of-playlist'
  },

  COOKIE_CONSENT: 'cookie_consent'
};

export const DATA = {
  EMPTY_ARRAY: [],
  EMPTY_OBJECT: {}
};
