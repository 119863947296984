import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/drag-drop';
// import { isDraggable } from '@functions/is';
import isEqual from 'react-fast-compare';

import { createMachine } from 'xstate';

import * as actions from './actions';

export const dragDropMachine = createMachine(
  {
    id: MACHINE_NAMES.DRAG_DROP,
    context: {
      draggingContext: null,
      draggingOverContext: null
    },
    on: {},
    initial: STATES.IDLE,
    states: {
      [STATES.IDLE]: {
        entry: [
          'resetDraggingContext',
          'resetDraggingOverContext',
          'setDraggingContextToWindow',
          'setDraggingOverContextToWindow'
        ],
        on: {
          [EVENT_NAMES.DRAG_START]: [
            {
              target: STATES.DRAGGING,
              actions: ['setDraggingContext', 'setDraggingContextToWindow']
            }
          ]
        }
      },
      [STATES.DRAGGING]: {
        exit: [
          'resetDraggingContext',
          'resetDraggingOverContext',
          'setDraggingContextToWindow',
          'setDraggingOverContextToWindow'
        ],
        on: {
          [EVENT_NAMES.DRAG_END]: {
            target: STATES.IDLE
          },
          [EVENT_NAMES.DRAG_OVER]: [
            {
              actions: ['setDraggingOverContext', 'setDraggingOverContextToWindow'],
              cond: ({ draggingOverContext }, { value }) => {
                const { data, index } = value;

                return !isEqual(draggingOverContext, { data, index });
              }
            }
          ],
          [EVENT_NAMES.DRAG_LEAVE]: {
            actions: ['resetDraggingOverContext']
          },
          [EVENT_NAMES.DRAG_ENTER]: {},
          [EVENT_NAMES.DROP]: {
            target: STATES.IDLE
          }
        }
      }
    }
  },
  {
    actions,
    guards: {}
  }
);
