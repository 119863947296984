import { EVENT_NAMES } from '@constants/machines/notification';
import { MESSAGES, MESSAGES_NAMES, DESCRIPTION_NAMES } from '@constants/app';

import { getNotificationService } from '@services/notification-service';

const add = value => {
  const service = getNotificationService();

  const { messageName, descriptionName, isNetworkError, timeout, ...rest } = value;
  const { type, title, description } = rest;
  const data = {
    position: 'top-center',
    // eslint-disable-next-line no-nested-ternary
    timeout: timeout === -1 ? undefined : type === 'error' ? 6_000 : 3_000,
    ...rest
  };

  if (!title) {
    if (type === 'error') {
      if (isNetworkError) {
        data.title = MESSAGES.NO_CONNECTION.TITLE;
      } else {
        data.title = MESSAGES.ERROR;
      }
    } else if (type === 'success') {
      data.title = MESSAGES.SUCCESS;
    }

    if (messageName && MESSAGES_NAMES[messageName]) {
      data.title = MESSAGES_NAMES[messageName];
    }
  }

  if (!description) {
    if (descriptionName && DESCRIPTION_NAMES[descriptionName]) {
      data.description = DESCRIPTION_NAMES[descriptionName];
    }
  }

  service.send(EVENT_NAMES.NOTIFICATION.ADD, { value: data });
};

const remove = value => {
  const service = getNotificationService();

  service.send(EVENT_NAMES.NOTIFICATION.REMOVE, { value });
};

const removeAll = () => {
  const service = getNotificationService();

  service.send(EVENT_NAMES.NOTIFICATION.REMOVE_ALL);
};

export const notification = {
  add,
  remove,
  removeAll,
  service: () => getNotificationService()
};
