export const get = (key, defaultValue = {}) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return defaultValue;

    // for none object values
    // try to parse it
    try {
      return JSON.parse(serializedState);
    } catch (e) {
      // means it's not object, so we'll return the string or number or any type
      // doesn't need to capture errors here.
      return serializedState;
    }
  } catch (err) {
    // Sentry.captureException(err);

    return defaultValue;
  }
};

export const set = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Sentry.captureException(err);
  }
};

export const remove = key => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // Sentry.captureException(err);
  }
};

export const clearStorage = () => {
  try {
    localStorage.clear();
  } catch (err) {
    // Sentry.captureException(err);
  }
};
