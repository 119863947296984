import { LOGIN_URL, SIGNUP_URL, FORGOT_PASS_URL } from '@constants/urls';
import { getWhiteListEmails } from '@functions/server/get';

export function isAuthenticationRequest({ url }) {
  const currentURL = url.replaceAll('/api/p', '');

  return [LOGIN_URL, SIGNUP_URL, FORGOT_PASS_URL].includes(currentURL);
}

export function isWhiteListEmail({ email }) {
  return getWhiteListEmails().includes(email);
}
