/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';

import { Box, Text } from '@ui';

const VARIANT_CLASSNAMES = {
  rj: ({ checked }) => {
    if (checked) {
      return [
        [
          'bg-roof-terracotta-500 ring-opacity-50 text-white-900',
          'hover:bg-roof-terracotta-400 active:bg-roof-terracotta-500'
        ],
        ['bg-white-900']
      ];
    }
    return [
      [
        'bg-th-foreground-low',
        'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
      ],
      ['bg-white-900', 'hover:scale-105 active:scale-75']
    ];
  }
};
const SIZES = {
  xs: 'w-6 h-3',
  sm: 'w-8 h-4',
  md: 'w-10 h-5',
  lg: 'w-12 h-6'
};

const Switch = ({
  checked,
  size,
  variant,
  name,
  isInvalid,
  label,
  placeholder,
  prefix,
  as,
  ...rest
}) => {
  return (
    <Box
      as={as}
      htmlFor={name}
      className={cx('rj-switch flex items-center justify-between cursor-pointer', {
        checked,
        invalid: isInvalid
      })}
    >
      <Box>
        <Box className="flex space-x-4 items-center">
          {prefix ? <Box>{prefix}</Box> : null}
          <Box>
            <Text className="text-xs xl:text-sm text-th-foreground-max">{label}</Text>
            <Text className="text-th-foreground-max text-xs">{placeholder}</Text>
          </Box>
        </Box>
      </Box>
      <input
        className="switch appearance-none invisible"
        type="checkbox"
        checked={checked}
        id={name}
        name={name}
        {...rest}
      />
      <Box>
        <Box
          className={cx(
            'relative rounded-full transition-colors duration-300 appearance-none',
            SIZES[size],
            VARIANT_CLASSNAMES[variant]({ checked })[0]
          )}
        >
          <Box
            className={cx(
              'w-1/2 h-full absolute lef-0 transition-all duration-300 rounded-full transform-gpu',
              VARIANT_CLASSNAMES[variant]({ checked })[1],
              {
                'translate-x-full': checked,
                'translate-x-0': !checked
              }
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

Switch.defaultProps = {
  size: 'md',
  variant: 'rj',
  as: 'label'
};

export default Switch;
