import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/notification';

import { createMachine, sendParent } from 'xstate';

// import * as actions from './actions';

/**
 *
 * @param {*} initialContext
 * {
 *    title,
 *    description,
 *    id,
 *    timeout,
 *    position
 *    closable
 *    icon
 *  }
 */

export const notificationItemMachine = initialContext => {
  const { timeout } = initialContext;

  const INITIAL_ACTIVE_STATE = timeout
    ? STATES.NOTIFICATION_ITEM.ACTIVE.DESTROYING
    : STATES.NOTIFICATION_ITEM.ACTIVE.IDLE;

  return createMachine(
    {
      id: MACHINE_NAMES.NOTIFICATION_ITEM,
      context: initialContext,
      initial: STATES.NOTIFICATION_ITEM.ACTIVE.INDEX,
      states: {
        [STATES.NOTIFICATION_ITEM.ACTIVE.INDEX]: {
          exit: [
            sendParent(context => {
              return {
                type: EVENT_NAMES.NOTIFICATION.REMOVE,
                item: context
              };
            })
          ],
          on: {
            [EVENT_NAMES.NOTIFICATION_ITEM.REMOVE]: {
              target: STATES.NOTIFICATION_ITEM.DESTROYED
            }
          },

          initial: INITIAL_ACTIVE_STATE,
          states: {
            [STATES.NOTIFICATION_ITEM.ACTIVE.IDLE]: {},
            [STATES.NOTIFICATION_ITEM.ACTIVE.DESTROYING]: {
              after: {
                [timeout]: {
                  target: `#${MACHINE_NAMES.NOTIFICATION_ITEM}.${STATES.NOTIFICATION_ITEM.DESTROYED}`
                }
              }
            }
          }
        },
        [STATES.NOTIFICATION_ITEM.DESTROYED]: {}
      }
    },
    {
      actions: {}
    }
  );
};
