import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';

import { DATA } from '@constants';

import { getAppService } from '@state/services/app-service';
import { getLibraryService, getNowPlayingService } from '@machines/app/selectors';
import { getRaw } from '@machines/library/selectors';
import { getRecentlyPlayed } from '@machines/now-playing/selectors';

import { isMyPlaylist, isNotMyPlaylist } from '@functions/is';
import { getPlaylistsOrderedByRecentlyPlayed } from '@functions/get';
import { isSynced } from '@machines/library/machines/item/matchers';
import { getID } from '@machines/library/data-helpers';

const filterArray = (data, key, query) => {
  if (!Array.isArray(data)) {
    return DATA.EMPTY_ARRAY;
  }
  return data.filter(item => {
    return item?.[key]?.toLowerCase().includes(query.toLowerCase());
  });
};

export const sort = (data, type) => {
  if (!type) {
    return data;
  }

  if (type === 'playlist_type') {
    return flatten([data.filter(isMyPlaylist), data.filter(isNotMyPlaylist)]);
  }

  if (type === 'recently_played_playlists') {
    const service = getAppService();
    const nowPlayingService = getNowPlayingService(service.state);
    const recentlyPlayed = getRecentlyPlayed(nowPlayingService.state);

    if (!recentlyPlayed?.length) {
      return data;
    }

    return getPlaylistsOrderedByRecentlyPlayed({
      items: data,
      recentlyPlayed
    });
  }

  if (type === 'plays') {
    return sortBy(data, v => {
      if (!v.plays) {
        return false;
      }
      return Number(v.plays.replace(/,/g, ''));
    }).reverse();
  }

  if (type === 'updated_at' || type === 'created_at') {
    return orderBy(data, [type], ['desc']);
  }

  return sortBy(data, [type], ['asc']);
};

const filterDownloaded = data => {
  const service = getAppService();
  const libraryService = getLibraryService(service.state);
  const raw = getRaw(libraryService.state);

  return data.filter(item => {
    if (item.ref) {
      return isSynced(item.ref.state);
    }

    const id = getID(item);
    const itemInLibrary = raw?.[id];

    if (!itemInLibrary) {
      return false;
    }

    return isSynced(itemInLibrary.ref.state);
  });
};

export const filter = (data, params) => {
  let items = Array.isArray(data) ? data : DATA.EMPTY_ARRAY;

  if (params?.query) {
    const key = params.searchKey || 'title';
    items = filterArray(items, key, params?.query);
  }

  if (params?.downloaded) {
    items = filterDownloaded(items, params.downloaded);
  }

  if (params?.sort) {
    items = sort(items, params.sort);
  }

  return items;
};
