import React, { useContext, useRef } from 'react';
import { getGridService } from '@services/grid-service';

export const GridContext = React.createContext();

export const GridConfigProvider = ({ children }) => {
  const service = useRef(getGridService());

  return (
    <GridContext.Provider
      value={{
        service: service.current
      }}
    >
      {children}
    </GridContext.Provider>
  );
};

export const useGridConfig = () => useContext(GridContext);
