import { loadLibrary } from '@api/library';

import { normalizeServerItem } from './data-helpers';

export const loadLibraryData = async ({ updatedAt }) => {
  let items = {};
  try {
    const data = await loadLibrary(updatedAt);
    if (data) {
      items = data;
      if (items.deleted) {
        items.deleted = data.deleted.map(normalizeServerItem);
      }
      if (items.items) {
        items.items = data.items.map(normalizeServerItem);
      }
    }
  } catch (e) {
    console.log(e);
  }

  return items;
};
