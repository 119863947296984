/* eslint-disable react/no-unescaped-entities */
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/react';

import { EVENTS } from '@constants/analytics';

import { ga } from '@helpers/ga4';

import { PureComponent } from 'react';

const ErrorBoundaryFallback = dynamic(() => import('./ErrorBoundaryFallback'), { ssr: false });

class ErrorBoundaryComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
  }

  static getDerivedStateFromError() {
    return {
      error: true
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    ga().event({
      category: EVENTS.ERROR.CATEGORY,
      action: EVENTS.ERROR.ACTIONS.SOMETHING_WENT_WRONG
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorBoundaryFallback />;
    }

    return children;
  }
}

const ErrorBoundary = props => {
  return <ErrorBoundaryComponent {...props} />;
};

export default ErrorBoundary;
