import LoadingIcon from '@ui/icons/LoadingIos';

import React from 'react';

import cx from 'classnames';

import Box from './Box';
import Icon from './Icon';

const DEFAULT_CLASS_NAMES = [
  'inline-flex items-center align-middle select-none relative whitespace-nowrap w-auto leading-tight transition-all duration-200 outline-none appearance-none touch-manipulation font-medium',
  'focus:outline-none hover:text-th-primary-foreground focus:text-th-primary-foreground active:text-th-primary-foreground'
];

const VARIANT_CLASS_NAMES = {
  solid: [
    'bg-th-foreground-min',
    'focus:ring-th-highlight hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
  ],
  outline: [
    'bg-transparent border-th-foreground-mid border-p-1',
    'focus:ring-th-highlight hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid focus:border-th-primary-foreground'
  ],
  'outline-ghost': [
    'bg-transparent border-th-foreground-mid border-p-1',
    'focus:ring-th-highlight hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid focus:border-th-primary-foreground'
  ],
  ghost: [
    'bg-transparent',
    'focus:ring-th-highlight hover:bg-th-foreground-min focus:bg-th-foreground-mid active:bg-th-foreground-low'
  ],
  'rj-ghost': [
    'bg-transparent',
    'focus:bg-roof-terracotta-500 hover:bg-roof-terracotta-500 focus:bg-roof-terracotta-500 active:bg-roof-terracotta-500'
  ],
  success: [
    'bg-green-800',
    'focus:ring-green-900 hover:bg-green-700 focus:bg-green-700 active:bg-green-600'
  ],
  danger: ['bg-red-800', 'focus:ring-red-900 hover:bg-red-700 focus:bg-red-700 active:bg-red-600'],
  'outline-danger': [
    'bg-transparent text-red-600 border-red-600 border-p-1',
    'focus:ring-red-900 hover:bg-red-700 hover:border-red-700 focus:bg-red-700 active:bg-red-600'
  ],
  'real-ghost': [],
  'real-ghost-icon': ({ disabled }) => {
    if (disabled) {
      return ['!text-th-foreground-high'];
    }

    return [
      'text-th-foreground-max',
      'hover:text-th-primary-foreground active:text-th-primary-foreground'
    ];
  },
  rj: [
    'bg-roof-terracotta-500 ring-opacity-50 text-white-900',
    'focus:ring-th-highlight hover:bg-roof-terracotta-400 active:bg-roof-terracotta-500'
  ],
  info: [
    'bg-science-blue-500 ring-opacity-50 text-white-900',
    'hover:bg-science-blue-600',
    'focus:bg-science-blue-500'
  ],
  'solid-primary': [
    'bg-th-primary-foreground ring-opacity-50 text-th-inverted-foreground',
    'hover:text-th-inverted-foreground focus:ring-th-highlight'
  ],
  inverted: [
    'bg-th-inverted-background ring-opacity-50 text-th-inverted-foreground shadow-2xl',
    'hover:text-th-inverted-foreground focus:text-th-inverted-foreground active:text-th-inverted-foreground'
  ]
};

const CLASS_NAMES_BY_SIZE = {
  xxs: 'text-xs h-4 min-w-4 px-2',
  xs: 'text-xs h-6 min-w-6 px-2',
  sm: 'text-sm h-8 min-w-8 px-3',
  md: 'text-sm h-10 min-w-10 px-4',
  lg: 'text-sm h-12 min-w-12 px-6',
  xl: 'text-lg h-14 min-w-14 px-8',
  'sm-wide-xs': 'text-sm h-8 min-w-36 px-5',
  'sm-wide': 'text-sm h-8 min-w-40 px-7',
  'sm-wide-small': 'text-sm h-8 min-w-32 px-5'
};

const RING_CLASS_NAMES = {
  xxs: 'focus:ring-p-2',
  xs: 'focus:ring-p-2',
  sm: 'focus:ring-p-2',
  md: 'focus:ring-p-2',
  lg: 'focus:ring-p-2',
  xl: 'focus:ring-p-2',
  'sm-wide-xs': 'focus:ring-p-2',
  'sm-wide': 'focus:ring-p-2',
  'sm-wide-small': 'focus:ring-p-2'
};

const LOADING_SIZE = {
  xxs: { width: '0.8em', height: '0.8em' },
  xs: { width: '1.1em', height: '1.1em' },
  sm: { width: '1.3em', height: '1.3em' },
  md: { width: '1.4em', height: '1.4em' },
  lg: { width: '1.8em', height: '1.8em' },
  xl: { width: '1.9em', height: '1.9em' },
  'sm-wide-xs': { width: '1.3em', height: '1.3em' },
  'sm-wide': { width: '1.3em', height: '1.3em' },
  'sm-wide-small': { width: '1.3em', height: '1.3em' }
};

const LEFT_ICON_CLASS_NAMES = 'inline-flex self-center mr-2';
const RIGHT_ICON_CLASS_NAMES = 'inline-flex self-center ml-2';

const Button = ({
  className,
  variant,
  justify,
  ring,
  leftIcon,
  size,
  rightIcon,
  children,
  // IS CONDITIONS
  isRound,
  noRound,
  isLoading,
  disabled,
  ...props
}) => {
  const selectedVariant =
    typeof VARIANT_CLASS_NAMES[variant] === 'function'
      ? VARIANT_CLASS_NAMES[variant]({ disabled })
      : VARIANT_CLASS_NAMES[variant];

  const classNames = cx(
    DEFAULT_CLASS_NAMES,
    CLASS_NAMES_BY_SIZE[size],
    selectedVariant,
    className,
    {
      'rounded-full': isRound,
      'rounded-md': !isRound && !noRound,
      'justify-center': justify === 'center',
      'justify-left': justify === 'left',
      'cursor-not-allowed': disabled,
      [RING_CLASS_NAMES[size]]: ring && variant !== 'real-ghost',
      'cursor-progress': isLoading
    }
  );

  return (
    <Box className={classNames} disabled={disabled || isLoading} {...props}>
      {isLoading ? (
        <Icon>
          <LoadingIcon {...LOADING_SIZE[size]} />
        </Icon>
      ) : (
        <>
          {leftIcon && (
            <Box as="span" className={LEFT_ICON_CLASS_NAMES}>
              <Icon>{leftIcon}</Icon>
            </Box>
          )}
          {children}
          {rightIcon && (
            <Box as="span" className={RIGHT_ICON_CLASS_NAMES}>
              <Icon>{rightIcon}</Icon>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

Button.defaultProps = {
  as: 'button',
  size: 'md',
  variant: 'solid',
  justify: 'center',
  leftIcon: null,
  rightIcon: null,
  isRound: true,
  ring: false,
  noRound: false
};

export default Button;
