class LibraryNativeBlobStorage {
  add(params, id) {
    return new Promise((resolve, reject) => {
      window.native.sendCommand('data:encryption:encrypt', params, id);

      const subscribers = [];
      const unsubscribe = () => subscribers.map(fn => fn());

      subscribers[0] = window.native.registerCommand(`data:encryption:encrypt:done:${id}`, () => {
        resolve();
        unsubscribe();
      });

      subscribers[1] = window.native.registerCommand(`data:encryption:encrypt:error:${id}`, e => {
        reject(e);
        unsubscribe();
      });
    });
  }

  find(id) {
    return new Promise((resolve, reject) => {
      window.native.sendCommand('data:encryption:decrypt', id);

      const subscribers = [];
      const unsubscribe = () => subscribers.map(fn => fn());

      subscribers[0] = window.native.registerCommand(
        `data:encryption:decrypt:done:${id}`,
        result => {
          resolve(result);
          unsubscribe();
        }
      );

      subscribers[1] = window.native.registerCommand(`data:encryption:decrypt:error:${id}`, e => {
        reject(e);
        unsubscribe();
      });
    });
  }

  has(id) {
    return new Promise((resolve, reject) => {
      window.native.sendCommand('data:encryption:has', id);

      const subscribers = [];
      const unsubscribe = () => subscribers.map(fn => fn());

      subscribers[0] = window.native.registerCommand(`data:encryption:has:done:${id}`, () => {
        resolve();
        unsubscribe();
      });

      subscribers[1] = window.native.registerCommand(`data:encryption:has:error:${id}`, e => {
        reject(e);
        unsubscribe();
      });
    });
  }

  remove(_, id) {
    window.native.sendCommand('data:encryption:remove', id);
    return Promise.resolve();
  }

  clear() {
    window.native.sendCommand('data:encryption:destroy');
    return Promise.resolve();
  }

  destroy() {
    window.native.sendCommand('data:encryption:destroy');
    return Promise.resolve();
  }
}

let __singletonInstance;
export const getLibraryBlobStorage = () => {
  if (!__singletonInstance) {
    __singletonInstance = new LibraryNativeBlobStorage();
  }
  return __singletonInstance;
};
