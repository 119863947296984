import { spawn } from 'xstate';

import { realTypeIs } from '@helpers/type-is';
import { normalizeClientItem } from '@machines/library/data-helpers';

import { createItemMachine } from '@machines/library/machines';

const machines = {
  default: createItemMachine
};

export const createItemWithMachine = ({ item }) => {
  const data = normalizeClientItem(item);

  const params = {
    data
  };

  const type = realTypeIs(item);
  const machine = machines[type] || machines.default;

  const ref = spawn(machine(params));
  // .onTransition((...args) => {
  //   console.log(`ITEM MACHINE:`, ...args);
  // });

  return {
    ...data,
    ref
  };
};
