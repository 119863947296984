import { MACHINE_NAMES } from '@constants/machines/library';

import { spawn, assign } from 'xstate';

import { fetcher } from './callbacks/fetcher';

export const updateProgress = assign({
  progress(_, event) {
    return event.data.percentage;
  }
});

export const setDownloaderRef = assign({
  downloaderRef(context) {
    return spawn(fetcher(context), MACHINE_NAMES.FETCHER);
  }
});
