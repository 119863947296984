import cx from 'classnames';

import { useMemo } from 'react';
import { useIsTouch } from '@hooks';

import { Box, Heading, Text, Icon } from '@ui';

import LoadingIcon from '@ui/icons/LoadingIos';

const LoadingState = ({
  className,
  title,
  description,
  children,
  icon,
  iconClassName,
  isFullscreen
}) => {
  const isTouch = useIsTouch();

  return useMemo(() => {
    return (
      <Box
        className={cx(
          'flex flex-col items-center justify-center flex-1 z-10 bg-th-primary-background',
          className,
          {
            'absolute inset-0 space-y-2': isTouch ? false : isFullscreen
          }
        )}
      >
        {icon || (
          <Icon>
            <LoadingIcon className={iconClassName} />
          </Icon>
        )}
        {title && (
          <Heading as="h4" className="text-3xl text-th-foreground-max">
            {title}
          </Heading>
        )}
        {description && <Text className="text-th-foreground-max">{description}</Text>}
        {children}
      </Box>
    );
  }, [children]);
};

LoadingState.defaultProps = {
  iconClassName: 'w-8 h-8 md:w-10 md:h-10',
  isFullscreen: true
};

export default LoadingState;
