import platform from 'platform';

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const isTest = () => {
  return process.env.STAGING;
};

export const isWeb = () => {
  if (process.browser) {
    return !window?.native?.isNative;
  }

  return process.env.TARGET === 'web';
};

export const isNative = () => {
  if (process.browser) {
    return window?.native?.isNative === true;
  }

  return !isWeb();
};

export const isMacOS = () => {
  const os = platform.os.toString();

  return os.toLowerCase().includes('os x');
};

export const isWindows = () => {
  const os = platform.os.toString();

  return os.toLowerCase().includes('windows');
};

export const isNativeWindows = () => {
  if (!isNative()) {
    return false;
  }

  return isWindows();
};

export const isiOS = () => {
  return platform.os.family?.toLowerCase() === 'ios';
};

export function isAndroid() {
  return platform.os.family?.toLowerCase() === 'android';
}
