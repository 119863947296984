import cx from 'classnames';

import React from 'react';

import Button from './Button';

const IconButton = ({ children, icon, className, ...props }) => {
  return (
    <Button className={cx('pl-0 pr-0', className)} {...props}>
      {React.cloneElement(children || icon, {
        className: cx('flex-shrink-0 align-middle fill-current', children?.props?.className)
      })}
    </Button>
  );
};

IconButton.defaultProps = {
  isRound: true
};

export default IconButton;
