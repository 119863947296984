import CloseIcon from '@public/vectors/close.svg';

import cx from 'classnames';

import { useEffect } from 'react';
import { useUpdateEffect } from '@hooks';

import { Text, Box, IF, IconButton } from '@ui';

const DEFAULT_CLASSNAMES =
  'appearance-none w-full bg-transparent outline-none rounded-md transition-all duration-100 border-p-1 border-th-foreground-mid placeholder-th-foreground-high';
const DEFAULT_VARIANT_CLASSNAMES =
  'input focus:outline-none focus:border-th-highlight ring-opacity-5';
const CLASSNAMES_BY_SIZE = {
  xs: 'text-xs p-2 focus:ring-p-1 ',
  sm: 'text-sm p-3 focus:ring-p-1 ',
  md: 'text-md p-4 focus:ring-p-1 ',
  lg: 'text-lg p-4 focus:ring-p-2 '
};

const Textarea = ({
  input,
  meta: { error, touched },
  prefix,
  postfix,
  className,
  size,
  wrapperProps,
  label,
  labelProps,
  showError,
  prefixProps,
  afterChanged,
  close,
  onCloseClicked = () => input.onChange(undefined),
  autoFocus,
  focusTimeout,
  cleanButtonProps,
  ...props
}) => {
  useEffect(() => {
    if (autoFocus && input.name) {
      setTimeout(() => {
        document.getElementById(input.name)?.focus();
      }, focusTimeout);
    }
  }, []);

  useUpdateEffect(() => {
    if (afterChanged) {
      afterChanged(input.value);
    }
  }, [input.value]);

  return (
    <Box className={cx('relative w-full', wrapperProps?.className)}>
      <IF when={label}>
        <Box className="mb-1">
          <label
            className={cx(
              'text-xs xl:text-sm w-full text-th-foreground-max',
              labelProps?.className
            )}
            htmlFor={input.name}
          >
            {label}
          </label>
        </Box>
      </IF>
      <Box className="flex items-center relative">
        <IF when={prefix}>
          <Box
            className="flex items-center justify-content absolute left-0 top-0 bottom-0 px-3 pointer-events-none"
            {...prefixProps}
          >
            {prefix}
          </Box>
        </IF>
        <textarea
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          id={input.name}
          className={cx(
            DEFAULT_CLASSNAMES,
            DEFAULT_VARIANT_CLASSNAMES,
            CLASSNAMES_BY_SIZE[size],
            className,
            {
              invalid: error && touched
            }
          )}
          {...props}
          {...input}
        >
          {input.value}
        </textarea>
        <IF when={postfix}>{postfix}</IF>
        <IF when={close && input.value}>
          <Box className="flex items-center justify-center absolute top-0 right-0 bottom-0 h-full px-3">
            <IconButton size="xs" onClick={onCloseClicked} {...cleanButtonProps}>
              <CloseIcon width="0.6rem" height="0.6rem" />
            </IconButton>
          </Box>
        </IF>
        <IF when={error && touched && showError}>
          <Box className="right-4 top-0">
            {/* <Icon name="warning" className="text-red-900 text-md" /> */}
          </Box>
        </IF>
      </Box>
      <IF when={error && touched && showError}>
        <Box className="absolute top-full left-0">
          <Text className="text-xs text-red-600">{error}</Text>
        </Box>
      </IF>
    </Box>
  );
};

Textarea.defaultProps = {
  showError: true,
  wrapperProps: {},
  labelProps: {},
  prefixProps: {},
  cleanButtonProps: {},
  focusTimeout: 0,
  size: 'md'
};

export default Textarea;
