import { useEffect, useMemo } from 'react';

import StaticCheckbox from './StaticCheckbox';

const Checkbox = ({ input, meta: { error, touched }, afterChanged, className, ...props }) => {
  useEffect(() => {
    if (afterChanged) {
      afterChanged(input.checked);
    }
  }, [afterChanged, input.checked]);

  return useMemo(() => {
    return (
      <StaticCheckbox
        {...props}
        {...input}
        checked={input.checked}
        error={error}
        touched={touched}
        type="checkbox"
      />
    );
  }, [input.checked, input.name]);
};

Checkbox.defaultProps = {};

export default Checkbox;
