import { EVENT_NAMES } from '@constants/machines/drag-drop';
import { getID } from '@functions/get';

import { useCallback, useMemo, useState } from 'react';
import { useDragDrop } from '@contexts';
import { usePartial, useMatchers } from '@state/hooks';

import { getDraggingContext, getDraggingOverContext } from '@machines/app/drag-drop/selectors';

export const useDragDropRef = () => {
  const { service } = useDragDrop();

  return service;
};

export const useDragDropContext = selector => {
  const ref = useDragDropRef();
  const value = usePartial(ref, selector);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useDragDropMatch = matcher => {
  const ref = useDragDropRef();

  const value = useMatchers(ref, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useDrag = ({ data, index, listId }) => {
  const ref = useDragDropRef();

  const onDragStart = useCallback(
    e => {
      const label = document.getElementById('dragging-label');

      if (label) {
        e.dataTransfer.setDragImage(label, 0, 0);
      }

      const value = { data, index, listId };

      ref.send(EVENT_NAMES.DRAG_START, { value });
    },
    [ref, data, index, listId]
  );

  const onDragEnd = useCallback(() => {
    ref.send(EVENT_NAMES.DRAG_END);
  }, [ref]);

  return useMemo(() => {
    return {
      onDragStart,
      onDragEnd
    };
  }, [data, index]);
};

export const useDrop = ({
  data,
  index,
  listId,
  onDropFromOutside,
  onDragEnd: onDragEndProp,
  collect,
  collect2
}) => {
  const ref = useDragDropRef();
  const [state, setState] = useState({});

  const onDragOver = useCallback(
    e => {
      const context = window.draggingContext;

      if (!context) {
        return;
      }

      // ACCEPT STATE
      if (
        context?.data?.id === data?.id &&
        index === context?.index &&
        listId === context?.listId
      ) {
        return;
      }

      if (collect) {
        setState(collect({ e, draggingContext: context }));
      }
      if (collect2) {
        setState(collect2({ e, draggingContext: context, state }));
      }

      e.preventDefault();

      const value = { data, index, listId };

      ref.send(EVENT_NAMES.DRAG_OVER, { value });
    },
    [ref, data, index, listId]
  );

  const onDragEnter = useCallback(
    e => {
      if (collect) {
        const context = window.draggingContext;

        setState(collect({ e, draggingContext: context }));
      }

      if (collect2) {
        const context = window.draggingContext;

        setState(collect2({ e, draggingContext: context, state }));
      }

      ref.send(EVENT_NAMES.DRAG_ENTER, { e });
    },
    [ref]
  );

  const onDragLeave = useCallback(
    e => {
      if (collect) {
        const context = window.draggingContext;

        setState(collect({ e, draggingContext: context }));
      }

      if (collect2) {
        const context = window.draggingContext;

        setState(collect2({ e, draggingContext: context, state }));
      }

      ref.send(EVENT_NAMES.DRAG_LEAVE, { e });
    },
    [ref]
  );

  const onDrop = useCallback(
    e => {
      if (onDropFromOutside) {
        const destination = { data, index, listId };
        const source = window.draggingContext;

        if (listId !== source?.listId) {
          if (typeof state.isDraggingOverAfter === 'boolean') {
            destination.after = state.isDraggingOverAfter;
          }

          onDropFromOutside({ destination, source });
          ref.send(EVENT_NAMES.DROP);
          return;
        }
      }

      if (onDragEndProp) {
        const destination = { e, data, index };
        const source = window.draggingContext;

        if (typeof state.isDraggingOverAfter === 'boolean') {
          destination.after = state.isDraggingOverAfter;
        }

        onDragEndProp({ destination, source });
      }

      ref.send(EVENT_NAMES.DROP);
    },
    [data, index, listId, onDropFromOutside, state]
  );

  return useMemo(() => {
    return {
      onDragOver,
      onDragEnter,
      onDragLeave,
      onDrop,
      isDroppingAllowed: true,
      ...state
    };
  }, [state, data, index]);
};

export const useIsDragging = ({ data, index, listId }) => {
  return useMemo(() => {
    const context = window.draggingContext;

    if (!context || !context?.data) {
      return false;
    }

    const { data: currentData, index: currentIndex } = context;

    if (data.selectedId) {
      return (
        getID(currentData) === getID(data) &&
        index === currentIndex &&
        listId === context.listId &&
        currentData.selectedId === data.selectedId
      );
    }

    return (
      getID(currentData) === getID(data) && index === currentIndex && listId === context.listId
    );
  }, [data?.id, index, listId]);
};

export const useIsDraggingOver = ({ data, index, listId }) => {
  const ref = useDragDropRef();
  const context = usePartial(ref, getDraggingOverContext);

  return useMemo(() => {
    if (!context || !context?.data) {
      return false;
    }

    const { data: currentData, index: currentIndex } = context;

    return (
      getID(currentData) === getID(data) && index === currentIndex && listId === context.listId
    );
  }, [context?.index, context?.data?.type, context?.data?.id, context?.listId, index, listId]);
};

export const useDraggingContextState = ({ collect }) => {
  const ref = useDragDropRef();
  const context = usePartial(ref, getDraggingContext);

  return useMemo(() => {
    if (!context || !context?.data) {
      return false;
    }

    return collect(context);
  }, [context?.index, context?.data?.type, context?.data?.id, context?.listId]);
};
