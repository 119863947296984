import { isVideo, isPublicPlaylist } from '@functions/is';

export const hasPlaylists = ({ playlists }, event) => {
  const data = Array.isArray(event.value) ? event.value[0] : event.value;

  if (!data) {
    return false;
  }

  if (isVideo(data)) {
    return playlists.videos;
  }

  return playlists.mp3s;
};

export const hasNotAnyPlaylists = ({ data, playlists }) => {
  if (!data[0]) {
    return false;
  }

  if (isVideo(data[0])) {
    return !playlists?.videos?.length;
  }

  return !playlists?.mp3s?.length;
};

export const publicPrivateHasChanged = ({ formData, selectedPlaylist }) => {
  if (isPublicPlaylist(selectedPlaylist)) {
    if (formData.private) {
      return true;
    }
  }
  if (!isPublicPlaylist(selectedPlaylist)) {
    if (!formData.private) {
      return true;
    }
  }
  return false;
  // return formData?.private !== isPublicPlaylist(selectedPlaylist);
};

export const hasSelectedDifferentFile = ({ selectedFile }) => {
  // mean user selected file
  return typeof selectedFile?.name === 'string' && typeof selectedFile !== 'string';
};
