import { interpret } from 'xstate';

import { notificationMachine } from '@machines/app/notification/notification-machine';

let service;
export const startNotificationService = () => {
  service = interpret(notificationMachine)
    // .onTransition((...args) => {
    //   console.log('notification-service', ...args);
    // })
    .start();
};

export const stopNotificationService = () => {
  if (!service) {
    return;
  }
  service.stop();

  service = undefined;
};

export const getNotificationService = () => {
  if (service) {
    return service;
  }

  startNotificationService();

  return service;
};
