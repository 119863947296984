/* eslint-disable jsx-a11y/label-has-associated-control */
import cx from 'classnames';
import { SCALE } from '@theme/framer-variants';

import CheckIcon from '@public/vectors/check.svg';

import { useMemo } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import Box from '../Box';

const VARIANT_CLASSNAMES = {
  solid: () => {
    return [
      'bg-th-foreground-min',
      'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
    ];
  },
  outline: ({ checked }) => {
    return cx(
      'border-p-1 transition-colors duration-100',
      checked ? 'border-transparent' : 'border-th-foreground-mid',
      {
        'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid':
          !checked
      }
    );
  },
  ghost: () => {
    return [
      'bg-transparent',
      'hover:bg-th-foreground-low focus:bg-th-foreground-mid active:bg-th-foreground-mid'
    ];
  },
  success: () => {
    return ['bg-green-800', 'er:bg-green-700 focus:bg-green-700 active:bg-green-600'];
  },
  rj: () => {
    return [
      'bg-roof-terracotta-500 ring-opacity-50 text-white-900',
      'hover:bg-roof-terracotta-400 active:bg-roof-terracotta-500'
    ];
  }
};

const SIZES = {
  xs: 'w-2 h-2',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  lg: 'w-5 h-5',
  xl: 'w-6 h-6',
  '2xl': 'w-7 h-7'
};

const ICON_SIZES = {
  xs: '0.7rem',
  sm: '0.7rem',
  md: '0.7rem',
  lg: '0.7rem',
  xl: '0.9rem',
  '2xl': '1rem'
};

const LABEL_CLASSNAMES = 'cursor-pointer absolute top-0 left-0';
const LABEL_CHECKED_CLASSNAMES = 'bg-roof-terracotta-500 text-white-900';

const onDoubleClick = e => e.stopPropagation();

const StaticCheckbox = ({ className, name, size, variant, checked, isRound, ...props }) => {
  return useMemo(() => {
    return (
      <Box
        className={cx('relative rj-checkbox', SIZES[size], { checked })}
        onDoubleClick={onDoubleClick}
      >
        <label
          className={cx(
            LABEL_CLASSNAMES,
            VARIANT_CLASSNAMES[variant]({ checked }),
            SIZES[size],
            checked ? LABEL_CHECKED_CLASSNAMES : '',
            {
              'rounded-full': isRound,
              'rounded-sm': !isRound
            }
          )}
          htmlFor={name}
        >
          <input
            type="checkbox"
            className={cx('invisible hidden', className)}
            checked={checked}
            name={name}
            id={name}
            {...props}
          />
          <AnimatePresence mode="wait" initial={false}>
            {checked ? (
              <motion.div className="w-full h-full" key={checked ? 1 : 2} {...SCALE}>
                <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <CheckIcon width={ICON_SIZES[size]} height={ICON_SIZES[size]} />
                </Box>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </label>
      </Box>
    );
  }, [name, checked, size, variant, isRound]);
};

StaticCheckbox.defaultProps = {
  size: 'md',
  variant: 'outline',
  isRound: false
};

export default StaticCheckbox;
