import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import axios from 'axios';

import { getXRJHeader } from '@functions/browser';

export default function engine({ baseURL, url, headers: propHeaders = {}, ...props }) {
  const { userAgent, ...restHeaders } = propHeaders;
  const headers = { ...restHeaders };

  headers['x-rj-user-agent'] = getXRJHeader({ userAgent });

  return axios({
    baseURL,
    url,
    headers,
    withCredentials: true,
    ...props
  }).then(response => {
    const { data } = response;

    if (isObject(data)) {
      const hasError =
        isBoolean(data.success) && !data.success && (Object.keys(data).length === 1 || data.msg);

      if (hasError) {
        return Promise.reject(data, response);
      }

      return Promise.resolve(data, response);
    }

    return Promise.resolve(data, response);
  });
}
