import { useEffect } from 'react';

export const useCommand = (...args) => {
  useEffect(() => {
    const unsubscribe = window.native.registerCommand(...args);

    return () => {
      return unsubscribe();
    };
  }, [...args]);

  return null;
};

// multiple subscribers [fn, fn2, fn3, ....]
export const useMultipleCommand = (...args) => {
  useEffect(() => {
    const unsubscribe = window.native.registerMultipleCommand(...args);

    return () => {
      return unsubscribe();
    };
  }, [...args]);

  return null;
};
