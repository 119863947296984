export const EVENT_NAMES = {
  PLAYLIST: {
    BACK: 'PLAYLIST__BACK',
    CLOSE: 'PLAYLIST__CLOSE',
    ON_SUBMIT: 'PLAYLIST__ON_SUBMIT',
    SELECT_FILE: 'PLAYLIST__SELECT_FILE',
    FORM_DATA: {
      ON_CHANGE: 'PLAYLIST__FORM_DATA__ON_CHANGE'
    },
    UPDATE_PLAYLISTS: 'PLAYLIST__UPDATE_PLAYLISTS',

    ADD_TO_PLAYLIST: {
      INDEX: 'PLAYLIST__ADD_TO_PLAYLIST',

      SELECTING_PLAYLIST: {
        CREATE_NEW_PLAYLIST:
          'PLAYLIST__ADD_TO_PLAYLIST__SELECTING_PLAYLIST__CREATE_NEW_PLAYLIST'
      }
    },

    REMOVE_PLAYLIST: {
      INDEX: 'PLAYLIST__REMOVE_PLAYLIST'
    },

    EDIT_PLAYLIST: {
      INDEX: 'PLAYLIST__EDIT_PLAYLIST'
    }
  }
};

export const STATES = {
  PLAYLIST: {
    IDLE: 'IDLE',

    ADD_TO_PLAYLIST: {
      INDEX: 'ADD_TO_PLAYLIST',

      SELECTING_PLAYLIST: {
        INDEX: 'SELECTING_PLAYLIST',

        IDLE: 'IDLE',
        SUBMITTING: 'SUBMITTING'
      },

      CREATING_PLAYLIST: {
        INDEX: 'CREATING_PLAYLIST',

        EDITING: {
          INDEX: 'EDITING',

          IDLE: 'IDLE',
          SUBMITTING: {
            INDEX: 'SUBMITTING',

            DATA: 'DATA',
            ADDING_ITEMS: 'ADDING_ITEMS',
            PHOTO: 'PHOTO',
            PRIVATE_PUBLIC: 'PRIVATE_PUBLIC'
          }
        },

        CROPPING_PHOTO: {
          INDEX: 'CROPPING_PHOTO'
        }
      }
    },

    REMOVE_PLAYLIST: {
      INDEX: 'REMOVE_PLAYLIST',

      IDLE: 'IDLE',
      SUBMITTING: 'SUBMITTING'
    },

    EDIT_PLAYLIST: {
      INDEX: 'EDIT_PLAYLIST',

      IDLE: 'IDLE',
      SUBMITTING: {
        INDEX: 'SUBMITTING',

        VALIDATING: 'VALIDATING',
        RENAMING: 'RENAMING',
        PHOTO: 'PHOTO',
        PUBLIC_PRIVATE: 'PUBLIC_PRIVATE'
      },

      CROPPING_PHOTO: 'CROPPING_PHOTO'
    }
  }
};

export const MACHINE_NAMES = {
  PLAYLIST: 'PLAYLIST_MACHINE'
};
