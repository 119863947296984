import { assign, send, actions } from 'xstate';
import { getAppService } from '@state/services/app-service';
import { getAppConfigService } from '@machines/app/selectors';

const { pure } = actions;

export const setUser = assign({
  user(_, event) {
    return event.data;
  }
});

export const resetUser = assign({
  user() {
    return null;
  }
});

export const setFormData = assign({
  formData({ formData }, event) {
    return {
      ...formData,
      [event.name]: event.value
    };
  }
});

export const setRegisterDataToLogin = assign({
  formData({ formData }) {
    return {
      ...formData,
      login: {
        login_email: formData.register?.email,
        login_password: formData.register?.password
      }
    };
  }
});

export const toggleNotifyingUserToSubscribe = assign({
  notifyingUserToSubscribe({ notifyingUserToSubscribe }, { value }) {
    if (typeof value === 'undefined') {
      return !notifyingUserToSubscribe;
    }

    return value;
  }
});

export const toggleNotifyingUserToSubscribeBecauseReachedSkipLimits = assign({
  notifyingUserToSubscribeBecauseReachedSkipsLimit(
    { notifyingUserToSubscribeBecauseReachedSkipsLimit },
    { value }
  ) {
    if (typeof value === 'undefined') {
      return !notifyingUserToSubscribeBecauseReachedSkipsLimit;
    }

    return value;
  }
});

export const toggleNotifyingUserToSubscribeToViewLyrics = assign({
  notifyingUserToSubscribeToViewLyrics({ notifyingUserToSubscribeToViewLyrics }, { value }) {
    if (typeof value === 'undefined') {
      return !notifyingUserToSubscribeToViewLyrics;
    }

    return value;
  }
});

export const setActionsRequiringAuth = assign({
  actionsRequiringAuth(_, { type, ...event }) {
    return {
      ...event
    };
  }
});

export const resetActionsRequiringAuth = assign({
  actionsRequiringAuth() {
    return null;
  }
});

export const reloadAppConfig = pure(() => {
  const service = getAppService();
  const appConfigService = getAppConfigService(service.state);

  return [
    send(
      {
        type: 'RELOAD'
      },
      { to: appConfigService }
    )
  ];
});
