/* eslint-disable camelcase */
/* eslint-disable prefer-promise-reject-errors */
import {
  LOGIN_URL,
  PROFILE_URL,
  LOGOUT_URL,
  UPDATE_PROFILE_URL,
  USER_NOTIFICATIONS_URL,
  UPDATE_USER_NOTIFICATIONS_URL,
  SIGNUP_APP_URL,
  SIGNUP_URL,
  FORGOT_PASS_URL,
  UPDATE_PASS_URL,
  DE_ACTIVE_PROFILE_URL,
  SUBSCRIPTION_MANAGE_URL,
  CHECK_USER_SUBSCRIPTION,
  FOLLOW_USER_URL,
  UN_FOLLOW_USER_URL
} from '@constants/urls';

import api from './api';

export const login = data => {
  return api({
    url: LOGIN_URL,
    method: 'POST',
    data
  });
};

export const appRegister = data => {
  return api({
    url: SIGNUP_APP_URL,
    method: 'post',
    data
  });
};

export const register = data => {
  return api({
    url: SIGNUP_URL,
    method: 'post',
    data
  });
};

export const forgotPass = data => {
  return api({
    url: FORGOT_PASS_URL,
    method: 'post',
    data
  });
};

export const resetPass = ({ oldpass, newpass1, newpass2 }) => {
  return api({
    url: UPDATE_PASS_URL,
    method: 'post',
    data: {
      oldpass,
      newpass1,
      newpass2
    }
  });
};

export const loadSubscriptionStatus = ({ baseURL, headers } = {}) => {
  return api({
    baseURL,
    url: CHECK_USER_SUBSCRIPTION,
    method: 'get',
    headers
  });
};

export const loadManageSubscription = ({ baseURL, headers } = {}) => {
  return api({
    baseURL,
    url: SUBSCRIPTION_MANAGE_URL,
    method: 'get',
    headers
  });
};

const getUsername = ({ username }) => {
  if (!username) {
    return undefined;
  }

  if (username[0].includes('@')) {
    return username.replaceAll('@', '');
  }

  return username;
};

// when you pass username it loads third party profile
export const loadProfile = ({ baseURL, headers, username, stats } = {}) => {
  return api({
    baseURL,
    url: PROFILE_URL,
    method: 'GET',
    headers,
    params: {
      username: getUsername({ username }),
      stats
    }
  })
    .then((data, response) => {
      if (typeof data !== 'object') {
        return new Promise((_, reject) => reject(data, response));
      }

      if (!Object.keys(data).length) {
        return new Promise((_, reject) => reject(data, response));
      }

      return new Promise(resolve => resolve(data));
    })
    .then(profile => {
      if (username) {
        return Promise.resolve({ ...profile, type: 'user' });
      }

      return loadSubscriptionStatus({ baseURL, headers })
        .then(data => {
          const { success, ...rest } = data;

          return Promise.resolve({
            ...profile,
            ...rest,
            // hasSubscription: tried % 3 === 0 ? !success : success
            hasSubscription: success
          });
        })
        .catch(() => {
          return Promise.resolve({
            ...profile,
            hasSubscription: false
          });
        });
    });
};

export const updateProfile = ({
  firstname,
  lastname,
  email,
  username,
  bio,
  // eslint-disable-next-line
  remove_photo,
  photo
}) => {
  let headers = {};
  let data = {
    firstname,
    lastname,
    email,
    username,
    bio,
    remove_photo,
    photo
  };
  if (photo) {
    data = Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());
    headers = {
      'Content-Type': 'multipart/form-data'
    };
  }
  return api({
    url: UPDATE_PROFILE_URL,
    method: 'post',
    data,
    headers
  });
};

export const updateUserNotifications = ({ music_email, artists_email }) => {
  return api({
    url: UPDATE_USER_NOTIFICATIONS_URL,
    method: 'post',
    data: {
      music_email: music_email ? '1' : '0',
      artists_email: artists_email ? '1' : '0'
    }
  });
};

export const loadUserNotificationSettings = () => {
  return api({
    url: USER_NOTIFICATIONS_URL,
    method: 'get'
  });
};

export const deactivateAccount = data => {
  return api({
    url: DE_ACTIVE_PROFILE_URL,
    method: 'post',
    data
  });
};

export const logout = async () => {
  return api({
    url: LOGOUT_URL,
    method: 'get'
  });
};

export const followUser = ({ username }) => {
  return api({
    url: FOLLOW_USER_URL,
    method: 'post',
    data: {
      username
    }
  });
};

export const unFollowUser = ({ username }) => {
  return api({
    url: UN_FOLLOW_USER_URL,
    method: 'post',
    data: {
      username
    }
  });
};
