import { useMemo } from 'react';

import { useApp } from '@contexts';
import { useMatchers, useSafeMatchers } from '@state/hooks';
import { isActive } from '@machines/app/matchers';

export const useAppService = () => {
  const { service } = useApp();

  return service;
};

export const useAppMatch = matcher => {
  const service = useAppService();

  const value = useMatchers(service, matcher);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useIsAppActive = () => {
  const service = useAppService();
  const value = useSafeMatchers(service, isActive);

  return useMemo(() => {
    return value;
  }, [value]);
};

export const useIsTouch = () => {
  const { isTouch } = useApp();

  return useMemo(() => {
    return isTouch;
  }, []);
};

export const useIsBot = () => {
  const { isBot } = useApp();

  return useMemo(() => {
    return isBot;
  }, []);
};

export const usePlatform = () => {
  const { platform } = useApp();

  return useMemo(() => {
    return platform;
  }, []);
};
