import { MACHINE_NAMES, EVENT_NAMES, STATES } from '@constants/machines/library';
import { isArtist } from '@functions/is';
import { swap } from '@functions/array';
import { isSyncing } from '@machines/library/machines/item/matchers';

import { createMachine, send, actions as xstateActions, assign, spawn } from 'xstate';

import { appSettingsSubscriber } from './callbacks/app-settings-subscriber';

import * as actions from './actions';

const { pure } = xstateActions;

export const downloaderMachine = createMachine(
  {
    id: MACHINE_NAMES.DOWNLOADER,

    context: {
      queueKeys: [],
      queue: [],
      /**
       * @Downloaded: Array<item-machine>
       * This only includes items in the queue, not all the downloaded items
       */
      downloaded: [],
      concurrency: 1,

      appSettingsSubscriber: null
    },

    initial: STATES.DOWNLOADER.IDLE,

    on: {
      [EVENT_NAMES.DOWNLOADER.PUSH_TO_QUEUE]: {
        actions: ['updateQueue', 'setQueueKeys'],
        target: STATES.DOWNLOADER.VALIDATING
      },
      [EVENT_NAMES.DOWNLOADER.UPDATE_CONCURRENCY]: {
        actions: ['setConcurrency']
      }
    },

    states: {
      [STATES.DOWNLOADER.VALIDATING]: {
        always: [
          {
            target: STATES.DOWNLOADER.IDLE,
            cond: ({ queue }) => queue.length === 0
          },
          {
            target: STATES.DOWNLOADER.SYNCING,
            cond: ({ queue }) => {
              return isArtist(queue[0]);
            },
            actions: [
              assign({
                queue({ queue }) {
                  return swap(queue, 0, queue.length - 1);
                }
              })
            ]
          },
          {
            target: STATES.DOWNLOADER.SYNCING
          }
        ]
      },

      [STATES.DOWNLOADER.IDLE]: {
        entry: ['clearDownloaded'],
        after: {
          1_000: {
            actions: [
              assign({
                appSettingsSubscriber() {
                  return spawn(appSettingsSubscriber());
                }
              })
            ]
          }
        }
      },

      [STATES.DOWNLOADER.SYNCING]: {
        entry: pure(({ queue, concurrency }) => {
          if (queue.length > 1 && queue.every(isArtist)) {
            return [];
          }

          const items = queue.slice(0, concurrency);

          const events = items
            .filter(({ ref }) => !isSyncing(ref.state))
            .map(({ ref }) => {
              return send(EVENT_NAMES.ITEM.SYNC, { to: ref });
            });

          return events;
        }),
        on: {
          [EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE_AND_SET_TO_DOWNLOADED]: [
            {
              actions: ['removeItem', 'setQueueKeys'],
              target: STATES.DOWNLOADER.IDLE,
              cond: ({ queue }) => {
                return queue.length === 1;
              }
            },
            {
              actions: ['removeItem', 'setQueueKeys', 'setToDownloaded'],
              target: STATES.DOWNLOADER.VALIDATING
            }
          ],
          [EVENT_NAMES.DOWNLOADER.REMOVE_FROM_QUEUE]: [
            {
              actions: ['removeItem', 'setQueueKeys'],
              target: STATES.DOWNLOADER.IDLE,
              cond: ({ queue }) => {
                return queue.length === 1;
              }
            },
            {
              actions: ['removeItem', 'setQueueKeys'],
              target: STATES.DOWNLOADER.VALIDATING
            }
          ],
          [EVENT_NAMES.DOWNLOADER.CANCEL_ALL]: {
            actions: [
              pure(({ queue }) => {
                const events = queue.map(({ ref }) => {
                  return send(EVENT_NAMES.ITEM.CANCEL, { to: ref });
                });

                return events;
              })
            ]
          }
        }
      }
    }
  },
  {
    actions,
    guards: {}
  }
);
