import { assign, spawn } from 'xstate';

import { getUUID } from '@functions/get';

import { notificationItemMachine } from '@machines/app/notification/notification-item/notification-item-machine';

export const addNotification = assign({
  notifications({ notifications }, { value }) {
    const context = {
      ...value,
      id: getUUID()
    };

    const machine = spawn(notificationItemMachine(context));

    context.ref = machine;

    return [...notifications, context];
  }
});

export const removeNotification = assign({
  notifications({ notifications }, { item }) {
    return notifications.filter(notification => {
      return notification.id !== item.id;
    });
  }
});

export const removeNotifications = assign({
  notifications() {
    return [];
  }
});
