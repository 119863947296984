import { createMachine, actions as xstateActions } from 'xstate';
import { loadProfile } from '@api/auth';

import * as actions from './actions';

const { escalate } = xstateActions;

export const userMachine = createMachine(
  {
    id: 'user-machine',
    context: {
      user: {}
    },
    initial: 'idle',
    states: {
      idle: {
        always: {
          target: 'loading'
        }
      },
      loading: {
        invoke: {
          id: 'load-profile',
          src: () => loadProfile(),
          onDone: {
            target: '#user-machine.done',
            actions: ['setUser']
          },
          onError: [
            {
              target: '#user-machine.failure',
              cond: (_, event) => {
                if (typeof event.data === 'object' && !Object.keys(event.data).length) {
                  return true;
                }

                return false;
              }
            },
            /**
             * If there was no internet connection & user is already login we'll keep the data
             */
            {
              target: '#user-machine.done',
              cond: ({ user }, event) => {
                if (!Object.keys(user).length) {
                  return false;
                }

                // client
                if (process.browser) {
                  if (!window.navigator.onLine) {
                    return true;
                  }
                }

                // Check if response is availbale to check the status code
                if (typeof event?.data?.response === 'object') {
                  if (event?.data?.response.status >= 500) {
                    return true;
                  }
                } else {
                  // Network error because response is empty
                  return true;
                }

                return false;
              }
            },
            {
              target: '#user-machine.failure'
            }
          ]
        }
      },
      failure: {
        type: 'final',
        entry: escalate('failed to load user data!')
      },
      done: {
        type: 'final',
        data({ user }) {
          return user;
        }
      }
    }
  },
  {
    actions: {
      ...actions
    },
    guards: {}
  }
);
