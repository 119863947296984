import dynamic from 'next/dynamic';

import ExplicitIcon from '@ui/icons/Explicit';
import CircleIcon from '@components/ui/icons/Circle';

import cx from 'classnames';

import { isMp3, isVideo, isAlbum, isEvent, isArtist, isPodcast } from '@functions/is';
import { getLink, getArtistLink, getShowLink } from '@functions/get-link';
import { persianRex } from '@functions/string';
import { formatDateTime } from '@functions/date';

import { useMemo } from 'react';

import { Box, Text, Link } from '@ui';
import Type from './Type';
import IconType from './IconType';
import ArtistLink from './ArtistLink';

import { getTitle, getSubTitle } from './helpers';

const Protected = dynamic(() => import('@components/logic/Protected'), {
  ssr: false
});
const SyncProgress = dynamic(() => import('@components/actions/library/sync/SyncProgress'), {
  ssr: false
});

const pTypes = {
  selfie: 'justify-center',
  user: 'justify-center'
};

const LinkWrapper = ({ children, showLink, className, ...props }) => {
  if (showLink) {
    return (
      <Link
        {...props}
        className={cx(className, 'truncate hover:underline focus:underline', {
          'font-farsi': typeof children === 'string' && persianRex.hasRtl.test(children)
        })}
      >
        {children}
      </Link>
    );
  }

  return children;
};

const SubTitle = ({ data, linkProps, subTitle }) => {
  if (isMp3(data) || isVideo(data) || isAlbum(data)) {
    return <ArtistLink data={data} linkProps={linkProps} />;
  }

  if (isPodcast(data)) {
    return (
      <LinkWrapper
        to={data.show_permlink ? getShowLink(data) : getLink(data)}
        showLink
        {...linkProps}
      >
        {subTitle}
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper to={getArtistLink(data)} showLink {...linkProps}>
      {subTitle}
    </LinkWrapper>
  );
};

const Title = ({
  data,
  titleProps,
  subTitleProps,
  textCenter,
  showTitle,
  showSubTitle,
  showLink,
  showTitleLink,
  showSubTitleLink,
  showType,
  showIconType,
  showLyricSnippet,
  showDate,
  showSyncedBadge,
  showExplicit,
  className,
  linkProps,
  isTouch,
  ...rest
}) => {
  return useMemo(() => {
    const title = getTitle(data);
    const subTitle = getSubTitle(data);

    return (
      <Box className={cx('flex flex-col', className)} {...rest}>
        {showDate && data.created_at ? (
          <Text as="h6" className="text-roof-terracotta-500 text-md tracking-wide">
            {formatDateTime(data.created_at)}
          </Text>
        ) : null}

        {showTitle ? (
          <>
            <Box className={cx('flex items-center', { 'justify-center': textCenter })}>
              <Box className={cx('truncate flex-1', { 'text-center': textCenter })}>
                <Text className={cx('truncate', titleProps?.className)}>
                  <LinkWrapper
                    showLink={showLink && showTitleLink}
                    to={getLink(data)}
                    {...linkProps}
                  >
                    {title}
                  </LinkWrapper>
                </Text>
              </Box>
              {showIconType && !isArtist(data) ? (
                <Box className="ml-4">
                  <IconType data={data} forceHideArtist />
                </Box>
              ) : null}
            </Box>

            {isTouch ? null : (
              <>
                {showSyncedBadge && !showSubTitle ? (
                  <Protected>
                    <SyncProgress
                      className="mr-1"
                      data={data}
                      isShowingDownloading={false}
                      isShowingButton={false}
                      showSyncedBadge
                    />
                  </Protected>
                ) : null}
              </>
            )}
          </>
        ) : null}

        {(data.type && showSubTitle) || showType ? (
          <Box
            className={cx('flex items-center', pTypes?.[data?.type], {
              'justify-center': textCenter
            })}
          >
            {!isTouch && data.type && showType && showSubTitle && subTitle ? (
              <Protected>
                {showSyncedBadge ? (
                  <SyncProgress
                    className="mr-1"
                    data={data}
                    isShowingDownloading={false}
                    isShowingButton={false}
                    showSyncedBadge
                  />
                ) : null}
              </Protected>
            ) : null}
            {showType ? <Type data={data} /> : null}
            {data.type && showSubTitle && subTitle ? (
              <>
                {showType ? <CircleIcon /> : null}
                {isTouch || showType ? null : (
                  <Protected>
                    {showSyncedBadge ? (
                      <SyncProgress
                        className="mr-1"
                        data={data}
                        isShowingDownloading={false}
                        isShowingButton={false}
                        showSyncedBadge
                      />
                    ) : null}
                  </Protected>
                )}
                {showExplicit && (data.explicit || data?.item?.explicit) ? (
                  <ExplicitIcon className="mr-1" />
                ) : null}
                <Text className={cx('text-th-foreground-max truncate', subTitleProps?.className)}>
                  {showLink && showSubTitleLink ? (
                    <SubTitle data={data} subTitle={subTitle} linkProps={linkProps} />
                  ) : (
                    subTitle
                  )}
                </Text>
              </>
            ) : null}
          </Box>
        ) : null}

        {isEvent(data) && data.date ? (
          <Box className="truncate">
            <Text className="text-th-foreground-max text-xs">{data.date}</Text>
          </Box>
        ) : null}

        {showLyricSnippet && data.lyric_snippet ? (
          <Box className="mt-1 line-clamp-2">
            <Text
              className={cx('text-th-foreground-max text-xs', {
                'text-right rtl font-farsi': persianRex.hasRtl.test(data.lyric_snippet)
              })}
            >
              {`"${data.lyric_snippet}"`}
            </Text>
          </Box>
        ) : null}
      </Box>
    );
    // eslint-disable-next-line
  }, [data.id, data.photo, data.title, titleProps, subTitleProps]);
};

Title.defaultProps = {
  titleProps: {},
  subTitleProps: {},
  textCenter: false,
  showType: false,
  showIconType: false,
  showLyricSnippet: false,
  showDate: false,
  showSyncedBadge: false,
  showTitle: true,
  showSubTitle: true,
  showExplicit: true,
  showLink: true,
  showTitleLink: true,
  showSubTitleLink: true,
  linkProps: {}
};

export default Title;
