export const swap = (arr, from, to) => {
  const clone = [...arr];
  const temp = clone[from];
  clone[from] = clone[to];
  clone[to] = temp;

  return clone;
};

export const insertAt = (arr, index, ...elementsArray) => {
  const clone = [...arr];
  clone.splice(index, 0, ...elementsArray);

  return clone;
};

export const reorder = (arr, fromIndex, toIndex) => {
  const result = [...arr];
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);

  return result;
};

export const forceReturn = () => {
  return true;
};
