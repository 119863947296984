/* eslint-disable react/no-array-index-key */
import dynamic from 'next/dynamic';
import cx from 'classnames';
import { COMPONENTS } from '@constants/components';

import { isHeaderSection, isHomeSlider } from '@functions/is';

import { Fragment } from 'react';

import { useIsTouch } from '@hooks';

import { Box } from '@ui';
import { LoadingState } from '@components/states';

const DesktopSectionItem = dynamic(() => import('@components/section/desktop/SectionItem'));
const TouchSectionItem = dynamic(() => import('@components/section/touch/SectionItem'));
const AdSlot = dynamic(() => import('@components/google-ads/AdSlot'), {
  ssr: false
});

const List = ({ data, showIconType }) => {
  const isTouch = useIsTouch();

  if (isTouch) {
    return data.map((item, index) => {
      const isList = index === data.length - 1;

      return (
        <Fragment key={index}>
          <Box className={cx({ 'mb-6': !(isHeaderSection(item) || isList) })}>
            <TouchSectionItem data={item} index={index} showIconType={showIconType} />
          </Box>

          {item.isLastSlider ? <AdSlot className="px-4 mb-6" /> : null}
        </Fragment>
      );
    });
  }

  return (
    <Box className="flex flex-col">
      {data.map((item, index) => {
        const isList = index === data.length - 1;

        return (
          <Fragment key={index}>
            <Box
              className={cx({
                'pb-10': !(isHeaderSection(item) || isList),
                'pb-2': isHeaderSection(item)
              })}
            >
              <DesktopSectionItem data={item} index={index} showIconType={showIconType} />
            </Box>
            {item.type === COMPONENTS.SLIDER && isHomeSlider(item) ? (
              <AdSlot className="mb-10" />
            ) : null}
          </Fragment>
        );
      })}
    </Box>
  );
};

const SectionList = ({ isLoadingSlow, isLoading, ...rest }) => {
  if (isLoadingSlow) {
    return <LoadingState />;
  }

  if (isLoading) {
    return null;
  }

  return <List {...rest} />;
};

SectionList.defaultProps = {
  data: [],
  isLoadingSlow: false,
  isLoading: false,
  showIconType: false
};

export default SectionList;
