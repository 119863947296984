import dynamic from 'next/dynamic';

import { FADE_FAST } from '@theme/framer-variants';

import cx from 'classnames';

import { useEffect, forwardRef, useMemo } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Text, Box, IconButton, Icon } from '@ui';
import LoadingIcon from '@ui/icons/LoadingIos';

const CloseIcon = dynamic(() => import('@public/vectors/close.svg'));

const DEFAULT_VARIANT_CLASS_NAMES =
  'input focus:outline-none appearance-none w-full outline-none transition-all duration-200';

const WRAPPER_CLASS_NAMES = {
  'outline-ghost': 'text-th-foreground-high',
  inverted: 'text-th-inverted-foreground',
  solid: 'text-th-foreground-high'
};

const VARIANT_CLASS_NAMES = {
  'outline-ghost': function ({ hasError, isFocused, showRing }) {
    return cx(
      'bg-transparent text-th-primary-foreground border-p-1 border-th-foreground-mid placeholder:text-th-foreground-high',
      'focus:ring-offset-th-primary-foreground focus:border-th-primary-foreground',
      {
        'focus:ring-th-foreground-mid': showRing,
        'focus:ring-transparent': !showRing,
        'ring-offset-red-500 ring-red-900/30 border-red-500': !isFocused && hasError
      }
    );
  },
  inverted() {
    return [
      'bg-th-inverted-background text-th-inverted-foreground',
      'placeholder:text-th-inverted-foreground focus:ring-transparent'
    ];
  },
  solid({ hasError, isFocused, showRing }) {
    return cx(
      'bg-th-foreground-min text-th-primary-foreground placeholder:text-th-foreground-high',
      'focus:bg-th-foreground-low',
      'focus:ring-offset-th-primary-foreground focus:border-th-primary-foreground',
      {
        'focus:ring-th-foreground-mid': showRing,
        'focus:ring-transparent': !showRing,
        'ring-offset-red-500 ring-red-900/30 border-red-500': !isFocused && hasError
      }
    );
  }
};

const CLASS_NAMES_BY_SIZE = {
  xs({ isFocused, hasError }) {
    return (
      'text-xs px-2 h-6 placeholder:text-xs focus:ring-[0.3rem] focus:ring-offset-[0.03rem]',
      {
        'ring-[0.3rem] ring-offset-[0.05rem]': !isFocused && hasError
      }
    );
  },
  sm({ isFocused, hasError }) {
    return cx(
      'text-sm px-3 h-8 placeholder:text-sm focus:ring-[0.3rem] focus:ring-offset-[0.04rem]',
      {
        'ring-[0.3rem] ring-offset-[0.05rem]': !isFocused && hasError
      }
    );
  },
  md({ isFocused, hasError }) {
    return cx(
      'text-md px-4 h-10 placeholder:text-sm focus:ring-[0.3rem] focus:ring-offset-[0.05rem]',
      {
        'ring-[0.3rem] ring-offset-[0.05rem]': !isFocused && hasError
      }
    );
  },
  lg({ isFocused, hasError }) {
    return cx(
      'text-lg px-4 h-12 placeholder:text-sm focus:ring-[0.2rem] focus:ring-offset-[0.08rem]',
      {
        'ring-[0.2rem] ring-offset-[0.05rem]': !isFocused && hasError
      }
    );
  },
  xl({ isFocused, hasError }) {
    return cx(
      'text-lg px-4 h-14 placeholder:text-sm focus:ring-[0.3rem] focus:ring-offset-[0.15rem]',
      {
        'ring-[0.3rem] ring-offset-[0.1rem]': !isFocused && hasError
      }
    );
  }
};

const StaticInput = forwardRef(
  (
    {
      name,
      prefix,
      postfix,
      className,
      isRound,
      showRing,
      size,
      variant,
      wrapperProps,
      label,
      labelProps,
      showError,
      prefixProps,
      afterChanged,
      close,
      autoFocus,
      cleanButtonProps,
      cleanIconProps,
      hasError,
      errorMessage,
      onClean,
      value,
      focusTimeout,
      isFocused,
      isLoading,
      loadingIconProps,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (autoFocus && name) {
        setTimeout(() => {
          document.getElementById(name)?.focus();
        }, focusTimeout);
      }
    }, []);

    const classNames = useMemo(() => {
      return cx(
        DEFAULT_VARIANT_CLASS_NAMES,
        VARIANT_CLASS_NAMES[variant]({ hasError, isFocused, showRing }),
        CLASS_NAMES_BY_SIZE[size]({ hasError, isFocused, showRing }),
        className,
        {
          invalid: hasError,
          'rounded-full': isRound,
          'rounded-md': !isRound
        }
      );
    }, [className, hasError, variant, isRound, isFocused, showRing]);

    return (
      <Box className={cx('relative w-full', WRAPPER_CLASS_NAMES[variant], wrapperProps?.className)}>
        {label ? (
          <Box className="mb-1">
            <label
              className={cx(
                'text-xs xl:text-sm w-full text-th-foreground-max',
                labelProps?.className
              )}
              htmlFor={name}
            >
              {label}
            </label>
          </Box>
        ) : null}
        <Box className="flex items-center relative">
          {prefix ? (
            <Box
              className="flex items-center justify-content absolute left-0 top-0 bottom-0 px-3 pointer-events-none"
              {...prefixProps}
            >
              {prefix}
            </Box>
          ) : null}
          <input
            ref={ref}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            name={name}
            id={name}
            className={classNames}
            {...props}
            value={value}
          />
          {postfix}
          {isLoading ? (
            <Box className="flex items-center justify-center absolute top-0 right-0 bottom-0 h-full px-3">
              <Icon>
                <LoadingIcon {...loadingIconProps} />
              </Icon>
            </Box>
          ) : null}
          {close && value ? (
            <Box className="flex items-center justify-center absolute top-0 right-0 bottom-0 h-full px-3">
              <IconButton size="xs" onClick={onClean} {...cleanButtonProps}>
                <CloseIcon {...cleanIconProps} />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <AnimatePresence mode="wait" initial={false}>
          {hasError ? (
            <motion.div
              className={cx('absolute top-full mt-1 left-0 right-0 py-1 px-2 text-red-600')}
              {...FADE_FAST}
            >
              <Text className="text-xs">{errorMessage}</Text>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Box>
    );
  }
);

StaticInput.defaultProps = {
  wrapperProps: {},
  labelProps: {},
  prefixProps: {},
  cleanButtonProps: {},
  cleanIconProps: {
    className: 'w-4 h-4'
  },
  isLoading: false,
  loadingIconProps: {
    className: 'w-6 h-6'
  },
  size: 'md',
  isRound: true,
  showRing: false,
  errorMessage: '',
  focusTimeout: 0,
  postfix: null,
  // onFocus() {},
  // onBlur() {},
  // onClean() {},
  variant: 'outline-ghost'
};

export default StaticInput;

/* <AnimatePresence mode="wait" initial={false}>
            {hasError ? (
              <motion.div
                className="flex items-center justify-content absolute right-0 top-0 bottom-0 px-3 pointer-events-none text-red-600"
                {...FADE_FAST}
              >
                <InfoIcon className="w-6 h-6" />
              </motion.div>
            ) : null}
          </AnimatePresence> */
