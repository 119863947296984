import { TITLE_BASED_ON_TYPES, TYPES_BASED_ON_TITLE, LINKS_BASED_ON_TYPES } from '@constants/app';
import { COMPONENTS } from '@constants/components';
import {
  isPlaylist,
  isArtist,
  isRecentlyPlayedHeader,
  isRecentlyPlayedSlider,
  isSlider
} from '@functions/is';

import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

export const withHome = data => {
  if (!data) {
    return [];
  }

  return data.map(item => {
    if (item.id === 'new_playlists_header' || item.id === 'playlists_header') {
      return {
        ...item,
        link: '/playlists',
        playlistHome: true,
        show_link: true
      };
    }

    return item;
  });
};

export const withStaticSliders = ({ data }) => {
  const firstSliderIndex = findIndex(data, { type: COMPONENTS.SLIDER });
  const sliders = data.filter(item => {
    return isSlider(item);
  });

  if (firstSliderIndex < 0 || sliders.length === 0) {
    return data;
  }

  // if (isTouch) {
  const sections = data.reduce((acc, item, index) => {
    if (isSlider(item)) {
      if (index === firstSliderIndex) {
        acc.push(...sliders);
        acc[acc.length - 1] = {
          ...acc[acc.length - 1],
          isLastSlider: true
        };
      }

      return acc;
    }

    acc.push(item);

    return acc;
  }, []);

  return sections;
  // }

  // const sections = data.reduce((acc, item, index) => {
  //   if (isSlider(item)) {
  //     if (index === firstSliderIndex) {
  //       const items = sliders.reduce((acc2, slider) => {
  //         if (slider.items) {
  //           acc2.push(...slider.items);
  //         }

  //         return acc2;
  //       }, []);
  //       const slider = {
  //         ...item,
  //         items
  //       };

  //       acc.push(slider);
  //     }

  //     return acc;
  //   }

  //   acc.push(item);

  //   return acc;
  // }, []);

  // return sections;
};

export const withRecentlyPlayed = ({ data, hasRecentlyPlayedItems }) => {
  if (!hasRecentlyPlayedItems) {
    return data.filter(item => {
      return !isRecentlyPlayedHeader(item) && !isRecentlyPlayedSlider(item);
    });
  }

  return data.reduce((acc, item) => {
    if (isRecentlyPlayedHeader(item)) {
      acc[acc.length] = {
        ...item,
        show_link: true,
        link: '/library/recently-played'
      };

      return acc;
    }

    if (isRecentlyPlayedSlider(item)) {
      acc[acc.length] = {
        ...item,
        type: COMPONENTS.RECENTLY_PLAYED
      };

      return acc;
    }

    acc[acc.length] = item;

    return acc;
  }, []);
};

export const withUpgradeRjPremiumBanner = ({ data }) => {
  if (!data) {
    return [];
  }

  return [
    {
      type: COMPONENTS.UPGRADE_TO_RJ_PREMIUM_BANNER
    },
    ...data
  ];
};

const formatCategories = (categories, type) => {
  return categories.map(item => ({
    ...item,
    type
  }));
};

export const formatPlaylistsAfterFetch = data => {
  const playlistsData = {
    ...data
  };

  if (Array.isArray(data.mp3s.categories)) {
    playlistsData.mp3s.categories = formatCategories(data.mp3s.categories, 'mp3s_category');
  }

  if (Array.isArray(data.videos.categories)) {
    playlistsData.videos.categories = formatCategories(data.videos.categories, 'videos_category');
  }

  if (Array.isArray(data.listing)) {
    playlistsData.listing = data.listing.map(listing => {
      return {
        ...listing,
        categories: listing.categories.map(category => {
          if (isPlaylist(category)) {
            return {
              ...category,
              subtype: TYPES_BASED_ON_TITLE[category.subtype],
              photo: category.player_photo,
              listing: true
            };
          }

          return category;
        })
      };
    });
  }

  return playlistsData;
};

export const formatPlaylists = (data, props = {}, parentKey) => {
  if (!data) {
    return [];
  }
  const { type, subType, id } = props;

  if (id) {
    /**
     * Backend doesn't provide the "categories" data in video section. so the page will return undefined
     */
    if (type === 'videos') {
      const { listing } = data;

      if (listing?.length) {
        const category = listing.reduce((_, item) => {
          return item.categories.find(categoryItem => {
            return categoryItem.id === Number(id);
          });
        }, null);

        return {
          title: `${category?.name} Playlists`,
          items: category?.playlists ?? [],
          category
        };
      }
    }

    const dataType = data?.[type];
    const categories = dataType?.[subType] ?? [];
    const category = categories.find(item => item.id === Number(id));

    return {
      title: `${category?.name} Playlists`,
      items: category?.playlists ?? [],
      category
    };
  }

  if (type || subType) {
    const allItems = data?.[type]?.[subType] ?? [];
    return {
      title: get(TITLE_BASED_ON_TYPES.PLAYLISTS, `${type}.${subType}`, type),
      items: allItems
    };
  }

  const { listing, ...rest } = data;
  const keys = Object.keys(rest);

  return keys.reduce((acc, key) => {
    if (Array.isArray(data[key])) {
      if (data[key].length) {
        const path = `${parentKey}.${key}`;
        const title = get(TITLE_BASED_ON_TYPES.PLAYLISTS, path, key);
        let header = {
          title
        };
        if (!type || !subType) {
          header = {
            ...header,
            link: `${parentKey || key}/${key}`,
            show_link: data[key].length > 4,
            playlist: true,
            type: COMPONENTS.HEADER
          };
        }
        acc.push(
          {
            ...header
          },
          {
            type: key === 'categories' ? COMPONENTS.SLIDER : COMPONENTS.GRID,
            items: data[key],
            key
          }
        );
      }
      return acc;
    }
    if (typeof data[key] === 'object') {
      acc = [].concat(acc, formatPlaylists(data[key], props, key));
    }

    return acc;
  }, []);
};

export const formatPlaylistListing = data => {
  if (!data) {
    return [];
  }

  const initialData = [];

  return data.reduce((acc, item) => {
    if (!item.categories?.length) {
      return acc;
    }

    acc.push(
      {
        type: COMPONENTS.HEADER,
        title: item.title
      },
      {
        items: item.categories,
        type: 'grid',
        id: 'categories'
      }
    );

    return acc;
  }, initialData);
};

export const formatArtist = data => {
  if (!data) {
    return [];
  }
  const { mp3s, videos, playlists, podcasts, albums, latest } = data;
  const items = {
    videos,
    albums,
    playlists,
    podcasts
  };

  if (latest) {
    items.mp3s = mp3s;
  }

  const keys = Object.keys(items);

  return keys.reduce((acc, key) => {
    if (items[key]?.length) {
      acc.push(
        {
          show_link: data[key].length > 1,
          link: `${data.query}/${LINKS_BASED_ON_TYPES[key] || key}`,
          artist: true,
          title: TITLE_BASED_ON_TYPES[key] || key,
          type: COMPONENTS.HEADER
        },
        {
          type: COMPONENTS.SLIDER,
          items: items[key],
          id: key
        }
      );
    }

    return acc;
  }, []);
};

export const formatArtistTouch = data => {
  if (!data) {
    return [];
  }
  const { videos, playlists, podcasts, albums } = data;
  const items = {
    videos,
    albums,
    playlists,
    podcasts
  };

  const keys = Object.keys(items);

  return keys.reduce((acc, key) => {
    if (items[key]?.length) {
      acc.push(
        {
          show_link: data[key].length > 6,
          link: `${data.query}/${LINKS_BASED_ON_TYPES[key] || key}`,
          artist: true,
          title: TITLE_BASED_ON_TYPES[key] || key,
          type: COMPONENTS.HEADER
        },
        {
          type: key === 'videos' ? COMPONENTS.SLIDER : COMPONENTS.SLIDER_SQUARE,
          items: items[key].slice(0, 6),
          id: key
        }
      );
    }

    return acc;
  }, []);
};

export const formatSearch = data => {
  const sortedData = {};
  sortedData.artists = data.all_artists;
  sortedData.songs = data.mp3s;
  sortedData.videos = data.videos;
  sortedData.lyrics = data.lyrics;
  sortedData.albums = data.albums;
  sortedData.podcasts = data.podcasts;

  if (sortedData?.playlists?.length) {
    sortedData.playlists = data?.playlists?.map(item => item.playlist);
  }

  if (data?.top) {
    sortedData.top = data?.top?.map(item => {
      if (isPlaylist(item)) {
        return item.playlist;
      }

      return item;
    });
  }

  if (data?.profiles?.length) {
    sortedData.profiles = data.profiles.map(item => {
      return {
        ...item,
        type: 'user'
      };
    });
  }

  const artistsFromTop = sortedData.top?.filter(isArtist);

  if (artistsFromTop.length === sortedData?.artists?.length) {
    sortedData.artists = [];
  }

  return Object.keys(sortedData).reduce((result, key) => {
    if (sortedData[key]?.length) {
      result[key] = sortedData[key];
    }

    return result;
  }, {});
};

export const formatEvents = ({ data, tag }) => {
  if (tag) {
    return [
      {
        type: COMPONENTS.HEADER,
        title: data.title
      },
      {
        items: data.events,
        type: COMPONENTS.GRID
      }
    ];
  }

  const initialData = [];

  if (data.near?.length) {
    initialData.push(
      {
        type: COMPONENTS.HEADER,
        title: 'Events near you'
      },
      {
        items: data.near,
        type: COMPONENTS.GRID
      }
    );
  }

  if (data.upcoming?.length) {
    initialData.push(
      {
        type: COMPONENTS.HEADER,
        title: 'Upcoming Events'
      },
      {
        items: data.upcoming,
        type: COMPONENTS.GRID
      }
    );
  }

  return initialData;
};

export const formatPlaylistItem = params => {
  const data = params;

  if (data.playlists) {
    return {
      ...data,
      playlists: data.playlists.map(item => item.playlist)
    };
  }

  return data;
};

export const formatProfileSections = data => {
  if (!data) {
    return data;
  }

  const { selfies, artists, playlists } = data;

  const initialValues = [];
  const newData = {
    artists,
    playlists
  };
  const stories = selfies?.map(item => {
    return {
      ...item,
      isUserProfile: true
    };
  });

  if (selfies?.length) {
    initialValues.push(
      {
        type: COMPONENTS.HEADER,
        title: TITLE_BASED_ON_TYPES.selfies
      },
      {
        items: stories,
        type: COMPONENTS.STORIES
      }
    );
  }

  const sections = Object.entries(newData).reduce((acc, [key, value]) => {
    if (value?.length) {
      acc.push(
        {
          type: COMPONENTS.HEADER,
          title: TITLE_BASED_ON_TYPES[key]
          // show_link: newData[key].length > 6,
          // link: `${data.username}/${key}`,
          // isUserProfile: true
        },
        {
          items: newData[key],
          type: COMPONENTS.SLIDER_SQUARE
        }
      );
    }

    return acc;
  }, initialValues);

  return {
    ...data,
    selfies: stories,
    sections
  };
};
