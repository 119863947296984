import platform from 'platform';
import isbot from 'isbot';
// import Bowser from 'bowser';

import { isTouch as isTouchBrowser } from '@functions/browser';

export function isTouch({ headers }) {
  if (headers) {
    if (headers['CloudFront-Is-Tablet-Viewer'] || headers['CloudFront-Is-Mobile-Viewer']) {
      return true;
    }

    const userAgent = headers['user-agent'];

    return isTouchBrowser({ userAgent });
  }

  return isTouchBrowser();
}

export function isMacOS({ headers }) {
  return platform.parse(headers['user-agent']).os.family === 'OS X';
}

export function isWindows({ headers }) {
  return platform.parse(headers['user-agent']).os.family === 'Windows';
}

export function isBot({ headers }) {
  return isbot(headers['user-agent']);
}

// https://github.com/lancedikson/bowser/blob/master/src/constants.js
export function isSupportedBrowser() {
  return true;
  // const browser = Bowser.getParser(headers['user-agent']);
  // try {
  // if (!browser.parsedResult.browser.version) {
  //   return true;
  // }
  // const isSupported = browser.satisfies({
  //   iOS: {
  //     chrome: '>=69',
  //     safari: '>=11'
  //   },
  //   android: {
  //     chrome: '>=69',
  //     firefox: '>=95',
  //     samsung_internet: '>=95'
  //   },
  //   safari: '>=12.1',
  //   chrome: '>=66',
  //   firefox: '>=60',
  //   opera: '>=49'
  // });
  // return isSupported;
  // } catch (e) {
  //   console.log('error in parsing user agent', e);
  //   return true;
  // }
}
