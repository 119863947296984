import { isNative } from '@functions/env';

// SAFE NATIVE
import nativeUseFullScreen from './native/useFullScreen';
import browserUseFullScreen from './dom/useFullScreen';

export { default as useToggle } from './useToggle';
export { default as useBooleanState } from './useBooleanState';
export { default as useDisclosure } from './useDisclosure';
export { default as useUpdateEffect } from './useUpdateEffect';
export { default as useDebounce } from './useDebounce';

// DOM
export { default as useWindowSize } from './dom/useWindowSize';
export { default as useScroll } from './dom/useScroll';
export { default as useKeyboard } from './dom/useKeyboard';
export { default as useTopNav } from './dom/useTopNav';

// STATE RELATED HOOKS
export { default as useUser } from './state/useUser';
export * from './state/useApp';
export * from './state/useAuth';
export * from './state/useLibrary';
export * from './state/useLibraryHelpers';
export * from './state/useUpdateUserProfile';
export * from './state/useNowPlaying';
export * from './state/useAppSettings';
export * from './state/useAppConfig';
export * from './state/useDragDrop';
export * from './state/useSearchHistory';
export * from './state/useGrid';
export { useBrowserPersistedState, useNativePersistedState } from './dom/usePersistedState';

export const useFullScreen = isNative() ? nativeUseFullScreen : browserUseFullScreen;
