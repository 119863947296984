import { SUBSCRIPTION_MANAGE_URL, USER_NOTIFICATIONS_URL, PROFILE_URL } from '@constants/urls';

import { useData, mutate, getDataKey } from '@data';
import { loadProfile, loadManageSubscription, loadUserNotificationSettings } from '@api/auth';

import { formatPlaylistItem, formatProfileSections } from '@api/helpers';

export const useUserProfile = ({ username }) => {
  return useData({
    key: PROFILE_URL,
    loader: loadProfile,
    params: {
      username,
      stats: 1
    },
    modifier({ data }) {
      if (!data) {
        return {};
      }

      const formattedData = formatPlaylistItem(data);

      return {
        data: formatProfileSections(formattedData)
      };
    }
  });
};

export const mutateUserProfile = ({ username }) => {
  const params = { username, stats: 1 };
  const key = getDataKey({ key: PROFILE_URL, params });

  return mutate(key, () => loadProfile(params));
};

export const useSubscriptionManage = () => {
  return useData({
    key: SUBSCRIPTION_MANAGE_URL,
    loader: loadManageSubscription
  });
};

export const useUserNotificationSettings = () => {
  return useData({
    key: USER_NOTIFICATIONS_URL,
    loader: loadUserNotificationSettings,
    dedupingInterval: 1_000
  });
};

export const mutateUserNotificationSettings = () => {
  return mutate(USER_NOTIFICATIONS_URL, loadUserNotificationSettings);
};
