import { SERVER_CONFIG_URL, APP_CONFIG_URL, DEEP_LINK_URL } from '@constants/urls';
import api from './api';

export const loadServerConfig = () => {
  return api({
    baseURL: SERVER_CONFIG_URL,
    method: 'GET'
  });
};

export const loadAppConfig = () => {
  return api({
    url: APP_CONFIG_URL,
    method: 'GET'
  }).then(d => d.config);
};

export const loadDeepLink = ({ prefix, id }) => {
  return api({
    url: DEEP_LINK_URL,
    method: 'GET',
    params: {
      url: `${prefix}/${id}`
    }
  });
};
