export const EVENT_NAMES = {
  NOTIFICATION: {
    ADD: 'NOTIFICATION__ADD',
    REMOVE: 'NOTIFICATION__REMOVE',
    REMOVE_ALL: 'NOTIFICATION__REMOVE_ALL'
  },
  NOTIFICATION_ITEM: {
    REMOVE: 'NOTIFICATION_ITEM__REMOVE'
  }
};

export const STATES = {
  NOTIFICATION_ITEM: {
    ACTIVE: {
      INDEX: 'ACTIVE',

      IDLE: 'IDLE',
      DESTROYING: 'DESTROYING'
    },
    DESTROYED: 'DESTROYED'
  }
};

export const MACHINE_NAMES = {
  NOTIFICATION: 'NOTIFICATION_MACHINE',
  NOTIFICATION_ITEM: 'NOTIFICATION_ITEM_MACHINE'
};
