import { APP_VERSION_URL } from '@constants/urls';
import api from '@api/api';

export function loadAppVersion() {
  return api({
    url: APP_VERSION_URL,
    method: 'GET',
    isLocalApi: true
  }).then(data => {
    if (typeof data?.version === 'undefined') {
      return Promise.reject();
    }

    return data;
  });
}
