import ms from 'ms';

import { EVENT_NAMES, MACHINE_NAMES, STATES } from '@constants/machines/now-playing';
import { EVENTS } from '@constants/analytics';

import { ga } from '@helpers/ga4';

import {
  createMachine,
  sendParent,
  assign,
  send,
  forwardTo,
  actions as xstateActions
} from 'xstate';
import { getLibraryBlobStorage } from '@helpers/storages/library';
import { getAttachmentID } from '@machines/library/data-helpers';
import { getFailedUserPlaysItem } from '@functions/get';

import { adCheckBreak, userPlays } from '@api/media';

import { isMp3, isPodcast } from '@functions/is';
import * as actions from './actions';
import * as guards from './guards';

const { pure, choose } = xstateActions;

export const nowPlayingItemMachine = createMachine(
  {
    preserveActionOrder: true,
    id: MACHINE_NAMES.NOW_PLAYING_ITEM,
    context: {
      streamRef: null,
      streamSource: null,
      silent: false,
      preload: false,
      buffer: null
    },
    initial: STATES.NOW_PLAYING_ITEM.IDLE,
    states: {
      [STATES.NOW_PLAYING_ITEM.IDLE]: {
        on: {
          [EVENT_NAMES.NOW_PLAYING_ITEM.START]: [
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
              cond: ({ buffer }) => buffer
            },
            {
              target: STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.INDEX
            }
          ],
          [EVENT_NAMES.NOW_PLAYING_ITEM.START_SILENT]: {
            target: STATES.NOW_PLAYING_ITEM.SILENT_MODE
          },
          [EVENT_NAMES.NOW_PLAYING_ITEM.PRE_LOAD]: [
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
              actions: ['setPreload'],
              cond: ({ buffer }) => buffer
            },
            {
              target: STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.INDEX,
              actions: ['setPreload']
            }
          ]
        }
      },

      [STATES.NOW_PLAYING_ITEM.SILENT_MODE]: {
        entry: ['setSilent'],
        on: {
          [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE]: [
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
              cond: ({ buffer }) => buffer
            },
            {
              target: STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.INDEX
            }
          ],
          [EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY]: {
            target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`
          },
          [EVENT_NAMES.NOW_PLAYING_ITEM.NEXT]: [
            {
              actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
              cond: 'hasUserReachedSkipLimit'
            },
            {
              target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
              actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT)]
            }
          ],
          [EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS]: {
            target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
            actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS)]
          }
        }
      },

      [STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.INDEX]: {
        initial: STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.VALIDATING,
        states: {
          [STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.VALIDATING]: {
            always: [
              {
                target: STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.FETCHING,
                cond: 'hasDownloadedItem'
              },
              {
                target: `#${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}`
              }
            ]
          },
          [STATES.NOW_PLAYING_ITEM.LOADING_FROM_LIBRARY.FETCHING]: {
            invoke: {
              src(context) {
                const id = getAttachmentID(context);

                return getLibraryBlobStorage().find(id);
              },
              onDone: {
                target: `#${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}`,
                actions: [
                  assign({
                    buffer(_, event) {
                      return event.data;
                    }
                  })
                ]
              },
              onError: {
                target: `#${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}`
              }
            }
          }
        },
        on: {
          [EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY]: {
            target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`
          },
          [EVENT_NAMES.NOW_PLAYING_ITEM.NEXT]: [
            {
              actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
              cond: 'hasUserReachedSkipLimit'
            },
            {
              target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
              actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT)]
            }
          ],
          [EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS]: {
            target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
            actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS)]
          }
        }
      },

      /**
       * * ACTIVE STATE
       */
      [STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX]: {
        id: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
        initial: STATES.NOW_PLAYING_ITEM.ACTIVE.LOADING,
        states: {
          [STATES.NOW_PLAYING_ITEM.ACTIVE.LOADING]: {
            always: [
              {
                target: STATES.NOW_PLAYING_ITEM.ACTIVE.VALIDATING.INDEX,
                actions: ['setStreamSource', 'setStreamMachine'],
                cond: ({ streamRef, streamSource }) => {
                  return !streamRef && !streamSource;
                }
              },
              {
                target: STATES.NOW_PLAYING_ITEM.ACTIVE.VALIDATING.INDEX
              }
            ]
          },
          [STATES.NOW_PLAYING_ITEM.ACTIVE.VALIDATING.INDEX]: {
            always: [
              {
                target: STATES.NOW_PLAYING_ITEM.ACTIVE.PRE_LOADING.INDEX,
                cond: ({ preload }) => {
                  return preload;
                }
              },
              {
                target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX
              }
            ]
          },
          [STATES.NOW_PLAYING_ITEM.ACTIVE.PRE_LOADING.INDEX]: {
            id: STATES.NOW_PLAYING_ITEM.ACTIVE.PRE_LOADING.INDEX,
            exit: ['resetPreload'],
            on: {
              [EVENT_NAMES.NOW_PLAYING_ITEM.START]: [
                {
                  target: `#${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}`,
                  actions: [send('play', { to: 'streamRef' })]
                }
              ]
            }
          },

          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX]: {
            id: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX,
            type: 'parallel',
            on: {
              [EVENT_NAMES.NOW_PLAYING_ITEM.PLAY_FROM_LAST_CURRENT_TIME]: [
                {
                  actions: ['setLastPlayedCurrentTimeForPodcast', 'removeLastPlayedCurrentTime'],
                  cond: context => isPodcast(context)
                },
                {
                  actions: ['setLastPlayedCurrentTime']
                }
              ],
              [EVENT_NAMES.NOW_PLAYING.ACTIVE.PRE_LOAD_NEXT]: {
                actions: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PRE_LOAD_NEXT)]
              }
            },
            states: {
              [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.INDEX]: {
                id: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.INDEX,
                initial: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.IDLE,
                states: {
                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.IDLE]: {
                    always: [
                      {
                        target:
                          STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.INDEX,
                        cond: context => {
                          return (
                            isPodcast(context) &&
                            typeof window?.appConfig?.ad_break_podcast === 'number' &&
                            window?.appConfig?.ad_break_podcast > 0
                          );
                        }
                      },
                      {
                        target:
                          STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                            .INDEX
                      }
                    ]
                  },
                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.INDEX]: {
                    type: 'parallel',
                    states: {
                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                        .INDEX]: {
                        id: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                          .INDEX,
                        initial:
                          STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                            .WAITING,
                        states: {
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                            .WAITING]: {
                            after: [
                              {
                                delay() {
                                  return ms(`${window?.appConfig?.ad_break_podcast}secs`);
                                },
                                target:
                                  STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                    .WITH_MIDDLE_ADS.ADS.ADS_CHECK
                              }
                            ]
                          },
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                            .ADS_CHECK]: {
                            invoke: {
                              src: () => adCheckBreak({ podcast: 1 }),
                              onDone: [
                                {
                                  target:
                                    STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                      .WITH_MIDDLE_ADS.ADS.WAITING_TO_PLAY_MIDDLE_ADS.INDEX,
                                  actions: [
                                    sendParent((_, event) => {
                                      return {
                                        type: EVENT_NAMES.NOW_PLAYING.ACTIVE.SET_MIDDLE_ADS,
                                        items: event.data
                                      };
                                    })
                                  ],
                                  cond: (_, event) => {
                                    return event?.data?.length > 0;
                                  }
                                },
                                {
                                  target:
                                    STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                      .WITH_MIDDLE_ADS.ADS.WAITING
                                }
                              ],
                              onError: {
                                target:
                                  STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                    .WITH_MIDDLE_ADS.ADS.WAITING
                              }
                            }
                          },
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS
                            .WAITING_TO_PLAY_MIDDLE_ADS.INDEX]: {
                            initial: '5',
                            states: {
                              5: {
                                after: {
                                  [ms('1sec')]: [
                                    {
                                      target: '4',
                                      in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                    },
                                    {
                                      target: '5'
                                    }
                                  ]
                                }
                              },
                              4: {
                                after: {
                                  [ms('1sec')]: [
                                    {
                                      target: '3',
                                      in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                    },
                                    {
                                      target: '4'
                                    }
                                  ]
                                }
                              },
                              3: {
                                after: {
                                  [ms('1sec')]: [
                                    {
                                      target: '2',
                                      in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                    },
                                    {
                                      target: '3'
                                    }
                                  ]
                                }
                              },
                              2: {
                                after: {
                                  [ms('1sec')]: [
                                    {
                                      target: '1',
                                      in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                    },
                                    {
                                      target: '2'
                                    }
                                  ]
                                }
                              },
                              1: {
                                after: {
                                  [ms('1sec')]: [
                                    {
                                      target: '0',
                                      in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                    },
                                    {
                                      target: '1'
                                    }
                                  ]
                                }
                              },
                              0: {
                                entry: choose([
                                  {
                                    actions: [send(EVENT_NAMES.NOW_PLAYING_ITEM.START_MIDDLE_ADS)],
                                    in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING}`
                                  },
                                  {
                                    actions: [send(EVENT_NAMES.NOW_PLAYING_ITEM.START_MIDDLE_ADS)],
                                    in: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX}.${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.BUFFERING}`
                                  }
                                ]),
                                on: {
                                  [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.RESET_ADS_CHECKING_STATE]: {
                                    target: `#${STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.ADS.INDEX}`
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS.NONE_ADS
                        .INDEX]: {
                        initial:
                          STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS
                            .NONE_ADS.WAITING,
                        states: {
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS
                            .NONE_ADS.WAITING]: {
                            after: {
                              [ms('20secs')]: {
                                target:
                                  STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                    .WITH_MIDDLE_ADS.NONE_ADS.USER_PLAYS
                              }
                            }
                          },
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS
                            .NONE_ADS.USER_PLAYS]: {
                            invoke: {
                              src(context) {
                                return userPlays(getFailedUserPlaysItem(context));
                              },
                              onDone: {
                                target:
                                  STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                    .WITH_MIDDLE_ADS.NONE_ADS.ADD_TO_RECENTLY_PLAYED
                              },
                              onError: {
                                target:
                                  STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING
                                    .WITH_MIDDLE_ADS.NONE_ADS.ADD_TO_RECENTLY_PLAYED,
                                actions: [
                                  sendParent(context => {
                                    return {
                                      type: EVENT_NAMES.NOW_PLAYING.FAILED_USER_PLAYS.ADD,
                                      data: getFailedUserPlaysItem(context)
                                    };
                                  })
                                ]
                              }
                            }
                          },
                          [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITH_MIDDLE_ADS
                            .NONE_ADS.ADD_TO_RECENTLY_PLAYED]: {
                            entry: [
                              sendParent(
                                ({
                                  buffer,
                                  streamSource,
                                  streamRef,
                                  related,
                                  selfies,
                                  ...context
                                }) => {
                                  const data = context.parent ? context.parent : context;

                                  return {
                                    type: EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.ADD,
                                    data
                                  };
                                }
                              )
                            ]
                          }
                        }
                      }
                    }
                  },
                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS.INDEX]: {
                    initial:
                      STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                        .WAITING,
                    states: {
                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                        .WAITING]: {
                        after: {
                          [ms('20secs')]: {
                            target:
                              STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                                .ADS_CHECK
                          }
                        }
                      },
                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                        .ADS_CHECK]: {
                        invoke: {
                          src: adCheckBreak,
                          onDone: {
                            target:
                              STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                                .USER_PLAYS,
                            actions: [
                              sendParent((_, event) => {
                                return {
                                  type: EVENT_NAMES.NOW_PLAYING.ACTIVE.SET_ADS,
                                  items: event.data
                                };
                              })
                            ]
                          },
                          onError: {
                            target:
                              STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                                .USER_PLAYS
                          }
                        }
                      },

                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                        .USER_PLAYS]: {
                        invoke: {
                          src(context) {
                            return userPlays(getFailedUserPlaysItem(context));
                          },
                          onDone: {
                            target:
                              STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                                .ADD_TO_RECENTLY_PLAYED
                          },
                          onError: {
                            target:
                              STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                                .ADD_TO_RECENTLY_PLAYED,
                            actions: [
                              sendParent(context => {
                                return {
                                  type: EVENT_NAMES.NOW_PLAYING.FAILED_USER_PLAYS.ADD,
                                  data: getFailedUserPlaysItem(context)
                                };
                              })
                            ]
                          }
                        }
                      },
                      [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.EVENTING.WITHOUT_MIDDLE_ADS
                        .ADD_TO_RECENTLY_PLAYED]: {
                        entry: [
                          sendParent(
                            ({ buffer, streamSource, streamRef, related, selfies, ...context }) => {
                              const data = context.parent ? context.parent : context;

                              return {
                                type: EVENT_NAMES.NOW_PLAYING.RECENTLY_PLAYED.ADD,
                                data
                              };
                            }
                          )
                        ]
                      }
                    }
                  }
                }
              },

              [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX]: {
                id: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.INDEX,
                entry: [
                  sendParent(EVENT_NAMES.NOW_PLAYING.GET_VOLUME),
                  ({ title }) => {
                    ga().event({
                      category: EVENTS.NOW_PLAYING.CATEGORY,
                      action: EVENTS.NOW_PLAYING.ACTIONS.PLAYING,
                      label: title
                    });
                  }
                ],
                initial: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING,
                on: {
                  [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK]: {
                    actions: [forwardTo('streamRef')]
                  },
                  [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_FORWARD]: {
                    actions: [forwardTo('streamRef')]
                  },
                  [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_BACKWARD]: {
                    actions: [forwardTo('streamRef')]
                  },
                  [EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME]: {
                    actions: [forwardTo('streamRef')]
                  }
                },
                states: {
                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.BUFFERING]: {
                    on: {
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAYING]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED,
                        actions: [send('pause', { to: 'streamRef' })]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START]: {
                        actions: [forwardTo('streamRef')]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY]: {
                        target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                        actions: [send('pause', { to: 'streamRef' }), 'setLastPlayedCurrentTime']
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.START_MIDDLE_ADS]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED,
                        actions: [
                          send('pause', { to: 'streamRef' }),
                          sendParent({
                            type: EVENT_NAMES.NOW_PLAYING.ACTIVE.START_MIDDLE_ADS
                          })
                        ]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.NEXT]: [
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          cond: (_, event) => {
                            return guards.hasUserReachedSkipLimit() && !event.finished;
                          }
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            send('pause', { to: 'streamRef' }),
                            sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT),
                            'setLastPlayedCurrentTime'
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS]: [
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          cond: 'hasUserReachedSkipLimit'
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            send('pause', { to: 'streamRef' }),
                            sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS),
                            'setLastPlayedCurrentTime'
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_CURRENT_TIME]: {
                        actions: [forwardTo('streamRef')]
                      }
                    }
                  },

                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING]: {
                    entry: [
                      sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PLAY),
                      pure(context => {
                        if (isMp3(context)) {
                          return [];
                        }

                        return [sendParent(EVENT_NAMES.NOW_PLAYING.HIDE_FULL_SCREEN_LYRICS)];
                      })
                    ],
                    exit: ['resetSilent'],
                    on: {
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED,
                        actions: [send('pause', { to: 'streamRef' })]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PAUSED_EXTERNALLY]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.BUFFERING]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.BUFFERING
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START]: {
                        actions: [
                          send('pause', { to: 'streamRef' }),
                          forwardTo('streamRef', { delay: 300 }),
                          send('play', { to: 'streamRef' })
                        ]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY]: {
                        target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                        actions: [send('pause', { to: 'streamRef' }), 'setLastPlayedCurrentTime']
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.START_MIDDLE_ADS]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED,
                        actions: [
                          send('pause', { to: 'streamRef' }),
                          sendParent({
                            type: EVENT_NAMES.NOW_PLAYING.ACTIVE.START_MIDDLE_ADS
                          }),
                          send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.RESET_ADS_CHECKING_STATE)
                        ]
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.NEXT]: [
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          cond: (_, event) => {
                            return guards.hasUserReachedSkipLimit() && !event.finished;
                          }
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            send('pause', { to: 'streamRef' }),
                            sendParent((_, event) => {
                              return {
                                type: EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT,
                                finished: event.finished
                              };
                            }),
                            'setLastPlayedCurrentTime'
                          ],
                          cond: (_, { finished }) => !finished
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            sendParent((_, event) => {
                              return {
                                type: EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT,
                                finished: event.finished
                              };
                            }),
                            'removeLastPlayedCurrentTimePodcast',
                            'removeLastPlayedCurrentTime'
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS]: [
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          cond: 'hasUserReachedSkipLimit'
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            send('pause', { to: 'streamRef' }),
                            sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS),
                            'setLastPlayedCurrentTime'
                          ]
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_CURRENT_TIME]: {
                        actions: [forwardTo('streamRef')]
                      }
                    }
                  },

                  [STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PAUSED]: {
                    entry: [sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PAUSE)],
                    exit: ['resetSilent'],
                    on: {
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.TOGGLE_PLAY_PAUSE]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING,
                        actions: [send('play', { to: 'streamRef' })],
                        cond: 'isNotPlayingMiddleAds'
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAYED_EXTERNALLY]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING,
                        cond: 'isNotPlayingMiddleAds'
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING,
                        actions: [forwardTo('streamRef'), send('play', { to: 'streamRef' })],
                        cond: 'isNotPlayingMiddleAds'
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.DESTROY]: {
                        target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                        actions: ['setLastPlayedCurrentTime']
                      },

                      [EVENT_NAMES.NOW_PLAYING_ITEM.NEXT]: [
                        {
                          actions: ['toggleNotifyUserToSubscribeBecauseReachedSkipLimits'],
                          cond: (_, event) => {
                            return guards.hasUserReachedSkipLimit() && !event.finished;
                          }
                        },
                        {
                          target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                          actions: [
                            sendParent((_, event) => {
                              return {
                                type: EVENT_NAMES.NOW_PLAYING.ACTIVE.NEXT,
                                finished: event.finished
                              };
                            }),
                            'setLastPlayedCurrentTime'
                          ],
                          cond: 'isNotPlayingMiddleAds'
                        }
                      ],
                      [EVENT_NAMES.NOW_PLAYING_ITEM.PREVIOUS]: {
                        target: `#${MACHINE_NAMES.NOW_PLAYING_ITEM}.${STATES.NOW_PLAYING_ITEM.DESTROYING}`,
                        actions: [
                          sendParent(EVENT_NAMES.NOW_PLAYING.ACTIVE.PREVIOUS),
                          'setLastPlayedCurrentTime'
                        ],
                        cond: 'isNotPlayingMiddleAds'
                      },
                      [EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.SEEK_CURRENT_TIME]: {
                        target: STATES.NOW_PLAYING_ITEM.ACTIVE.INTERACTING.MEDIA.PLAYING,
                        actions: [
                          forwardTo('streamRef'),
                          send('play', { to: 'streamRef', delay: 30 })
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },

      [STATES.NOW_PLAYING_ITEM.DESTROYING]: {
        after: {
          [ms('5min')]: [
            /**
             * Re enter to this state to check the condition after 5mins.
             */
            {
              target: STATES.NOW_PLAYING_ITEM.DESTROYING,
              cond: 'isInQueue'
            },
            {
              target: STATES.NOW_PLAYING_ITEM.DONE
            }
          ]
        },
        on: {
          [EVENT_NAMES.NOW_PLAYING_ITEM.START]: [
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
              actions: [
                send('play', { to: 'streamRef' }),
                send(EVENT_NAMES.NOW_PLAYING_ITEM.PLAY_FROM_LAST_CURRENT_TIME, {
                  to: 'streamRef'
                })
              ],
              // play from last current time
              cond: ({ streamRef, ...context }) => streamRef && isPodcast(context)
            },
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX,
              actions: [
                send(EVENT_NAMES.NOW_PLAYING_ITEM.ACTIVE.PLAY_FROM_START, { to: 'streamRef' }),
                send('play', { to: 'streamRef' })
              ],
              cond: ({ streamRef }) => streamRef
            },
            {
              target: STATES.NOW_PLAYING_ITEM.ACTIVE.INDEX
            }
          ]
        }
      },

      [STATES.NOW_PLAYING_ITEM.DONE]: {
        type: 'final',
        entry: [
          sendParent(context => {
            return {
              type: EVENT_NAMES.NOW_PLAYING.REMOVE_ITEM,
              value: context
            };
          })
        ]
      }
    }
  },
  { actions, guards }
);
