import ms from 'ms';

let __singletonInstance;
class LibraryBlobStorageWithWorker {
  constructor() {
    this.worker = null;
    this.listener = {};

    this.afterDestroy = this.afterDestroy.bind(this);
  }

  init() {
    const options = {
      type: 'module'
    };

    return new Promise(resolve => {
      this.worker = new Worker(
        new URL('@workers/storages/library-blob-storage.worker.js', import.meta.url),
        options
      );

      resolve();
    });
  }

  sendCommand(command, ...args) {
    return new Promise((resolve, reject) => {
      const channelID = Math.random();

      const params = {
        command,
        args,
        channelID
      };

      this.worker?.postMessage(params);

      const cb = e => {
        const { done, result } = e.data;

        if (e?.data?.channelID === channelID) {
          if (done) {
            resolve(result);
          } else {
            reject(result);
          }

          this.worker?.removeEventListener('message', cb);
        }
      };

      this.worker.addEventListener('message', cb);

      setTimeout(() => {
        reject();
        this.worker?.removeEventListener('message', cb);
      }, ms('1m'));
    });
  }

  has(id) {
    return this.sendCommand('has', id);
  }

  add(params, id) {
    return this.sendCommand('pureAdd', params, id);
  }

  remove(params, id) {
    return this.sendCommand('remove', params, id);
  }

  clear() {
    return this.sendCommand('clear');
  }

  find(params, id) {
    return this.sendCommand('find', params, id);
  }

  async destroy() {
    try {
      await this.sendCommand('destroy');
    } catch (e) {
      console.log(e);
    } finally {
      this.afterDestroy();
    }
  }

  afterDestroy() {
    this.worker?.terminate();

    this.worker = null;
    __singletonInstance = null;

    console.log('LibraryBlobStorageWithWorker worker terminated...');
  }
}

export const getLibraryBlobStorage = () => {
  if (!__singletonInstance) {
    __singletonInstance = new LibraryBlobStorageWithWorker();
    __singletonInstance.init();
  }
  return __singletonInstance;
};
