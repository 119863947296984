import { createMachine, assign } from 'xstate';

function getOnlineStatus() {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
}

const networkCallback = sendParent => {
  const goOnline = () => sendParent({ type: 'ON_NETWORK_CHANGE', value: getOnlineStatus() });
  const goOffline = () => sendParent({ type: 'ON_NETWORK_CHANGE', value: getOnlineStatus() });

  sendParent({ type: 'ON_NETWORK_CHANGE', value: getOnlineStatus() });

  window.addEventListener('online', goOnline);
  window.addEventListener('offline', goOffline);

  return () => {
    window.removeEventListener('online', goOnline);
    window.removeEventListener('offline', goOffline);
  };
};

export const networkMachine = createMachine({
  id: 'network-machine',
  context: {
    state: true
  },
  invoke: {
    src: () => networkCallback
  },
  on: {
    ON_NETWORK_CHANGE: {
      actions: [
        assign({
          state(_, event) {
            return event.value;
          }
        })
      ]
    }
  }
});
