import { PAGES } from '@shared/constants';
import { CHARACTERS } from '@constants/app';
import { APP_NAME_FA, APP_DESCRIPTION } from '@constants/head';

import { isPlaylistCreatedByRadioJavan } from '@functions/is';
import { getIS8601Duration, getISOStringDate } from '@functions/date';
import { getAlbumName, getArtistName } from '@functions/get';
import {
  getWebPlayerLink,
  getWebPlayerArtistStaticLink,
  getAlternateLinkAutoPlay,
  getAlbumLink,
  getCreatorLink
} from '@functions/get-link';
import { hasAlbum } from '@functions/has';

export function getPotentialAction({ data }) {
  return {
    '@type': 'ListenAction',
    target: [
      {
        '@type': 'EntryPoint',
        urlTemplate: getWebPlayerLink(data),
        actionPlatform: [
          'http://schema.org/DesktopWebPlatform',
          'http://schema.org/IOSPlatform',
          'http://schema.googleapis.com/GoogleAudioCast',
          'http://schema.googleapis.com/GoogleVideoCast'
        ]
      },
      getAlternateLinkAutoPlay(data)
    ],
    expectsAcceptanceOf: {
      '@type': 'Offer',
      category: 'free',
      eligibleRegion: []
    }
  };
}

function getCreatingDate({ data }) {
  const json = {};

  if (data.created_at) {
    json.datePublished = getISOStringDate(data.created_at);
  }

  if (data.last_updated_at) {
    json.dateModified = getISOStringDate(data.last_updated_at);
  }

  return json;
}

export function getMusicGroupJsonByArtistJson({ data }) {
  const name = getArtistName(data);

  return {
    '@type': 'MusicGroup',
    url: getWebPlayerArtistStaticLink(getArtistName(data)),
    name,
    description: `Listen to ${name} on ${process.env.APP_NAME}.`
  };
}

export function getMusicAlbum({ data, addAlbumTracks = true }) {
  const albumName = getAlbumName(data);
  const artistName = getArtistName(data);

  const json = {
    '@type': 'MusicAlbum',
    '@id': `${getWebPlayerLink(getAlbumLink(data))}#MusicAlbum`,
    image: data.photo,
    url: getWebPlayerLink(getAlbumLink(data)),
    name: getAlbumName(data),
    byArtist: getMusicGroupJsonByArtistJson({ data }),
    description: `Download and listen to ${albumName} album by ${artistName} on ${process.env.APP_NAME}.`,
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    ...getCreatingDate({ data })
  };

  if (data?.album_tracks?.length) {
    json.numTracks = data?.album_tracks?.length;

    if (addAlbumTracks) {
      json.tracks = data?.album_tracks.slice(0, 50).map(item => {
        // eslint-disable-next-line no-use-before-define
        return getMusicRecordingJson({ data: item, addPotentialAction: false });
      });
    }
  }

  return json;
}

export function getMusicRecordingJson({
  data,
  name: nameProp,
  headline,
  description,
  keywords,
  addPotentialAction = true
}) {
  const name =
    nameProp || [data.song, data.song_farsi].filter(Boolean).join(` ${CHARACTERS.HEAD} `);

  const json = {
    '@type': 'MusicRecording',
    '@id': getWebPlayerLink(data),
    url: getWebPlayerLink(data),
    name,
    headline,
    description,
    author: {
      name: `${process.env.APP_NAME} ${CHARACTERS.HEAD} ${APP_NAME_FA}`,
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/author/radiojavan`
    },
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    image: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    isPartOf: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    audio: {
      '@type': 'AudioObject'
    },
    offers: {
      '@type': 'Offer',
      category: 'free',
      price: 0
    },
    producer: {
      '@type': 'Person',
      name: getArtistName(data)
    },
    recordingOf: {
      '@id': `${getWebPlayerLink(data)}#MusicComposition`
    },
    byArtist: {
      ...getMusicGroupJsonByArtistJson({
        data
      })
    },
    inLanguage: 'fa-IR',
    duration: getIS8601Duration(data.duration),
    keywords,
    ...getCreatingDate({ data })
  };

  if (hasAlbum(data)) {
    json.inAlbum = {
      '@id': `${getWebPlayerLink(getAlbumLink(data))}#MusicAlbum`
    };
  }

  if (addPotentialAction) {
    json.potentialAction = getPotentialAction({ data });
  }

  return json;
}

export function getMusicPlaylistJson({ data, description }) {
  const json = {
    '@type': 'MusicPlaylist',
    '@id': getWebPlayerLink(data),
    url: getWebPlayerLink(data),
    name: data.title,
    description: description || data.desc,
    numTracks: data.items?.length,
    isPartOf: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    image: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    potentialAction: getPotentialAction({ data }),
    track: data?.items?.slice(0, 50).map(item => {
      return getMusicRecordingJson({ data: item, addPotentialAction: false });
    }),
    ...getCreatingDate({ data })
  };

  if (isPlaylistCreatedByRadioJavan(data)) {
    json.author = {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/author/radiojavan`
    };
  } else {
    json.author = {
      '@type': 'Person',
      name: data.created_title,
      url: getCreatorLink(data)
    };
  }

  return json;
}

export function getMusicComposition({ data, name, headline, description, keywords }) {
  return {
    '@type': 'MusicComposition',
    '@id': `${getWebPlayerLink(data)}#MusicComposition`,
    name,
    headline,
    description,
    keywords,
    isPartOf: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    author: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/author/radiojavan`
    },
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    image: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    thumbnailUrl: data.thumbnail || data.photo,
    isFamilyFriendly: true,
    inLanguage: 'fa-IR',
    composer: [],
    lyricist: [],
    lyrics: data.lyric
      ? [
          {
            '@type': 'CreativeWork',
            name: headline,
            inLanguage: 'fa-IR',
            text: data.lyric
          }
        ]
      : [],
    potentialAction: [
      {
        '@type': 'CommentAction',
        name: 'Comment',
        target: [`${getWebPlayerLink(data)}/#respond`]
      }
    ],
    ...getCreatingDate({ data })
  };
}

export function getWebsiteJson() {
  return {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    '@id': `${PAGES.WEB_PLAYER_INDEX}/#website`,
    name: process.env.APP_NAME,
    url: PAGES.WEB_PLAYER_INDEX,
    description: APP_DESCRIPTION,
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${PAGES.WEB_PLAYER_INDEX}/search/{search_term_string}`
        },
        'query-input': 'required name=search_term_string'
      }
    ]
  };
}

export function getRadioJavanOrganizationJson() {
  return {
    '@type': 'Organization',
    '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`,
    name: process.env.APP_NAME,
    url: PAGES.WEB_PLAYER_INDEX,
    description: APP_DESCRIPTION,
    sameAs: [
      PAGES.RJ_TWITTER,
      PAGES.RJ_INSTAGRAM,
      PAGES.RJ_TIKTOK,
      PAGES.RJ_FB,
      PAGES.RJ_YOU_TUBE,
      PAGES.RJ_TELEGRAM
    ],
    logo: {
      '@type': 'ImageObject',
      '@id': `${PAGES.WEB_PLAYER_INDEX}#/schema/logo/image/`,
      url: `${PAGES.WEB_PLAYER_INDEX}/images/radiojavan.png`,
      contentUrl: `${PAGES.WEB_PLAYER_INDEX}/images/radiojavan.png`,
      width: 500,
      height: 500
    },
    image: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}#/schema/logo/image/`
    }
  };
}

export function getImageObjectJson({ data, caption, width = 500, height = 500 }) {
  return {
    '@type': 'ImageObject',
    '@id': `${getWebPlayerLink(data)}/#primaryimage`,
    url: data.photo,
    contentUrl: data.photo,
    inLanguage: 'fa-IR',
    width,
    height,
    caption
  };
}

export function getBreadcrumbListJson({ data, name }) {
  return {
    '@type': 'BreadcrumbList',
    '@id': `${getWebPlayerLink(data)}/#breadcrumb`,
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: process.env.APP_NAME,
        item: PAGES.WEB_PLAYER_INDEX
      },
      {
        '@type': 'ListItem',
        position: 2,
        name
      }
    ]
  };
}

export function getWebPageJson({ data, name, description }) {
  const json = {
    '@type': 'WebPage',
    '@id': getWebPlayerLink(data),
    url: getWebPlayerLink(data),
    name,
    isPartOf: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    primaryImageOfPage: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    image: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    thumbnailUrl: data.photo,
    description,
    breadcrumb: {
      '@id': `${getWebPlayerLink(data)}/#breadcrumb`
    },
    inLanguage: 'fa-IR',
    potentialAction: [
      {
        '@type': 'ReadAction',
        target: [getWebPlayerLink(data)]
      }
    ],
    ...getCreatingDate({ data })
  };

  return json;
}

export function getRadioJavanPersonJson() {
  return {
    '@type': 'Person',
    '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/author/radiojavan`,
    name: `${process.env.APP_NAME} ${CHARACTERS.HEAD} ${APP_NAME_FA}`,
    url: `${PAGES.WEB_PLAYER_INDEX}/artist/radio+javan`,
    image: {
      '@type': 'ImageObject',
      inLanguage: 'fa-IR',
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/person/image/`,
      url: 'https://play.radiojavan.com/images/radiojavan.png',
      contentUrl: 'https://play.radiojavan.com/images/radiojavan.png',
      caption: `${process.env.APP_NAME} ${CHARACTERS.HEAD} ${APP_NAME_FA}`
    },
    sameAs: [
      PAGES.RJ_TWITTER,
      PAGES.RJ_INSTAGRAM,
      PAGES.RJ_TIKTOK,
      PAGES.RJ_FB,
      PAGES.RJ_YOU_TUBE,
      PAGES.RJ_TELEGRAM
    ]
  };
}

export function getArticleJson({ data, name, headline, description, keywords }) {
  return {
    '@type': 'Article',
    '@id': `${getWebPlayerLink(data)}/#article`,
    name,
    isPartOf: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    author: {
      name: `${process.env.APP_NAME} ${CHARACTERS.HEAD} ${APP_NAME_FA}`,
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#/schema/author/radiojavan`
    },
    headline,
    description,
    mainEntityOfPage: {
      '@id': getWebPlayerLink(data)
    },
    wordCount: 30,
    commentCount: 0,
    publisher: {
      '@id': `${PAGES.WEB_PLAYER_INDEX}/#organization`
    },
    image: {
      '@id': `${getWebPlayerLink(data)}/#primaryimage`
    },
    thumbnailUrl: data.thumbnail || data.photo,
    keywords,
    articleSection: ['آهنگ', 'موزیک', 'music', 'song', 'lyric'],
    inLanguage: 'fa-IR',
    potentialAction: [
      {
        '@type': 'CommentAction',
        name: 'Comment',
        target: [`${getWebPlayerLink(data)}/#respond`]
      }
    ],
    ...getCreatingDate({ data })
  };
}

export function getFaqJson() {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'I already have an RJ account, can I signup for RJ Premium?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, RJ premium is a subscription add-on to your RJ account'
        }
      },
      {
        '@type': 'Question',
        name: 'Can I cancel at any time?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Yes, RJ Premium is a month-to-month service with no contract, or if you may try the yearly service option at discounted price.'
        }
      },
      {
        '@type': 'Question',
        name: 'How do I signup for RJ Premium?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'You can get RJ Premium either through our website or directly through our iOS app.'
        }
      },
      {
        '@type': 'Question',
        name: 'Will I keep seeing ads?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "No, RJ Premium will remove ads from the RJ app and website. However, ads on <a href='https://play.radiojavan.com/tv'>RJTV</a> or live radio will continue as those are different services."
        }
      }
    ]
  };
}
